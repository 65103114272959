<template>
  <popup v-if="visible" :visible="visible" size="md" ref="popup" @hide="closePopup">
    <popup-title v-if="status === 'init' || status === 'error'">
      <!-- temp to test states -->
      <!-- <button @click="testChangeState()" style="cursor: pointer; margin: 0 20px">State: {{ status }}</button> -->
      <popup-button-close @hide="closePopup($event)" />
    </popup-title>
    <popup-body class="text-center pc-popup-habits-edit">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitForm)">
          <popup-content>
            <popup-head>
              <popup-text-heading
                v-if="status === 'init'"
                class="intruduction-popup__header"
              >
                <p>{{ $t("message['habit-tracker.popup-edit-title']") }}</p>
              </popup-text-heading>
            </popup-head>
            <div v-if="status === 'loading'" class="pc-popup-update-habit-data">
              <TextHeading4 :weight="'extra-bold'">
                {{ $t('message[ "habit-tracker.pop-up-edit-text-editing"]') }}
              </TextHeading4>
              <loading-buffer :height="'75'"></loading-buffer>
            </div>
            <div
              v-if="status === 'success'"
              class="pc-popup-update-habit-data pc-popup-update-habit-data--success"
            >
              <TextHeading4 :weight="'extra-bold'">
                {{ $t('message[ "habit-tracker.pop-up-edit-text-edited"]') }}
              </TextHeading4>
              <icon-complete-filled :size="'lg3'" />
            </div>
            <div v-if="status === 'init'">
              <validation-provider
                name="Habit"
                rules="required|min:5|max:50"
                v-slot="{ errors }"
              >
                <text-area
                  :rows="2"
                  :error-message="errors[0]"
                  :placeholder="'Type here...'"
                  @change="handleInput"
                  v-model="formData.habit"
                  value="passedHabit.name "
                />
                <text-body-extra-small class="pc-popup-habits-add__text-limit"
                  >{{ formData.habit.length }}/50</text-body-extra-small
                >
              </validation-provider>
            </div>
            <div
              v-if="generalErrors.length > 0"
              class="info-modal__general-error"
            >
              <div
                class="ma-form__message ma-form__message--error"
                v-for="(error, eid) in generalErrors"
                :key="'fd-general-errors' + eid"
                v-html="error.toString()"
              ></div>
            </div>
          </popup-content>
          <transition>
            <popup-footer v-if="status === 'init'">
              <!-- button change text -->
              <button-primary
                v-if="formData.habit !== passedHabit.name"
                class="pc-form-workout-log__button-submit"
                type="submit"
                :disabled="status=== 'loading'"
                :text="$t('message[\'habit-tracker.popup-edit-save-button\']')"
              />
              <button-primary
                v-else-if="formData.habit === passedHabit.name"
                :disabled="true"
                class="pc-form-workout-log__button-submit"
                type="button"
                :text="$t('message[\'habit-tracker.popup-edit-save-button\']')"
              />
            </popup-footer>
          </transition>

        </form>
      </validation-observer>
    </popup-body>
  </popup>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Popup from "../../../../global/popups/Popup";
import PopupTitle from "../../../../global/popups/popup-sub-components/PopupTitle";
import PopupButtonClose from "../../../../global/popups/popup-sub-components/PopupButtonClose";
import PopupBody from "../../../../global/popups/popup-sub-components/PopupBody";
import PopupContent from "../../../../global/popups/popup-sub-components/PopupContent";
import PopupHead from "../../../../global/popups/popup-sub-components/PopupHead";
import PopupTextHeading from "../../../../global/popups/popup-sub-components/PopupTextHeading";
import popupAnimationMixin from "../../../../../mixins/popupAnimationMixin";
import PopupTextBody from "../../../../global/popups/popup-sub-components/PopupTextBody";
import IconCompletedLinear from "../../../../root/icons/IconCompletedLinear";
import IconArrowCircularLinear from "../../../../root/icons/IconArrowCircularLinear";
import IconIncompletedLinear from "../../../../root/icons/IconIncompletedLinear";
import ButtonPrimary from "@/components/global/buttons/ButtonPrimary.vue";
import IconCompleteFilled from "@/components/root/icons/IconCompleteFilled.vue";
import TextArea from "@/components/global/inputs/TextArea.vue";
import PopupFooter from "@/components/global/popups/popup-sub-components/PopupFooter.vue";
import LoadingBuffer from "../../global/widgets/LoadingBuffer.vue";
import HabitsTrackerService from "@/services/habits-tracker/HabitsTrackerService";
import Habit from "@/services/habits-tracker/Habit";
import { AxiosError } from "axios";
import TextBodyExtraSmall from "@/components/global/typography/TextBodyExtraSmall";
import TextHeading4 from "../../../../global/typography/TextHeading4";

export default {
  name: "PopupHabitsEdit",
  components: {
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupFooter,
    PopupTextBody,
    IconCompletedLinear,
    IconArrowCircularLinear,
    IconIncompletedLinear,
    ButtonPrimary,
    IconCompleteFilled,
    TextArea,
    ValidationProvider,
    ValidationObserver,
    LoadingBuffer,
    TextBodyExtraSmall,
    TextHeading4,
  },
  mixins: [popupAnimationMixin],
  props: {
    habitsList: { type: Array, required: true },
    visible: { default: false, type: Boolean },
    passedHabit: { type: Habit, required: true },
    onEditCb: { type: Function },
  },
  data: function () {
    return {
      /** init, loading, success, error */
      status: "init",
      hasError: false,
      generalErrors: [],
      errors: [],
      formData: {
        habit: "",
      },
    };
  },
  watch: {},
  beforeMount() {
    this.formData.habit = this.passedHabit.name;
  },
  mounted() {
    this.addEventListener()
  },
  beforeDestroy () {
    this.removeEventListener()
  },
  computed: {
    existingHabits() {
      return this.habitsList.map((habit) => habit.name);
    },
  },
  methods: {
    addEventListener () {
      if (this.enableVisualViewport && window.visualViewport) {
        visualViewport.addEventListener('resize', this.setContentHeight)
      } else {
        window.addEventListener('resize', this.setContentHeight)
      }
    },
    removeEventListener () {
      if (this.enableVisualViewport && window.visualViewport) {
        visualViewport.removeEventListener('resize', this.setContentHeight)
      } else {
        window.removeEventListener('resize', this.setContentHeight)
      }
    },

    handleInput() {
      console.log("handleInput ======");
    },
    closePopup(e) {
      this.closeAnimatedPopup(() => {
        this.$emit("close");
        // backdrop close event fire
        if (e && e.trigger === "backdrop") {
          this.submitCloseEvent("HabitsTracker", "background");
        }
      });
    },
    submitForm() {
      if( this.status === "loading"){
        return
      }
      console.log("Submit form");
      this.status = "loading";
      console.log("Submit form editing", this.existingHabits);

      this.status = "loading";
      // internal simple validation fot the same habit name
      if (
        this.existingHabits.some(
          (habitName) => habitName == this.formData.habit.trim()
        )
      ) {
        // this.status = 'error'
        this.status = "init";
        this.generalErrors = [
          "This habit already exist. Please try a different name",
        ];
        return;
      }
      // reset local errors
      this.generalErrors = [];

      const api = new HabitsTrackerService();

      api.updateHabit(this.passedHabit.id, this.formData.habit.trim()).then( (newHabit) => {
        this.status = 'success'
        this.$emit('onEditCb', newHabit)
      }).catch(err => {
        console.error(err);
        this.status = 'error'
        const errorMessage = err instanceof AxiosError ? err.response?.data.message : err.message;
        this.generalErrors = [errorMessage];
      }).finally(() => {
        this.hideLoading();
        this.submited = false;
        if (this.status === 'error') return;
        setTimeout(() => {
          this.closePopup()
        }, 1000)
      })

      return;
    },
  },
};
</script>
