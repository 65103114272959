<template>
  <div>
    <div
      class="pc-habit-tracker-popup-options-menu options-menu"
      v-on:click="this.closePopup"
      v-bind:style="position"
    >
      <div
        v-if="this.habit.habitSource !== 'creator'"
        class="pc-habit-tracker-popup-options-menu__icon"
        @click="handleEdit"
      >
        <IconEdit />
        <TextBodySmall>
          {{
            $t('message["habit-tracker.pop-up-edit-button-text"]')
          }}</TextBodySmall
        >
      </div>
      <div
        class="pc-habit-tracker-popup-options-menu__icon"
        @click="handleDelete"
      >
        <IconDelete />
        <TextBodySmall>
          {{
            $t('message["habit-tracker.pop-up-delete-button-text"]')
          }}</TextBodySmall
        >
      </div>
    </div>
    <div
      class="pc-habit-tracker-popup-options-menu-overlay overlay"
      @click="this.closePopup"
      @touchstart="closePopup($event)"
    ></div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Popup from "../../../../global/popups/Popup";
import PopupTitle from "../../../../global/popups/popup-sub-components/PopupTitle";
import PopupButtonClose from "../../../../global/popups/popup-sub-components/PopupButtonClose";
import PopupBody from "../../../../global/popups/popup-sub-components/PopupBody";
import PopupContent from "../../../../global/popups/popup-sub-components/PopupContent";
import PopupHead from "../../../../global/popups/popup-sub-components/PopupHead";
import PopupTextHeading from "../../../../global/popups/popup-sub-components/PopupTextHeading";
import popupAnimationMixin from "../../../../../mixins/popupAnimationMixin";
import PopupTextBody from "../../../../global/popups/popup-sub-components/PopupTextBody";
import ButtonPrimary from "@/components/global/buttons/ButtonPrimary.vue";
import TextArea from "@/components/global/inputs/TextArea.vue";
import PopupFooter from "@/components/global/popups/popup-sub-components/PopupFooter.vue";
import LoadingBuffer from "../../global/widgets/LoadingBuffer.vue";
import IconEdit from "@/components/root/icons/IconEdit";
import IconDelete from "@/components/root/icons/IconDelete";
import TextBodySmall from "@/components/global/typography/TextBodySmall";

export default {
  name: "PopupHabitOptionsMenu",
  components: {
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupFooter,
    PopupTextBody,
    ButtonPrimary,
    TextArea,
    ValidationProvider,
    ValidationObserver,
    LoadingBuffer,
    IconEdit,
    IconDelete,
    TextBodySmall,
  },
  mixins: [popupAnimationMixin],
  props: {
    habit: { required: true, type: Object },
    parent: { required: true, type: HTMLElement },
  },
  data: function () {
    return {
      menuWidth: 80,
    };
  },
  watch: {},
  beforeMount() {},
  mounted() {},
  computed: {
    position() {
      return {
        top: this.parent.offsetTop + this.parent.offsetHeight + "px",
        left: this.parent.offsetLeft - this.menuWidth - 36 + "px",
        width: this.menuWidth + "px",
      };
    },
  },
  methods: {
    closePopup(e) {
      // a short delay for a smoother transition, adjust for animations
      setTimeout(() => {
        this.$emit("onClose");
        if (e && e.trigger === "backdrop") {
          this.submitCloseEvent("HabitsTracker", "background");
        }
      }, 250);
    },
    handleDelete() {
      this.$emit("onDelete", this.habit.id);
    },
    handleEdit() {
      this.$emit("onEdit", this.habit);
    },
  },
};
</script>

<style scoped></style>
