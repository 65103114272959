import WorkoutProgramDayService from './WorkoutProgramDayService'
import WorkoutProgramExerciseService from './WorkoutProgramExerciseService'

export default class WorkoutProgramWeekService {
  id = 0
  programID = 0
  name = ''
  tileImage = ''
  backgroundImage = ''
  /**
   *
   * @type {[WorkoutProgramDayService]}
   */
  days = []
  completed = false

  constructor (week) {
    this.id = week.id
    this.name = week.name
    this.programID = week.programID
    this.tileImage = week.tileImage
    this.backgroundImage = week.backgroundImage
    this.completed = week.completed || false
    if (Array.isArray(week.days)) {
      week.days.forEach(day => {
        this.days.push(new WorkoutProgramDayService({
          ...day,
          'programID': this.programID,
          'weekID': this.id
        }))
      })
    } else if (typeof week.days === 'object') {
      Object.values(week.days).forEach(day => {
        this.days.push(new WorkoutProgramDayService({
          ...day,
          'programID': this.programID,
          'weekID': this.id
        }))
      })
    } else {
      throw new Error('invalid workout days type')
    }
  }

  getID () {
    return this.id
  }

  getName () {
    return this.name
  }

  getTileImage () {
    return this.tileImage || null
  }

  getIsCompleted () {
    return this.completed
  }

  getBackgroundImage () {
    return this.backgroundImage || null
  }

  /**
   * returns days array
   * @returns {WorkoutProgramDayService[]}
   */
  getDays () {
    return this.days
  }

  /**
   *  returns a day based in day ID
   * @param dayID
   * @returns {WorkoutProgramDayService}
   */
  getDayByID (dayID) {
    return this.days.find(day => {
      return day.getID() === dayID
    })
  }

  /**
   * returns an exercises based in exercise ID
   * @param exerciseID
   * @returns {WorkoutProgramExerciseService|null}
   */
  getExerciseByID (exerciseID) {
    for (let day of this.days) {
      let exercise = day.getExercise(exerciseID)
      if (exercise && exercise instanceof WorkoutProgramExerciseService) {
        return exercise
      }
    }
    return null
  }

  /**
   * set week completed if all days are completed
   */
  setDayCompleteStateByDays () {
    if(this.completed){
      this.completed = this.days.every((exercise) => {
        return exercise.getIsCompleted()
      })
    }

  }
  setWeekIncomplete () {
    this.completed = false
  }

  setCompleteStateByDays () {
    this.setDayCompleteStateByDays()
  }

  /**
   * get the day array index
   * @param dayID
   */
  getDayIndex (dayID) {
    return this.days.findIndex((day) => {
      return day.getID() === dayID
    })
  }
}
