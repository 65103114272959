import Vue from 'vue'
import Vuex from 'vuex'
import ImageService from '../services/ImageService'
import LanguageService from '../services/LanguageService'
import instructionPopupStore from './modules/instructionPopupStore'
import ConfirmationPopupStore from './modules/ConfirmationPopupStore'
import pageStore from './modules/pageStore'
import ShoppingStore from './modules/shoppingStore'
import bannerStore from './modules/bannerStore'
import FormWizardStore from './modules/FormWizardStore'
import WorkoutlogStore from './modules/WorkoutlogStore'
import SingleExercisePageStore from './modules/SingleExerciseSetPageStore'
import WorkoutCircuitStore from './modules/WorkoutCircuitStore'
import MealSwapStore from './modules/MealSwapStore'
import ListLastExpandedStore from './modules/ListLastExpandedStore'
import FeedBackPopupStore from './modules/FeedBackPopupStore'
import FoodDiaryStore from './modules/FoodDiaryStore'
import PeriodTrackerStore from './modules/PeriodTrackerStore'
import ChatBoxStore from './modules/ChatBoxStore'
import FitnessDiaryStore from './modules/FitnessDiaryStore'
import SignUpStore from './modules/SignUpStore'
import featurePopupStore from './modules/featurePopupStore'
import DynamicContentPopupStore from './modules/DynamicContentPopupStore'
import CustomMealLogPopupStore from './modules/CustomMealLogPopupPopupStore'
import communityStore from './modules/CommunityStore'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    workoutLogStore: WorkoutlogStore,
    signUpStore: SignUpStore,
    foodDiaryStore: FoodDiaryStore,
    shoppingStore: ShoppingStore,
    formWizardStore: FormWizardStore,
    singleExercisePageStore: SingleExercisePageStore,
    instructionPopupStore: instructionPopupStore,
    confirmationPopupStore: ConfirmationPopupStore,
    pageStore: pageStore,
    workoutCircuitStore: WorkoutCircuitStore,
    bannerStore: bannerStore,
    mealSwapStore: MealSwapStore,
    listLastExpandedStore: ListLastExpandedStore,
    feedBackPopupStore: FeedBackPopupStore,
    periodTrackerStore: PeriodTrackerStore,
    chatBoxStore: ChatBoxStore,
    FitnessDiaryStore: FitnessDiaryStore,
    dynamicContentPopupStore: DynamicContentPopupStore,
    customMealLogPopupStore: CustomMealLogPopupStore,
    featurePopupStore: featurePopupStore,
    communityStore: communityStore
  },
  state: {
    notificationList: [],
    appLoaded: false,
    notFoundPopupShow: false,
    planExpiredPopup: {
      show: false,
      context: ''
    },
    routeTransition: {
      name: 'fade',
      mode: 'out-in'
    },
    loading: true,
    languageReference: {},
    token: '',
    notificationCount: 0,
    images: {},
    showServerError: false,
    serverErrorID: '',
    showNoConnectionError: false,
    previousRoute: { path: '/dashboard' },
    lastVisitPage: null,
    orientation: 'portrait',
    appMode: 'mobile',
    bottomNavVisibility: true,
    workoutFrom: 'dashboard', // from - dashboard, workout-week
    serverErrorPage: ''
  },
  mutations: {

    setLastVisitPage (state, payload) {
      state.lastVisitPage = payload
    },
    setWorkoutFrom (state, payload) {
      state.workoutFrom = payload
    },
    setRouteTransition (state, payload) {
      const defaults = { name: 'fade', mode: 'out-in' }
      state.routeTransition = Object.assign(defaults, payload)
    },
    showServerErrorPopup (state) {
      state.showServerError = true
    },
    showBottomNav (state) {
      state.bottomNavVisibility = true
    },
    hideBottomNav (state) {
      state.bottomNavVisibility = false
    },
    setServerErrorID (state, payload) {
      state.serverErrorID = payload
    },
    setServerErrorPage (state, payload) {
      state.serverErrorPage = payload
    },
    setAppMode (state, payload) {
      state.appMode = payload
    },
    unsetServerErrorID (state) {
      state.serverErrorID = ''
    },
    unsetServerErrorPage (state) {
      state.serverErrorPage = ''
    },
    hideServerErrorPopup (state) {
      state.showServerError = false
    },
    showNoConnectionErrorPopup (state) {
      state.showNoConnectionError = true
    },
    hideNoConnectionErrorPopup (state) {
      state.showNoConnectionError = false
    },
    showLoading (state, payload = {}) {
      // to show the tap effects
      let time = payload.time || 200
      setTimeout(() => {
        state.loading = true
      }, time)
    },
    showNotFoundPopupShow (state) {
      state.notFoundPopupShow = true
    },
    hideNotFoundPopupShow (state) {
      state.notFoundPopupShow = false
    },
    hideLoading (state, payload = {}) {
      // to show the tap effects
      let time = payload.time || 200
      setTimeout(() => {
        state.loading = false
      }, time)
    },
    showSubscriptionExpiredPopup (state, payload) {
      state.planExpiredPopup.context = payload
      state.planExpiredPopup.show = true
    },
    hideSubscriptionExpiredPopup (state) {
      state.planExpiredPopup = {
        context: '',
        show: false
      }
    },
    setApiToken (state, payload) {
      state.token = payload
      window.localStorage.setItem('access_token', state.token)
    },
    setPreviousRoute (state, payload) {
      state.previousRoute = payload
    },
    setNotificationCount (state, payload) {
      state.notificationCount = payload
    },
    setNotificationsList (state, payload) {
      state.notificationList = payload
    },
    initApiToken (state) {
      const savedToken = window.localStorage.getItem('access_token')
      if (savedToken !== '') {
        state.token = savedToken
      }
    },
    setLangReferences (state) {
      const lang = new LanguageService()
      lang.getAll().then(function (data) {
        state.languageReference = data
      })
    },
    setImages (state) {
      // existing image check
      const imagesCount = Object.keys(state.images).length

      if (imagesCount === 0) {
        let imgObj = new ImageService()
        imgObj.setImages().then(() => {
          state.images = imgObj.getImages()
        })
      }
    },
    setAppLoaded (state, payload) {
      state.appLoaded = payload
    },
    setOrientation (state, payload) {
      if (payload.height > payload.width) {
        state.orientation = 'portrait'
      } else {
        state.orientation = 'landscape'
      }
    }
  },
  getters: {
    getPlanExpiredPopupShow (state) {
      return state.planExpiredPopup.show
    },
    getRouteTransition (state) {
      return state.routeTransition
    },
    getBottomNavVisibility (state) {
      return state.bottomNavVisibility
    },
    getPlanExpiredPopupContext (state) {
      return state.planExpiredPopup.context
    },
    getApiToken (state) {
      return state.token
    },
    getServerErrorID (state) {
      return state.serverErrorID
    },
    getServerErrorPage (state) {
      return state.serverErrorPage
    },
    getPreviousRoute (state) {
      return state.previousRoute
    },
    getServerErrorPopupStatus (state) {
      return state.showServerError
    },
    getNoConnectionErrorPopup (state) {
      return state.showNoConnectionError
    },
    getNotificationCount (state) {
      return state.notificationCount
    },
    getLanguageReference (state) {
      return state.languageReference
    },
    getNotificationList (state) {
      return state.notificationList
    },
    getImages (state) {
      return state.images
    },
    getAppLoaded (state) {
      return state.appLoaded
    },
    getOrientation (state) {
      return state.orientation
    },
    getWorkoutFrom (state) {
      return state.workoutFrom
    },
    getLastVisitPage  (state) {
      return state.lastVisitPage
    },
    getLoading (state) {
      return state.loading
    },
    getAppMode (state) {
      return state.appMode
    }
  }
})

export default store
