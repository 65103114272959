<template>
  <page-secondary
    :header="headerOptions"
    class="pc-meal-plan-meal macroapp--page-mealplan-meal pc-content-recipe-details"
  >
    <grid-row class="pc-meal-plan-meal__row" no-gutters v-if="recipe">
      <grid-col class="pc-meal-plan-meal__col" col="12" lg="6">
        <card-meal-featured
          class="pc-meal-plan__card-featured"
          :imagesSrc="images"
          :macroNutrients="macroNutrients"
          :calories="calories"
        >
        </card-meal-featured>
      </grid-col>
      <grid-col class="pc-meal-plan-meal__col" col="12" lg="6">
        <div class="pc-meal-plan-meal__container pc-meal-plan-meal__container-recipe">
          <div class="pc-meal-plan-meal__types-wrapper" v-if="recipe.mealTypes.length">
            <div class="pc-meal-plan-meal__types">
              <badge-choice class="pc-meal-plan-meal__type" v-for="type in recipe.mealTypes" :key="type">
                <text-body-small>{{ type }}</text-body-small>
              </badge-choice>

            </div>
          </div>
          <page-container class="pc-meal-plan-meal__separator">
            <div class="pc-meal-plan-meal__separator-line"></div>
          </page-container>
          <div class="pc-meal-plan-meal__types-wrapper" v-if="recipe.tags.length">
            <div class="pc-meal-plan-meal__tags">
              <text-body-extra-small :line-height="'lh-lg'">
                {{ recipe.tags.join(' &#x2022; ').concat('&ensp;&ensp; ') }}
              </text-body-extra-small>
            </div>
          </div>
          <page-container style="padding-top: 0" class="pc-meal-plan-meal__recipe-details">
            <accordion-recipe-tile
              :image-src="images[0][0]"
              :text="recipe.name"
              :sub-text="$tc('message[\'meal-plan.make-serving\']',recipe.servings,{count:recipe.servings })"
              :sub-secondary-text="$t('message[\'meal-plan.meal-preparation-time\']',{time:preparationTime}) "
              :show-arrow="false"
            >
              <card-content>
                <card-text-header>{{
                    $tc('message["general.ingredients"]', 2)
                  }}
                </card-text-header>
                <text-body-extra-small v-html="recipe.ingredients"/>
              </card-content>
              <card-content>

                <card-text-header>{{
                    $t('message["general.instructions"]')
                  }}
                </card-text-header>
                <text-body-extra-small v-html="instructions"/>
              </card-content>
            </accordion-recipe-tile>
            <page-fixed-footer v-if="hasMealPlans">
              <button-primary :text="$t('message[\'custom-meal-log.add-to-food-diary\']')" @click="addToFoodDiary"/>
            </page-fixed-footer>

          </page-container>
        </div>
      </grid-col>
    </grid-row>
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import ContentPageFactoryService from '../../../../services/ContentPageFactoryService'
import ContentContainer from './widgets/ContentContainer'
import {mapMutations} from 'vuex'
import PageContentImage from '../../../global/pages/page-sub-components/PageContentImage'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageContentText from './page-components/PageContentText'
import PageSecondary from '../../../global/pages/PageSecondary'
import {pageReadyEvent} from '@/helpers/dom/events/customEvents'
import GridCol from '@/components/global/grid/GridCol.vue'
import GridRow from '@/components/global/grid/GridRow.vue'
import CardMeal from '@/components/global/cards/CardMeal.vue'
import InputSelect from '@/components/global/inputs/InputSelect.vue'
import InputSearchBar
  from '@/components/layout/template-2/workout/page-components/workout-log-sub-components/InputSearchBar.vue'
import CardMealFeatured from '@/components/global/cards/CardMealFeatured.vue'
import AccordionRecipe from '@/components/layout/template-1/meal-plan/AccordionRecipe.vue'
import CardContent from '@/components/global/cards/CardContent.vue'
import ButtonPrimary from '@/components/global/buttons/ButtonPrimary.vue'
import CardTextHeader from '@/components/global/cards/card-text-sub-components/CardTextHeader.vue'
import TextBodyExtraSmall from '@/components/global/typography/TextBodyExtraSmall.vue'
import AccordionRecipeTile from '@/components/layout/template-1/meal-plan/page-components/AccordionRecipeTile.vue'
import PageFixedFooter from '@/components/global/pages/PageFixedFooter.vue'
import moment from 'moment'
import 'moment-duration-format'
import NutritionService from '@/services/nutrition/NutritionService'
import BadgeChoice from '@/components/global/badges/BadgeChoice.vue'
import TextBodySmall from '@/components/global/typography/TextBodySmall.vue'

export default {
  name: 'PageDetailRecipe',
  extends: BaseComponent,
  components: {
    TextBodySmall,
    BadgeChoice,
    PageFixedFooter,
    AccordionRecipeTile,
    TextBodyExtraSmall,
    CardTextHeader,
    ButtonPrimary,
    CardContent,
    AccordionRecipe,
    CardMealFeatured,
    InputSearchBar,
    InputSelect,
    CardMeal,
    GridCol,
    GridRow,
    PageSecondary,
    ContentContainer,
    PageContentImage,
    PageContainer,
    PageContentText
  },
  data: function () {
    return {
      pageType: this.$route.params.contentPage,
      hasMealPlans: false,
      recipe: null,
      filters: {
        tags: [],
        categories: []
      },
      selectedTags: [],
      selectedCategories: [],
      searchName: '',
      mainImage: '',
      description: '',
      headerOptions: {
        isTopFixed: false,
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'previous',
        right: '',
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: '',
        subTitle: ''
      }
    }
  },
  mounted() {
    this.hideLoading()
    const mealPlans = new NutritionService()
    this.hasMealPlans = mealPlans.getAllMealPlans().length > 0
    const service = new ContentPageFactoryService()
    service.getPageList().then(data => {
      const link = service.findPageInList(this.pageType, data)
      this.setPageName(link.code)
      this.loadPageData(link.code)
    })
  },
  computed: {
    images() {
      return this.recipe ? this.recipe.images : []
    },
    instructions() {
      if (!this.recipe) {
        return null
      }
      return this.linkifyText(this.recipe.instructions)
    },
    preparationTime() {
      if (!this.recipe) {
        return '-'
      }

      if (moment.duration(this.recipe.prepTime, 'minutes').minutes()) {
        return moment.duration(this.recipe.prepTime, 'minutes').format('h [hour] m [minute]')
      }
      return moment.duration(this.recipe.prepTime, 'minutes').format('h [hour]')
    },
    macroNutrients() {
      if (!this.recipe) {
        return []
      }
      const macroNutrients = []
      const protein = this.recipe.macronutrients.protein
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.proteins\']'),
        qut: protein,
      })
      const fat = this.recipe.macronutrients.fat
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.fats\']'),
        qut: fat,
      })
      const carb = this.recipe.macronutrients.carbs
      macroNutrients.push({
        unit: this.$i18n.t('message[\'general.g\']'),
        name: this.$i18n.t('message[\'general.carbs\']'),
        qut: carb,
      })
      const sugar = this.recipe.macronutrients.sugar
      if (sugar) {
        macroNutrients.push({
          unit: this.$i18n.t('message[\'general.g\']'),
          name: this.$i18n.t('message[\'general.sugar\']'),
          qut: sugar,
        })
      }

      return macroNutrients
    },
    calories() {
      if (!this.recipe) {
        return []
      }
      return [
        {
          unit: '',
          name: this.$i18n.tc('message[\'general.calories\']', this.recipe.calories),
          qut: this.recipe.calories,
        }
      ]
    },

  },
  methods: {
    ...mapMutations({
      setPageName: 'pageStore/setPageName',
      setUserLogContext: 'customMealLogPopupStore/setUserLogContext',
      unsetPageName: 'pageStore/unsetPageName'
    }),
    addToFoodDiary() {
      console.log(this.recipe.images[0][0])
      const meal = {
        'name': this.recipe.name,
        'recipes':
          [{
            'name': this.recipe.name,
            'image': 'https:' + this.recipe.images[0][0],
            'calories': this.recipe.calories,
            'macronutrients': {
              'fats': this.recipe.macronutrients.fat,
              'carbohydrates': this.recipe.macronutrients.carbs,
              'proteins': this.recipe.macronutrients.protein
            }
          }]
      }
      this.setUserLogContext('recipe-page')
      NLAF.foodDiary.addCustomMeal(meal)

    },
    linkifyText(text) {
      return text
      /*// Regex to match URLs that are not already inside an HTML anchor tag
      const urlRegex = /(?<!href="|">)(https?:\/\/[^\s<]+)/g;

      // Replace URLs with HTML anchor tags while preserving existing HTML structure
      return text.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank">${url}</a>`;
      });*/
    },
    loadPageData(pageType) {
      const recipeStr = this.$route.query.recipe
      const contentPageFactory = new ContentPageFactoryService()
      const page = contentPageFactory.getPage(pageType)
      page.setPageData().then(() => {

        this.recipe = page.getRecipeByName(recipeStr)
        this.headerOptions.mainTitle = page.pageData.page.title
        pageReadyEvent()
      })
    }
  },
  destroyed() {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
