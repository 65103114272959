<template>
    <page :header="header" class="gc-page-enable-period-tracker">
      <validation-observer ref="form" v-slot="{handleSubmit, invalid}">
          <page-container>
              <div class="gc-page-enable-period-tracker__header">
                  <text-heading-4
                      class="gc-page-enable-period-tracker__title text-center"
                      weight="extra-bold"
                      lineHeight="multi">{{ $t('message[\'period-tracker.onboarding-enable-title\']') }}</text-heading-4>
              </div>
              <div>
                <text-body-small class="gc-page-enable-period-tracker__description" :line-height="'multi'">
                  <div v-html="$t('message[\'period-tracker.subscription.desciption\']')"></div>
                </text-body-small>
                <text-body-small class="pc-popup-period-tracker-enable__description pc-popup-period-tracker-enable__skip" :decoration="'underline'" :weight="'extra-bold'" :line-height="'multi'" @click="nextComponent('skip')">
                  {{ $t('message[\'general.skip-for-now\']') }}
                </text-body-small>
                  <form id="form-input-custom-field"
                      class=""
                      @submit.prevent="handleSubmit(nextComponent)">
                          <validation-provider v-slot="{ errors }" :vid="'cutomField'" :name="title.main">
                              <text-input
                                  class="gc-page-enable-period-tracker__input-cutom-field"
                                  style="display: none;"
                                  v-model="isEnable"
                                  :error-messages="errors[0]">
                              </text-input>
                              <text-body-extra-small class="gc-page-progress-form__input-error text-center" lineHeight="multi">{{errors[0]}}</text-body-extra-small>
                          </validation-provider>
                  </form>
              </div>
          </page-container>
          <page-footer :form="'form-input-custom-field'" :text=" $t('message[\'period-tracker.subscription.enable-now\']')" :validated="invalid"></page-footer>
        </validation-observer>
    </page>
</template>

<script>
import FormBaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ButtonPrimary from '../buttons/ButtonPrimary'
import TextBodySmall from '../typography/TextBodySmall'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextHeading4 from '../typography/TextHeading4'
import PageContainer from './page-sub-components/PageContainer'
import Page from './PageForm'
import { setValuesMixin } from '../../layout/template-1/global/widgets/forms/helpers/SetValuesMixin'
import PageFooter from './page-sub-components/PageFormFooter'
import TextInput from '../inputs/TextInput'

export default {
  name: 'PageHeight',
  extends: FormBaseComponent,
  mixins: [ setValuesMixin ],
  props: {
    switchPreference: {
      default: true
    }
  },
  components: {
    Page,
    PageContainer,
    TextHeading4,
    ButtonPrimary,
    TextBodySmall,
    TextInput,
    ValidationObserver,
    ValidationProvider,
    PageFooter,
    TextBodyExtraSmall
  },
  data: function () {
    return {
        isEnable: 0,
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''
      },
      validations: ''
    }
  },
  beforeMount () {
  },
  methods: {
    nextComponent (status = 'enable') {
      if (status === 'enable') {
        this.isEnable = true
        this.$emit('submit', { key: this.inputKey, value: this.isEnable })
      } else if (status === 'skip') {
        this.isEnable = false
        this.$emit('submit', { key: this.inputKey, value: this.isEnable })
      }

    }
  }
}
</script>
