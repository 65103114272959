<template>
  <page-secondary :header="headerOptions" class="pc-content pc-content-recipes">
    <page-content-image v-if="mainImage" :contentImage="mainImage"/>
    <page-container>
      <page-content-text :description="description"/>
      <div class="pc-content-recipes-list" v-if="recipes.length>0">
        <grid-row>
          <grid-col cols="12">
            <input-search-bar class="pc-content-recipes__input-search" :placeholder="$t('message[\'general.search\']')"
                              v-model="searchName"/>
          </grid-col>

          <grid-col cols="12">
            <div class="pc-content-recipes__content-filters">
              <text-body-extra-small class="pc-content-recipes__text-filters">{{
                  $t('message["content.recipe.filters"]')
                }}
              </text-body-extra-small>
              <div class="pc-content-recipes__list-filters" @click="filterPopup.visible= true">
                <div class="pc-content-recipes__filter">
                  <text-body-extra-small>{{ $t('message["content.recipe.type"]') }}</text-body-extra-small>
                  <text-body-extra-small class="pc-content-recipes__filter-count">{{ filterTypeCategoryText }}
                  </text-body-extra-small>
                  <icon-down-arrow size="sm1"/>
                </div>
                <div class="pc-content-recipes__filter">
                  <text-body-extra-small>{{ $t('message["content.recipe.tags"]') }}</text-body-extra-small>
                  <text-body-extra-small class="pc-content-recipes__filter-count">{{ filterTypeCountText }}
                  </text-body-extra-small>
                  <icon-down-arrow size="sm1"/>
                </div>
              </div>
            </div>
          </grid-col>
        </grid-row>
        <grid-row>
          <grid-col v-for="(recipe,index) in filteredRecipes" :key="'recipe-'+recipe._sequence+'-'+index" cols="12" lg="6">
            <card-meal
              :title="recipe.name"
              :type="recipe.mealTypes"
              :calories="[]"
              :macroNutrientsToShow="false"
              :imagesSrc="recipe.images"
              :tags="recipe.tags"
              @click="goToRecipe(recipe)"
            />
          </grid-col>

          <text-body-extra-small class="pc-content-recipes__text-no-result" v-if="filteredRecipes.length===0">
            {{ $t('message[\'general.no-results\']') }}
          </text-body-extra-small>
        </grid-row>


      </div>

    </page-container>
    <popup-content-recipe-filters
      v-if="filterPopup.visible"
      :meal-categories="filters.categories"
      :meal-types="filters.tags"
      :selected-meal-categories="selectedCategories"
      :selected-meal-types="selectedTags"
      @updated="applyFilters"
      @close="filterPopup.visible = false"

    />
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import ContentPageFactoryService from '../../../../services/ContentPageFactoryService'
import ContentContainer from './widgets/ContentContainer'
import {mapMutations} from 'vuex'
import PageContentImage from '../../../global/pages/page-sub-components/PageContentImage'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageContentText from './page-components/PageContentText'
import PageSecondary from '../../../global/pages/PageSecondary'
import {pageReadyEvent} from '@/helpers/dom/events/customEvents'
import GridCol from '@/components/global/grid/GridCol.vue'
import GridRow from '@/components/global/grid/GridRow.vue'
import CardMeal from '@/components/global/cards/CardMeal.vue'
import InputSelect from '@/components/global/inputs/InputSelect.vue'
import InputSearchBar
  from '@/components/layout/template-2/workout/page-components/workout-log-sub-components/InputSearchBar.vue'
import TextBodySmall from '@/components/global/typography/TextBodySmall.vue'
import IconDownArrow from '@/components/root/icons/IconDownArrow.vue'
import TextBodyExtraSmall from '@/components/global/typography/TextBodyExtraSmall.vue'
import PopupContentRecipeFilters
  from '@/components/layout/template-1/content/page-components/PopupContentRecipeFilters.vue'

export default {
  name: 'PageRecipes',
  extends: BaseComponent,
  components: {
    PopupContentRecipeFilters,
    TextBodyExtraSmall,
    IconDownArrow,
    TextBodySmall,
    InputSearchBar,
    InputSelect,
    CardMeal,
    GridCol,
    GridRow,
    PageSecondary,
    ContentContainer,
    PageContentImage,
    PageContainer,
    PageContentText
  },
  data: function () {
    return {
      pageType: this.$route.params.contentPage,
      recipes: [],
      filters: {
        tags: [],
        categories: []
      },
      filterPopup: {
        visible: false
      },
      selectedTags: [],
      selectedCategories: [],
      searchName: '',
      mainImage: '',
      description: '',
      headerOptions: {
        isTopFixed: false,
        isTransparent: false,
        isFixed: false,
        show: true,
        left: 'menu',
        right: '',
        rightBlink: false,
        modifiers: ['small-title'],
        mainTitle: '',
        subTitle: ''
      }
    }
  },
  mounted() {
    this.hideLoading()
    const service = new ContentPageFactoryService()
    service.getPageList().then(data => {
      const link = service.findPageInList(this.pageType, data)
      this.setPageName(link.code)
      this.loadPageData(link.code)
    })
  },
  computed: {
    filterTypeCountText: function () {
      if (this.selectedTags.length === 0) {
        return ''
      }

      return this.selectedTags.length !== this.filters.tags.length ? `(${this.selectedTags.length})` : this.$i18n.t('message[\'general.all\']')
    },

    filterTypeCategoryText: function () {
      if (this.selectedCategories.length === 0) {
        return ''
      }
      return this.selectedCategories.length !== this.filters.categories.length ? `(${this.selectedCategories.length})` : this.$i18n.t('message[\'general.all\']')
    },

    filteredRecipes() {
      if (!this.recipes) return []

      const searchNameLower = this.searchName.toLowerCase()
      const hasSelectedTags = Array.isArray(this.selectedTags) && this.selectedTags.length > 0
      const hasSelectedCategories = Array.isArray(this.selectedCategories) && this.selectedCategories.length > 0

      return this.recipes.filter(recipe => {

        if(!recipe.name.toLowerCase().includes(searchNameLower) ){
          return  false
        }
        const tagMatch = !hasSelectedTags || this.selectedTags.some(tag => recipe.tags.includes(tag))
        if(!tagMatch){
          return  false
        }
        return !hasSelectedCategories || this.selectedCategories.some(category => recipe.mealTypes.includes(category))
      })
    }
  },
  methods: {
    ...mapMutations({
      setPageName: 'pageStore/setPageName',
      unsetPageName: 'pageStore/unsetPageName'
    }),
    applyFilters(selection) {
      this.filterPopup.visible = false
      this.selectedTags = selection.mealTypes
      this.selectedCategories = selection.mealCategories

    },
    goToRecipe(recipe) {
      this.$router.push({
        path: this.$route.path,
        query: {
          recipe: recipe.name,
        }
      })
    },
    loadPageData(pageType) {

      const contentPageFactory = new ContentPageFactoryService()
      const page = contentPageFactory.getPage(pageType)
      page.setPageData().then(() => {
        this.headerOptions.mainTitle = page.pageData.page.title
        this.setPageTitle(page.pageData.page.title)
        this.description = page.pageData.page.description
        this.mainImage = page.pageData.page.header_image
        this.recipes = page.getRecipes()
        this.filters.tags = page.getTags()
        this.filters.categories = page.getCategories()
        pageReadyEvent()
        this.logEvent('RecipePages.ViewedPage',
          {slug: '/page/' + pageType, title: (page.pageData.page.title)})
    })
    }
  },
  destroyed() {
    this.resetHeader()
    this.resetBackground()
  }
}
</script>
