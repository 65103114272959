import PushNotificationsService from '@/services/PushNotificationsService'

export default class NativeAppService {
  inAppBrowsing = false
  inAppPurchases = false
  microphonePermissions = false
  cameraPermissions = false
  pushDeviceStateResponse = null
  pushPersonalization = false
  wearableIntegration = false

  constructor () {
    if (!NativeAppService.instance) {
      NativeAppService.instance = this
      this._listen()
      this.sendWhatCanDoEvent()
    }
    return NativeAppService.instance
  }

  sendWhatCanDoEvent () {
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: {
            name: 'whatYouCan'
          }
        })
      )
    } catch (e) {
      console.log(e)
    }
  }

  showAppBrowser (url) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        command: {
          name: 'openInAppBrowserUrl',
          args: {
            url: url
          }
        }
      })
    )
  }

  sendIdentifyCustomerRequest (user) {
    const ids = [user.reseller_id, user.trainer_id, user.id]
    const command = {
      name: 'identifyCustomerRequest',
      args: {
        customer: {
          id: ids.join('-')
        }
      }
    }

    this._sendReactNativeWebViewPostMessage(command)
  }

  startRookSessionRequest (user) {
    const ids = [user.reseller_id, user.trainer_id, user.id]
    const command = {
      name: 'startRookSessionRequest',
      args: {
        customer: {
          id: ids.join('-')
        }
      }
    }

    this._sendReactNativeWebViewPostMessage(command)
  }

  sendHasAndroidStepsPermissionsRequest  () {

    const command = {
      name: 'hasAndroidStepsPermissionsRequest ',
      args: {}
    }

    this._sendReactNativeWebViewPostMessage(command)
  }
  sendRequestAndroidStepsPermissionsRequest   () {

    const command = {
      name: 'requestAndroidStepsPermissionsRequest  ',
      args: {}
    }

    this._sendReactNativeWebViewPostMessage(command)
  }
  sendEnableBackgroundAndroidStepsRequest   () {

    const command = {
      name: 'enableBackgroundAndroidStepsRequest  ',
      args: {}
    }

    this._sendReactNativeWebViewPostMessage(command)
  }

  endRookSessionRequest () {

    const command = {
      name: 'endRookSessionRequest',
      args: {}
    }

    this._sendReactNativeWebViewPostMessage(command)
  }

  sendWearablePermissionsRequest () {
    const command = {
      name: 'wearablePermissionsRequest',
      args: {}
    }

    this._sendReactNativeWebViewPostMessage(command)
  }

  sendLogout () {
    const command = {
      name: 'logout',
      args: {}
    }

    this._sendReactNativeWebViewPostMessage(command)
  }

  sendWearableEnableSyncRequest () {

    const command = {
      name: 'wearableEnableSyncRequest',
      args: {}
    }

    this._sendReactNativeWebViewPostMessage(command)
  }

  _sendReactNativeWebViewPostMessage (command) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        command: command
      })
    )
  }

  canSendPushNotifications () {
    return this.pushPersonalization
  }

  getPushDeviceStateRequest () {
    if (this.pushPersonalization) {
      const command = {
        name: 'getPushDeviceStateRequest',
        args: {}
      }
      this._sendReactNativeWebViewPostMessage(command)
    }
  }

  _sendReactNativeWebViewPostMessage (command) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        command: command
      })
    )
  }

  _listen () {
    window.addEventListener('message', (ev) => {
      try {
        const data = JSON.parse(ev.data)
        if (data.name && data.name === 'whatYouCanResponse') {
          if (data.args) {
            this.inAppBrowsing = data.args.inAppBrowsing || false
            this.inAppPurchases = data.args.inAppPurchases || false
            this.microphonePermissions = data.args.microphonePermissions || false
            this.cameraPermissions = data.args.cameraPermissions || false
            this.pushPersonalization = data.args.pushPersonalization || false
            this.wearableIntegration = data.args.wearableIntegration || false
          }
        } else if (data.name && data.name === 'getPushDeviceStateResponse') {
          if (data.args) {
            this.pushDeviceStateResponse = data.args
            const pushNotifications = new PushNotificationsService()
            pushNotifications.registerDevice(this.pushDeviceStateResponse.userId)
          }
        } else if (data.name && data.name === 'ping') {
          this.sendPongEvent()
        } else if (data.name && data.name === 'wearablePermissionsResponse') {
          // create custom event name wearablePermissionsResponse
          // emit event to the store
          const event = new CustomEvent('wearablePermissionsResponse', {
            detail: data.args
          })
          // Dispatch the custom event
          window.dispatchEvent(event)
          if(data.args && !data.args.error){
            localStorage.setItem('isNativeWearableConnected','1')
          }
        } else if (data.name && data.name === 'startRookSessionResponse') {
          // create custom event name startRookSessionResponse
          const event = new CustomEvent('startRookSessionResponse', {
            detail: data.args
          })
          // Dispatch the custom event
          window.dispatchEvent(event)

        } else if (data.name && data.name === 'endRookSessionResponse') {
          // create custom event name endRookSessionResponse
          const event = new CustomEvent('endRookSessionResponse', {
            detail: data.args
          })
          // Dispatch the custom event
          window.dispatchEvent(event)

        } else if (data.name && data.name === 'wearableEnableSyncResponse') {
          // create custom event name wearableEnableSyncResponse
          const event = new CustomEvent('wearableEnableSyncResponse', {
            detail: data.args
          })
          // Dispatch the custom event
          window.dispatchEvent(event)
        } else if(data.name && data.args){
          // create generic event
          /**
           * tested for below events
           * enableBackgroundAndroidStepsResponse
           * requestAndroidStepsPermissionsResponse
           * hasAndroidStepsPermissionsResponse
           * @type {CustomEvent<unknown>}
           */
          const event = new CustomEvent(data.name, {
            detail: data.args
          })
          // Dispatch the custom event
          window.dispatchEvent(event)
        }
      } catch (e) {
        // no json returned
      }
    })
  }
  isSupportWearables(){
    return this.wearableIntegration
  }
  sendPongEvent () {
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(
      JSON.stringify({
        command: {
          name: 'pong'
        }
      })
    )
  }

  _destroyListener () {
    window.removeEventListener('message')
  }

  removeListeners () {
    this._destroyListener()
  }
}
