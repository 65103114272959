<template>
  <!-- <PageSecondary> -->
  <div>
    <div class="pc-push-notification-card">
      <div class="pc-push-notification-card-details">
        <div class="pc-push-notification-card-details__title">
          <TextBodySmall2 :weight="'extra-bold'">Community</TextBodySmall2>
        </div>

        <div class="pc-push-notification-card-details__description">
          <TextBodyExtraSmall
            >Receive real-time updates on posts, comments and
            mentions</TextBodyExtraSmall
          >
        </div>
      </div>
      <div class="gc-push-notification-card__button">
        <InputSwitch></InputSwitch>
      </div>
    </div>
  </div>
  <!-- </PageSecondary> -->
</template>

<script>
import PageContainer from "../../../../global/pages/page-sub-components/PageContainer.vue";
import BaseComponent from "../../global/base/BaseComponent";
import TextBodySmall2 from "@/components/global/typography/TextBodySmall2";
import TextBodyExtraSmall from "@/components/global/typography/TextBodySmall2";
import InputSwitch from "@/components/global/inputs/InputSwitch.vue";
import PageSecondary from "@/components/global/pages/PageSecondary.vue";

export default {
  name: "PagePushNotifications",
  extends: BaseComponent,
  components: {
    PageSecondary,
    PageContainer,
    TextBodySmall2,
    TextBodyExtraSmall,
    InputSwitch,
  },

  mounted() {
    this.hideLoading();
    this.setPageClass();
    this.setHeader(this.headerOptions);
    headerEventBus.$on("back-button-click", this.navigatePrevious);
    pageReadyEvent();
    this.updateFontFamily();
  },
  methods: {},
};
</script>

<style scoped></style>
