<template>
  <page-secondary :header="headerOptions" class="pc-step-tracker">
    <div class="pc-step-tracker__connect-button"  @click="navigateToConnectScreen()">
      <div class="pc-step-tracker__connect-button__left">
        <icon-connect-wearable :size="'sm2'"/>
        <text-body-extra-small v-if="connectedWearables.length < 1" :weight="'extra-bold'" v-html="$t('message[\'general.connect-a-wearable\']')"></text-body-extra-small>
        <text-body-extra-small v-else-if="connectedWearables.length === 1" :weight="'extra-bold'" v-html="$t('message[\'general.one-wearable-connected\']', [connectedWearables[0]])"></text-body-extra-small>
        <text-body-extra-small v-else :weight="'extra-bold'" v-html="$t('message[\'general.multiple-wearables-connected\']', [connectedWearables.length])"></text-body-extra-small>
      </div>
      <icon-forward :size="'sm1'"/>
    </div>
    <grid-row  class="pc-step-tracker__navigation">
      <div
        :class="graphComponent === 'week' ? 'pc-step-tracker__navigation-button col-3 pc-step-tracker__navigation-button-active' : 'pc-step-tracker__navigation-button col-3'"
        class="pc-step-tracker__navigation-button col-3"
        @click="changeGraphComponent('week')">
        <text-body-extra-small>{{ $t('message["general.week-letter"]') }}</text-body-extra-small>
      </div>
      <div
        :class="graphComponent === 'month' ? 'pc-step-tracker__navigation-button col-3 pc-step-tracker__navigation-button-active' : 'pc-step-tracker__navigation-button col-3'"
        @click="changeGraphComponent('month')">
        <text-body-extra-small> {{ $t('message["general.month-letter"]') }}</text-body-extra-small>
      </div>
      <div
        :class="graphComponent === 'sixMonths' ? 'pc-step-tracker__navigation-button col-3 pc-step-tracker__navigation-button-active' : 'pc-step-tracker__navigation-button col-3'"
        @click="changeGraphComponent('sixMonths')">
        <text-body-extra-small>{{ $t('message["general.six-months-letter"]') }}</text-body-extra-small>
      </div>
      <div
        :class="graphComponent === 'year' ? 'pc-step-tracker__navigation-button col-3 pc-step-tracker__navigation-button-active' : 'pc-step-tracker__navigation-button col-3'"
        @click="changeGraphComponent('year')">
        <text-body-extra-small>{{ $t('message["general.year-letter"]') }}</text-body-extra-small>
      </div>
    </grid-row>
    <page-container class="container">
      <loading-buffer v-if="dataLoading" :height="'400'"/>
      <div v-if="!dataLoading" class="pc-step-tracker__header-steps">
        <text-body-small class="pc-step-tracker__header-data-range-header">{{dataRangeHeader}}</text-body-small>
        <div  v-if="!dataLoading" class="pc-step-tracker__header-step-count">
          <text-heading3 class="pc-step-tracker__header-count">{{ totalSteps }}</text-heading3><text-body-small>{{ $t('message["general.steps"]') }}</text-body-small>
        </div>
      <text-body-extra-small>{{dataRange}}</text-body-extra-small>
      </div>
      <div>
        <bar-chart class="pc-step-tracker__graph-week" v-if="graphComponent === 'week' && !dataLoading" :chart-data="weekChartData.chartData" :max-value="weekChartData.maxValue"/>
        <bar-chart class="pc-step-tracker__graph-month" v-if="graphComponent === 'month' && !dataLoading" :chart-data="monthChartData.chartData" :max-value="monthChartData.maxValue"/>
        <bar-chart class="pc-step-tracker__graph-six-months" v-if="graphComponent === 'sixMonths' && !dataLoading" :chart-data="sixMonthsChartData.chartData" :max-value="sixMonthsChartData.maxValue"/>
        <bar-chart class="pc-step-tracker__graph-six-months" v-if="graphComponent === 'year' && !dataLoading" :chart-data="yearChartData.chartData" :max-value="yearChartData.maxValue"/>
      </div>
      <div  class="pc-step-tracker__no-data-text">
        <text-body-extra-small v-if="data.length < 1 && !dataLoading">{{ $t('message["general.no-data"]') }}</text-body-extra-small>
      </div>
      <div  v-if="!dataLoading">
      <div class="pc-step-tracker__step-listing" v-for="item in data" :key="item.day">
        <text-body-small class="pc-step-tracker__step-listing-date" v-if="graphComponent ===  'week' || graphComponent === 'month'"> {{ formatCustomDateDay(item.day) }}</text-body-small>
        <text-body-small class="pc-step-tracker__step-listing-date" v-else> {{ formatCustomDateMonth(item.month) }}</text-body-small>
          <div class="pc-step-tracker__step-listing-count">
            <icon-steps :size="'md2'"></icon-steps>
            <text-body-small :weight="'extra-bold'">{{ item.steps }} {{ $t('message["general.steps"]') }}</text-body-small>
          </div>
        </div>
      </div>
    </page-container>
    <popup-step-tracker-introduction
      v-if="showIntroPopup"
      :visible="showIntroPopup" @close="toggleIntroPopup()"/>
  </page-secondary>
</template>

<script>
import BaseComponent from '../global/base/BaseComponent'
import LoadingBuffer from '../global/widgets/LoadingBuffer'
import pageMixin from '../../../../mixins/pageMixin'
import {mapGetters, mapMutations} from 'vuex'
import PageSecondary from '../../../global/pages/PageSecondary'
import PopupStepTrackerIntroduction from '../step-tracker/page-components/PopupStepTrackerIntroduction'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import {pageReadyEvent} from '@/helpers/dom/events/customEvents'
import PopupDeleteMood from '@/components/layout/template-1/mood-tracker/page-components/PopupDeleteMood.vue'
import BarChart from "@/components/global/charts/BarChart.vue";
import ButtonPrimaryForwardIcon from "@/components/global/buttons/ButtonPrimaryForwardIcon.vue";
import IconConnectWearable from "@/components/root/icons/IconConnectWearable.vue";
import TextBodyExtraSmall from "@/components/global/typography/TextBodyExtraSmall.vue";
import IconForwardArrow from "@/components/root/icons/IconForwardArrow.vue";
import IconForward from "@/components/root/icons/IconForward.vue";
import IconSteps from "@/components/root/icons/IconSteps.vue";
import NavigationBar from "@/components/global/navigators/NavigationBar.vue";
import GridRow from "@/components/global/grid/GridRow.vue";
import GridCol from "@/components/global/grid/GridCol.vue";
import StepTrackerService from "@/services/StepTrackerService";
import TextBodySmall from "@/components/global/typography/TextBodySmall.vue";
import TextHeading3 from "@/components/global/typography/TextHeading3.vue";
import colors from './../../../../../static/assets/sass-v2/theme-variables.scss'
import UserEventsService from "@/services/UserEventsService";
import headerEventBus from '@/event-buses/headerEventBus';
import moment from 'moment'
import DashBoardService from '@/services/DashboardService'
import NutritionService from "@/services/nutrition/NutritionService";
import { isMobileNativeApp } from '@/includes/NativeAppCommon'
import NativeAppService from '@/services/NativeAppService'
import { isIOS } from 'mobile-device-detect'

export default {
  name: 'step-tracker',
  extends: BaseComponent,
  mixins: [pageMixin],
  components: {
    TextBodySmall,
    IconSteps,
    TextHeading3,
    GridCol,
    GridRow,
    NavigationBar,
    IconForward,
    IconForwardArrow,
    TextBodyExtraSmall,
    IconConnectWearable,
    ButtonPrimaryForwardIcon,
    BarChart,
    PopupDeleteMood,
    PageContainer,
    PageSecondary,
    LoadingBuffer,
    PopupStepTrackerIntroduction
  },
  data: function () {
    return {
      headerOptions: {
        show: true,
        left: 'previous',
        right: 'info',
        rightDisabled: false,
        modifiers: ['with-bg-color', 'small-title'],
        header: {
          mainTitle: this.$i18n.t('message["general.step-tracker"]'),
          subTitle: ''
        },
        mainTitle: this.$i18n.t('message["general.step-tracker"]')
      },
      loading: true,
      pageClass: ['macroapp--page-common'],
      showIntroPopup: false,
      weekChartData: {},
      monthChartData: {},
      sixMonthsChartData: {},
      yearChartData: {},
      graphComponent: '',
      data: [],
      dataLoading: false,
      connectedWearables: [],
      totalSteps: 0,
      dataRange: '',
      dataRangeHeader: ''
    }
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  created () {
    this.setPageTitle(this.$t('message["general.step-tracker"]'))
  },
  mounted () {
    this.headerOptions.header.mainTitle = this.$i18n.t('message["general.step-tracker"]')
    // this.setHeader(this.headerOptions)
    this.showInfoPopup()
    this.graphComponent = 'week'
    this.changeGraphComponent('week')
    this.getConnectedWearables()
    this.hideLoading()
    headerEventBus.$on('info-button-click', this.toggleIntroPopup)

    window.addEventListener('startRookSessionResponse',this.afterStartRookSessionResponse)
    window.addEventListener('enableBackgroundAndroidStepsResponse',this.afterEnableBackgroundAndroidStepsResponse)
    window.addEventListener('requestAndroidStepsPermissionsResponse',this.afterRequestAndroidStepsPermissionsResponse)
    window.addEventListener('hasAndroidStepsPermissionsResponse',this.afterHasAndroidStepsPermissionsResponse)
  },
  beforeDestroy () {
    headerEventBus.$off('info-button-click', this.toggleIntroPopup)
    window.removeEventListener('startRookSessionResponse',this.afterStartRookSessionResponse)
    window.removeEventListener('enableBackgroundAndroidStepsResponse',this.afterEnableBackgroundAndroidStepsResponse)
    window.removeEventListener('requestAndroidStepsPermissionsResponse',this.afterRequestAndroidStepsPermissionsResponse)
    window.removeEventListener('hasAndroidStepsPermissionsResponse',this.afterHasAndroidStepsPermissionsResponse)
  },
  methods: {
    ...mapMutations({}),
    afterStartRookSessionResponse(data){
      const nativeAppService = new NativeAppService();
      nativeAppService.sendEnableBackgroundAndroidStepsRequest()
    },
    afterRequestAndroidStepsPermissionsResponse(data){
      const dashboard = new DashBoardService();
      const nativeAppService = new NativeAppService();
      nativeAppService.startRookSessionRequest(dashboard.userData)
    },
    afterEnableBackgroundAndroidStepsResponse(data){
        alert('afterEnableBackgroundAndroidStepsResponse')
    },
    afterHasAndroidStepsPermissionsResponse(data){
      if(data.result){

      }else if(!data.result){
        const nativeAppService = new NativeAppService();
        nativeAppService.sendHasAndroidStepsPermissionsRequest()
      }
    },
    showInfoPopup () {
      if (
          templateConfig.instance_config_timestamps
              .enable_step_tracker &&
          templateConfig.instance_config_timestamps
              .enable_step_tracker.created_at
      ) {
        const enabledDate = moment(
            templateConfig.instance_config_timestamps
                .enable_step_tracker.created_at
        )
        const dashboard = new DashBoardService()
        const userRegDate = dashboard.getRegisteredDate()
        if (userRegDate.isBefore(enabledDate)) {
          const pathText = this.$i18n.t('message[\'step-tracker.introduction-popup.info\']')
          const pathHTML = `<b>${pathText}</b>`
          const popupContent = this.$i18n.t('message[\'step-tracker.introduction-popup.description\']') + '<br><br>' + pathHTML
          UserEventsService.stepTrackerFeaturePopupView('', popupContent)
        }
      }
    },
    toggleIntroPopup () {
      this.showIntroPopup = !this.showIntroPopup
    },
    formatCustomDateDay(dateString) {
      const date = moment(dateString);
      const day = date.date();
      const month = date.format('MMMM');
      let daySuffix;

      if (day === 1 || day === 21 || day === 31) {
        daySuffix = `${day}st`;
      } else if (day === 2 || day === 22) {
        daySuffix = `${day}nd`;
      } else if (day === 3 || day === 23) {
        daySuffix = `${day}rd`;
      } else {
        daySuffix = `${day}th`;
      }

      const today = moment();
      const yesterday = moment().subtract(1, 'days');
      const isToday = today.isSame(date, 'day');
      const isYesterday = yesterday.isSame(date, 'day');
      const yesterdayLang = this.$i18n.t('message[\'general.yesterday\']');
      const todayLang = this.$i18n.t('message[\'general.today\']');

      return isToday ? `${todayLang}, ${daySuffix} ${month}`
        : isYesterday ? `${yesterdayLang}, ${daySuffix} ${month}`
          : `${daySuffix} ${month}`;
    },
    formatCustomDateMonth(dateString) {
      return  moment(dateString, 'YYYY-MM').format('MMMM YYYY');
    },
    async getConnectedWearables() {
      try {
        let service = new StepTrackerService();
        const response = await service.getConnectedDataSources();
        this.connectedWearables = Object.entries(response)
            .filter(([key, value]) => value.enabled)
            .map(([key, value]) => value.id);


        if (isMobileNativeApp() && (this.connectedWearables.includes('Health Connect')|| this.connectedWearables.includes('Apple Health') || this.connectedWearables.includes('healthConnect'))) {
          const nativeAppService = new NativeAppService();
          if(!isIOS){
            await nativeAppService.sendHasAndroidStepsPermissionsRequest()
          }else if(sessionStorage.getItem('isNativeWearableConnected')===null){
            await nativeAppService.sendWearablePermissionsRequest()
            sessionStorage.setItem('isNativeWearableConnected','1')
          }

        }
      } catch (error) {
        // this.$store.commit('showServerErrorPopup');
        // this.$router.go(-1); //TODO
      }
    },
    navigateToConnectScreen () {
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.connectWearable
      })
    },
    async changeGraphComponent(component) {
      this.graphComponent = component;
      const dataType = component === 'sixMonths' || component === 'year' ? 'monthly' : 'daily';
      const dataRange = component;

      try {
        await this.getData(dataType, dataRange);
        if (this.data.length > 0) {
          this.setGraphData(component, this.data);
        } else {
          this.setDataRange('default') // if there is no data , setting data range to default
        }
      } catch (error) {
        this.$store.commit('showServerErrorPopup');
        // this.$router.go(-1);
      } finally {
        setTimeout(() => {
          this.dataLoading = false;
        }, 500)
      }
    },
    async getData(type, range) {
      this.dataLoading = true;
      try {
        const today = moment();
        const todayISO = today.toISOString();

        let fromDate;
        if (range === "month") {
          fromDate = moment().startOf('month').toISOString();
        } else {
          const rangeMapping = {
            week: 7,
            month: 30,
            sixMonths: 6 * 30,
            year: 335
          };
          fromDate = moment().subtract(rangeMapping[range] || 0, 'days').toISOString();
        }

        const service = new StepTrackerService();
        const response = await service.getGraphData(fromDate, todayISO, type);
        this.data = response.data;

      } catch (error) {
        this.$store.commit('showServerErrorPopup');
        this.$router.go(-1);
      } finally {
        setTimeout(() => {
          this.dataLoading = false;
        }, 500)
      }
    },
    setGraphData(component, data) {
      const formatDates = (data, formatOptions) =>
        data
          .map(item => {
            let date =  item.day ? moment(item.day) : moment(item.month,'YYYY-MM');

            if (component === 'month') {
              return date.date();
            } else {
              return date.format(formatOptions);
            }
          })
          .reverse();

      const getMaxValue = (stepsArray) =>
        Math.ceil(Math.max(...stepsArray) / 1000) * 1000;

      const stepsArray = data.map(item => item.steps).reverse();
      this.totalSteps = data
        .reduce((accumulator, current) => accumulator + parseInt(current.steps, 10), 0)
        .toLocaleString();
      const maxValue = getMaxValue(stepsArray);

      let chartData, formattedDates;

      switch (component) {
        case 'week':
          formattedDates = formatDates(data, 'ddd');
          chartData = {
            labels: formattedDates,
            datasets: [
              {
                label: '',
                backgroundColor: templateConfig.style_variables['$button-primary'],
                data: stepsArray
              }
            ]
          };
          this.weekChartData = { chartData, maxValue };
          this.setDataRange(data);
          break;

        case 'month':
          formattedDates = formatDates(data, 'D');
          chartData = {
            labels: formattedDates,
            datasets: [
              {
                label: '',
                backgroundColor: templateConfig.style_variables['$button-primary'],
                data: stepsArray
              }
            ]
          };
          this.monthChartData = { chartData, maxValue };
          this.setDataRange(data);
          break;

        case 'sixMonths':
        case 'year':
          formattedDates = formatDates(data, 'MMM');
          chartData = {
            labels: formattedDates,
            datasets: [
              {
                label: '',
                backgroundColor: templateConfig.style_variables['$button-primary'],
                data: stepsArray
              }
            ]
          };
          if (component === 'sixMonths') {
            this.sixMonthsChartData = { chartData, maxValue };
          } else {
            this.yearChartData = { chartData, maxValue };
          }
          this.setDataRange(data);
          break;
      }
    },
    setDataRange(data) {
      const headers = {
        week: 'message["step-tracker.data-rage-header.weekly"]',
        month: 'message["step-tracker.data-rage-header.monthly"]',
        sixMonths: 'message["step-tracker.data-rage-header.six-month"]',
        year: 'message["step-tracker.data-rage-header.yearly"]',
      };
      this.dataRangeHeader = this.$i18n.t(headers[this.graphComponent]);
      if (data === 'default') {
        if (this.graphComponent === 'week') {
          const sevenDaysAgo = moment().subtract(7, 'days');
          const today = moment();
          this.dataRange = `${sevenDaysAgo.format('DD MMM YYYY')} - ${today.format('DD MMM YYYY')}`;
        } else if (this.graphComponent === 'month') {
          const today = moment();
          this.dataRange = `01 ${today.format('MMM YYYY')} - ${today.format('DD MMM YYYY')}`;
        } else if (this.graphComponent === 'sixMonths') {
          const sixMonthsAgo = moment().subtract(6, 'months');
          const today = moment();
          this.dataRange = `${sixMonthsAgo.format('MMM YYYY')} - ${today.format('MMM YYYY')}`;
        } else if (this.graphComponent === 'year') {
          const sixMonthsAgo = moment().subtract(1, 'years');
          const today = moment();
          this.dataRange = `${sixMonthsAgo.format('MMM YYYY')} - ${today.format('MMM YYYY')}`;
        }
      } else {
        const formatDate = (date, formatType) => {
          const formattedDate = moment(date);
          const month = formattedDate.format('MMM');
          const year = formattedDate.year();
          const day = formattedDate.date();
          return { day, month, year };
        };

        if (this.graphComponent === 'week' || this.graphComponent === 'month') {
          const firstDate = data[data.length - 1].day;
          const lastDate = data[0].day;
          const formatType = 'short';
          const first = formatDate(firstDate, formatType === 'short');
          const last = formatDate(lastDate, formatType === 'short');

          this.dataRange = this.graphComponent === 'week'
            ? `${first.day} ${first.month} ${first.year} - ${last.day} ${last.month} ${last.year}`
            : `${first.day} ${first.month} ${first.year} - ${last.day} ${last.month} ${last.year}`;

        } else if (this.graphComponent === 'sixMonths' || this.graphComponent === 'year') {
          const formatMonth = (date, short = false) => {
            const formattedDate = moment(date + '-01');
            const month = formattedDate.format(short ? 'MMM' : 'MMMM');
            const year = formattedDate.year();
            return { month, year };
          };

          const firstMonth = data[data.length - 1].month;
          const lastMonth = data[0].month;
          const formatType = 'short';

          const first = formatMonth(firstMonth, formatType === 'short');
          const last = formatMonth(lastMonth, formatType === 'short');
          this.dataRange = this.graphComponent === 'sixMonths'
            ? `${first.month} ${first.year} - ${last.month} ${last.year}`
            : `${first.month} ${first.year} - ${last.month} ${last.year}`;
        }
      }
    }
  }

}
</script>
