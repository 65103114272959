<template>
  <page-secondary :header="headerOptions" class="gc-page-connect-wearables">
    <page-container class="container">
      <text-body-small class="gc-page-connect-wearables__description">
        {{ $t('message[\'connect-wearables.description\']') }}
      </text-body-small>
      <div class="gc-page-connect-wearables__native-links" v-if="(isWearableSupportForNativeApps && (isIOSNative || isAndroidNative) )|| isAppleHealthConnected || isHealthConnectConnected">
        <button-primary-forward-icon v-if="isIOSNative || isAppleHealthConnected"
                                     :text="$t('message[\'general.apple-health\']')"
                                     :class="isAppleHealthConnected? 'gc-page-connect-wearables__button gc-page-connect-wearables__button__connected' : 'gc-page-connect-wearables__button'"
                                     @click="showNativeConnectPopup('Apple Health', isAppleHealthConnected)"
                                     :sub-text="isAppleHealthConnected ? $t('message[\'general.connected\']') : $t('message[\'step-tracker.first-time-card-button-text\']')">
          <icon-apple-health :size="'md4'"/>
        </button-primary-forward-icon>
        <button-primary-forward-icon v-if="isAndroidNative || isHealthConnectConnected"
                                     :text="$t('message[\'general.health-connect\']')"
                                     :class="isHealthConnectConnected? 'gc-page-connect-wearables__button gc-page-connect-wearables__button__connected' : 'gc-page-connect-wearables__button'"
                                     @click="showNativeConnectPopup('Health Connect', isHealthConnectConnected)"
                                     :sub-text="isHealthConnectConnected ? $t('message[\'general.connected\']') : $t('message[\'step-tracker.first-time-card-button-text\']')">
          <icon-health-connect :size="'md4'"/>
        </button-primary-forward-icon>
        <text-body-extra-small v-if="!isHealthConnectConnected && isAndroidNative" class="gc-page-connect-wearables__native-links__description">
          {{ $t('message[\'connect-wearables.native-app.warning\']') }}</text-body-extra-small>
      </div>
      <div v-for="source in dataSources"
           :key="source.name">
        <button-primary-forward-icon @click="showPopup(source)"
                                     :class="source.connected ? 'gc-page-connect-wearables__button gc-page-connect-wearables__button__connected' : 'gc-page-connect-wearables__button'"
                                     :text="source.name"
                                     :sub-text="source.connected ? $t('message[\'general.connected\']') : $t('message[\'step-tracker.first-time-card-button-text\']')">
          <image-component class="" :src="source.image"></image-component>
        </button-primary-forward-icon>
      </div>
    </page-container>
    <popup-confirm-wearable-connect
        :source="popupData"
        v-if="connectPopupVisibility"
        :visible="connectPopupVisibility"
        @close="closePopup()"
    @success="reload()"/>
  </page-secondary>
</template>

<script>
import BaseComponent from '../../layout/template-1/global/base/BaseComponent'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer'
import pageMixin from '../../../mixins/pageMixin'
import StepTrackerService from '../../../services/StepTrackerService'
import DashBoardService from '../../../services/DashboardService'
import {mapGetters, mapMutations} from 'vuex'
import PageSecondary from '../pages/PageSecondary'
import ImageComponent from '../../root/Image'
import PageContainer from '../pages//page-sub-components/PageContainer'
import TextBodySmall from '@/components/global/typography/TextBodySmall.vue'
import TextBodyExtraSmall from '@/components/global/typography/TextBodyExtraSmall.vue'
import ButtonPrimaryForwardIcon from '@/components/global/buttons/ButtonPrimaryForwardIcon'
import PopupConfirmWearableConnect from '@/components/global/popups/PopupConfirmWearableConnect'
import {isIOS, isAndroid} from 'mobile-device-detect'
import {isMobileNativeApp} from '@/includes/NativeAppCommon'
import IconAppleHealth from '@/components/root/icons/IconAppleHealth.vue'
import IconHealthConnect from '@/components/root/icons/IconHealthConnect.vue'
import store from './../../../store/index'
import NativeAppService from "@/services/NativeAppService";
export default {
  name: 'pageConnectWearables',
  extends: BaseComponent,
  mixins: [pageMixin],
  components: {
    IconHealthConnect,
    IconAppleHealth,
    ButtonPrimaryForwardIcon,
    TextBodySmall,
    PageContainer,
    PageSecondary,
    LoadingBuffer,
    ImageComponent, PopupConfirmWearableConnect,
    TextBodyExtraSmall
  },
  data: function () {
    return {
      headerOptions: {
        show: true,
        left: 'previous',
        right: '',
        rightDisabled: false,
        modifiers: ['with-bg-color', 'small-title'],
        header: {
          mainTitle: this.$i18n.t('message["connect-wearables.title"]'),
          subTitle: ''
        },
        mainTitle: this.$i18n.t('message["connect-wearables.title"]')
      },
      loading: true,
      pageClass: ['macroapp--page-common'],
      dataSources: [],
      popupData: {},
      connectPopupVisibility: false,
      isAndroidNative: !isIOS && isMobileNativeApp(),
      isIOSNative: isIOS && isMobileNativeApp(),
      isAppleHealthConnected: false,
      isHealthConnectConnected: false,
      isWearableSupportForNativeApps: false
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    })
  },
  created () {
    this.setPageTitle(this.$t('message["connect-wearables.title"]'))
  },
  mounted () {
    let dashboard = new DashBoardService()
    if (!(dashboard.isEntriesEditable())) {
      if (!(this.getPlanExpiredPopupShow)) {
        this.showSubscriptionExpiredPopup('connect_wearables')
      }
    }
    this.headerOptions.header.mainTitle = this.$i18n.t('message["connect-wearables.title"]')
  },
  async beforeMount () {
    await this.loadData().then(() => {
      this.hideLoading()
    })
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    showPopup (source) {
      this.popupData = source
      this.connectPopupVisibility = true
    },
    async loadData () {
      this.dataLoading = true;
      const nativeApp = new NativeAppService()
      this.isWearableSupportForNativeApps = nativeApp.isSupportWearables()
      this.showLoading();
      try {
        let service = new StepTrackerService();
        await service.getConnectedDataSources()
            .then(response => {
              if (response.healthConnect) {
                this.isHealthConnectConnected = response.healthConnect.enabled;
              }
              if (response.appleHealth) {
                this.isAppleHealthConnected = response.appleHealth.enabled;
              }
            })
            .catch(error => {
              this.$store.commit('showServerErrorPopup')
              this.$router.go(-1);
            });
        let redirectUrl =   location.origin + '/wearables';

        await service.getAvailableDataSources(redirectUrl)
            .then(response => {
              this.dataSources = response.dataSources;
            })
            .catch(error => {
              this.$store.commit('showServerErrorPopup')
              this.$router.go(-1);
            });
      } catch (error) {
        this.$store.commit('showServerErrorPopup')
        this.$router.go(-1);
      } finally {
        this.hideLoading();
      }
    },
    closePopup () {
      this.connectPopupVisibility = false;
    },
    async reload () {
      try {
        await this.loadData();
      } catch (error) {
        this.$store.commit('showServerErrorPopup')
        this.$router.go(-1);
      } finally {
        this.hideLoading();
      }
    },
    showNativeConnectPopup (name, state) {
      let source = {
        connected: state,
        name: name
      };
      this.showPopup(source);
    }
  }

}
</script>
