<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    modal-class="pc-popup-content-recipe-filters gc-content-page-popup"
    ref="modal"
  >
    <popup-title>
      <popup-button-close @hide="closePopup"/>
    </popup-title>
    <popup-body>
      <popup-head class="text-center">
        <popup-text-heading>{{ $t('message["content.recipe.filters"]') }}</popup-text-heading>
      </popup-head>
      <popup-content class="gc-popup-content-page__content" style="overflow-x: hidden">
        <div>
          <div class="pc-popup-content-recipe-filters__filter-title">
            <div class="pc-popup-content-recipe-filters__filter-title-text">
              <text-body-small> {{ $t('message["content.recipe.meal-type"]') }}</text-body-small>
              <text-body-small>{{ filterTypeCategoryText }}</text-body-small>

            </div>

            <text-body-small class="pc-popup-content-recipe-filters__clear" @click="clearSelectedMealCategories">
              {{ $t('message["general.clear"]') }}
            </text-body-small>
          </div>
          <grid-row class="pc-popup-content-recipe-filters__filter-meal-types">
            <grid-col cols="6" v-for="(mealType) in mealCategories" :key="mealType"
                      class="pc-popup-content-recipe-filters__input-check">
              <input-check-box-squared :id="mealType" v-model="selected.mealCategories"/>
              <text-body-small2 weight="extra-bold">{{ mealType }}</text-body-small2>
            </grid-col>

          </grid-row>

          <div class="pc-popup-content-recipe-filters__filter-title">
            <div class="pc-popup-content-recipe-filters__filter-title-text">
              <text-body-small>{{ $t('message["content.recipe.meal-tags"]') }}</text-body-small>
              <text-body-small>{{ filterTypeCountText }}</text-body-small>
            </div>

            <text-body-small class="pc-popup-content-recipe-filters__clear" @click="clearSelectedMealTypes">
              {{ $t('message["general.clear"]') }}
            </text-body-small>
          </div>
          <grid-row style="position:relative;">
            <grid-col cols="12">
              <select2 style="width: 100%"
                       v-if="showTags"
                       ref="select2"
                       :options="mealTagOptions" :multiple="true"
                       v-model="selected.mealTypes" :select-all-if-option-count-is-one="false"
                       :place-holder="$t('message[\'content.recipe.search-tags\']')"/>
            </grid-col>

          </grid-row>


        </div>
      </popup-content>

      <popup-footer>
        <button-primary :text="$t('message[\'content.recipe.filters\']')" @click="apply"/>
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>


import NativeAppMixin from '@/mixins/NativeAppMixin'
import popupAnimationMixin from '@/mixins/popupAnimationMixin'
import LoadingBuffer from '@/components/layout/template-1/global/widgets/LoadingBuffer.vue'
import Popup from '@/components/global/popups/Popup.vue'
import PopupBody from '@/components/global/popups/popup-sub-components/PopupBody.vue'
import PopupButtonClose from '@/components/global/popups/popup-sub-components/PopupButtonClose.vue'
import PopupContent from '@/components/global/popups/popup-sub-components/PopupContent.vue'
import PopupTextBody from '@/components/global/popups/popup-sub-components/PopupTextBody.vue'
import PopupHead from '@/components/global/popups/popup-sub-components/PopupHead.vue'
import PopupTextHeading from '@/components/global/popups/popup-sub-components/PopupTextHeading.vue'
import PopupTextSubHeading from '@/components/global/popups/popup-sub-components/PopupTextSubHeading.vue'
import PopupTitle from '@/components/global/popups/popup-sub-components/PopupTitle.vue'
import TextBodySmall2 from '@/components/global/typography/TextBodySmall2.vue'
import ButtonLinkSecondary from '@/components/global/buttons/ButtonLinkSecondary.vue'
import GridRow from '@/components/global/grid/GridRow.vue'
import InputCheckBoxSquared from '@/components/global/inputs/InputCheckBoxSquared.vue'
import TextBodyMedium from '@/components/global/typography/TextBodyMedium.vue'
import TextBodyRegular from '@/components/global/typography/TextBodyRegular.vue'
import GridCol from '@/components/global/grid/GridCol.vue'
import PopupFooter from '@/components/layout/template-2/global/popups/popup-sub-components/PopupFooter.vue'
import ButtonPrimary from '@/components/global/buttons/ButtonPrimary.vue'
import InputSelect from '@/components/global/inputs/InputSelect.vue'
import Select2 from '../../../../global/inputs/Select2'
import TextBodySmall from '@/components/global/typography/TextBodySmall.vue'

export default {
  name: 'PopupContentRecipeFilters',
  mixins: [NativeAppMixin, popupAnimationMixin],
  components: {
    TextBodySmall,
    Select2,
    InputSelect,
    ButtonPrimary,
    PopupFooter,
    GridCol,
    TextBodyRegular,
    TextBodyMedium,
    InputCheckBoxSquared,
    GridRow,
    ButtonLinkSecondary,
    TextBodySmall2,
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle
  },
  props: {
    mealTypes: {
      required: true,
      default: function () {
        return []
      },
      type: Array
    },
    mealCategories: {
      required: true,
      default: function () {
        return []
      },
      type: Array
    },
    selectedMealTypes: {
      required: true,
      default: function () {
        return []
      },
      type: Array
    },
    selectedMealCategories: {
      required: true,
      default: function () {
        return []
      },
      type: Array
    }
  },
  data: function () {
    return {
      showTags: true,
      visible: true,
      title: '',
      selected: {
        mealTypes: [],
        mealCategories: []
      }
    }
  },
  computed: {
    filterTypeCountText: function () {
      if (this.selected.mealTypes.length === 0) {
        return ''
      }

      return this.selected.mealTypes.length !== this.mealTypes.length ? `(${this.selected.mealTypes.length})` : this.$i18n.t("message['general.all']")
    },

    filterTypeCategoryText: function () {
      if (this.selected.mealCategories.length === 0) {
        return ''
      }
      return this.selected.mealCategories.length !== this.mealCategories.length ? `(${this.selected.mealCategories.length})` : this.$i18n.t("message['general.all']")
    },


    mealTagOptions: function () {
      return this.mealTypes.map(cat => {
        return {
          id: cat,
          text: cat
        }
      })
    },
    mealTypeOptions: function () {
      return this.mealCategories.map(cat => {
        return {
          id: cat,
          text: cat
        }
      })
    }

  },
  mounted() {
    this.selected.mealTypes = [...this.selectedMealTypes]
    this.selected.mealCategories = [...this.selectedMealCategories]
    setTimeout(() => {
      if (this.$refs && this.$refs.modal) {
        this.$refs.select2.resetOptionWrapperHeight()
        this.$nextTick(() => {
          this.$refs.modal.setContentHeight()
        })
      }
    }, 3500)
  },
  methods: {
    apply() {

      this.closePopup()
      this.$emit('updated', this.selected)
    },
    clearSelectedMealTypes() {
      this.selected.mealTypes = []
      this.showTags = false
      this.$nextTick(() => {
        this.showTags = true

      })
    },
    clearSelectedMealCategories() {

      this.selected.mealCategories = []

    },


    closePopup(e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        this.visible = false
      })
    }
  }
}
</script>
