import { render, staticRenderFns } from "./PageAddPhotos.vue?vue&type=template&id=95fdd3d2&scoped=true"
import script from "./PageAddPhotos.vue?vue&type=script&lang=js"
export * from "./PageAddPhotos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95fdd3d2",
  null
  
)

export default component.exports