import BaseComponent from '../../../../components/layout/template-1/global/base/BaseComponent'
import { mapGetters, mapMutations } from 'vuex'
import { isMeasurementWeightEnabled, isMeasurementShouldersEnabled, isMeasurementArmsEnabled, isMeasurementChestEnabled, isMeasurementWaistEnabled, isMeasurementHipEnabled, isMeasurementCalvesEnabled, isMeasurementLegsEnabled, isMeasurementFatEnabled } from '../../../../includes/TemplateSettings'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'

export default {
  extends: BaseComponent,
  data: function () {
    return {
      isEditable: true,
      isLoaded: false,
      page: 'fitness-diary-home',
      pageClass: ['macroapp--page-common', 'app-background-color'],
      error: '',
      fitnessData: [],
      cardsToHide: ['activity'],
      updatePopup: {
        show: false,
        type: '',
        data: 0
      },
      headerOptions: {
        show: true,
        left: 'menu',
        right: '',
        rightDisabled: true,
        helpContent: '',
        modifiers: ['with-bg-color', 'fixed-top'],
        formData: {
          image: ''
        },
        header: {
          mainTitle: 'My Journey',
          subTitle: ' '
        }
      }
    }
  },
  beforeMount () {
    this.checkFlagValues()
  },

  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    ...mapMutations({
      showServerErrorPopup: 'showServerErrorPopup',
      hideServerErrorPopup: 'hideServerErrorPopup'
    }),
    getData () {
      const service = this.service()
      service.getDataFromAPI().then(() => {
        this.fitnessData = Object.values(service.getData())
        this.afterGetFitnessData()
      }).catch(() => {
        this.$router.replace({ path: '/dashboard' })
        this.afterGetFitnessDataError()
      }).finally(() => {
        this.isPaidUser().then(() => {
          this.hideLoading()
          this.isLoaded = true
          pageReadyEvent()
        })

      })
    },
    afterGetFitnessData () {

    },
    afterGetFitnessDataError () {

    },
    dataUpdated () {
      setTimeout(() => {
        this.updatePopup.show = false
      }, 2000)
    },
    getCardProperties (data) {
      return { data: data }
    },
    requestUpdate (type) {
      if (type) {
        const currentData = this.fitnessData.find(data => {
          return data.getType() === type
        })
        if (currentData) {
          this.updatePopup.show = true
          this.updatePopup.type = currentData.getType()
          this.updatePopup.data = currentData
        }
      }
    },
    checkFlagValues () {
      // Height should be hidden in my journey page irrespective of flag values
      this.cardsToHide.push('height')

      if (!isMeasurementWeightEnabled()) {
        this.cardsToHide.push('weight')
      }

      if (!isMeasurementShouldersEnabled()) {
        this.cardsToHide.push('shoulders')
      }

      if (!isMeasurementArmsEnabled()) {
        this.cardsToHide.push('arms')
      }

      if (!isMeasurementChestEnabled()) {
        this.cardsToHide.push('chest')
      }

      if (!isMeasurementWaistEnabled()) {
        this.cardsToHide.push('waist')
      }

      if (!isMeasurementHipEnabled()) {
        this.cardsToHide.push('hips')
      }

      if (!isMeasurementCalvesEnabled()) {
        this.cardsToHide.push('calves')
      }

      if (!isMeasurementLegsEnabled()) {
        this.cardsToHide.push('legs')
      }

      if (!isMeasurementFatEnabled()) {
        this.cardsToHide.push('body_fat')
      }
    }
  }
}
