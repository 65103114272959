import moment from 'moment'
import FoodDiaryService from '../../../../services/FoodDiaryService'
import {mapGetters, mapMutations} from 'vuex'
import NutritionService from '../../../../services/nutrition/NutritionService'
import logMealMixin from '@/mixins/component-mixins/page/food-diary/logMealMixin'

export default {
  mixins: [logMealMixin],
  props: {
    subHeading: {
      default: ''
    },
    supportNetCarbs: {
      default: false,
      type: Boolean
    },
    mealLog: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: function () {
    return {
      currentDay: null,
      service: new FoodDiaryService(),
      state: 'init',
      error: '',
      mealPlanID: 0,
      days: [],
      selectedDayID: 0,
      imageError: [],
      newMealText: '',
      dayText: '',
      selectedOptionText: '',
      formData: {
        name: '',
        image: '',
        calories: null,
        saved: true,
        mealIngredients: '',
        macronutrients: {
          fats: null,
          carbohydrates: null,
          proteins: null,
          net_carbohydrates: null,
          show_net_carbs: false
        }
      },
      showTextToMealPopup: false,
      showNutritionAnalysisCard: false,
      individualMacros: [],
      mealGenerationState: 'init'
    }
  },
  watch: {
    mealLog: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.setMealPlanIDAndDays()
        this.dayText = moment(value.logged_date).format('ll')
        if (this.dayText === moment().format('ll')) {
          this.dayText = this.$i18n.t('message["general.today"]').toLowerCase()
        }
      }
    },
    formData: {
      deep: true,
      handler: function (value) {
        if (value.macronutrients.proteins) {
          this.formData.macronutrients.proteins = parseFloat(value.macronutrients.proteins.toString().substring(0, 7)) || 0
        }
        if (value.macronutrients.carbohydrates) {
          this.formData.macronutrients.carbohydrates = parseFloat(value.macronutrients.carbohydrates.toString().substring(0, 7)) || 0
        }
        if (value.macronutrients.fats) {
          this.formData.macronutrients.fats = parseFloat(value.macronutrients.fats.toString().substring(0, 7)) || 0
        }
        if (value.macronutrients.net_carbohydrates) {
          this.formData.macronutrients.net_carbohydrates = parseFloat(value.macronutrients.net_carbohydrates.toString().substring(0, 7)) || 0
        }
        if (value.calories) {
          this.formData.calories = parseFloat(value.calories.toString().substring(0, 7)) || 0
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'getPreviousRoute'
    }),
    optionalText: function () {
      if(this.$t('message[\'general.form.optional-with-brackets\']') !== '(Optional)'){
        return this.$t('message[\'general.form.optional-with-brackets\']')
      }

      return '('+this.$t('message[\'general.form.optional\']')+')'
    },
  },
  methods: {
    ...mapMutations({
      setLastSelectedMeals: 'foodDiaryStore/setLastSelectedMeals'
    }),
    setProteinsValue (value) {
      this.formData.macronutrients.proteins = value
    },
    setCarbohydratesValue (value) {
      this.formData.macronutrients.carbohydrates = value
    },
    setFatsValue (value) {
      this.formData.macronutrients.fats = value
    },
    setNetCarbohydratesValue (value) {
      this.formData.macronutrients.net_carbohydrates = value
    },
    setCaloriesValue (value) {
      this.formData.calories = value
    },
    setMealPlanIDAndDays () {
      let mealPlanService = new NutritionService()
      const latestMealPlan = mealPlanService.getLatestMealPlan()
      latestMealPlan.getData().then(() => {
        this.mealPlanID = latestMealPlan.getId()
        this.days = latestMealPlan.getDays()
        let selectedDay = this.getSelectedDayByRequiredMacronutrientsAndCalories()
        if (selectedDay && selectedDay.getId()) {
          this.selectedDayID = selectedDay.getId()
        }
        this.setSelectedOptionDayText()
      })
    },
    getSelectedDayByRequiredMacronutrientsAndCalories () {
      let currentRequired = this.mealLog.required
      return this.days.find(day => {
        return day.getCalories() === currentRequired.calories &&
          day.getMacroNutrients().carbohydrates === currentRequired.macronutrients.carbohydrates &&
          day.getMacroNutrients().fats === currentRequired.macronutrients.fats &&
          day.getMacroNutrients().proteins === currentRequired.macronutrients.proteins
      })
    },
    setSelectedOptionDayText () {
      let selectedDay = this.getSelectedDayByRequiredMacronutrientsAndCalories()
      if (selectedDay) {
        this.selectedOptionText = this.numberWordToNumeric(selectedDay.getName())
      } else {
        this.selectedOptionText = this.$i18n.t('message["food-diary.another-option"]')
      }
    },
    uploadedImage (image) {
      this.formData.image = image
      this.imageError = []
    },
    removedImage () {
      this.formData.image = ''
    },
    setImageUploadError (errors) {
      this.imageError = errors
    },
    submitForm () {
      if (this.state !== 'submitting') {
        this.state = 'submitting'
        this.$emit('submitting')
        if (!this.formData.macronutrients.net_carbohydrates) {
          this.formData.macronutrients.net_carbohydrates = 0
        }

        this.service.saveRecipe(this.formData).then(recipeData => {
          this.logEvent('FoodDiary.CustomMealAdded', {})
          this.saveMeal(recipeData.recipe)
        }).catch(err => {
          this.state = 'error'
          this.handleErrors(err)
        })
      }
    },
    handleErrors (err) {
      if (err.response.status === 422 && err.response.data.type === 'data') {
        this.$refs.form.setErrors(err.response.data.errors)
        if (err.response.data.errors.image) {
          this.imageError = err.response.data.errors.image
        }
        this.error = err.response.data.message
      } else if (err.response.status === 422 && err.response.data.type === 'logic') {
        this.error = err.response.data.errors
      } else {
        this.error = err.response.data.message || this.$i18n.t('message[\'general.common-error-message\']')
      }
    },
    backButtonClick () {
      this.setLastSelectedMeals(null)
      this.$emit('request-meal-plan', {})
      this.$emit('changed')
    },
    saveMeal (recipe) {
      let mealName = this.newMealText
      let mealData = {
        name: this.formData.name,
        saved: this.formData.saved,
        type: {
          id: 0,
          name: mealName
        },
        recipes: [recipe]
      }
      this.service.saveMeal(mealData).then(meal => {
        let mealLog = this.mealLog
        if (mealLog.length === 0) {
          mealLog.required = {
            macronutrients: this.currentDay.macronutrients,
            calories: this.currentDay.calories
          }
        }
        mealLog.logged_meals.push({
          id: meal.meal._id,
          time: moment().locale('en').format('HH:mm'),
          date: moment().locale('en').format('YYYY-MM-DD'),
          mealType: 'custom'
        })
        this.service.saveMealLog(mealLog).then(log => {
          this.mealLog = log.log
          this.state = 'success'
          this.$emit('log', log.log)
          this.setUserEventCustomMealAdded(this.formData.name, !!this.formData.image)
          this.$emit('success', {type: 'custom-meal', saved: this.formData.saved})
        }).catch(err => {
          this.state = 'error'
          this.handleErrors(err)
        })
      }).catch(err => {
        this.state = 'error'
        this.handleErrors(err)
      })
    },
    setDay (day) {
      this.currentDay = day
      this.setSelectedOptionDayText()
    },
    setUserEventCustomMealAddingInitiated () {
      let context = (this.previousRoute.path.includes('/dashboard')) ? 'dashboard' : 'food-diary'
      this.logEvent('FoodDiary.CustomMealAddingInitiated', {
        'context': context
      })
    },
    setUserEventCustomMealAdded (name, hasImage) {
      let context = (this.previousRoute.path.includes('/dashboard')) ? 'dashboard' : 'food-diary'
      this.logEvent('FoodDiary.SavedMealAdded', {
        'context': context,
        'name': name,
        'has_image': hasImage,
        'is_saved': this.formData.saved
      })
    },
    getNutritionAnalysis(ingredients) {
      const ingredientsArray = ingredients.split(/[\n,]+/).filter(ingredient => ingredient.trim() !== '');
      this.mealGenerationState = 'submitting'
      this.service.getNutritionAnalysis({ingredients: ingredientsArray}).then(data => {
          this.setCaloriesValue(data.macros.totalMacros.calories)
          this.setProteinsValue(data.macros.totalMacros.protein)
          this.setCarbohydratesValue(data.macros.totalMacros.carbs)
          this.setFatsValue(data.macros.totalMacros.fat)
          this.setNetCarbohydratesValue(data.macros.totalMacros.netCarbs)

          this.formData.name = data.name
          this.formData.image = data.image
          this.individualMacros = data.macros.individualMacros
          this.showNutritionAnalysisCard = true
          this.showTextToMealPopup = false
          this.logEvent('FoodDiary.SmartAddLookedUp', {
            'successful': true
          })
      }

      ).catch(err => {
        this.error = err.response.data.message || this.$i18n.t("message['general.common-error-message']")
        this.logEvent('FoodDiary.SmartAddLookedUp', {
          'successful': false
        })
      }).finally(() => {
        this.mealGenerationState = 'init'
      })
    },

    handleMealGeneration(mealIngredients) {
      this.getNutritionAnalysis(mealIngredients)
    },

    clearError() {
      this.error = ''
    }

  }

}
