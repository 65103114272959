<template>
  <div :class="componentClasses" >
    <loading-buffer
      v-if="status === 'loading'"
      :height="40"
      class="gc-card-subscription-plan__loading-buffer" />

    <div
      v-else
      class="gc-card-subscription-plan__container">
      <icon-complete-filled
        :size="'sm4'"
        class="gc-card-subscription-plan__icon" />

      <div class="gc-card-subscription-plan__content">
        <text-body-regular
          :weight="'extra-bold'"
          :line-height="'multi'"
          class="gc-card-subscription-plan__content-title">{{ title }}</text-body-regular>
        <text-body-extra-small
          :line-height="'multi'"
          class="gc-card-subscription-plan__content-period"><div v-html="planType"/></text-body-extra-small>
        <!-- When discount is not applied -->
        <text-body-small
          v-if="discountedPrice===price"
          :weight="'extra-bold'"
          :line-height="'multi'"
          class="gc-card-subscription-plan__content-price">
          {{ planPrice }} <text-body-extra-small style="margin-left: 4px;margin-right: 4px">{{ billingText }}</text-body-extra-small>
        </text-body-small>
        <!-- When discount is applied -->
        <text-body-small
          v-else-if="isOneOffCoupon&&type === 'subscription'"
          :weight="'extra-bold'"
          :line-height="'multi'"
          class="gc-card-subscription-plan__content-price" style="flex-direction: column;">
          <div>
            <text-body-small weight="'extra-bold'">{{ planPrice }}  <text-body-extra-small style="margin-left: 4px;margin-right: 4px">{{ $t('message["sign-up.one-time-discount"]') }}</text-body-extra-small></text-body-small>
          </div>

         <span>{{ currency + ' ' + price.toFixed(2) }} {{billingTextOneOffCoupon}}</span>
        </text-body-small>
        <text-body-small
          v-else
          :weight="'extra-bold'"
          :line-height="'multi'"
          class="gc-card-subscription-plan__content-price">
          <span class="gc-card-subscription-plan__content-price-discounted">{{ currency + ' ' + price.toFixed(2) }}</span>
          <text-body-small weight="'extra-bold'">{{ planPrice }}  <text-body-extra-small style="margin-left: 4px;margin-right: 4px">{{ billingText }}</text-body-extra-small></text-body-small>
        </text-body-small>
        <text-body-small
          v-if="trialPeriod"
          :weight="'extra-bold'"
          :line-height="'multi'"
          class="gc-card-subscription-plan__content-trial-period">
          <span class="gc-card-subscription-plan__content-trial-period-text">{{ $i18n.t('message["sign-up.plan-card.trial-period"]') }}</span>
          {{ trialPeriodText }}
        </text-body-small>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBuffer from '../../../layout/template-1/global/widgets/LoadingBuffer'
import IconCompleteFilled from '../../../root/icons/IconCompleteFilled'
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall'
import TextBodyRegular from '../../typography/TextBodyRegular'
import TextBodySmall from '../../typography/TextBodySmall'
export default {
  name: 'CardSubscriptionPlan',
  components: { TextBodyRegular, TextBodyExtraSmall, IconCompleteFilled, TextBodySmall, LoadingBuffer },
  props: {
    title:
     {
       type: String,
       default: '',
       required: true
     },
    isOneOffCoupon:
     {
       type: Boolean,
       default: true,
     },
    type: {
      type: String,
      default: 'subscription',
      required: true
    },
    period: {
      type: String,
      default: '-',
      required: false
    },
    price: {
      type: Number,
      default: 20,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    status: {
      type: String,
      default: 'default'
    },
    discountedPrice: {
      default: 0,
      type: Number
    },
    trialPeriod: {
      default: 0,
      type: Number
    }
  },
  computed: {
    trialPeriodText: function () {
      if(this.trialPeriod === 1){
        return '1 '+this.$i18n.tc('message["general.number-of-days"]',this.trialPeriod,{number:this.trialPeriod})
      }
      return this.$i18n.tc('message["general.number-of-days"]',this.trialPeriod,{number:this.trialPeriod})
    },
    planType: function () {
      if (this.type === 'subscription') {
        return this.$i18n.t('message["sign-up.recurring-payment"]')
      } else if (this.type === 'one-off') {
        return this.$i18n.t('message["sign-up.one-off-purchase"]')
      }
      return ''
    },
    planPrice:function (){
      if (this.discountedPrice !== this.price){
        return this.currency + ' ' + this.discountedPrice.toFixed(2)
      }

      return this.currency + ' ' + this.price.toFixed(2)
    },
    billingText: function () {
      if (this.type === 'subscription') {
        const {number, unit} = this.extractNumberAndUnitFromPeriod(this.period)
        let billingCycle = this.period
        if(unit==='day'){
          billingCycle = this.$i18n.tc('message["general.number-of-days"]',number,{number})
        } else if(unit==='week'){
          billingCycle = this.$i18n.tc('message["general.number-of-weeks"]',number,{number})
        } else if(unit==='month'){
          billingCycle = this.$i18n.tc('message["general.number-of-months"]',number,{number})
        } else if(unit==='year'){
          billingCycle = this.$i18n.tc('message["general.number-of-years"]',number,{number})
        }
        return this.$i18n.t('message["sign-up.recurring-payment-billing-cycles"]',{billingCycle})
      }
      return ''
    },
    billingTextOneOffCoupon: function () {
      if (this.type === 'subscription') {
        const {number, unit} = this.extractNumberAndUnitFromPeriod(this.period)
        let billingCycle = this.period
        if(unit==='day'){
          billingCycle = this.$i18n.tc('message["general.number-of-days"]',number,{number})
        } else if(unit==='week'){
          billingCycle = this.$i18n.tc('message["general.number-of-weeks"]',number,{number})
        } else if(unit==='month'){
          billingCycle = this.$i18n.tc('message["general.number-of-months"]',number,{number})
        } else if(unit==='year'){
          billingCycle = this.$i18n.tc('message["general.number-of-years"]',number,{number})
        }
        return this.$i18n.t('message["sign-up.recurring-payment-billing-cycles-after"]',{billingCycle})
      }
      return ''
    },
    priceText: function () {
      let values = {
        currency: this.currency,
        price: this.price.toFixed(2)
      }
      if (this.discountedPrice !== this.price) {
        values.price = this.discountedPrice.toFixed(2)
      }
      if (this.type === 'subscription') {
        return this.$i18n.t('message["sign-up.plan-card.subscription.price"]', values)
      } else if (this.type === 'one-off' || this.type === 'challenge') {
        return this.$i18n.t('message["sign-up.plan-card.one-off.price"]', values)
      }
      return ''
    },
    componentClasses: function () {
      return {
        'gc-card-subscription-plan': true,
        'gc-card-subscription-plan__loading': this.status === 'loading'
      }
    }
  },
   methods:{
     extractNumberAndUnitFromPeriod(text) {
       const match = text.match(/(\d+)\s*(day|week|month|year)/i);
       return match ? { number: parseInt(match[1], 10), unit: match[2] } : null;
     }
   }

}
</script>

<style>

</style>
