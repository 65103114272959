<template>
  <div
      class="gc-slider-navigation"
      ref="slider"
  >
    <slick
        :class="sliderClass"
        ref="slick"
        :options="slickOptions"
        @afterChange="handleAfterChange"
        @beforeChange="handleBeforeChange"
        @breakpoint="handleBreakpoint"
        @destroy="handleDestroy"
        @edge="handleEdge"
        @init="handleInit"
        @reInit="handleReInit"
        @setPosition="handleSetPosition"
        @swipe="handleSwipe"
        @lazyLoaded="handleLazyLoaded"
        @lazyLoadError="handleLazeLoadError">
      <div

          v-for="(item, index) in links"
          :key="index"
          @click="clickLink(index)"
      >
        <div class="gc-slider-navigation__slide" :class="{'gc-slider-navigation__slide--active': item.id ===currentActiveLinkID}">
          <div class="gc-slider-navigation__content" :class="{'gc-slider-navigation__content--active': item.id ===currentActiveLinkID}">
            <icon-completed-filled class="gc-slider-navigation__slide-link-icon gc-slider-navigation__slide-link-icon-completed"
                                   v-if="item.completed"
                                   :size="'sm1'"></icon-completed-filled>
            <text-content class="gc-slider-navigation__slide-link"
                          :class="{'gc-slider-navigation__slide-link--active': item.id ===currentActiveLinkID}"
                          :line-height="'multi'" :size="'sm1'" :weight="'medium'">{{limitText(item.text)}}

            </text-content>
          </div>
        </div>
      </div>
    </slick>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import CalenderSliderCalDay from './calender-slider-sub-components/CalenderSliderCalDay'
import { slickMixin } from '@/mixins/slickMixin'
import LoadingBuffer from '../../layout/template-1/global/widgets/LoadingBuffer.vue'
import TextContent from '../../root/TextContent'
import IconInfo from '../../root/icons/IconInfo'
import { mapMutations } from 'vuex'
import IconCompleted from '../../root/icons/IconComplete.vue'
import IconCompletedFilled from '../../root/icons/IconCompletedFilled'
import SliderService from '../../../services/SliderService'

export default {
  name: 'SliderNavigation',
  mixins: [slickMixin],
  components: {
    IconCompletedFilled,
    IconCompleted,
    IconInfo,
    TextContent,
    LoadingBuffer,
    Slick,
    CalenderSliderCalDay
  },
  props: {
    /**
     * array of links
     * [{text,id,info}]
     */
    links: {
      type: Array
    },
    slidesToShow: {
      default: 4.5
    },
    isWeekSlider: {
      default: false
    },
    /**
     * active link ID
     */
    activeLinkID: {}
  },
  created () {
    this.slickOptions.rtl = this.isRTL()
    this.currentActiveLinkID = this.activeLinkID
  },
  data () {
    return {
      show: false,
      currentActiveLinkID: true,
      currentSlide: null,
      slickOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: false,
        arrows: false,
        cssEase: 'ease-out',
        centerMode: false,
        touchThreshold: 40,
        rtl: false,
        responsive: [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 8,
              slidesToScroll: 8
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 350,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      }
    }
  },
  watch: {
    links: {
      deep: true,
      handler: function () {
        if (this.$refs.slick && this.links.length > 0) {
          this.$refs.slick.destroy()
          this.$nextTick(() => {
            let ele = null
            if (!this.$refs.slider) {
              return
            }
            this.$refs.slider.querySelectorAll('.gc-slider-navigation__slide').forEach((i) => {
              if (ele === null || (ele && ele.offsetWidth < i.offsetWidth)) {
                ele = i
              }
            })
            let sliderWidth = this.$refs.slider.offsetWidth

            if (ele && ele.offsetWidth) {
              if (3 * (ele.offsetWidth + 12) < sliderWidth) {
                this.slickOptions.slidesToScroll = 3
                this.slickOptions.responsive = this.slickOptions.responsive.map(item => {
                  item.settings.slidesToScroll = 3
                  return item
                })
              } else if (2 * (ele.offsetWidth + 12) < sliderWidth) {
                this.slickOptions.slidesToScroll = 2
                this.slickOptions.responsive = this.slickOptions.responsive.map(item => {
                  item.settings.slidesToScroll = 2
                  return item
                })
              } else {
                this.slickOptions.slidesToScroll = 1
                this.slickOptions.responsive = this.slickOptions.responsive.map(item => {
                  item.settings.slidesToScroll = 1
                  return item
                })
              }
            }
            this.$refs.slick.create()
            this.goToActiveSlide()
          })
        }
      }
    },
    activeLinkID: {
      deep: true,
      handler: function () {
        if (this.$refs.slick && this.links.length > 0) {
          this.currentActiveLinkID = this.activeLinkID
          this.goToActiveSlide()
        }
      }
    }
  },
  beforeMount () {
    if (this.isWeekSlider && this.links.length < 4) {
      this.slickOptions.responsive = [
        {
          breakpoint: 1000,
          settings: "unslick"
        },
        {
          breakpoint: 600,
          settings: "unslick"
        },
        {
          breakpoint: 400,
          settings: "unslick"
        }
      ]
      this.slickOptions= {
        ...this.slickOptions,
        initialSlide: 0,
        focusOnSelect: false,
        swipe: false,
        swipeToSlide: false,
        touchMove: false,
        draggable: false,
        accessibility: false,
        arrows: false
      }
    }
  },
  mounted () {
    this.show = true
    this.currentActiveLinkID = this.activeLinkID
  },
  computed: {
    sliderClass: function () {
      return {
        'gc-slider-navigation__slider': !this.links.length < 4,
        'gc-slider-navigation__slider-unslicked': this.isWeekSlider && this.links.length < 4 ,
        'gc-slider-navigation__three-weeks': this.links.length === 3 && !this.isTablet && !this.isDesktop
      }
    }
  },
  methods: {
    ...mapMutations({
      setInstructionsPopupContent: 'instructionPopupStore/setContent',
      viewInstructionsPopup: 'instructionPopupStore/show'
    }),
    /**
     *
     * @param info -array of info text html
     */
    showInfo (info) {
      let instructionsPopupContent = {
        instructions: info
      }
      this.setInstructionsPopupContent(instructionsPopupContent)
      this.viewInstructionsPopup()
    },
    handleInit () {
      this.$nextTick(() => {
        this.goToActiveSlide()
      })
    },
    goToActiveSlide () {
      let currentIndex = this.getCurrentActiveLinkIndex()
      if (currentIndex >= 0 &&this.links.length > 3 && this.$refs.slick) {
        this.$refs.slick.goTo(currentIndex)
      }
    },
    limitText (text, limit = 37) {
      if (typeof text !== 'string') {
        return text
      }
      return (text.length < limit) ? text : (text.substring(0, limit) + '...')
    },
    getCurrentActiveLinkIndex () {
      let current = this.links.findIndex(link => {
        return this.currentActiveLinkID === link.id
      })
      return parseInt((current / this.slickOptions.slidesToScroll)) * this.slickOptions.slidesToScroll
    },
    clickLink (index) {
      this.currentActiveLinkID = this.links[index].id

      // this.goToActiveSlide()

      this.currentSlide = this.getCurrentActiveLinkIndex()
      this.$emit('click-link', this.currentActiveLinkID)
    },
    handleAfterChange (event, slick, currentSlide) {
      this.currentSlide = currentSlide
      if (this.slick.sliderType) {
        const service = new SliderService()
        service.setCurrentSlide(this.slick.sliderType, this.slick.sliderID, currentSlide)
      }
    }

  },
  beforeDestroy () {
    this.show = false
    this.$refs.slick.destroy()
  }
}
</script>

<style scoped>

</style>
