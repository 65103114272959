<template>
  <div class="pc-video-container">
    <div class="pc-video-container__card-search" v-if="noDelayTags && noDelayTags.length>0">
      <!-- <text-content :size="'sm1'" :lineHeight="'multi'" class="pc-video-container__card-search-text">{{$t("message['settings.filter-tags']")}}</text-content> -->
      <div class="form-group ma-form">
        <select2 v-if="noDelayTags && noDelayTags.length !== 0" :multiple="true" :options="noDelayTags" :select-all-if-option-count-is-one="false" v-model="selectedTag" :placeHolder="$t('message[\'custom-page-search-tag\']')"></select2>
      </div>
    </div>
    <div class="pc-video-container__video-list"  v-if="videos && videos.length > 0">
        <page-video-area
                         v-for="video in videos"
                         v-bind:key="video.id"
                         :class="['pc-video-container__video',video.id]"
                         :load-video-player="newPlayerIds.includes(video.id)"
                         :id="video.id"
                         v-if="selectedTag && selectedTag.length ===0 || showVideo(video.tags) "
                         :video="video" ref="video"  />

    </div>
  </div>
</template>

<script>
import Select2 from '../../../../global/inputs/Select2'
import PageVideoArea from './PageVideoArea'
import DashboardService from '../../../../../services/DashboardService'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'
import moment from 'moment'
import TextContent from '../../../../root/TextContent'
import lodash from 'lodash'
export default {
  name: 'PageVideoContainer',
  components: { Select2, PageVideoArea, TextContent },
  mixins: [NativeAppMixin],
  props: {
    tags: {},
    videoList: {}
  },
  data: function () {
    return {
      playerIds: ['video1', 'video2', 'video0'],
      newPlayerIds: ['video1', 'video2', 'video0'],
      selectedTag: [],
      videos: [],
      noDelayTags: [],
      isScrolling: null,
      scrollState: false,
      convert: {
        m: 60000,
        h: 3600000,
        d: 86400000,
        w: 604800000,
        y: 31536000000
      }
    }
  },
  watch: {
    scrollState: function (o, n) {
      if (!this.scrollState) {

      }
    },
    videoList (newList, oldList) {
      if (oldList.length === 0 && newList.length > 0) {
        const service = new DashboardService()
        service.getUserData()
          .then(response => {
            const compare = (response.subscriptions && response.subscriptions[0]) ? response.subscriptions[0].created_at : moment().format()
            this.startDate = new Date(compare).getTime()
            const videos = newList
            const filter = this.filterVideos(videos)
            this.noDelayTags = filter.tags
            const newVideos = filter.videos
            this.videos = newVideos.map((video, index) => {
              video.id = 'video' + index
              return video
            })

            this.$nextTick(() => {
              const options = {
                root: null,
                rootMargin: '20px',
                threshold: 0.01
              }
              const observer = new IntersectionObserver(this.callback, options)

              document.querySelectorAll('.pc-video-container__video').forEach((i) => {
                if (i) {
                  observer.observe(i)
                }
              })
            })
          })
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.resizeVideoListener()
    document.querySelector('body')
      .addEventListener('click', this.linkNavigateClickHandler, true)
  },
  methods: {
    handleScroll (e) {
      // Clear the existing timer
      clearTimeout(this.isScrolling)

      // Execute the startScroll function when scrolling starts
      if (!this.isScrolling) {
        this.startScroll()
      }

      // Set a timer to detect the end of scrolling
      this.isScrolling = setTimeout(() => {
        this.isScrolling = null
        this.endScroll()
      }, 100) // Adjust the delay as needed
    },
    endScroll () {
      // Your code for the end of scrolling
      this.scrollState = false
      const aaa = [...this.playerIds]
      this.newPlayerIds = aaa
    },
    startScroll () {
      // Your code for the start of scrolling
      this.scrollState = true
    },
    callback (entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.playerIds.push(entry.target.id)
        } else {
          const index = this.playerIds.indexOf(entry.target.id)
          if (index > -1) {
            this.playerIds.splice(index, 1)
          }
        }
      })
    },
    generateRandomID (length = 8) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      let randomID = ''

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        randomID += characters.charAt(randomIndex)
      }

      return randomID
    },
    resizeVideoListener () {
      window.addEventListener('resize', this.resizeVideos)
    },
    resizeVideos () {
      this.videos.forEach((video, index) => {
        if (this.$refs.video[index]) {
          this.$refs.video[index].resizeVideo()
        }
      })
    },
    showVideo (VideoTag) {
      const res = VideoTag.findIndex(ele => {
        return this.selectedTag.includes(ele)
      })

      return (res >= 0)
    },
    filterVideos (videos) {
      let noDelayTags = []
      const filteredVideos = videos.filter((video) => {
        let show = true
        const tags = video.tags
        if (tags) {
         tags.forEach((tag, j) => {
            const tdelay = tags[j].match(/^delay(\d+)(m|h|d|w|y)$/)
            if (tdelay) {
              show = show && this.isVideoVisibleToUser( tdelay)
            } else {

              noDelayTags.push({ id: tag, text: tag })

            }
          })
        }

        return show
      })
      return { videos: filteredVideos, tags: lodash.uniqBy(noDelayTags,'id') }
    },
    isVideoVisibleToUser ( tdelay) {
      const amt = tdelay[1]
      const period = tdelay[2]
      let now = new Date()
      if (period === 'd' || period === 'w' || period === 'y') {
        now.setHours(0, 0, 0, 0)
        now = now.getTime()
      } else {
        now = now.getTime()
      }
      const compare = now - amt * this.convert[period]
     return (compare > this.startDate)

    },
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.video-container .content-text a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeVideos)
    document.querySelector('body')
      .removeEventListener('click', this.linkNavigateClickHandler, true)
  }
}
</script>
