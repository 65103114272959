import customConfig from "../config/custom-config";
import { isMobileNativeApp } from "./includes/NativeAppCommon";
import {
  showSignUpFlow,
  showSignUpFlowForAllTypes,
  showSubscriptionForm,
} from "./includes/TemplateSettings";

const resourcesHost = customConfig.resources_base_url;
const apiUrl = customConfig.api_url;
const apiV2Url = customConfig.api_v2_url;
const habitsTrackerApiUrl = customConfig.habits_tracker_api_url;
const stepTrackerAPIUrl = customConfig.step_tracker_api_url || 'https://api-dev.macroactive.com/wearable/2024-07-03/'
const memberProgressApiUrlV1 = customConfig.member_progress_api_url_v1;
const memberProgressApiUrl = customConfig.member_progress_api_url;
const communityAPIUrl =
  customConfig.community_api_url ||
  "https://api.macroactive.com/community/2023-08-02/";
const periodTrackerAPIUrl = customConfig.period_tracker_api_url;
const macroactivistApiUrl = customConfig.macroactivist_api_url
  ? customConfig.macroactivist_api_url
  : "https://engage-crm-api-staging.macroactivemvp.com/api/v1/";
const feedBackAPIURL = customConfig.feedBackAPIURL
  ? customConfig.feedBackAPIURL
  : "https://customer-feedback.macroactivemvp.com/api/v1/feedback";
const featureUpdatesUrl = customConfig.feature_updates_url;
const chatApiUrl = customConfig.chat_api_url;
const chatAppId = customConfig.chat_app_id;
const pluginFilesUrl = customConfig.plugin_files_url;
const shoppingListEmailAPIurl = customConfig.email_system_api_url
  ? customConfig.email_system_api_url
  : "https://crimson-cloud-qhkhuud8roib.vapor-farm-d1.com/api/";
const foodDiaryAPIURL = customConfig.food_diary_api_url
  ? customConfig.food_diary_api_url
  : "https://food-diary.macroactivemvp.com/api/";
const communityAssetURL = customConfig.community_asset_url
  ? customConfig.community_asset_url
  : "/static/assets/community/";
const chatAssetURL = customConfig.chat_asset_url
  ? customConfig.chat_asset_url
  : "/static/assets/chat/";

let urlsToSkip = {
  signUp: "/subscribe/:plan/sign-up",
  signUpPay: "/subscribe/:plan/pay",
  signUpThankYou: "/subscribe/:subscription/thank-you",
};

const string = window.location.origin;
const substring = "macroactivemvp.com";

if (
  string.indexOf(substring) !== -1 ||
  process.env.NODE_ENV === "development"
) {
  // do nothing
} else if (isMobileNativeApp()) {
  urlsToSkip = {
    signUp: "/subscribe/:planID/sign-up",
    debug: "/debug",
  };
} else {
  // live build
  urlsToSkip = {
    onboard: "/subscriptions/:subscription/settings",
    signUp: "/subscribe/:plan/sign-up",
    signUpPay: "/subscribe/:plan/pay",
    signUpThankYou: "/subscribe/:subscription/thank-you",
    signUpConfirmPayment: "/subscribe/:plan/sign-up/confirm-payment",
    signUpConfirmPaymentLegacy: "/subscribe/:plan/checkout-thank-you",
    restartSignUp: "/subscribe/:plan/restart-sign-up",
    signUpExternal: "/subscribe/:code/activate",
    debug: "/debug",
    // signIn: '/login'
  };
  if (showSubscriptionForm()) {
    delete urlsToSkip.onboard;
  }
}

if (showSignUpFlow()) {
  delete urlsToSkip.signUp;
  delete urlsToSkip.signUpPay;
  delete urlsToSkip.signUpThankYou;
  delete urlsToSkip.onboard;
  delete urlsToSkip.signUpConfirmPayment;
  delete urlsToSkip.alreadySubscribed;
  delete urlsToSkip.signUpExternal;
  delete urlsToSkip.signUpConfirmPaymentLegacy;
  delete urlsToSkip.restartSignUp;
}

if (showSignUpFlowForAllTypes()) {
  delete urlsToSkip.onboard;
  delete urlsToSkip.signUpConfirmPayment;
  delete urlsToSkip.alreadySubscribed;
  delete urlsToSkip.signUpExternal;
  delete urlsToSkip.signUpConfirmPaymentLegacy;
}

const DefineInclude = {
  memberProgressApiUrl: memberProgressApiUrl,
  memberProgressApiUrlV1: memberProgressApiUrlV1,
  testAPI: "https://nz-alpha-customer.alpha-macro.com/api/",
  toningAPIUrl: "https://toning.macroactive.com/api/",
  baseUrl: window.location.hostname,
  shoppingListEmailAPIurl: shoppingListEmailAPIurl,
  progressFeedbackAPIUrl:
    "https://whispering-farm-bbkgak1uwwji.vapor-farm-a1.com/api/",
  foodDiaryAPIURL: foodDiaryAPIURL,
  trainerFeedAPI: macroactivistApiUrl,
  eventAPIUrl: "https://wandering-sun-lya3rbignhsg.vapor-farm-c1.com/api/",
  announceKit:
    "https://api.macroactive.com/announce-kit-integration/2024-07-02/",
  communityAssetURL: communityAssetURL,
  chatAssetURL: chatAssetURL,
  featureUpdatesUrl: featureUpdatesUrl,
  apiUrl: apiUrl,
  apiV2Url: apiV2Url,
  currentPath: window.location.pathname,
  habitsTrackerAPIUrl: habitsTrackerApiUrl,
  stepTrackerAPIUrl: stepTrackerAPIUrl,
  communityAPIUrl: communityAPIUrl,
  periodTrackerAPIUrl: periodTrackerAPIUrl,
  chatApiUrl: chatApiUrl,
  chatAppId: chatAppId,
  pluginFilesUrl: pluginFilesUrl,
  feedBackAPIURL: feedBackAPIURL,
  /**
   * imagesUrl must be a full url
   * not relative
   * */
  imagesUrl: resourcesHost + "/static/assets/images/",
  assetsUrl: resourcesHost + "/static/assets/",
  allCorsUrl: "https://cors-anywhere.herokuapp.com/",
  vimeoVideoAPI: "https://breezy-tokyo-ay5rce4jpixl.vapor-farm-b1.com/api/",
  resourceBaseURL: resourcesHost,
  // please do not make changes to existing items
  appUrlList: {
    onboard: "/subscriptions/:subscription/settings",
    subscriptionSettingsStep: "/subscriptions/:subscription/settings/:form",
    workoutUpdate: "/workout-programs/update",
    workout: "/workout-programs/",
    workoutBase: "/workout-programs",
    dashboard: "/dashboard",
    mealPlanUpdate: "/meal-plans/update",
    mealPlan: "/meal-plans/:mid",
    shoppingList: "/meal-plans/:mid/shopping-list",
    mealPlanBase: "/meal-plans",
    contentPage: "/page/:contentPage",
    profile: "/account-details",
    // habitsTracker: '/dashboard?page=habit-tracker',
    signIn: "/login",
    logout: "/logout",
    forgotPasswordRequest: "/forgot-password",
    forgotPassword: "/forgot-password/:token",
    resetPassword: "/reset-password/:token",
    contact: "/contact",
    externalPage: "/external-page",
    progress: "/progress",
    home: "/",
    signUp: "/subscribe/:plan/sign-up",
    signUpPay: "/subscribe/:plan/pay",
    signUpThankYou: "/subscribe/:subscription/thank-you",
    signUpConfirmPayment: "/subscribe/:plan/sign-up/confirm-payment",
    signUpConfirmPaymentLegacy: "/subscribe/:plan/checkout-thank-you",
    alreadySubscribed: "/subscribe/:plan/already-subscribed",
    restartSignUp: "/subscribe/:plan/restart-sign-up",
    signUpExternal: "/subscribe/:code/activate",
    profileMenu: "/menu",
    pageInvite: "/invitations",
    pageAccountDeletionConfirmation: "/account-delete/verify-email/:token",
    accountDeletion: "/account-delete",
    connectWearable: '/wearables',
    connectWearableRedirect: '/wearables/client_uuid/:uuid/user_id/:uid',
    pagePushNotifications: "/push-notifications",

    // iap: '/iap/sign-up'
  },
  appUrlListToSkip: urlsToSkip,
  app: {
    version: 'v1.45.0'
  },
  nutritionAnalysisAPIURL:
    "https://api.macroactive.com/nutrition-analysis/2024-05-30/",
};
export default DefineInclude;
