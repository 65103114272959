<template>
  <page-secondary :header="headerOptions">
    <div class="pc-workout-program">
      <div v-if="program">
        <page-container class="pc-workout-program__container__top">
          <div class="pc-workout-program__container-upper">
            <div class="pc-workout-program__card-image-cover-bg">
              <card-image-cover
                class="pc-workout-program__cover-image"
                :image-src="program.getBackgroundImage()"
                :default-image-src="getStoreImage('workout.week1.background')"
              >
                <div class="pc-workout-program__cover-content-area">
                  <div class="pc-workout-program__cover-text">

                    <text-content
                      class="pc-workout-program__image-cover-text"
                      :size="'md3'"
                      :weight="'extra-bold'"
                      :lineHeight="'multi'">
                      <p>{{ program.name }}
                      </p>
                    </text-content>
                  </div>
                </div>
              </card-image-cover>
            </div>
            <div class="pc-workout-program__sliders">
              <slider-navigation class='pc-workout-program__slider-navigation-weeks'
                                 v-if="weekNavigationLinks && weekNavigationLinks.length>0"
                                 :active-link-i-d="currentWeekID"
                                 :links="weekNavigationLinks"
                                 :isWeekSlider="true"
                                 @click-link="setActiveWeek($event)"
              />
            </div>
          </div>
          <div class="pc-workout-program__sliders">
            <slider-navigation class='pc-workout-program__slider-navigation-days'
                               v-if="dayNavigationLinks.length>0"
                               :active-link-i-d="currentDayID"
                               :key="currentWeekID"
                               :links="dayNavigationLinks"
                               @click-link="setActiveDay($event)"
            />
          </div>
        </page-container>
        <!--   page wrapper -->
        <page-container class="pc-workout-program__container">
          <div class="pc-workout-program__header-day" v-if="currentDay">
            <text-body-small line-height="lh-lg" class="pc-workout-program__header-day__title">
              {{ $i18n.tc('message["workout.exercise-count"]', currentDay.getExerciseCount(), {count: currentDay.getExerciseCount()}) }}
            </text-body-small>
            <icon-info-bulb v-if="showDayInstructionsPopupIcon" size="sm4" class="pc-workout-program__header-day__icon"
                            @click="showCurrentDayInstructions"/>
          </div>
          <div v-if="currentDayInjuries.length>0" class="pc-workout-program__alert-warning-wrapper">
            <alert-warning
              v-for="injury in currentDayInjuries"
              :key="'injury-'+injury.name"
              :description="injury.description"
              :name="injury.name"
              class="pc-workout-program__alert-warning"
            />
          </div>
          <grid-row class="pc-workout-program__row">
            <card-exercise-list-group
              v-for="(exerciseGroup ,exerciseGroupID) in currentDayExerciseGroups"
              :key="'day-exercise-group-'+exerciseGroupID"
              class="pc-workout-program__card-exercise-list-group"
              :circuit="currentDay.getCircuitDetails()"
              :exercise-group="exerciseGroup"
              @click-exercise="goToExercisePage($event)"
            />
          </grid-row>
          <div class="pc-workout-program__footer" v-if="workoutLogEnabled && currentDay && !currentDay.isAllExercisesCompleted()" >
            <button-custom :text="workoutLogDayCompleteButtonText"
                           :class="workoutLogDayCompleteButtonClass"
                           @click="popupWorkoutLogDayComplete.show=true">
              <template v-slot:left>
                <IconCompletedFilled v-if="!currentDay.getIsCompleted()" :size="'sm2'"></IconCompletedFilled>
                <icon-complete-rounded v-else :size="'sm2'"></icon-complete-rounded>
              </template>

            </button-custom>
            <popup-workout-log-day-complete v-if="popupWorkoutLogDayComplete.show"
                                            :visible="true"
                                            :workout-day="currentDay"
                                            @uncompleted="loadPageData(true)"
                                            @completed="loadPageData(true)"
                                            @close="popupWorkoutLogDayComplete.show=false" />
          </div>
        </page-container>
      </div>
      <div v-else>
        <loading-buffer></loading-buffer>
      </div>
    </div>
    <popup-workout-pdf-send :visible="showWorkoutPopup" :workout-id="workoutID" :weeks="workoutWeeks"
                            @close="closePdfPopup"/>
  </page-secondary>
</template>

<script>
import pageSingleWorkoutMixin from '../../../../mixins/component-mixins/page/workout/pageSingleWorkoutMixin'
import WorkoutProgramsService from '../../../../services/WorkoutProgramsService'
import CardImageCover from '../../../global/cards/CardImageCover'
import SliderNavigation from '../../../global/sliders/SliderNavigation'
import CardExerciseListGroup from '../../../global/cards/CardExerciseListGroup'
import AlertWarning from '../../../global/alerts/AlertWarning'
import TextContent from '../../../root/TextContent'
import IconInfo from '../../../root/icons/IconInfo'
import pageMixin from '../../../../mixins/pageMixin'
import PageSecondary from '../../../global/pages/PageSecondary'
import ButtonStandard from '../../../global/buttons/ButtonPrimary'
import PopupWorkoutPdfSend from '../../../global/popups/PopupWorkoutPdfSend'
import IconSend from '../../../root/icons/IconSend'
import PageContainerFluid from '../../../global/pages/page-sub-components/PageContainerFluid'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import GridCol from '../../../global/grid/GridCol'
import GridRow from '../../../global/grid/GridRow'
import headerEventBus from '../../../../event-buses/headerEventBus'
import IconInfoBulb from '../../../root/icons/IconInfoBulb'
import TextBodySmall from '../../../global/typography/TextBodySmall'
import IconTick from '../../../root/icons/IconTick.vue'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall.vue'
import IconCompletedFilled from '../../../root/icons/IconCompletedFilled.vue'
import {showNewDashboardLayout} from '@/includes/TemplateSettings'
import {pageReadyEvent} from '@/helpers/dom/events/customEvents'
import ButtonCustom from "@/components/global/buttons/ButtonCustom.vue";
import PageFixedFooter from "@/components/global/pages/PageFixedFooter.vue";
import PopupWorkoutLogDayComplete
  from "@/components/layout/template-1/workout/page-components/popups/PopupWorkoutLogDayComplete.vue";
import IconCompleted from "@/components/layout/template-2/root/icons/IconCompleted.vue";
import IconComplete from "@/components/root/icons/IconComplete.vue";
import IconCompleteRounded from "@/components/root/icons/IconCompleteRounded.vue";

export default {
  name: 'PageWorkoutProgram',
  mixins: [pageSingleWorkoutMixin, pageMixin],
  components: {
    IconCompleteRounded,
    IconComplete,
    IconCompleted,
    PopupWorkoutLogDayComplete,
    PageFixedFooter,
    ButtonCustom,
    IconCompletedFilled,
    TextBodyExtraSmall, IconTick,
    TextBodySmall,
    IconInfoBulb,
    GridCol,
    GridRow,
    IconInfo,
    CardExerciseListGroup,
    SliderNavigation,
    CardImageCover,
    AlertWarning,
    TextContent,
    PageSecondary,
    ButtonStandard,
    PopupWorkoutPdfSend,
    IconSend,
    PageContainerFluid,
    PageContainer
  },
  beforeMount() {
    this.loadPageData()
  },
  data: function () {
    return {
      popupWorkoutLogDayComplete: {
        show: false
      },
      workoutLogEnabled: false,
      pageClass:
        ['macroapp--with-fixed-nav', 'macroapp--page-common', 'macroapp--page-workout-program'],
      weeks: null,
      program: null,
      currentDay: null,
      currentWeekID: 0,
      currentDayID: 0,
      instructionsPopup: {
        instructions: '',
        notes: '',
        visible: false
      },
      headerOptions: {
        show: true,
        left: 'message',
        right: 'help',
        modifiers: ['with-small-title', 'with-bg-color'],
        header: {
          mainTitle: '',
          subTitle: ''
        }
      },
      workoutWeeks: [],
      workoutID: '',
      showWorkoutPopup: false
    }
  },
  watch: {
    currentDay: function () {
      if (this.currentDay) {
        this.logEvent('WorkoutProgram.ViewedSingleDay', {
          day: this.numberWordToNumeric(this.currentDay.getName()),
          context: 'workout-program-week-page',
          type: this.currentDay.getType()
        })
      }
    }
  },
  computed: {
    workoutLogDayCompleteButtonText: function () {
      if (this.currentDay && this.currentDay.getIsCompleted()) {
        return this.$i18n.t('message["workout.workout-log-day-incomplete"]')
      }
      return this.$i18n.t('message["workout.workout-log-day-complete"]')
    },
    workoutLogDayCompleteButtonClass: function () {
      if (this.currentDay && this.currentDay.getIsCompleted()) {
        return 'pc-workout-program__button-incomplete'
      }
      return 'pc-workout-program__button-complete'
    },
    showInstructionsPopupIcon: function () {
      return (
        this.checkIsNotEmptyTextArray(this.instructionsPopup.instructions) ||
        this.checkIsNotEmptyTextArray(this.instructionsPopup.notes)
      )
    },
    showDayInstructionsPopupIcon: function () {
      if (!this.currentDay) {
        return false
      }
      return (
        this.checkIsNotEmptyTextArray(this.currentDay.getDescription())
      )
    },
    weekNavigationLinks: function () {
      if (!this.weeks) {
        return []
      }
      return this.weeks.map(week => {
        return {
          text: this.numberWordToNumeric(week.getName(), true),
          id: week.id,
          info: '',
          completed: week.getIsCompleted()
        }
      })
    },
    dayNavigationLinks: function () {
      if (!this.program || !this.currentWeekID) {
        return []
      }

      return this.program.getWeekByID(this.currentWeekID).getDays().map(day => {
        return {
          text: this.numberWordToNumeric(day.getName(), true),
          id: day.id,
          info: day.getDescription(),
          completed: day.getIsCompleted()
        }
      })
    },
    currentDayExerciseGroups() {
      if (!this.program || !this.currentDayID || !this.currentDay) {
        return []
      }
      return this.currentDay.getGroupedExercisesBySetType()
    },
    currentDayInjuries() {
      if (!this.currentDay) {
        return []
      }
      return this.currentDay.getInjuries()
    }

  },
  created() {
    this.setCurrentWorkoutSliderActiveData()
    if (showNewDashboardLayout()) {
      this.headerOptions.left = 'menu'
      this.headerOptions.right = 'message|help'
    }

  },
  mounted() {
    headerEventBus.$on('info-bulb-button-click', this.showInstructionsPopup)
    this.setPageClass()
    this.setHeader(this.headerOptions)
    headerEventBus.$on('send-button-click', this.sendWorkoutPdf)
    headerEventBus.$on('info-popup-closed', this.setDescriptionPopupDetails)
  },
  methods: {
    closePdfPopup() {
      this.showWorkoutPopup = false
    },
    sendWorkoutPdf() {
      this.workoutWeeks = this.weeks.map((item) => ({
        id: item.id,
        text: this.numberWordToNumeric(item.name)
      }))
      this.workoutWeeks.unshift({id: '*', text: this.$i18n.t('message["popup.workout-send-pdf.dropdown-default"]')})
      this.showWorkoutPopup = true
    },
    setDescriptionPopupDetails() {
      if (showNewDashboardLayout()) {
        this.headerOptions.left = 'menu'
        this.headerOptions.right = 'message|help'
      }
      this.instructionsPopup.instructions = [this.program.getDescription()]
      this.instructionsPopup.notes = this.program.getNotes()
      this.instructionsPopup.instructionType = 'workout-program'
      // calling to setContent function in the pageStore
      this.setInstructionsPopupContent(this.instructionsPopup)
    },
    showCurrentDayInstructions() {
      if (!this.showInstructionsPopupIcon) {
        this.headerOptions.right = 'message'
      }

      this.instructionsPopup.instructions = [this.currentDay.getDescription()]
      this.instructionsPopup.instructionType = 'workout-program-day'
      // calling to setContent function in the pageStore
      this.setInstructionsPopupContent(this.instructionsPopup)
      this.viewInstructionsPopup()
    },
    showInstructionsPopup() {
      this.setDescriptionPopupDetails()
      this.viewInstructionsPopup()
    },
    setCurrentWorkoutSliderActiveData() {
      let storageData = localStorage.getItem('workoutWeekSlider') || '{ "weekID":null,"dayID": null,"programID": null }'
      storageData = JSON.parse(storageData)
      if (!storageData.programID || parseInt(storageData.programID) !== parseInt(this.params.workout)) {
        return
      }

      this.currentDayID = storageData.dayID || null
      this.currentWeekID = storageData.weekID || null
    },

    loadPageData(force=false) {
      let workoutProgramID = this.params.workout
      workoutProgramID = parseInt(workoutProgramID)
      let workoutProgramsService = new WorkoutProgramsService()
      workoutProgramsService.setWorkoutProgramList().then(() => {
        let program = workoutProgramsService.getWorkoutProgram(workoutProgramID)

        if (!force&&program.checkDetailsLoaded()&&!force) {
          this.setProgramData(program)
        } else {
          this.weeks = null
          this.program = null
          program.setProgramDetails().then(() => {
            this.setProgramData(program)
          })
        }
      }).catch((e) => {
        console.log(e)
        this.hideLoading()
      })
    },
    setActiveWeek(weekID) {
      this.currentWeekID = weekID
      this.currentDayID = this.program.getWeekByID(this.currentWeekID).getDays()[0].id
      this.setActiveDay(this.currentDayID)
      this.saveSliderActiveDataInStorage()

      const eventData = {
        program_name: this.numberWordToNumeric(this.program.getWeekByID(this.currentWeekID)),
        'context ': 'workout'

      }
      this.logEvent('WorkoutProgram.SliderWeekSelected', eventData, false)
    },
    setActiveDay(dayID) {
      this.currentDayID = dayID
      this.saveSliderActiveDataInStorage()

      this.currentDay = this.program.getWeekByID(this.currentWeekID).getDayByID(this.currentDayID)
    },
    saveSliderActiveDataInStorage() {
      let storageData = {
        'weekID': this.currentWeekID,
        'dayID': this.currentDayID,
        'programID': this.params.workout
      }
      storageData = JSON.stringify(storageData)

      localStorage.setItem('workoutWeekSlider', storageData)
    },
    setProgramData(program) {
      let weeks = program.getWeeks()
      this.workoutLogEnabled =program.getWorkoutLogEnabled()
      if (!this.currentWeekID && weeks && weeks[0]) {
        this.currentWeekID = weeks[0].id
        this.currentDayID = weeks[0].getDays()[0].id
      }
      this.weeks = weeks
      this.program = program
      this.workoutID = this.program.id
      this.setActiveDay(this.currentDayID)
      this.hideLoading()
      pageReadyEvent()
      this.setDescriptionPopupDetails()
    },
    goToExercisePage(exerciseData) {
      this.$router.push({
        path: this.$appConfig.appUrlList.workout,
        query: {
          workout: this.program.id,
          week: this.currentWeekID,
          day: this.currentDayID,
          exercise: exerciseData.exerciseId,
          grouped: exerciseData.isGrouped,
          'set-id': exerciseData.setID,
          mode: this.currentDay.getType()
        }
      })
    }
  },
  beforeDestroy() {
    headerEventBus.$off('info-bulb-button-click', this.showInstructionsPopup)
    headerEventBus.$off('send-button-click', this.sendWorkoutPdf)
    headerEventBus.$off('info-popup-closed', this.setDescriptionPopupDetails)
  }
}
</script>
