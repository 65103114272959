import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    visible: false,
    customMeal: null,
    userLogContext: 'external-page', // for amplitude
  },
  mutations: {
    showPopup (state) {
      state.visible = true
    },
    hidePopup (state) {
      state.userLogContext = 'external-page'
      state.visible = false
    },
    setUserLogContext(state, data){
      state.userLogContext = data
    },
    setCustomMeal (state, data) {
      state.customMeal = data
      state.visible = true
    }
  },
  getters: {
    visible (state) {
      return state.visible
    },
    getCustomMeal (state) {
      return state.customMeal
    },
    getUserLogContext (state) {
      return state.userLogContext
    }
  }
}
