<template>
  <page-secondary class="pc-dashboard" :header="headerOptions">
    <page-container-fluid breakpoint="lg" :reverse="true">
      <grid-row no-gutters>
        <grid-col lg="12">
          <image-featured
              :has-notifications="notificationBarShow"
              :page-header="pageHeader"
          />
        </grid-col>
        <page-container class="pc-dashboard__main-content-area">
          <div class="pc-dashboard__text" v-if="pageContent">
            <text-body-extra-small
                :line-height="'multi'"
                class="pc-dashboard__text-description"
                v-html="pageContent"
            />
          </div>

          <grid-row :class="actionButtonsClasses">
            <grid-col
                v-for="(notification, id) in notifications"
                v-if="
                showNotification &&
                !notification.action.url.includes(
                  'api/notifications/subscription/'
                )
              "
                v-bind:key="'dashboard-notification' + id"
                :lg="notifications.length === 1 ? 12 : 6"
            >
              <button-progress-update
                  :text="notification.message"
                  :action="notification.action"
              />
            </grid-col>
          </grid-row>
          <grid-row>
            <grid-col :cols="12">
              <card-dashboard-evergreen-progress-image
                  v-if="
                  evergreenProgressEnabled &&
                  evergreenConfig.available &&
                  !isEvergreenVisited
                "
                  :configs="evergreenConfig"
                  @dismissCard="isEvergreenVisited = true"
              />
            </grid-col>
          </grid-row>
          <card-my-journey-with-inputs
              v-if="fitnessDiaryEnabled && isMyJourneyWidgetEnabled"
          />
          <grid-row>
            <grid-col v-if="stepTrackerEnabled" :cols="12">
              <card-step-tracker/>
            </grid-col>
            <grid-col v-if="moodTrackerEnabled" :cols="12">
              <card-mood-tracker v-if="moodTrackerEnabled"/>
            </grid-col>
            <grid-col v-if="habitsTrackerEnabled" :cols="12">
              <card-habits-tracker v-if="habitsTrackerEnabled"/>
            </grid-col>

            <grid-col
              v-if="periodTrackerEnabledByFeatureFlag && periodTrackerEnabled && isPeriodTrackEnabledByUser"
              :cols="12"
            >
              <card-period-tracker
                v-if="periodTrackerEnabledByFeatureFlag && periodTrackerEnabled && isPeriodTrackEnabledByUser"
              />
            </grid-col>
          </grid-row>
          <card-food-diary
              v-if="
              showBottomBar ||
              (mealPlanNotAChallenge.length === 0 &&
                challengeMealPlans.length > 0)
            "
          ></card-food-diary>
          <card-list-challenge-plans
              v-if="!showBottomBar"
              :workouts="challengeWorkoutPlans"
              :meal-plans="challengeMealPlans"
              :plan-pages="challengeProductPages"
          ></card-list-challenge-plans>
          <card-list-meal-plans
              class="pc-dashboard__nutrition-card-list"
              v-if="
              !showBottomBar &&
              (hasSubscriptionAndOneOffMealPlans ||
                mealPlanNotAChallenge.length > 0)
            "
              :none-challenge-meal-plans="mealPlanNotAChallenge"
              :challenge-meal-plans="challengeMealPlans"
          />

          <card-list-workouts
              v-if="
              !showBottomBar &&
              (hasSubscriptionAndOneOffWorkoutPrograms || workouts.length > 0)
            "
              class="pc-dashboard__workouts-list"
              :workouts="workouts"
          />
          <!-- <grid-row>
            <grid-col :cols="12" >
              <loading-buffer v-if="customVideoPages.length === 0" :height="50"/>
              <div v-if="customVideoPages.length > 0">
                <div v-for="(customVideoPage, index) in customVideoPages" :key="index">
                  <container-custom-video-slider v-if="customVideoPage.page.added_dashboard_slider" :startDate="startDate" :slider-config="customVideoPage"/>
                  <card-navigation
                    v-if="!customVideoPage.page.added_dashboard_slider"
                    class="pc-card-list-product-page__card pc-dashboard__video-slider--disable"
                    :image="customVideoPage.page.header_image"
                    :header-text="customVideoPage.page.title"
                    @click="navigate(customVideoPage.route)"
                  />
                </div>
              </div>
            </grid-col>

          </grid-row> -->
          <card-list-product-pages
              class="pc-dashboard__product-pages-list"
              v-if="productPlans.pages.length > 0"
              :header-text="productPlans.header"
              :pages="productPlans.pages"
          />
        </page-container>
      </grid-row>
    </page-container-fluid>

    <notification-popup-handler></notification-popup-handler>
    <container-chat
        v-if="showChatWidget"
        class="pc-dashboard__container-chat"
    />
  </page-secondary>
</template>

<script>
import CardDashboardEvergreenProgressImage from './page-components/CardDashboardEvergreenProgressImage'
import CardMoodTracker from './page-components/CardDashboardMoodTracker'
import CardStepTracker from './page-components/CardDashboardStepTracker'
import CardHabitsTracker from './page-components/CardDashboardHabitsTracker'
import NutritionService from '../../../../services/nutrition/NutritionService'
import FirstLoadService from '../../../../services/FirstLoadService'
import NotificationService from '../../../../services/NotificationService'
import DashboardService from '../../../../services/DashboardService'
import BaseComponent from '../global/base/BaseComponent'
import pageMixin from '../../../../mixins/pageMixin'
import CardMyJourneyWithInputs from './page-components/CardMyJourneyWithInputs'
import ButtonProgressUpdate from './page-components/ButtonProgressUpdate'
import CardListMealPlans from './page-components/CardListMealPlans'
import CardListWorkouts from './page-components/CardListWorkouts'
import {isSafari} from 'mobile-device-detect'
import CardListProductPages from './page-components/CardListProductPages'
import {mapGetters, mapMutations, mapActions} from 'vuex'
import {
  flagValue,
  enableStepTracker,
  showBottomNavigationBar,
  showMyJourneyWidget,
} from '../../../../includes/TemplateSettings'
import WorkoutProgramsService from '../../../../services/WorkoutProgramsService'
import FeaturesService from '../../../../services/FeaturesService'
import PageSecondary from '../../../global/pages/PageSecondary'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import TextBodyExtraSmall from '../../../global/typography/TextBodyExtraSmall'
import NotificationPopupHandler from '../notifications/Handler'
import CardPeriodTracker from '../period-tracker/page-components/CardPeriodTracker'
import PageContainerFluid from '../../../global/pages/page-sub-components/PageContainerFluid'
import GridRow from '../../../global/grid/GridRow'
import GridCol from '../../../global/grid/GridCol'
import CardListChallengePlans from './page-components/CardListChallengePlans'
import ImageFeatured from './page-components/ImageFeatured'
import CardFoodDiary from './page-components/CardFoodDiary'
import ContainerChat from '../../../global/containers/ContainerChat.vue'
import PopupCustomMealLog from '../food-diary/page-components/PopupCustomMealLog'
import UserEventsService from '../../../../services/UserEventsService'
import MemberProgressService from '../../../../services/api/member-progress/MemberProgressService'
import {pageReadyEvent} from '@/helpers/dom/events/customEvents'
import NativeAppService from '../../../../services/NativeAppService'
import {isMobileNativeApp} from '@/includes/NativeAppCommon'
import ContainerCustomVideoSlider
  from "@/components/layout/template-1/dashboard/page-components/ContainerCustomVideoSlider.vue";
import LoadingBuffer from '@/components/layout/template-1/global/widgets/LoadingBuffer'
import CardNavigation from '../../../global/cards/CardNavigation'
import ContentPageFactoryService from "@/services/ContentPageFactoryService";
import InputSwitch from "@/components/global/inputs/InputSwitch";
import CardPeriodTrackerManageWidget from "@/components/layout/template-1/period-tracker/CardPeriodTrackerManageWidget";

export default {
  name: 'PageDashboard',
  extends: BaseComponent,
  mixins: [pageMixin],
  components: {
    CardStepTracker,
    ContainerCustomVideoSlider,
    PopupCustomMealLog,
    CardFoodDiary,
    ImageFeatured,
    GridCol,
    GridRow,
    PageContainerFluid,
    CardListChallengePlans,
    NotificationPopupHandler,
    CardListWorkouts,
    CardListMealPlans,
    CardListProductPages,
    ButtonProgressUpdate,
    CardMyJourneyWithInputs,
    CardMoodTracker,
    CardHabitsTracker,
    PageSecondary,
    PageContainer,
    TextBodyExtraSmall,
    CardPeriodTracker,
    ContainerChat,
    CardDashboardEvergreenProgressImage,
    InputSwitch,
    CardPeriodTrackerManageWidget,
    LoadingBuffer,
    CardNavigation,
    CardStepTracker
  },
  computed: {
    ...mapGetters({
      notificationBarSettings: 'pageStore/notificationBarSettings',
      notificationCount: 'getNotificationCount',
    }),
    showBottomBar: function () {
      return showBottomNavigationBar() && !this.isDesktop
    },
    showChatWidget () {
      return !(showBottomNavigationBar() && !this.isDesktop)
    },
    hasSubscriptionAndOneOffWorkoutPrograms () {
      return (
          this.hasWorkouts &&
          (this.challengeWorkoutPlans.length === 0 || this.workouts.length > 0)
      )
    },
    hasSubscriptionAndOneOffMealPlans () {
      return (
          this.hasMealPlans &&
          (this.challengeMealPlans.length === 0 ||
              this.mealPlanOptions.length > 0)
      )
    },

    actionButtonsClasses: function () {
      return {
        'pc-dashboard__action-buttons': this.showNotification,
      }
    },
    notificationBarShow () {
      return (
          this.notificationBarSettings.show &&
          this.notificationCount > 0 &&
          !(this.params.page && this.params.page === 'notifications')
      )
    },
    evergreenProgressEnabled () {
      return flagValue('enable_ever_green_member_progress', true)
    },
    habitsTrackerEnabled () {
      const dashboard = new DashboardService()
      return dashboard.hasHabitTrackerEnabled()
    },
    stepTrackerEnabled () {
      const dashboard = new DashboardService()
      return dashboard.isStepTrackerEnabled()
    },
    periodTrackerEnabledByFeatureFlag() {
      let service = new DashboardService()
      return service.isPeriodTrackEnabledByFeatureFlag()
    },
    periodTrackerEnabled() {
      let service = new DashboardService();
      return service.userData.gender && service.userData.gender === "f";
    }
  },
  data: function () {
    return {
      startDate: '',
      customVideoPages: [],
      isPeriodTrackEnabledByUser: false,
      isEvergreenVisited: true,
      evergreenConfig: {},
      activeWorkout: null,
      backgroundImage: this.getStoreImage('dashboard.background'),
      showWorkoutSlider: false,
      text: {
        mealPlans: 'Meal Plans',
        workoutPlans: 'Workout Plans',
      },
      pwaPopup: {
        visible: false,
      },
      themeSwitchPopup: {
        visible: false,
      },
      productPlans: {
        header: '',
        pages: [],
      },
      preVisited: true,
      pageHeader: '',
      pageTitle: '',
      pageContent: ' ',
      headerOptions: {
        show: false,
        left: 'menu',
        right: 'pwa',
        helpContent: '',
        rightDisabled: false,
        modifiers: [],
        header: {
          mainTitle: '',
          subTitle: '',
        },
      },
      showNotification: false,
      notifications: [],
      fitnessDiaryEnabled: false,
      hasWorkouts: false,
      workouts: [],
      hasMealPlans: false,
      mealPlanOptions: [],
      mealPlanNotAChallenge: [],
      description: '',
      pageClass: ['macroapp--page-dashboard--with-card'],
      title: {
        main: '',
        sub: 'Get back on your Track',
      },
      loaded: {
        mealPlan: false,
        workout: false,
        dashboard: false,
        notification: false,
      },
      isMyJourneyWidgetEnabled: false,
      challengeMealPlans: [],
      challengeWorkoutPlans: [],
      challengeProductPages: [],
    }
  },
  watch: {
    loaded: {
      handler (value) {
        if (
            value.mealPlan &&
            value.workout &&
            value.dashboard &&
            value.notification
        ) {
          this.hideLoading(1)
        }
      },
      deep: true,
    },
  },
  created() {
    this.logEvent("Viewed Dashboard", {
      variation:
        showBottomNavigationBar() && !this.isDesktop
          ? "bottom_nav_bar"
          : "original",
    });
  },
  beforeMount () {
    this.getMemberProgressConfigs()
    this.toggleMyJourneyWidget()
    if (
        typeof localStorage.getItem('currentWorkoutPlan') === 'undefined' ||
        localStorage.getItem('currentWorkoutPlan') === null
    ) {
      this.preVisited = false
    }
    if (isMobileNativeApp()) {
      const nativeApp = new NativeAppService();
      nativeApp.getPushDeviceStateRequest();
    }

    document.body.style.backgroundColor =
        templateConfig.style_variables['$bg-homePages']
  },
  mounted() {
    this.setHeader(this.headerOptions);
    this.setNotificationBarSettings({ show: true });
    this.showFeatureUpdatesPopup();
    this.showThemeSwitchPopup();
    this.setPageClass();
    this.showMealPlans();
    this.showWorkouts();
    this.setNotification();
    this.periodTrackerEnabledByUser()
    this.hideLoading();
    pageReadyEvent()
    this.showDashboardContent()
  },
  beforeDestroy () {
  },
  methods: {
    ...mapMutations({
      viewInstructionsPopup: 'instructionPopupStore/show',
      setInstructionsPopupContent: 'instructionPopupStore/setContent',
      setTrainerDetails: 'pageStore/setTrainer',
    }),
    async getDashboardVideoSliderConfigs () {
      const dashboardService = new DashboardService()
      try {
        const response = await dashboardService.getDashboardVideoPages()
        this.customVideoPages = response.video_pages
      } catch (e){
        console.info(e)
      }

      const regDate = dashboardService.getRegisteredDate()
      this.startDate = new Date(regDate).getTime()
    },
    async checkEvegreenEnabled() {
      this.isEvergreenVisited = await UserEventsService.checkItemVisit(
          'everGreen',
          this.evergreenConfig.id
      )
    },
    periodTrackerEnabledByUser() {
      let service = new DashboardService();
      service.getUserConfig(true).then(() => {
        let result = service.isPeriodTrackEnabledByUser()
        this.isPeriodTrackEnabledByUser = result !== null
        ? result
        : true
      })
    },
    async getMemberProgressConfigs() {
      if (!this.evergreenProgressEnabled) {
        return
      }
      const memberProgressService = new MemberProgressService()
      const response = await memberProgressService.getMemberConfigData()

      this.evergreenConfig = response.data
      this.checkEvegreenEnabled()
    },

    showFeatureUpdatesPopup () {
      if (flagValue('enable_feature_updates_popup', false)) {
        let features = new FeaturesService()
        features.getNewFeatureUpdateContents().then((contents) => {
          if (contents.length > 0) {
            let instructionsPopup = {
              instructions: contents,
              title: this.$i18n.t('message["popup.feature-updates.title"]'),
            }
            this.setInstructionsPopupContent(instructionsPopup)
            this.viewInstructionsPopup()
          }
        })
      }
    },
    navigateToWorkout (id) {
      // holding router so slick dom breaking is not visible before fade effect
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.workout,
        query: {workout: id},
      })
    },
    setImages () {
      this.setBackgroundImage(this.getStoreImage('dashboard.background'))
    },
    hideInstructionsPopup () {
      this.instructionsPopup.visible = false
      this.setInstructionsPopup.visible = false
    },
    showMealPlans () {
      let mealService = new NutritionService()
      mealService
          .init()
          .then(() => {
            this.allMealPlans = mealService.getActiveMealPlans()
            this.challengeMealPlans = mealService.getChallengePlans()
            this.mealPlanOptions = mealService.getActiveMealPlans()
            this.mealPlanNotAChallenge = mealService.getNotChallengePlans()
          })
          .finally(() => {
            this.loaded.mealPlan = true
          })
    },

    showPwaPopup () {
      // ios check
      const isIos = this.$device.ios
      let isSafariBrowser = isSafari
      let firstTime = new FirstLoadService()
      // pwa check
      const isInStandaloneMode = () =>
          window.matchMedia('(display-mode: standalone)').matches ||
          window.navigator.standalone ||
          document.referrer.includes('android-app://')

      if (
          document.querySelector('link[rel=\'manifest\']') &&
          firstTime.getFirstTimeStatus('pwaPopUpView') &&
          !isInStandaloneMode() &&
          isIos &&
          isSafariBrowser
      ) {
        this.pwaPopup.visible = true
        this.logEvent('Viewed PWA Install Instructions', {}, false)
        firstTime.setFirstTimeStatus('pwaPopUpView')
      }
    },
    showThemeSwitchPopup () {
      this.themeSwitchPopup.visible = false
    },
    setNotification () {
      let notifService = new NotificationService()
      notifService
          .getFromAPI()
          .then((res) => {
            let allNotifications = res
            this.notifications = res.filter((notification) => {
              return (
                  typeof notification.action.url !== 'undefined' &&
                  (notification.action.url.includes('workout-programs/update') ||
                      (notification.action.url.includes(
                              'api/notifications/subscription/'
                          ) &&
                          notification.action.url.includes('/cancel')) ||
                      notification.action.url.includes('meal-plans/update'))
              )
            })
            this.notifications = this.notifications.map((notification) => {
              if (notification.action.url.includes('workout-programs/update')) {
                notification.colorClass = 'btn-notification--color-red'
                notification.message = this.$i18n.t(
                    'message["notification-workout-update-progress"]'
                )
              } else if (notification.action.url.includes('meal-plans/update')) {
                notification.colorClass = 'btn-notification--color-orange'
                notification.message = this.$i18n.t(
                    'message["notification-meal-plan-update-progress"]'
                )
              } else {
                notification.message = this.$i18n.t(
                    'message["notification-update-progress"]'
                )
              }
              return notification
            })
            this.showNotification = this.notifications.length !== 0
            this.loaded.notification = true
            let notifiCount = allNotifications.length - this.notifications.length
            this.$store.commit('setNotificationCount', notifiCount)
            this.$store.commit('setNotificationsList', allNotifications)
          })
          .finally(() => {
            this.loaded.notification = true
          })
    },
    showDashboardContent () {
      const service = new DashboardService()
      service
        .getPageData()
        .then( async (data) => {
          service.getRegisteredDate();
          this.pageContent = data.contents["page-content"];
          this.pageTitle = data.contents["page-title"];
          this.pageHeader = data.contents["page-header"];
          this.productPlans.header = data.contents["product-pages-header"];
          this.text.workoutPlans = data.contents["workout-programs-header"];
          this.text.mealPlans = data.contents["meal-plans-header"];
          this.productPlans.pages = service.getProductPages();
          this.challengeProductPages = service.getChallengeProductPages();
          this.setPageTitle(this.pageTitle);
          this.loaded.dashboard = true

          await this.checkAndSetVideoSliders(this.productPlans.pages)
        })
        .finally(() => {
          this.loaded.dashboard = true;
        });

      service.getUserData().then(() => {
        this.hasMealPlans = service.hasActiveMealPlans()
        this.hasWorkouts = service.hasActiveWorkoutPrograms()
        this.fitnessDiaryEnabled = service.isFitnessDiaryEnabled()

        let productPages = service.getProductPages()
        let hasMealPlans = this.hasMealPlans || this.mealPlanOptions.length > 0
        let hasWorkouts = this.hasWorkouts || this.workouts.length > 0

        if (
            Array.isArray(productPages) &&
            productPages.length === 1 &&
            !hasMealPlans &&
            !hasWorkouts
        ) {
          let defaultProductPage = productPages.find((page) => {
            return page.is_default
          })
          if (
              !defaultProductPage &&
              Array.isArray(productPages) &&
              productPages.length === 1
          ) {
            defaultProductPage = productPages[0]
          }
          const url = defaultProductPage.url || "";
          if (
            url &&
            url.startsWith("/page") &&
            sessionStorage.getItem("productPlanRedirected") !== "1"
          ) {
            sessionStorage.setItem("productPlanRedirected", "1");
            this.$router.replace({ path: url });
          }
        }
      })
    },
    async checkAndSetVideoSliders (productPlanPages) {

      if (flagValue('enable_video_slider_for_product_pages', true)) {
        await this.getDashboardVideoSliderConfigs()
        const customPages = new ContentPageFactoryService()
        if(this.customVideoPages.length===0){
          await customPages.getPageList()
          this.productPlans.pages = await Promise.all(
            productPlanPages.map(async (planPage) => {
              const route = planPage.url.split("/page/")[1];

              const matchedVideoPage = this.customVideoPages.find((videoPage) => {
                return videoPage.page.route === route;
              });

              if (!matchedVideoPage && planPage) {
                const page = customPages.getPage(route);
                await customPages.getPageList()
                if (page.getPageType() === 'custom-page-video') {
                  await page.setPageData(); // Ensure this happens before accessing page data
                  planPage.sliderConfigs = page.getPageData();
                  planPage.sliderConfigs.startDate = this.startDate;
                }
              }

              if (matchedVideoPage) {
                planPage.sliderConfigs = matchedVideoPage;
                planPage.sliderConfigs.startDate = this.startDate;
              }

              return planPage;
            })
          )
        } else {
          this.productPlans.pages = productPlanPages.map(planPage => {
            const matchedVideoPage = this.customVideoPages.find((videoPage) => {
              return videoPage.page.route === planPage.url.split("/page/")[1];
            });
            if (matchedVideoPage) {
              planPage.sliderConfigs = matchedVideoPage;
              planPage.sliderConfigs.startDate = this.startDate;
            }
            return planPage;
          })
        }

        this.showProductPages = true
      }else{
        this.showProductPages = true
      }

    },
    showWorkouts() {
      let workoutProgramsService = new WorkoutProgramsService();
      workoutProgramsService
          .setWorkoutProgramList()
          .then(() => {
            this.challengeWorkoutPlans =
                workoutProgramsService.getChallengeWorkoutPrograms()
            this.workouts = workoutProgramsService.workoutPrograms
          })
          .catch(() => {
            this.hideLoading()
          })
          .finally(() => {
            this.loaded.workout = true
          })
    },
    showSlider (id) {
      this.preVisited = id > 3
    },
    toggleMyJourneyWidget () {
      this.isMyJourneyWidgetEnabled = showMyJourneyWidget()
    },
  },
  destroyed () {
    this.resetHeader()
    this.resetBackground()
    document.body.style.removeProperty('backgroundColor')
  },
}
</script>
