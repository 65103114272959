import ContentPageApi from './api/content-page/ContentPageApi'
import ContentPageFactoryService from './ContentPageFactoryService'
import ContentPageService from '@/services/ContentPageService'
import ContentRecipeService from '@/services/ContentRecipeService'
import lodash from 'lodash'
export default class ContentRecipePageService extends ContentPageService{
  data = {
    listItems: {},
    headerImage: ''
  }
  recipes= []
  pageData = {}
  _pageName = ''

  _filters= {
    tags:[],
    categories:[],
  }
  constructor (pageName) {
    console.log(pageName)
    super(pageName)
    this._pageName = pageName
  }

  getPageType(){
    return 'custom-page-recipe'
  }
  setPageData () {

    return new Promise((resolve, reject) => {
      if (Object.keys(this.pageData).length !== 0) {
        resolve()
      } else {
        const contentPageApi = new ContentPageApi(this._pageName)
        contentPageApi.getList([]).then(data=>data.data).then((data) => {
          this.recipes = data.recipes.map(recipe => {
            this._filters.tags.push(...recipe.tags)
            this._filters.categories.push(...recipe.meal_types)
            return new ContentRecipeService(recipe)
          })

          this.recipes = lodash.sortBy(this.recipes, '_sequence')
          this._filters.tags = lodash.uniq(this._filters.tags)
          this._filters.categories = lodash.uniq(this._filters.categories)


          this.pageData = data
          this.data.headerImage = data.page.header_image
          const factory = new ContentPageFactoryService()
          factory.setPage(this._pageName, this)
          resolve()
        }).catch(err => { console.log(err);reject(err) })
      }
    })
  }


  getTags(){
    return this._filters.tags
  }

  getCategories(){
    return this._filters.categories
  }


  getRecipes(){
    return this.recipes
  }
  getRecipeByName(name){
    return this.recipes.find(recipe => recipe.name === name)
  }

}
