<template>
  <div className="">
    <transition name="fade" mode="out-in">
      <component className="pc-subscription"
                 v-bind:is="dynamicComponent" v-bind="componentData"
                 v-on:go-to-gender="gotoGender()"
                 v-on:submit="formSubmit($event)"
                 v-on:next="gotoNextPage($event)"
                 v-on:complete="enableGoBack"
                 v-on:back="delayedGoBack"></component>
    </transition>
  </div>
</template>

<script>
import {env as customEnv} from '../../../../../config/custom-config'
import {mapGetters, mapMutations, mapState} from 'vuex'
import Start from './PageSubscriptionStart'
import MaleFemale from '../../../global/pages/PageGender'
import SubscriptionService from '../../../../services/ui/forms/SubscriptionService'
import Birthday from '../../../global/pages/PageBirthday'
import Height from '../../../global/pages/PageHeight'
import Weight from '../../../global/pages/PageWeight'
import BodyFat from '../../../global/pages/PageBodyFat'
import ActivityLevel from '../../../global/pages/PageActivityLevel'
import NutritionalPreference from '../../../global/pages/PageMealCategory'
import DGoal from '../../../global/pages/PageDietaryGoal'
import MealsPerDay from '../../../global/pages/PageMealsPerDay'
import VegetarianDays from '../../../global/pages/PageVegetarianDays'
import Allergies from '../../../global/pages/PageDisliking'
import InjuriesPreventExercising from '../../../global/pages/PageInjuriesPreventExercising'
import UnitPreference from '../../../global/pages/PageUnitPref'
import Finished from './PageFinished'
import Workouts from '../../../global/pages/PageWorkouts'
import CustomDetails from '../global/widgets/forms/custom/CustomDetails'
import AddPhotos from '../../../global/pages/PageAddPhotos'
import Process from './Process'
import Preloader from './Preloader'
import moment from 'moment'
import lodash from 'lodash'
import FitnessDiaryStore from '../../../../store/modules/FitnessDiaryStore'
import DashBoardService from '../../../../services/DashboardService'
import PageMemberConnect from '../../../global/pages/PageMemberConnect'
import ConnectionService from '../../../../services/meal-plan-pair/ConnectionService'
import PairService from '../../../../services/meal-plan-pair/PairService'
import PageMealTypesPairing from '../../../global/pages/PageMealTypesPairing'
import MealPlanService from '../../../../services/MealPlanService'
import PageCustomField from '../../../global/pages/PageCustomField'
import PagePreferredRecipes from '../../../global/pages/PagePreferredRecipes'
import PageMealPlanDays from '../../../global/pages/PageMealPlanDays'
import PageEnablePeriodTracker from '../../../global/pages/PageEnablePeriodTracker'
import {isPhotoUploadEnabled, showMealPlanDays} from '@/includes/TemplateSettings'
import FormHabitSuggestion from '@/components/global/forms/FormHabitSuggestion.vue'

export default {
  name: 'Home',
  props: {
    subscription: {
      type: String,
      default: '0'
    },
    form: {
      type: String,
      default: '0'
    }
  },
  data: function () {
    return {
      showComponent: false,
      dynamicComponent: '',
      componentData: {},
      connectionService: new ConnectionService(),
      pairService: new PairService(),
      dashboardService: new DashBoardService(),
      mealPlanService: new MealPlanService(),
      memberConnected: false,
      components: [
        {
          name: 'subscription-start',
          component: {design1: Start},
          options: {},
          init: function (service) {
            return new Promise(resolve => {
              this.options = service.getStartFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-gender',
              component: MaleFemale
            })
          }
        },
        {
          name: 'subscription-gender',
          component: {design1: MaleFemale},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              this.options = service.getGenderFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-birthday',
              component: Birthday
            })
          }
        },
        {
          name: 'subscription-birthday',
          component: {design1: Birthday},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              this.options = service.getBirthdayFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-unit-preference',
              component: Height
            })
          }
        },
        {
          name: 'subscription-unit-preference',
          component: {design1: UnitPreference},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              if (!service.showMeasurementSystem()) {
                reject(new Error('Measurement System Form'))
              }
              this.options = service.getUnitPreferenceFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              component: Finished,
              name: 'subscription-height'
            })
          }
        },
        {
          name: 'subscription-height',
          component: {design1: Height},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              this.options = service.getHeightFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-wight',
              component: Weight
            })
          }
        },
        {
          name: 'subscription-wight',
          component: {design1: Weight},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              this.options = service.getWeightFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-body-fat',
              component: BodyFat
            })
          }
        },
        {
          name: 'subscription-body-fat',
          component: {design1: BodyFat},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              if (service.showFatQuestion()) {
                this.options = service.getBodyFatFormData()
                resolve()
              } else {
                reject(new Error('Disabled Subscription Body Fat'))
              }
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-activity-level',
              component: ActivityLevel
            })
          }
        },
        {
          name: 'subscription-activity-level',
          component: {design1: ActivityLevel},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              this.options = service.getActivityLevelFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-member-connect',
              component: PageMemberConnect
            })
          }
        },
        {
          name: 'subscription-member-connect',
          component: {design1: PageMemberConnect},
          options: {},
          init: function (service, params) {
            return new Promise(async (resolve, reject) => {
              if (!service.hasMealPlans() || !params.dashboardService.isMealPlanPairEnabled()) {
                reject(new Error('Meal plans pair not enabled'))
              }

              await params.mealPlanService.getMealPlanOptions()
                .then((options) => {
                  if (lodash.get(options, 'meal_plans', []).length > 0) {
                    reject(new Error('Active meal plan already exists'))
                  }
                })

              if (params.connectionService.isPartnerConnected() && params.connectionService.isOwner()) {
                reject(new Error('Current user is the owner'))
              }

              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            if (service.hasMealPlans()) {
              resolve({
                name: 'subscription-diet-type',
                component: NutritionalPreference
              })
            } else if (service.hasWorkoutProgrammes()) {
              resolve({
                name: 'subscription-injuries',
                component: InjuriesPreventExercising
              })
            } else {
              resolve({
                name: 'subscription-custom',
                component: PageCustomField
              })
            }
          }
        },
        {
          name: 'subscription-diet-type',
          component: {design1: NutritionalPreference},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              if (params.dashboardService.isMealPlanPairEnabled() &&
                params.connectionService.isPartnerConnected() &&
                params.pairService.ownerPlanIsValid()
              ) {
                reject(new Error('Member connected'))
              }

              this.options = service.getDietTypeFormData()
              resolve()
            })
          },
          getNextComponent: function (resolveMain, reject, service, data) {
            return new Promise((resolve, reject) => {
              service.setNutritionalPrefData().then(() => {
                resolveMain({
                  name: 'subscription-dietary-goals',
                  component: DGoal
                })
              })
            })
          }
        },
        {
          name: 'subscription-dietary-goals',
          component: {design1: DGoal},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              this.options = service.getDietaryGoalFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-vegetarian-days',
              component: VegetarianDays
            })
          }
        },
        {
          name: 'subscription-vegetarian-days',
          component: {design1: VegetarianDays},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              if (service.getVegetarianDaysFormData().options.length === 0) {
                reject(new Error('no vege days'))
              } else {
                this.options = service.getVegetarianDaysFormData()
                resolve()
              }
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-meals-pre-day',
              component: MealsPerDay
            })
          }
        },
        {
          name: 'subscription-meals-pre-day',
          component: {design1: MealsPerDay},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              this.options = service.getMealsPreDayFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-meal-plan-days',
              component: PageMealPlanDays
            })
          }
        },
        {
          name: 'subscription-meal-plan-days',
          component: {design1: PageMealPlanDays},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              if (!showMealPlanDays()) {
                reject(new Error('Meal Plan Days is hidden'))
              }
              if (params.dashboardService.isMealPlanPairEnabled()) {
                if (params.connectionService.isPartnerConnected() && !params.connectionService.isOwner()) {
                  reject(new Error('Current user is the member'))
                }
              }
              this.options = service.getMealPlanDaysFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-pair-meal-types',
              component: PageMealTypesPairing
            })
          }
        },
        {
          name: 'subscription-pair-meal-types',
          component: {design1: PageMealTypesPairing},
          options: {
            mealsPerDay: 3
          },
          init: function (service, params) {
            return new Promise(async (resolve, reject) => {
              if (!params.dashboardService.isMealPlanPairEnabled()) {
                reject(new Error('Meal plans pair not enabled'))
              }

              await params.mealPlanService.getMealPlanOptions()
                .then((options) => {
                  if (lodash.get(options, 'meal_plans', []).length > 0) {
                    reject(new Error('Active meal plan already exists'))
                  }
                })

              if (params.connectionService.isOwner()) {
                reject(new Error('Current user is the owner'))
              }

              if (!params.connectionService.isPartnerConnected()) {
                reject(new Error('Member not connected'))
              }

              if (!params.pairService.ownerPlanIsValid()) {
                reject(new Error('Owner has an invalid plan'))
              }

              this.options.mealsPerDay = service.getValue('meals_per_day')

              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-allergies',
              component: Allergies
            })
          }
        },
        {
          component: {design1: Allergies},
          name: 'subscription-allergies',
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              if (params.dashboardService.isMealPlanPairEnabled() && params.pairService.isPaired()) {
                reject(new Error('Meal plan pair enabled'))
              }

              if (!service.showIngredientDislikes()) {
                reject(new Error('Skip Allergies Form'))
              }
              this.options = service.getAllergiesFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({name: 'subscription-preferred-recipes', component: PagePreferredRecipes})
          }
        },
        {
          name: 'subscription-preferred-recipes',
          component: {design1: PagePreferredRecipes},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              if (service.showPreferredRecipes()) {
                if (params.pairService.isPaired()) {
                  if (params.connectionService.isOwner()) {
                    this.options = service.getPagePreferredRecipesData()
                    resolve()
                  } else {
                    reject(new Error('Not Manager'))
                  }
                } else {
                  this.options = service.getPagePreferredRecipesData()
                  resolve()
                }
              } else {
                reject(new Error('Disabled Preferred Recipes'))
              }
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            if (service.hasWorkoutProgrammes()) {
              resolve({
                name: 'subscription-injuries',
                component: InjuriesPreventExercising
              })
            } else {
              resolve({
                name: 'subscription-custom',
                component: PageCustomField
              })
            }
          }
        },
        {
          name: 'subscription-injuries',
          component: {design1: InjuriesPreventExercising},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              if (!service.showInjuries()) {
                reject(new Error('Skip InjuriesPreventExercising Form'))
              }
              this.options = service.getInjuriesFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-workouts',
              component: Workouts
            })
          }
        },
        {
          name: 'subscription-workouts',
          component: {design1: Workouts},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              this.options = service.getWorkoutsFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            if (service.hasCustomDetails()) {
              resolve({
                name: 'subscription-custom-details',
                component: CustomDetails
              })
            } else {
              resolve({
                name: 'subscription-custom',
                component: PageCustomField
              })
            }
          }
        },
        {
          name: 'subscription-custom-details',
          component: {design1: CustomDetails},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              this.options = service.getCustomFormData()
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-custom',
              component: PageCustomField
            })
          }
        },
        {
          name: 'subscription-custom',
          component: {design1: PageCustomField},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              if (service.isExtraFieldsEnabled()) {
                this.options = service.getCustomPageData()
                resolve()
              } else {
                reject(new Error('Disabled Extra Custom Field'))
              }
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-habit-suggestion',
              component: FormHabitSuggestion
            })
          }
        },
        {
          name: 'subscription-habit-suggestion',
          component: {design1: FormHabitSuggestion},
          backDisabled: false,
          options: {
            title: {
              main: '',
              sub: ''
            },
            errors: {},
            inputKey: 'habits',
            inputValue: 'habits',
            options: []
          },
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              const dashboard = new DashBoardService()
              if (dashboard.isHabitTrackerEnabled() && service.hasSuggestedHabits()) {
                this.options.options = service.getSuggestedHabits()
                resolve()
              } else {
                reject(new Error('No Suggested Habits'))
              }
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'subscription-enable-period-tracker',
              component: PageEnablePeriodTracker
            })
          }
        },
        {
          name: 'subscription-enable-period-tracker',
          component: {design1: PageEnablePeriodTracker},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              if (service.values.gender === 'male') {
                reject(new Error('Unavailable for male users'))
              }

              this.options = {
                title: {
                  main: 'Enable-Period-Tracker'
                },
                inputKey: 'enablePeriodTracker'

              }
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'process-data',
              component: Process
            })
          }
        },
        {
          name: 'process-data',
          component: {design1: Process},
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              resolve()
            })
          },
          getNextComponent: (resolve, reject, service, data) => {
            this.showLoading()
            let dashBoardService = new DashBoardService()
            let imageDates = this.getDiaryImageDates(true)
            let navigationLinks = dashBoardService.getNavigation()
            let userData = dashBoardService.getUserData()
            Promise.all([imageDates, navigationLinks, userData]).then((values) => {
              const dates = values[0]
              const progressEnabled = dashBoardService.isFitnessDiaryEnabled()

              if (!progressEnabled) {
                resolve({
                  name: 'preloader',
                  component: Preloader
                })
                return
              }
              let latestDate = dates.latestDate
              if (isPhotoUploadEnabled()) {
                if (latestDate) {
                  let latestDateObj = moment(latestDate)
                  if (latestDateObj.isSame(moment(), 'day')) {
                    // latest image uploaded today
                    resolve({
                      name: 'preloader',
                      component: Preloader
                    })
                  } else {
                    // latest image not uploaded today
                    resolve({
                      name: 'subscription-photo-upload',
                      component: AddPhotos
                    })
                  }
                } else {
                  // no images uploaded yet
                  resolve({
                    name: 'subscription-photo-upload',
                    component: AddPhotos
                  })
                }
              } else {
                resolve({
                  name: 'preloader',
                  component: Preloader
                })
              }
            }).catch(() => {
              resolve({
                name: 'preloader',
                component: Preloader
              })
            })
          }
        },
        {
          name: 'subscription-photo-upload',
          component: {design1: AddPhotos},
          backDisabled: true,
          options: {},
          init: function (service, params) {
            return new Promise((resolve) => {
              this.options.backButton = false
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({
              name: 'preloader',
              component: Preloader
            })
          }
        },
        {
          name: 'preloader',
          component: {design1: Preloader},
          backDisabled: true,
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({ name: 'subscription-finish',component: Finished})
          }
        },
        {
          name: 'subscription-finish',
          component: {design1: Finished},
          backDisabled: true,
          options: {},
          init: function (service, params) {
            return new Promise((resolve, reject) => {
              resolve()
            })
          },
          getNextComponent: function (resolve, reject, service, data) {
            resolve({component: Finished})
          }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      formStatus: 'formWizardStore/formStatus'
    })
  },
  beforeCreate () {
    const store = this.$store
    if (!(store && store.state && store.state.fitnessDiaryStore)) {
      store.registerModule('fitnessDiaryStore', FitnessDiaryStore)
    }
  },
  async mounted () {
    await Promise.all([this.connectionService.init(), this.dashboardService.getPageData()])
      .catch(() => {
        this.hideLoading()
      })

    if (this.getService() === null) {
      const subscriptionService = new SubscriptionService()
      subscriptionService.getDataFromAPI({id: this.subscription}).then(data => {
        this.setService(subscriptionService)
        this.loadComponent()
      })
    } else {
      this.loadComponent()
    }
    this.setPageTitle('Subscription')
  },
  updated () {
    this.setPageName(this.getCurrentComponent().name)
  },
  beforeRouteEnter (to, from, next) {
    if (from.name === null && customEnv === 'production' && to.params.form) {
      to.params.form = '0'
      const subId = to.params.subscription
      console.log(`/subscriptions/${subId}/settings`)
      next(`/subscriptions/${subId}/settings`)
    } else {
      next()
    }

  },
  methods: {
    ...mapGetters({
      getService: 'formWizardStore/getSubscriptionFormService',
      fitnessDiaryService: 'fitnessDiaryStore/getService'
    }),
    ...mapMutations({
      setService: 'formWizardStore/setSubscriptionFormService',
      setFormStatus: 'formWizardStore/setFormStatus',
      setPageName: 'pageStore/setPageName',
      unsetPageName: 'pageStore/unsetPageName'
    }),
    disableGoBack () {
      window.history.pushState(null, '', window.location.href)
      window.onpopstate = function () {
        window.history.pushState(null, '', window.location.href)
      }
    },
    enableGoBack () {
      window.onpopstate = function () {
      }
    },
    getCurrentComponent () {
      return this.components[parseInt(this.form)]
    },
    getDiaryImageDates (force = false) {
      return new Promise((resolve, reject) => {
        this.fitnessDiaryService()
          .getDataFromAPI(force)
          .finally(() => {
            let images = this.fitnessDiaryService().images

            let dates = Object.values(images).map(image => {
              return new Date(image.firstTime.date)
            })
            let newDates = Object.values(images).map(image => {
              return new Date(image.latestDate)
            })

            resolve({
              firstDate: lodash.min(dates),
              latestDate: lodash.max(newDates)
            })
          })
      })
    },
    loadComponent () {
      const curComponent = this.getCurrentComponent()

      if (curComponent.backDisabled) {
        this.disableGoBack()
      }

      const params = {
        index: (this.$route.query.index || 0),
        connectionService: this.connectionService,
        pairService: this.pairService,
        dashboardService: this.dashboardService,
        mealPlanService: this.mealPlanService
      }

      curComponent.init(this.getService(), params).then(() => {
        this.dynamicComponent = this.getComponentByConfig(curComponent)
        this.componentData = {
          ...curComponent.options,
          pageConfig: this.getPageConfigByName(curComponent.name)
        }
      }).catch(() => {
        this.nextComponent({}, true)
      })
    },
    getComponentByConfig (curComponent) {
      const pageConfig = this.getPageConfigByName(curComponent.name)
      const pageComponents = curComponent.component
      if (pageConfig && pageConfig.variation) {
        return pageComponents[pageConfig.variation] || pageComponents.design1
      } else {
        return pageComponents.design1
      }
    },
    formSubmit (data) {
      this.showLoading()
      this.getService().setValues(data)
      this.nextComponent(data)
    },
    gotoNextPage(event={replace: false}){
      this.nextComponent(null, event.replace)
    },
    nextComponent (data = null, replace = false) {
      const curComponent = this.getCurrentComponent()
      const componentData = new Promise((resolve, reject) => {
        curComponent.getNextComponent(resolve, reject, this.getService(), data)
      })
      componentData.then(next => {
        this.navigateToForm(this.getNextComponentIDByName(next.name), (next.query || {}), replace)
      }).catch(() => {
        this.hideLoading()
      })
    },
    getComponentByName (name) {
      this.components.find(component => {
        return component.name === name
      })
    },
    navigateToForm (nextComponentID, query = {}, replace = false) {
      console.log(nextComponentID)
      let routeObj = {
        path: this.$appConfig.appUrlList.onboard.replace(':subscription', this.subscription) + '/' + nextComponentID,
        query: query
      }
      if (replace) {
        this.$router.replace(routeObj)
      } else {
        this.showLoading()
        let self = this
        setTimeout(function () {
          self.$router.push(routeObj)
        }, 500)

      }
    },
    getNextComponentID (component) {
      return this.components.findIndex(ele => {
        return component === ele.component
      })
    },
    getNextComponentIDByName (name) {
      return this.components.findIndex(ele => {
        return name === ele.name
      })
    },
    gotoGender () {
      this.navigateToForm(this.getNextComponentIDByName('subscription-gender'), {})
    }

  },
  destroyed () {
    this.unsetPageName()
  },
  beforeRouteUpdate (to, from, next) {
    this.showLoading()
    let delay = 300
    if (from.params.form && (from.params.form === '7' || from.params.form === '5')) {
      delay = 500
    }
    setTimeout(() => {
      next()
    }, delay)
  }

}
</script>
