<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.64265 3.4555C4.85236 2.40728 6.39998 1.83127 8.00065 1.8335C11.6827 1.8335 14.6673 4.81817 14.6673 8.50017C14.6673 9.92417 14.2207 11.2442 13.4607 12.3268L11.334 8.50017H13.334C13.3341 7.45459 13.0268 6.43204 12.4504 5.55967C11.874 4.68731 11.0539 4.00361 10.0921 3.59359C9.13027 3.18356 8.06913 3.0653 7.04062 3.25351C6.01212 3.44173 5.06162 3.9281 4.30732 4.65217L3.64265 3.4555ZM12.3587 13.5448C11.1489 14.5931 9.60133 15.1691 8.00065 15.1668C4.31865 15.1668 1.33398 12.1822 1.33398 8.50017C1.33398 7.07617 1.78065 5.75617 2.54065 4.6735L4.66732 8.50017H2.66732C2.66723 9.54575 2.97449 10.5683 3.55088 11.4407C4.12726 12.313 4.94736 12.9967 5.9092 13.4068C6.87103 13.8168 7.93217 13.935 8.96068 13.7468C9.98918 13.5586 10.9397 13.0722 11.694 12.3482L12.3587 13.5448Z" fill="#AFAFAF"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconRefresh',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-refresh': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
