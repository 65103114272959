<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.40105 11.8382C6.17735 11.6145 6.17735 11.2527 6.40105 11.029L11.5956 5.83452C11.8193 5.61082 12.181 5.61082 12.4047 5.83452L17.5992 11.029C17.8229 11.2527 17.8229 11.6145 17.5992 11.8382C17.3755 12.0619 17.0138 12.0619 16.7901 11.8382L12.0001 7.04825L7.2102 11.8382C6.98651 12.0619 6.62474 12.0619 6.40105 11.8382ZM17.5992 16.2236C17.8229 16.4473 17.8229 16.809 17.5992 17.0327C17.3755 17.2564 17.0138 17.2564 16.7901 17.0327L12.0001 12.2428L7.2102 17.0327C6.98651 17.2564 6.62474 17.2564 6.40105 17.0327C6.17735 16.809 6.17735 16.4473 6.40105 16.2236L11.5956 11.029C11.8193 10.8053 12.181 10.8053 12.4047 11.029L17.5992 16.2236Z"
        fill="#AFAFAF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.913433 7.40754C2.77092 2.92293 7.14725 -0.000839279 12.0012 3.48433e-05C15.1854 -0.00765746 18.2404 1.2586 20.4857 3.51664C25.1714 8.20265 25.1714 15.7998 20.4857 20.4855C15.7999 25.1715 8.20283 25.1715 3.51689 20.4855C0.0835507 17.0542 -0.943706 11.8923 0.913433 7.40754ZM1.11675 12.0011C1.11675 18.0016 6.00077 22.8858 12.0012 22.8858C18.0016 22.8858 22.8858 18.0016 22.8858 12.0011C22.8858 6.00055 18.0016 1.11647 12.0012 1.11647C6.00077 1.11647 1.11675 6.00055 1.11675 12.0011Z"
        fill="#AFAFAF"
      />
    </svg>
  </div>
</template>

<script>
import iconMixin from "../../../mixins/component-mixins/root/iconMixin";

export default {
  name: "IconArrowCircularLinearV3",
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        "rc-icon": true,
        "rc-icon-dot": true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses,
      };
    },
  },
};
</script>

<style scoped></style>
