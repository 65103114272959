<template>
  <popup
    @hide="closePopup"
    modal-class="pc-popup-workout-log-exit"
    :visible="visible"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-content>
        <popup-head>
          <popup-text-heading>{{ title }}
          </popup-text-heading>
        </popup-head>
        <popup-text-body>
          <p v-html="description"></p>

        </popup-text-body>
      </popup-content>
      <popup-footer>
        <button-primary
          class="pc-popup-workout-log-exit__button-submit"
          :text="actionText"
          @click="doAction()"/>

      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>

import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import ButtonSecondary from '../../../../../global/buttons/ButtonSecondary'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../../../../global/popups/Popup'
import WorkoutProgramsService from "@/services/WorkoutProgramsService";

export default {
  name: 'PopupWorkoutLogDayComplete',
  components: {
    PopupBody,
    ButtonSecondary,
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupButtonClose,
    PopupTitle,
    Popup
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: {
      default: false
    },
    workoutDay: {
      required: true
    },

  },
  data: function () {
    return {
      action: 'complete', // can be complete or incomplete
      imagesUrl: this.$appConfig.imagesUrl

    }
  },
  watch:{
    workoutDay:{
      handler: function(){
        this.action = (!this.workoutDay.getIsCompleted()) ? 'complete' : 'incomplete'
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    title: function () {
      if (this.action === 'complete') {
        return this.$i18n.t('message[\'popup.workout-log-day-complete.title\']')
      }
      return this.$i18n.t('message[\'popup.workout-log-day-incomplete.title\']')
    },
    description: function () {
      if (this.action === 'complete') {
        return this.$i18n.t('message[\'popup.workout-log-day-complete.description\']')
      }
      return this.$i18n.t('message[\'popup.workout-log-day-incomplete.description\']')
    },
    actionText: function () {
      if (this.action === 'complete') {
        return this.$i18n.t('message[\'popup.workout-log-day-complete.action\']')
      }
      return this.$i18n.t('message[\'popup.workout-log-day-incomplete.action\']')
    },
  },
  mounted() {

  },
  methods: {
    doAction() {
      if(this.action==='complete'){
        this.workoutDay.setWorkoutComplete().then(() => {
          this.closeAnimatedPopup(() => {
            this.$emit('completed')
            this.$emit('close')
          })

        })

      } else {
        this.workoutDay.setWorkoutIncomplete().then(() => {
          this.closeAnimatedPopup(() => {

            this.$emit('uncompleted')
            this.$emit('close')
          })


        })
      }
    },
    closePopup(e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }

  }
}
</script>
