<template>
  <popup
    :visible="visible"
    v-if="visible"
    size="md"
    @hide="closePopup"
    :modal-class="'pc-popup-step-tracker-introduction'"
  >
    <popup-title>
      <popup-button-close @hide="closePopup($event)"></popup-button-close>
    </popup-title>

    <PopupBody>
      <popup-head>
        <div class="info-modal__head step-tracker-modal__head text-center" v-if="status==='init'">
          <popup-text-heading><p>{{ $t('message["step-tracker.introduction-popup.title"]') }}</p></popup-text-heading>
        </div>
      </popup-head>
      <popup-content>
        <popup-text-body>
          <p>{{ $t('message["step-tracker.introduction-popup.description"]') }}</p>
        </popup-text-body>
        <text-body-small :weight="'extra-bold'">{{
            $t('message["step-tracker.introduction-popup.info"]')
          }}
        </text-body-small>
      </popup-content>
      <popup-footer>
        <div v-if="status==='init'">
          <div class="step-tracker-modal__popup-single-btn-wrap">
            <button-primary :text="$t('message[\'general.understood\']')" @click="closePopup()"/>
          </div>
        </div>
      </popup-footer>
    </PopupBody>
  </popup>
</template>

<script>
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import TextContent from '../../../../root/TextContent'
import TextArea from '../../../../global/inputs/TextArea'
import ButtonSecondary from '../../../../global/buttons/ButtonSecondary'
import IconCompleteFilled from '../../../../root/icons/IconCompleteFilled'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import Popup from '../../../../global/popups/Popup'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextSubHeading from '../../../../global/popups/popup-sub-components/PopupTextSubHeading'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import {mapGetters, mapMutations} from 'vuex'
import TextBodySmall from '@/components/global/typography/TextBodySmall.vue'
import ButtonPrimary from '@/components/global/buttons/ButtonPrimary.vue'

export default {
  name: 'pop-up-step-tracker-introduction',
  mixins: [popupAnimationMixin],
  components: {
    ButtonPrimary,
    TextBodySmall,
    PopupFooter,
    PopupContent,
    PopupTitle,
    PopupHead,
    PopupTextHeading,
    PopupButtonClose,
    Popup,
    TextContent,
    TextArea,
    ButtonSecondary,
    IconCompleteFilled,
    LoadingBuffer,
    PopupTextSubHeading,
    PopupTextBody,
    PopupBody
  },
  props: {
    visible: {default: false}
  },
  data: function () {
    return {
      contentClass: 'info-modal__content info-modal__content--hidden custom-modal-padding fd-upload-image-popup',
      status: 'loading'
    }
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    })
  },
  mounted () {
    this.status = 'init'
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    closePopup (e) {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }
  }
}
</script>
