<template>
  <div>
    <ValidationObserver name="exerciseDays" ref="form">
      <form id="form-input-personalised-workout" @submit.prevent="nextComponent">
        <div class="gc-page-workouts__header-wrapper" ref="header">
          <div class="gc-page-workouts__header-wrapper--image">
             <image-base class="gc-page-workouts__header-image" v-if="title.image" :src="title.image"/>
          </div>
          <text-heading4 :class="titleClasses" ref="title" :weight="'extra-bold'" :lineHeight="'multi'">{{title.main}}</text-heading4>
          <text-body-extra-small class="gc-page-workouts__sub-title" :weight="'extra-bold'" :lineHeight="'multi'">{{title.sub}}</text-body-extra-small>
        </div>

        <PageContainer class="gc-page-workouts__input-dropdown">

          <div class=" mt-4 mb-4">
            <text-body-extra-small class="text-underline ma-form__message ma-form__message--error" v-if="!hasAllOptions" v-on:click="goToGender()">
              {{noWorkoutText}}
            </text-body-extra-small>
          </div>
          <ValidationProvider :name="title.exerciseLocation"
                              ref="exerciseLocationVP"
                              :rules="'required'"
                              v-slot="{ errors }">
            <text-body-regular
              :weight="'extra-bold'"
              :lineHeight="'multi'"
              class="gc-page-workouts__input-dropdown-label">
              {{title.exerciseLocation}}
            </text-body-regular>
            <input-single-select
              v-model="formData.location"
              :placeHolder="$t('message[\'general.select\']')"
              :options="exerciseLocationOptions"
              :sort="false"
              :error-message="errors[0]"/>
          </ValidationProvider>
          <ValidationProvider :name="title.experienceLevel"
                              ref="experienceLevelVP"
                              :rules="'required'"
                              v-slot="{ errors }">
            <text-body-regular
              :weight="'extra-bold'"
              :lineHeight="'multi'"
              class="gc-page-workouts__input-dropdown-label">
              {{title.experienceLevel}}
            </text-body-regular>
            <input-single-select
              v-model.number="formData.level"
              :placeHolder="$t('message[\'general.select\']')"
              :sort="false"
              :options="experienceLevelOptions"
              :error-message="errors[0]"/>
          </ValidationProvider>
          <ValidationProvider :name="title.workoutGoal"
                              ref="workoutGoalVP"
                              :rules="'required'"
                              v-slot="{ errors  }">
            <text-body-regular
              :weight="'extra-bold'"
              :lineHeight="'multi'"
              class="gc-page-workouts__input-dropdown-label">
              {{title.workoutGoal}}
            </text-body-regular>
            <input-single-select
              v-model.number="formData.workout_goal"
              :placeHolder="$t('message[\'general.select\']')"
              :options="workoutGoalOptions"
              :sort="false"
              :error-message="workoutGoalCustomErrorMessage || errors[0]"/>
          </ValidationProvider>
          <ValidationProvider :name="title.dayPreWeek"
                              ref="dayPreWeekVP"
                              :rules="'required'"
                              v-slot="{ errors  }">
            <text-body-regular
              :weight="'extra-bold'"
              :lineHeight="'multi'"
              class="gc-page-workouts__input-dropdown-label">
              {{title.dayPreWeek}}
            </text-body-regular>
            <input-single-select
              :sort="true"
              v-model.number="formData.days_per_week"
              :placeHolder="$t('message[\'general.select\']')"
              :options="daysPerWeekOptions"
              :error-message="errors[0]"/>
          </ValidationProvider>
          <ValidationProvider :name="title.duration"
                              ref="durationVP"
                              :rules="'required'"
                              v-slot="{  errors  }">
            <text-body-regular
              :weight="'extra-bold'"
              :lineHeight="'multi'"
              class="gc-page-workouts__input-dropdown-label">
              {{title.duration}}
            </text-body-regular>
            <input-single-select
              :sort="true"
              v-model.number="formData.duration"
              :options="sessionDurationOptions"
              :placeHolder="$t('message[\'general.select\']')"
              :error-message="errors[0]"
              @change="enableButton"/>
          </ValidationProvider>

          <text-body-extra-small v-if="totalPages > 1" class="gc-page-workouts__input-workout-count"> {{ countLang }}</text-body-extra-small>
        </PageContainer>
        <page-footer :form="'form-input-personalised-workout'" :text="submitText" :validated="buttonInvalid"></page-footer>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import BaseComponent from '../../../layout/template-1/global/widgets/forms/FormBaseComponent'
import InputSelect from '../../inputs/InputSelect'
import InputSingleSelect from '../../inputs/InputSingleSelect'
import PageContainer from '../page-sub-components/PageContainer'
import TextBodyRegular from '../../typography/TextBodyRegular'
import PageFooter from '../page-sub-components/PageFormFooter'
import TextHeading4 from '../../typography/TextHeading4'
import TextBodyExtraSmall from '../../typography/TextBodyExtraSmall'
import ImageBase from '../../../layout/template-2/root/Image'

export default {
  name: 'Workout',
  extends: BaseComponent,
  props: {
    inputKey: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    },
    totalPages: {
      default: 0
    },
    currentWorkoutIndex: {
      default: 0
    },
    submitText: {
      default: ''
    }
  },
  components: {
    ImageBase,
    TextBodyExtraSmall,
    TextHeading4,
    PageFooter,
    TextBodyRegular,
    PageContainer,
    InputSelect,
    InputSingleSelect
  },
  data: function () {
    return {
      buttonInvalid: true,
      defaultValue: '',
      pageClass: ['macroapp--page-workout-form'],
      selected: '',
      hasAllOptions: true,
      noWorkoutText: this.$i18n.t('message[\'subscriptions.no-workouts\']'),
      formData: {
        id: '',
        workout_goal: '',
        weeks: '',
        days_per_week: '',
        duration: '',
        level: '',
        location: ''
      },
      countLang: ''
    }
  },
  watch: {
    'formData.workout_goal': function (newVal, oldVal) {
      if (!oldVal) {
        return
      }
      this.formData.days_per_week = ''
      this.$refs.dayPreWeekVP.reset()
      this.formData.duration = ''
      this.$refs.durationVP.reset()
    },
    'formData.level': function (newVal, oldVal) {
      if (!oldVal) {
        return
      }
      this.formData.workout_goal = ''
      this.$refs.workoutGoalVP.reset()

      this.formData.days_per_week = ''
      this.$refs.dayPreWeekVP.reset()
      this.formData.duration = ''
      this.$refs.durationVP.reset()
    }

  },
  computed: {
    exerciseLocationOptions: function () {
      const avlLocations = Object.keys(this.options.supports)
      return this.options.exerciseLocation.filter(option => {
        return avlLocations.includes(option.id)
      })
    },
    experienceLevelOptions: function () {
      return this.options.experienceLevel
    },
    workoutGoalCustomErrorMessage: function () {
      if (this.formData.location && this.formData.level && this.workoutGoalOptions.length === 0) {
        return this.$i18n.t('message["workouts.validation-no-goals"]')
      }
      return ''
    },
    workoutGoalOptions: function () {
      if (!this.formData.location || !this.formData.level) {
        return []
      }
      try {
        const goalIds = Object.keys(this.options.supports[this.formData.location][this.formData.level])
        return this.options.workoutGoal.filter(goal => {
          return goalIds.includes(goal.id.toString())
        })
      } catch (e) {
        return []
      }
    },
    /**
       * filter the Session duration by selected workout goal
       * @returns {*}
       */
    sessionDurationOptions: function () {
      if (!this.formData.days_per_week) {
        return []
      }
      let days = []
      try {
        Object.entries(this.options.supports[this.formData.location][this.formData.level]).forEach(([key, value]) => {
          if (this.formData.workout_goal === parseInt(key)) {
            days = this.options.supports[this.formData.location][this.formData.level][key][this.formData.days_per_week]
          }
        })
      } catch (e) {
        days = []
      }

      let filterOptions = this.options.duration.filter(option => {
        return days.includes(option.id)
      })
      if (filterOptions) {
        return filterOptions
      } else {
        return this.options.duration
      }
    },
    /**
       * filter the days Pre Week by selected duration
       * @returns {*}
       */
    daysPerWeekOptions: function () {
      if (!this.formData.workout_goal || this.formData.workout_goal === '0') {
        return []
      }
      let durations = []
      try {
        Object.entries(this.options.supports[this.formData.location][this.formData.level]).forEach(([key, value]) => {
          if (this.formData.workout_goal === parseInt(key)) {
            durations = Object.keys(value)
          }
        })
      } catch (e) {
        durations = []
      }
      let filterOptions = this.options.daysPreWeek.filter(option => {
        return durations.includes(option.id.toString())
      })

      if (filterOptions) {
        return filterOptions
      } else {
        return this.options.daysPreWeek
      }
    },
    titleClasses: function () {
      return {
        'gc-page-workouts__title': this.title.image,
        'gc-page-workouts__title-no-image': !this.title.image
      }
    }
  },
  created () {
    this.formData = this.inputValue
  },
  mounted () {
    this.setWorkoutCount()
    this.setPageClass()
    this.errors[this.title.duration] = this.errors.duration || []
    this.errors[this.title.workoutGoal] = this.errors.workoutGoal || []
    this.errors[this.title.dayPreWeek] = this.errors.daysPerWeek || []
    if (this.hasDefinedError()) {
      this.$refs.form.setErrors(this.errors)
    }
    this.checkHasAllOptions()
    this.hideLoading()
  },
  methods: {
    setWorkoutCount () {
      this.countLang = this.$i18n.t("message['subscriptions.workout-count']", {count: this.currentWorkoutIndex, total: this.totalPages})
    },
    checkHasAllOptions () {
      if (this.options.duration.length === 0 || this.options.workoutGoal.length === 0 ||
          this.options.daysPreWeek.length === 0) {
        this.hasAllOptions = false
      }
    },
    enableButton () {
      if (this.formData.duration !== 0 && this.formData.duration) {
        this.buttonInvalid = false
      }
    },
    nextComponent () {
      if (this.formData.workout_goal === '0') {
        this.formData.workout_goal = ''
        this.formData.days_per_week = ''
        this.formData.workout_days = ''
      }
      if (this.formData.days_per_week === 0) {
        this.formData.days_per_week = ''
      }

      if (this.formData.duration === 0) {
        this.formData.duration = ''
      }

      this.$nextTick(() => {
        this.$refs.form.validate().then(success => {
          if (success) {
            this.formData.workout_days = this.formData.days_per_week
            this.formData.workout_goal = parseInt(this.formData.workout_goal)
            this.$emit('submit', { key: this.inputKey, value: this.formData })
          }
        })
      })
    },
    goToGender () {
      this.$emit('go-to-gender')
    },
    hasDefinedError () {
      return Object.values(this.errors).some(error => {
        return error.length > 0
      })
    }

  }

}
</script>

<style scoped>

</style>
