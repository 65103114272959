<template>
  <div
    v-if="show&&isEnabledInCurrentPage"
    class="gc-navigator-bottom-bar">

    <div class="gc-navigator-bottom-bar__container">
      <component
        v-for="icon in iconsToShow"
        v-if="icon && icon.show"
        :ref="icon.name"
        :is="icon.component"
        :key="icon.name"
        v-bind="icon.options"
        :active="icon.activeState()"
        @click="icon.onClick"
        @active="icon.onActive"/>

    </div>
    <div
      :style="{visibility: (showFloatingIcons)?'visible':'hidden' ,opacity : (showFloatingIcons)?'1':'0' }"
      class="gc-navigator-bottom-bar__floating-area"
      @click="afterBackgroundClick()">
      <div class="gc-navigator-bottom-bar__floating-area-buttons">

        <navigator-bottom-bar-button-photo v-if="fitnessDiaryEnabled && navigatorButtonsShow.photo"/>
        <navigator-bottom-bar-button-scale v-if="fitnessDiaryEnabled &&navigatorButtonsShow.scale"/>
        <!-- add following separator to break the line with 4 items -->
        <div class="gc-navigator-bottom-bar__floating-area-button-separator"/>
        <navigator-bottom-bar-button-metrics
          v-if="fitnessDiaryEnabled && navigatorButtonsShow.metrics"
          @click="goToMetrics()"/>

      </div>
    </div>
    <div v-if="showCommunityChatFloatingIcons" @click="showCommunityChatFloatingIcons = false"
         class="gc-navigator-bottom-bar__link--community-chat__floating-area-wrapper">
      <div class="gc-navigator-bottom-bar__link--community-chat__floating-area">
        <icon-down-arrow-filled
          class="gc-navigator-bottom-bar__link--community-chat__floating-area-pointer"></icon-down-arrow-filled>
        <div class="gc-navigator-bottom-bar__link--community-chat__floating-area-link"
             :class="{'gc-navigator-bottom-bar__link--community-chat__floating-area-link--active': this.$route.fullPath.includes('community')&&!chatOpen }"
             @click="clickedCommunity()">
          <icon-underline/>
          <div class="gc-navigator-bottom-bar__link--community-chat__floating-area-link-items">
            <div class="gc-navigator-bottom-bar__link--community-chat__floating-area-link-icon-wrapper">
              <icon-community :size="'sm2'"/>
              <icon-ellipse v-if="unreadCommunityNotificationsExist"/>
            </div>
            <text-body-extra-small :weight="'extra-bold'">{{ $t('message[\'notifications.tabs.community\']') }}
            </text-body-extra-small>
          </div>
        </div>
        <div class="gc-navigator-bottom-bar__link--community-chat__floating-area-link"
             :class="{'gc-navigator-bottom-bar__link--community-chat__floating-area-link--active': chatOpen }"
             @click="clickedChat()">
          <icon-underline/>
          <div class="gc-navigator-bottom-bar__link--community-chat__floating-area-link-items">
            <div class="gc-navigator-bottom-bar__link--community-chat__floating-area-link-icon-wrapper">
              <icon-chat :size="'sm2'"/>
              <icon-ellipse v-if="chatNewMessages"/>
            </div>
            <text-body-extra-small :weight="'extra-bold'">{{ $t('message[\'general.chat\']') }}</text-body-extra-small>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showPlans"
      :style="{visibility: (showPlans)?'visible':'hidden' ,opacity : (showPlans)?'1':'0' }"
      class="gc-navigator-bottom-bar__floating-area gc-navigator-bottom-bar__floating-area--plans"
      @click="afterPlanBackgroundClick()">
      <page-container class="gc-navigator-bottom-bar__container-plans">
        <card-list-challenge-plans
          :workout-widget-type="'card'"
          :workouts="challengeWorkoutPlans"
          :meal-plans="challengeMealPlans"
          :plan-pages="[]"
          :show-plan-name="false"
        />
        <card-list-meal-plans
          v-if="hasSubscriptionAndOneOffMealPlans || mealPlanNotAChallenge.length >0"
          :food-dairy="false"
          :none-challenge-meal-plans="mealPlanNotAChallenge"
          :challenge-meal-plans="challengeMealPlans"
          class="pc-dashboard__nutrition-card-list"
        />

        <card-list-workouts
          v-if="hasSubscriptionAndOneOffWorkoutPrograms || workouts.length>0"
          :widget-type="'card'"
          :workouts="workouts"
          class="pc-dashboard__workouts-list"/>

      </page-container>
    </div>
    <div class="gc-navigator-bottom-bar__static-area"/>
    <container-chat
      class="gc-navigator-bottom-bar__container-chat"
      @enabled="availableIcons.chat=true"/>
  </div>
</template>

<script>
import NavigatorBottomBarLinkHome from './navigator-bottom-bar-sub-components/NavigatorBottomBarLinkHome'
import NavigatorBottomBarLinkWorkout from './navigator-bottom-bar-sub-components/NavigatorBottomBarLinkWorkout'
import NavigatorBottomBarLinkAdd from './navigator-bottom-bar-sub-components/NavigatorBottomBarLinkAdd'
import NavigatorBottomBarLinkMeal from './navigator-bottom-bar-sub-components/NavigatorBottomBarLinkMeal'
import NavigatorBottomBarLinkMenu from './navigator-bottom-bar-sub-components/NavigatorBottomBarLinkMenu'
import NavigatorBottomBarButtonScale from './navigator-bottom-bar-sub-components/NavigatorBottomBarButtonScale'
import NavigatorBottomBarButtonPhoto from './navigator-bottom-bar-sub-components/NavigatorBottomBarButtonPhoto'
import NavigatorBottomBarButtonMetrics from './navigator-bottom-bar-sub-components/NavigatorBottomBarButtonMetrics'
import DashBoardService from '../../../services/DashboardService'
import {checkIsMember} from '../../../helpers/user'
import {mapGetters} from 'vuex'
import {
  isMeasurementsEnabled,
  isMeasurementWeightEnabled,
  isPhotoUploadEnabled
} from '../../../includes/TemplateSettings'
import UserEventsService from '../../../services/UserEventsService'
import moment from 'moment'
import globalEventBus from '../../../event-buses/globalEventBus'
import CardListChallengePlans from '../../layout/template-1/dashboard/page-components/CardListChallengePlans'
import CardListWorkouts from '../../layout/template-1/dashboard/page-components/CardListWorkouts'
import CardListMealPlans from '../../layout/template-1/dashboard/page-components/CardListMealPlans'
import NutritionService from '../../../services/nutrition/NutritionService'
import WorkoutProgramsService from '../../../services/WorkoutProgramsService'
import PageContainer from '../pages/page-sub-components/PageContainer'
import NavigatorBottomBarLinkChat from './navigator-bottom-bar-sub-components/NavigatorBottomBarLinkChat'
import ContainerChat from '../containers/ContainerChat'
import CommunityService from '../../../services/community/CommunityService'
import NavigatorBottomBarLinkCommunity from './navigator-bottom-bar-sub-components/NavigatorBottomBarLinkCommunity'
import NavigatorBottomBarLinkCommunityChat
  from './navigator-bottom-bar-sub-components/NavigatorBottomBarLinkCommunityChat'
import NavigatorBottomBarLinkProfile from './navigator-bottom-bar-sub-components/NavigatorBottomBarLinkProfile'
import NavigatorBottomBarLinkCoaching from './navigator-bottom-bar-sub-components/NavigatorBottomBarLinkCoaching'
import IconCommunity from '../../root/icons/IconCommunity.vue'
import IconEllipse from '../../root/icons/IconEllipse.vue'
import IconChat from '../../root/icons/IconChat.vue'
import IconDownArrowFilled from '../../root/icons/IconDownArrowFilled.vue'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall.vue'
import IconDot from '../../root/icons/IconDot.vue'
import IconUnderline from '@/components/root/icons/IconUnderline.vue'

export default {
  name: 'NavigatorBottomBar',
  components: {
    IconUnderline,
    IconDot,
    TextBodyExtraSmall,
    IconChat,
    IconDownArrowFilled,
    IconCommunity,
    IconEllipse,
    ContainerChat,
    PageContainer,
    NavigatorBottomBarButtonMetrics,
    NavigatorBottomBarButtonPhoto,
    NavigatorBottomBarButtonScale,
    NavigatorBottomBarLinkMenu,
    NavigatorBottomBarLinkChat,
    NavigatorBottomBarLinkMeal,
    NavigatorBottomBarLinkAdd,
    NavigatorBottomBarLinkWorkout,
    NavigatorBottomBarLinkCoaching,
    NavigatorBottomBarLinkHome,
    CardListChallengePlans,
    CardListWorkouts,
    CardListMealPlans
  },
  data: function () {
    return {
      availableIcons: {
        dashboard: true,
        mealPlans: false,
        workoutPrograms: false,
        addButton: false,
        community: false,
        chat: false,
        profile: true,
        more: true
      },
      chatMounted: false,
      chatNewMessages: false,
      chatVisibility: false,
      chatClickedBeforeMount: false,
      showCommunityChatFloatingIcons: false,
      communitiesLinks: [],
      iconsToShow: [],
      showOverlay: false,
      chatOpen: false,
      iconList: [
        {
          name: 'home',
          show: true,
          options: {},
          component: NavigatorBottomBarLinkHome,
          activeState: () => {
            return !this.showOverlay && this.activeLink === 'dashboard' && !this.showCommunityChatFloatingIcons && !this.chatOpen
          },
          onClick: () => {
            if (this.chatOpen) {
              this.closeChat()
            }
            this.goTo('/dashboard')
          },
          onActive: () => {
          }
        },
        {
          name: 'plans',
          show: true,
          options: {},
          component: NavigatorBottomBarLinkWorkout,
          activeState: () => {
            return (this.showPlans || (!this.showOverlay && ['meal-plans', 'workout-programs'].includes(this.activeLink))) && !this.showCommunityChatFloatingIcons && !this.chatOpen
          },
          onClick: () => {
            if (this.chatOpen) {
              this.closeChat()
            }
            const dashBoardService = new DashBoardService()
            const hasMealPlans = dashBoardService.hasMealPlans()
            const hasWorkoutPrograms = dashBoardService.hasWorkoutPrograms()

            if (hasMealPlans && hasWorkoutPrograms) {
              this.togglePlans()
              return
            }

            let activeWorkoutPrograms = this.workouts.filter(workout => {
              return workout.available
            })
            let activeChallengePrograms = this.challengeWorkoutPlans
            const totalActiveWorkoutProgramsCount = this.workouts.length + activeChallengePrograms.length

            let activeWorkoutProgramID = null
            if (activeWorkoutPrograms[0]) {
              activeWorkoutProgramID = activeWorkoutPrograms[0].getID()
            } else if (activeChallengePrograms[0]) {
              activeWorkoutProgramID = activeChallengePrograms[0].getID()
            }
            const nService = new NutritionService()
            let activeMealPlans = nService.getActiveMealPlans()
            let allMealPlans = nService.getAllMealPlans()
            if (totalActiveWorkoutProgramsCount === 1 && allMealPlans.length === 0) {
              this.$router.push({
                path: this.$appConfig.appUrlList.workoutBase,
                query: {
                  workout: activeWorkoutProgramID

                }
              })
            } else if (totalActiveWorkoutProgramsCount === 0 && Array.isArray(activeMealPlans) && allMealPlans.length === 1 && activeMealPlans.length === 1) {
              this.$router.push({
                path: [this.$appConfig.appUrlList.mealPlanBase, activeMealPlans[0].getId()].join('/')
              })
            } else {
              this.togglePlans()
            }
          },
          onActive: () => {
          }
        },
        {
          name: 'addButton',
          show: true,
          options: {},
          component: NavigatorBottomBarLinkAdd,
          activeState: () => {
            return !this.showOverlay && this.showFloatingIcons
          },
          onClick: (e) => {
            this.setFloatingIcons(e)
          },
          onActive: (e) => {
            this.setFloatingIcons(e)
          }
        },
        {
          name: 'chat',
          show: true,
          options: {},
          component: NavigatorBottomBarLinkChat,
          activeState: () => {
            return this.activeLink === 'community'
          },
          onClick: () => {
          },
          onActive: (state) => {
            this.onChatActiveEmit(state)
          }
        },
        {
          name: 'community',
          show: true,
          options: {},
          component: NavigatorBottomBarLinkCommunity,
          activeState: () => {
            return !this.showOverlay && this.activeLink === 'community'
          },
          onClick: () => {
            this.hideAll()
            this.setPlusButtonInactive()
            if (this.communitiesLinks[0]) {
              this.goTo('/dashboard?community=' + this.communitiesLinks[0].id)
            } else {
              setTimeout(() => {
                if (this.communitiesLinks[0]) {
                  this.goTo('/dashboard?community=' + this.communitiesLinks[0].id)
                }
              }, 2000)
            }
          },
          onActive: () => {

          }
        },
        {
          name: 'community-chat',
          show: true,
          options: {},
          component: NavigatorBottomBarLinkCommunityChat,
          activeState: () => {
            return ((!this.showOverlay && this.showCommunityChatFloatingIcons) || this.activeLink === 'community-chat' || this.chatOpen) && !this.showPlans && !this.showFloatingIcons
          },
          onClick: () => {
            if (!this.showCommunityChatFloatingIcons) {
              this.hideAll()
            }

            this.setPlusButtonInactive()
            this.toggleOverlayBodyClass()
            this.showCommunityChatFloatingIcons = !this.showCommunityChatFloatingIcons

            this.setCommunityFlotingIconPosition()

          },
          onActive: (state) => {
          }
        },
        {
          name: 'profile',
          show: true,
          options: {},
          component: NavigatorBottomBarLinkProfile,
          activeState: () => {
            return !this.showOverlay && this.activeLink === 'profile'
          },
          onClick: () => {
            if (this.chatOpen) {
              this.closeChat()
            }
            this.goTo('/account-details')
          },
          onActive: () => {

          }
        }, {

          name: 'more',
          show: true,
          options: {},
          component: NavigatorBottomBarLinkMenu,
          activeState: () => {
            return !this.showOverlay && (this.activeLink === 'menu' ||
              this.activeLink === '' ||
              (!this.findIcon('profile') && this.activeLink === 'profile') ||
              (!this.findIcon('community') && this.activeLink === 'community')) && !this.showCommunityChatFloatingIcons && !this.chatOpen
          },
          onClick: () => {
            if (this.chatOpen) {
              this.closeChat()
            }
            this.goTo('/dashboard?page=navigation')
          },
          onActive: () => {
          }
        }

      ],
      hasWorkouts: false,
      hasMealPlans: false,
      workouts: [],
      allMealPlans: [],
      mealPlanOptions: [],
      mealPlanNotAChallenge: [],
      challengeMealPlans: [],
      challengeWorkoutPlans: [],
      challengeProductPages: [],
      showChat: false,
      showPlans: false,
      isMember: false,
      fitnessDiaryEnabled: false,
      showFloatingIcons: false,
      showAddIcon: true,
      show: false,
      isEnabledInCurrentPage: false,
      activeLink: '',
      screen: {
        orientation: 'portrait',
        portrait: {
          maxHeight: 0,
          maxWidth: 0
        },
        landscape: {
          maxHeight: 0,
          maxWidth: 0
        }
      },
      navigatorButtonsShow: {
        scale: true,
        photo: true,
        metrics: true,
        mood: false,
        food: false,
        habit: false
      },
      features: {
        hasNew: false,
        isInviteAFriendVisited: false
      }
    }
  },
  watch: {
    show: {
      immediate: true,
      handler: function () {
        this.setAppClass()
      }
    },
    availableIcons: {
      immediate: true,
      deep: true,
      handler: function () {
        this.mapIcons()
      }
    },
    features: {
      deep: true,
      handler: function () {
        this.mapIcons()
      }
    },
    appLoading: {
      immediate: true,
      handler: function () {
        if (!this.appLoading && this.$route.meta.requiresAuth) {
          this.checkNewFeatures()
        }
      }
    },
    showOverlay: {
      immediate: true,
      handler: function () {
        this.toggleOverlayBodyClass()
      }
    },
    showPlans: {
      immediate: true,
      handler: function () {
        this.toggleOverlayBodyClass()
      }
    },
    showFloatingIcons: {
      immediate: true,
      handler: function () {
        this.toggleOverlayBodyClass()
      }
    },
    $route: {
      handler: function (value) {
        this.showOverlay = false
        let routeNamesNotToShow = ['OnboardMain',
          'WorkoutUpdateMain',
          'MealPlanUpdateMain',
          'PageSignUpPaymentConfirm',
          'PageSignUpThankYou',
          'restartSignUp',
          'PageSignUpPay',
          'PageSignUp',
          'PageSignUpAlreadySubscribed',
        ]
        const notToShowPages = [
          {
            routeName: 'MealPlanMain',
            queryNames: ['meal']
          }, {
            routeName: 'WorkoutMain',
            queryNames: ['exercise']
          }
        ]
        if (value.meta.requiresAuth && checkIsMember()) {
          const dashboard = new DashBoardService()
          Promise.all([dashboard.getUserConfig(), dashboard.getPageData()]).then(() => {
            this.hasMealPlans = dashboard.hasActiveMealPlans()
            this.hasWorkoutPrograms = dashboard.hasActiveWorkoutPrograms()
            this.loadCommunities()
            this.mapIcons()
            this.addListenerScreenResize()
            this.onWindowSize()
            this.showMealPlans()
            this.showWorkouts()
            this.loadUserConfig()

            if (!this.isDesktop && templateConfig.instance_config_timestamps.show_bottom_navigation_bar && templateConfig.instance_config_timestamps.show_bottom_navigation_bar.created_at) {
              const enabledDate = moment(templateConfig.instance_config_timestamps.show_bottom_navigation_bar.created_at)
              const userRegDate = dashboard.getRegisteredDate()
              if (userRegDate.isBefore(enabledDate)) {
                UserEventsService.bottomBarFeaturePopupView()
              }
            }
          }).catch(err => console.log(err))
        }
        let notToShowURL = !value.name.includes('PageSignUp') && !notToShowPages.some(notToShowPage => {
          if (value.name === notToShowPage.routeName) {
            if (notToShowPage.queryNames.length === 0) {
              return true
            }
            return notToShowPage.queryNames.every(param => {
              return param in value.query
            })
          }
        })

        this.isEnabledInCurrentPage = (value.meta && value.meta.requiresAuth && !routeNamesNotToShow.includes(value.name)) && notToShowURL

        if (this.isEnabledInCurrentPage) {
          this.checkNewFeatures()
        }
        this.setActiveLink()
        this.setAppClass()
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.isMember = checkIsMember()
  },
  beforeMount () {
    document.documentElement.style.height = '100vh'
  },
  beforeDestroy () {
    this.removeListenerScreenResize()
    window.addEventListener('ma-chat-mounted', this.handleChatMounted)
    window.removeEventListener('ma-chat-expanded', this.handleChatExpanded)
    window.removeEventListener('ma-chat-collapsed', this.handleChatCollapsed)
    window.removeEventListener('ma-chat-new-messages', this.handleNewMessages)
  },
  computed: {
    ...mapGetters({
      appLoading: 'getLoading',
      unreadCommunityNotificationsExist: 'communityStore/isUnreadNotificationsExist'
    }),
    hasSubscriptionAndOneOffWorkoutPrograms () {
      return this.hasWorkouts && (this.challengeWorkoutPlans.length === 0 || this.workouts.length > 0)
    },
    hasSubscriptionAndOneOffMealPlans () {
      return this.hasMealPlans && (this.challengeMealPlans.length === 0 || this.mealPlanOptions.length > 0)
    },
    hasAddButtonIcons: function () {
      return Object.values(this.navigatorButtonsShow).some(val => {
        return val
      })
    },
    isMetricsEnabled: function () {
      return isMeasurementsEnabled()
    },
    isPhotoJourneyEnabled: function () {
      return isPhotoUploadEnabled()
    },
    isWeightMetricEnabled: function () {
      return isMeasurementWeightEnabled()
    },
    isAddIconEnabled: function () {
      return isMeasurementsEnabled() || isPhotoUploadEnabled() || isMeasurementWeightEnabled()
    }
  },
  mounted () {
    setTimeout(() => {
      this.setActiveLink()
    }, 1500)
    window.addEventListener('ma-chat-mounted', this.handleChatMounted)
    window.addEventListener('ma-chat-expanded', this.handleChatExpanded)
    window.addEventListener('ma-chat-collapsed', this.handleChatCollapsed)
    window.addEventListener('ma-chat-new-messages', this.handleNewMessages)
  },
  methods: {
    toggleOverlayBodyClass () {
      this.showOverlay = this.showFloatingIcons || this.showPlans || !!document.querySelector('body.chat-app-open')
      const element = document.querySelector('body')
      if (element) {
        element.classList.toggle('bottom-bar-expanded', ((this.showFloatingIcons || this.showPlans) || this.showOverlay))
      }
    },
    setComponentByName (name, component) {
      this.iconList.forEach((icon, index) => {
        if (icon.name === name) {
          this.iconList[index].component = component
        }
      })
    },
    setPlusButtonInactive () {
      if (this.$refs['addButton'] && this.$refs['addButton'][0]) {
        this.$refs['addButton'][0].setInactive()
      }
    },
    mapIcons () {
      const hasNewFeature = this.features.hasNew || false
      const iconsToShow = [null, null, null, null, null]
      iconsToShow[0] = this.findIconByName('home')
      iconsToShow[4] = Object.assign(this.findIconByName('more'), {options: {hasNewFeature: hasNewFeature}})
      const hasWorkouts = this.hasWorkouts || this.workouts.length > 0 || this.challengeWorkoutPlans.length > 0
      const hasMealPlans = this.hasMealPlans || this.mealPlanOptions.length > 0 || this.challengeMealPlans.length > 0
      if (hasMealPlans || hasWorkouts) {
        if (hasMealPlans && hasWorkouts) {
          this.setComponentByName('plans', NavigatorBottomBarLinkCoaching)
        } else if (hasMealPlans) {
          this.setComponentByName('plans', NavigatorBottomBarLinkMeal)
        } else if (hasWorkouts) {
          this.setComponentByName('plans', NavigatorBottomBarLinkWorkout)
        }

        iconsToShow[1] = this.findIconByName('plans')
      }
      if (this.availableIcons.addButton) {
        iconsToShow[2] = this.findIconByName('addButton')
      }
      if (iconsToShow[1] || !iconsToShow[2]) {
        if (this.availableIcons.chat && this.availableIcons.community) {
          iconsToShow[3] = this.findIconByName('community-chat')
        } else if (this.availableIcons.chat) {
          iconsToShow[3] = this.findIconByName('chat')
        } else if (this.availableIcons.community) {
          iconsToShow[3] = this.findIconByName('community')
        } else {
          iconsToShow[3] = this.findIconByName('profile')
        }
      }
      this.iconsToShow = iconsToShow.filter(icon => {
        return !!icon
      })
    },
    findIconByName (name) {
      return this.iconList.find(icon => icon.name === name)
    },
    findIcon (name) {
      return this.iconsToShow.find(icon => icon.name === name)
    },
    hideAll () {
      if (document.querySelector('.custom-channel-header__icon-close')) {
        document.querySelector('.custom-channel-header__icon-close').click()
      }
      this.showCommunityChatFloatingIcons = false
      this.showPlans = false
      this.showFloatingIcons = false
      this.showOverlay = false
    },
    togglePlans () {
      if (!this.showPlans) {
        this.hideAll()
        this.setPlusButtonInactive()
        this.showPlans = true
      } else {
        this.showPlans = false
      }
      this.$nextTick(() => {
        this.showOverlay = this.showPlans
      })
    },
    onChatActiveEmit (state) {
      if (state) {
        this.showPlans = false
        this.showFloatingIcons = false
      }
      this.setPlusButtonInactive()
      this.$nextTick(() => {
        this.showOverlay = state
      })
    },
    showMealPlans () {
      let mealService = new NutritionService()
      mealService
        .init()
        .then(() => {
          this.allMealPlans = mealService.getActiveMealPlans()
          this.challengeMealPlans = mealService.getChallengePlans()
          this.mealPlanOptions = mealService.getActiveMealPlans()
          this.mealPlanNotAChallenge = mealService.getNotChallengePlans()
        })
    },
    loadCommunities () {
      let community = new CommunityService()
      const dashBoardService = new DashBoardService()
      community.getCommunities().then(async () => {
        this.communitiesLinks = await community.getActiveCommunities()
        this.availableIcons.community = this.communitiesLinks.length > 0
        if (checkIsMember() && await community.getActiveCommunities() === 0) {
          await UserEventsService.trackRegisterBeforeCommunities()
        } else if (checkIsMember() && dashBoardService.hasActivePlan()) {
          this.checkNewFeatures()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    showWorkouts () {
      let workoutProgramsService = new WorkoutProgramsService()
      workoutProgramsService
        .setWorkoutProgramList()
        .then(() => {
          this.challengeWorkoutPlans = workoutProgramsService.getChallengeWorkoutPrograms()
          this.workouts = workoutProgramsService.workoutPrograms
        })
    },
    checkNewFeatures () {
      this.features.hasNew = false
      if (!checkIsMember()) {
        return
      }
      let dashBoardService = new DashBoardService()
      dashBoardService.getUserData().then(() => {
        this.showChat = dashBoardService.hasChatEnabled()
        this.hasMealPlans = dashBoardService.hasActiveMealPlans()
        this.hasWorkouts = dashBoardService.hasActiveWorkoutPrograms()
      })
      dashBoardService.getNavigation('top').then(data => {
        let hasInviteAFriend = data.links.some(link => {
          return link.code === 'invitations'
        })
        if (hasInviteAFriend) {
          UserEventsService.checkInviteAFriendPageVisit().then(visit => {
            if (moment('2022-08-02').diff(dashBoardService.getRegisteredDate(), 'days') > 0) {
              this.features.hasNew = !visit
              this.features.isInviteAFriendVisited = visit
            } else {
              this.features.hasNew = false
            }
          })
        }
      })

      if (this.communitiesLinks.length > 0) {
        if (checkIsMember() && dashBoardService.hasActivePlan()) {
          UserEventsService.checkRegisterBeforeCommunities().then(registered => {
            if (registered || moment('2023-11-14').diff(dashBoardService.getRegisteredDate(), 'days') > 0) {
              const allCommunity = this.communitiesLinks[0]
              UserEventsService.communityFeaturePopupView('/dashboard?community=' + allCommunity.id)
              UserEventsService.checkCommunityPageVisit(allCommunity.id).then(visit => {
                this.features.hasNew = this.features.hasNew || !visit
                this.features.isCommunityVisited = visit
              })
            }
          })
        }
      }
    },
    afterBackgroundClick () {
      this.$refs['addButton'][0].iconClicked()
    },
    afterPlanBackgroundClick () {
      this.showPlans = false
    },
    setAppClass () {
      const element = document.querySelector('body')
      if (element) {
        if (this.isEnabledInCurrentPage && this.show) {
          element.classList.add('with-bottom-bar')
        } else {
          element.classList.remove('with-bottom-bar')
        }
      }
    },
    setCommunityFlotingIconPosition () {
      this.$nextTick(() => {
        const fContainer = document.querySelector('.gc-navigator-bottom-bar__link--community-chat__floating-area')
        if (!fContainer) {
          return
        }
        const icon = document.querySelector('.rc-icon-community-chat')
        const fContainerRect = fContainer.getBoundingClientRect()
        const iconRect = icon.getBoundingClientRect()

        fContainer.style.right = `calc(100% - ${iconRect.right}px - 87.5px + 10px)`

      })
    },
    addListenerScreenResize () {
      if (window.visualViewport) {
        visualViewport.addEventListener('resize', this.onWindowSize)
      } else {
        window.addEventListener('resize', this.onWindowSize)
      }
    },
    removeListenerScreenResize () {
      if (window.visualViewport) {
        visualViewport.addEventListener('resize', this.onWindowSize)
      } else {
        window.addEventListener('resize', this.onWindowSize)
      }
    },
    onWindowSize () {
      if (this.isDesktop) {
        this.show = false
        return
      }
      if (document.querySelector('.chat-app-open')) {
        return
      }

      if (window.matchMedia('(orientation: portrait)').matches) {
        this.screen.orientation = 'portrait'
      } else if (window.matchMedia('(orientation: landscape)').matches) {
        this.screen.orientation = 'landscape'
      }
      let currentHeight = window.innerHeight
      let currentWidth = window.innerWidth

      if (currentHeight > this.screen[this.screen.orientation].maxHeight) {
        this.screen[this.screen.orientation].maxHeight = currentHeight
      }
      if (currentWidth > this.screen[this.screen.orientation].maxWidth) {
        this.screen[this.screen.orientation].maxWidth = currentWidth
      }
      if (window.visualViewport) {
        this.show = (this.screen[this.screen.orientation].maxHeight - 150 < window.visualViewport.height)
      } else {
        this.show = true
      }
      this.setCommunityFlotingIconPosition()
    },
    loadUserConfig () {
      let service = new DashBoardService()
      service.getUserData().then(() => {
        this.fitnessDiaryEnabled = service.isFitnessDiaryEnabled()

        this.availableIcons.addButton = this.availableIcons.addButton || this.fitnessDiaryEnabled
        this.navigatorButtonsShow.scale = this.fitnessDiaryEnabled && isMeasurementWeightEnabled()
        this.navigatorButtonsShow.photo = this.fitnessDiaryEnabled && isPhotoUploadEnabled() && service.getDob() !== null
        this.navigatorButtonsShow.metrics = this.fitnessDiaryEnabled && isMeasurementsEnabled()
      })
    },
    setActiveLink () {
      this.navigatorButtonsShow.food = false
      this.navigatorButtonsShow.mood = false
      this.navigatorButtonsShow.habit = false
      if (this.$route.fullPath.endsWith('/dashboard')) {
        this.activeLink = 'dashboard'
      } else if (this.$route.fullPath.includes('/meal-plans')) {
        this.activeLink = 'meal-plans'
      } else if (this.$route.fullPath.includes('/workout-programs')) {
        this.activeLink = 'workout-programs'
      } else if (this.$route.fullPath.includes('page=navigation')) {
        this.activeLink = 'menu'
      } else if (this.$route.fullPath.includes('community') && this.availableIcons.chat && this.availableIcons.community) {
        this.activeLink = 'community-chat'
      } else if (this.$route.fullPath.includes('chat') && this.availableIcons.chat && this.availableIcons.community) {
        this.activeLink = 'community-chat'
      } else if (this.$route.fullPath.includes('community')) {
        this.activeLink = 'community'
      } else if (this.$route.fullPath.includes('account-details')) {
        this.activeLink = 'profile'
      } else {
        this.activeLink = ''
      }
    },
    setFloatingIcons (state) {
      this.hideAll()
      this.showFloatingIcons = state
    },
    clickedCommunity () {
      if (this.chatOpen) {
        this.closeChat()
      }
      if (this.$route.fullPath.includes('community')) {
        this.showCommunityChatFloatingIcons = false
        return
      }

      if (this.communitiesLinks[0]) {
        this.goTo('/dashboard?community=' + this.communitiesLinks[0].id)
      } else {
        setTimeout(() => {
          if (this.communitiesLinks[0]) {
            this.goTo('/dashboard?community=' + this.communitiesLinks[0].id)
          }
        }, 2000)
      }
    },
    clickedChat () {
      if (!this.chatOpen) {
        this.openChat()
      } else {
        this.closeChat()
      }
    },
    handleChatMounted () {
      this.mounted = true
      if (this.clickedBeforeMount) {
        this.clickedBeforeMount = false
        this.openChat()
      }
      this.hideLoading()
    },
    handleChatExpanded () {
      this.chatOpen = true
    },
    handleChatCollapsed () {
      this.chatOpen = false
      this.chatNewMessages = false
    },
    handleNewMessages (e) {
      this.chatNewMessages = true
    },
    openChat () {
      if (!this.mounted) {
        this.clickedBeforeMount = true
        this.showLoading()
        setTimeout(() => {
          this.hideLoading()
        }, 3000)
      }
      if (!document.querySelector('.chat-button')) {
        return
      }
      this.chatVisibility = true
      document.querySelector('.chat-button').click()
      this.$emit('active', true)
    },
    closeChat () {
      this.chatOpen = false
      this.chatVisibility = false
      document.querySelector('.custom-channel-header__icon-close div').click() //to-do add classname to div on chat-app
    },
    goToMetrics () {
      if (this.$route.fullPath === '/progress') {
        globalEventBus.$emit('metrics-clicked')
      } else if (this.$route.fullPath.includes('mood-tracker') || this.$route.fullPath.includes('period-tracker') || this.$route.fullPath.includes('habit-tracker')) {
        this.$router.push({path: '/progress'})
        setTimeout(() => {
          globalEventBus.$emit('metrics-clicked')
        }, 1000)
      } else {
        this.navigateToProgress().then(() => {
          globalEventBus.$emit('metrics-clicked')
          setTimeout(() => {
            globalEventBus.$emit('metrics-clicked')
          }, 1000)
        })
      }
    },
    navigateToProgress () {
      return new Promise((resolve) => {
        this.$router.push({path: '/progress'})
        this.$router.afterEach(() => {
          resolve()
        })
      })
    },

    goTo (path) {
      if (!(path === '/dashboard' && 'page' in this.$route.query) && this.$route.fullPath.includes(path)) {
        window.scrollTo({top: 0, behavior: 'smooth'})
      }
      this.setPlusButtonInactive()
      this.hideAll()

      if (this.showAddIcon) {
        this.showAddIcon = false
        this.$nextTick(() => {
          this.showFloatingIcons = false
          this.showPlans = false
          this.showOverlay = false
          this.showAddIcon = true
        })
      }
      this.$router.push(path)
    }
  }
}
</script>
