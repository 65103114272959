export function replaceWordsWithNumbers (numberStr, langNumbers) {
  let words = numberStr.split(' ')
  for (const key in langNumbers) {
    if (words.includes(key)) {
      words = words.map(word => {
        return (word === key) ? langNumbers[key] : word
      })
      numberStr = words.join(' ')
      return numberStr
    }
  }
  return numberStr
}


export function sortMixedStrings(a, b) {
  const numeric = /^[-+]??\d+/;

  const isNumericA = numeric.test(a);
  const isNumericB = numeric.test(b);
  // e.g. compare 5 and 6
  if (isNumericA && isNumericB) {
      return parseInt(a, 10) - parseInt(b, 10);
  }

  // e.g. 5 and A2
  if (isNumericA && !isNumericB) {
      return -1;
  }

  // e.g. A2 and 6
  if (!isNumericA && isNumericB) {
      return 1;
  }

  const alphabets = /^[a-zA-Z]+/;
  // Alphabet + number: A1, B3...
  const aAlphabets = a.replace(/\d+/g, "");
  const bAlphabets = b.replace(/\d+/g, "");
  if (aAlphabets === bAlphabets) {
      // e.g. Compare AB10 and AB12
      const aNumber = a.replace(alphabets, "");
      const bNumber = b.replace(alphabets, "");
      // e.g. Compare 10 and 12 for AB10 and AB12
      const result = aNumber === bNumber ? 0 : parseInt(aNumber, 10) - parseInt(bNumber, 10);
      return result;
  }
  // e.g. A12 and B12
  return aAlphabets > bAlphabets ? 1 : -1;
}
