<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 24.0012C18.6274 24.0012 24 18.6286 24 12.0012C24 5.3738 18.6274 0.0012207 12 0.0012207C5.37258 0.0012207 0 5.3738 0 12.0012C0 18.6286 5.37258 24.0012 12 24.0012ZM6.47173 11.1008C6.28709 11.2855 6.28709 11.5839 6.47173 11.7686C6.65637 11.9532 6.95482 11.9532 7.13946 11.7686L12.0001 6.9079L16.8608 11.7686C17.0454 11.9532 17.3439 11.9532 17.5285 11.7686C17.7131 11.5839 17.7131 11.2855 17.5285 11.1008L12.334 5.9063C12.1493 5.72166 11.8509 5.72166 11.6663 5.9063L6.47173 11.1008ZM17.5285 16.9631C17.7131 16.7784 17.7131 16.48 17.5285 16.2953L12.334 11.1008C12.1493 10.9162 11.8509 10.9162 11.6663 11.1008L6.47173 16.2953C6.28709 16.48 6.28709 16.7784 6.47173 16.9631C6.65637 17.1477 6.95482 17.1477 7.13946 16.9631L12.0001 12.1024L16.8608 16.9631C17.0454 17.1477 17.3439 17.1477 17.5285 16.9631Z"
        fill="#AFAFAF"
      />
    </svg>
  </div>
</template>

<script>
import iconMixin from "../../../mixins/component-mixins/root/iconMixin";

export default {
  name: "IconArrowCircularFilledV3",
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        "rc-icon": true,
        "rc-icon-dot": true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses,
      };
    },
  },
};
</script>

<style scoped></style>
