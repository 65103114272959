import HabitEntry from './HabitEntry'

/** Class, will do an array of statuses (all dummy) */
export default class HabitDayEntries {
  date = null
  /** @type {Array<HabitEntry>} */
  entries = []

  constructor (date, habits) {
    this.date = date

    this.entries = []
    habits.forEach(habit => {
      this.entries.push(new HabitEntry(habit, date))
    })
  }

  getDate () {
    return this.date
  }

  /** @returns {HabitEntry[]} */
  getEntries () {
    return this.entries
  }
  /** return entries without any labels, thus never marked */
  getUnmarkedEntries () {
    if(this.entries === null) return [];
    // console.log("Filtering entries: ", this.entries)
    return this.entries.filter((entry, index) => {
      // console.log("Filtering entry: ", index, entry.habit)
      return entry.entry.label === null})
  }

  /** return entries without any labels, thus never marked */
  getMarkedEntries () {
    // console.log("Filtering entries: ", this.entries)
    return this.entries.filter((entry, index) => {
      // console.log("Filtering entry: ", index, entry.habit)
      return entry.entry.label !== null})
  }

  isAllLogged () {
    return this.entries.every(entry => {
      return entry.isLogged()
    })
  }

  isSomeLogged () {
    return this.entries.some(entry => {
      return entry.isLogged()
    })
  }

  /**
   * delete the habit entry
   * @param id - habit entry ID
   * @returns {Promise<T>}
   */
  deleteHabitEntry (id) {
    const entry = this.findHabitEntry(id)
    if (entry) {
      return entry.deleteEntry()
    } else {
      throw new Error('Habit Entry not found')
    }
  }

  /**
   * find habbit entry by ID
   * @param entryID
   * @returns {*}
   */
  findHabitEntry (entryID) {
    const entries = this.getEntries()
    return entries.find((entry) => this.__isEntryIDEqualID(entry, entryID))
  }

  __isEntryIDEqualID (entry, entryID) {
    return entry.entry.id === entryID
  }

  setEntry (habitID, entry) {
    let currentEntry = this.findEntryByHabit(habitID)

    if (currentEntry) {
      currentEntry.setEntry(entry)
    } else {
      throw new Error('No Habit Entry Found')
    }
  }

  findEntryByHabit (habitID) {
    let currentEntry
    currentEntry = this.entries.find(entryItem => {
      return entryItem.getHabitID() === habitID
    })
    return currentEntry
  }

  hasLoggedEntry () {
    // console.log("Checking Entries 1: ", this.entries.map(entry => entry.isEntryEmpty()))
    // console.log("Checking Entries 2: ", this.entries.map(entry => entry.habit.id))
    return this.entries.some(habitEntry => {
      const entryId = habitEntry.getEntryID()
      if(entryId === null) return false;
      if(entryId === undefined) return false;
      return true;
    })
  }
}
