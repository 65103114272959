import { render, staticRenderFns } from "./IconConnectWearable.vue?vue&type=template&id=0cea3f80&scoped=true"
import script from "./IconConnectWearable.vue?vue&type=script&lang=js"
export * from "./IconConnectWearable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cea3f80",
  null
  
)

export default component.exports