<template>
  <li :key="index" class="splide__slide">
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: "SliderSlide"
}
</script>

