<template>
  <div class="container">
    <form-header :sub_title="title.sub" :title="title.main"></form-header>
    <ValidationObserver name="gender" ref="form" v-slot="{ handleSubmit,invalid, }">
      <form @submit.prevent="handleSubmit(nextComponent)">
        <div v-for="option in options" :key="'option-'+option.key">
          <ValidationProvider :name="option.label" :rules="option.validation" v-slot="{ errors , invalid , validated}">
            <label class="ma-form__label">{{option.label}}</label>
            <input
              :placeholder="[[option.placeholder]]"
              class="form-control ma-form__input"
              inputmode="text"
              type="text"
              v-bind:class="{'ma-form__input--error': invalid&&validated }"
              v-model="inputValue[option.key]"
            />
            <p class="ma-form__input-description head__subtitle text-left pt-1">{{ option.description }}</p>
            <p class="app-error-msg ">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <form-footer :text="submitText" :validated="!invalid"></form-footer>
      </form>
    </ValidationObserver>

  </div>
</template>

<script>
import BaseComponent from '../FormBaseComponent'

import FormHeader from '../../FormHeader'
import FormFooter from '../../FormFooter'

export default {
  name: 'CustomDetails',
  extends: BaseComponent,
  components: {
    FormHeader,
    FormFooter
  },
  data: function () {
    return {
      pageClass: ['page--modifier', 'page--custom-details-form'],
      formData: {

      }
    }
  },
  mounted () {
    this.setPageClass()
    this.hideLoading()
  },
  methods: { }
}
</script>
