<template>
    <div class="gc-slider-video">
        <div class="gc-slider-video__header">
            <text-content class="gc-slider-video__slider-title" :size="'sm3'" :lineHeight="'lh-lg'" :weight="'extra-bold'">
                {{ sliderTitle }}
            </text-content>
            <icon-forward class="gc-slider-video__see-more" v-if="showSeeAll" :size="'sm4'" @click="redirectToCustomPage" />
        </div>
        <video-player ref="videoPlayer" v-if="isFullScreen" :autoPlayFromParent="autoPlay" :mute="false" :url="fullScreenVideoPlayer.url" @exitFullScreen="exitScreen"/>
        <slider v-if="slides.length > 0" ref="slickVideo"
                :options="videosSlickOptions"
                :slider-i-d="route"
                @visible="sliderVisible"
                @hidden="sliderHidden"
                @active="sliderActive"
                @inactive="sliderinActive">
          <slider-slide  v-for="(slide, index) in slides" :key="index">
            <div>
                <div class="gc-slider-video__video-card-wrapper">
                 <div class="gc-slider-video__thumbnail-wrapper">
                    <img loading="lazy" :src="slide.thumbnail_url" alt="Thumbnail"  class="gc-slider-video__video-thumbnail" />
                    <div class="gc-slider-video__play-icon-wrapper">
                        <icon-play-variation class="gc-slider-video__play-icon" size="md4"  v-if="!isPlaying" @click="setFullscreen(slide)" />
                    </div>

                 </div>
                </div>
                 <div>
                    <text-content v-if="showVideoTitle" class="gc-slider-video__slider-video-title" :lineHeight="'lh-lg'" :size="'sm2'">
                        {{ slide.title }}
                    </text-content>
                 </div>
            </div>
          </slider-slide>

          <slider-slide :key="'showSeeMore'"  v-if="showSeeMore">
            <div class="gc-slider-video__see-more-card-wrapper" @click="redirectToCustomPage">
                <div class="gc-slider-video__see-more-thumbnail-wrapper">
                <div class="gc-slider-video__play-icon-wrapper">
                    <text-content class="gc-slider-video__see-more-text" :size="'sm2'" :decoration="'underline'" :weight="'extra-bold'">
                        {{ $t("message['general.add-more']") }}
                    </text-content>

                </div>

                </div>
            </div>
          </slider-slide>
        </slider>
    </div>
</template>
<script>
import Slick from 'vue-slick'
import { slickMixin } from '@/mixins/slickMixin'
import VideoPlayer from '../players/VideoPlayer'
import IconPlayVariation from '../../root/icons/IconPlayVariation'
import IconPause from '../../root/icons/IconPause'
import TextContent from '../../root/TextContent'
import IconForward from '../../root/icons/IconForward'
import { isIOS } from 'mobile-device-detect'
import SliderSlide from "@/components/global/sliders/slider-sub-components/SliderSlide.vue";
import Slider from "@/components/global/sliders/Slider.vue";
export default {
    name: 'SliderVideo',
    components: {
      Slider,
      SliderSlide,
        Slick,
        VideoPlayer,
        IconPlayVariation,
        IconPause,
        TextContent,
        IconForward
    },
    props: {
        showSeeMore: {
            type: Boolean,
            default: false
        },

        sliderTitle: {
            type: String,
            default: ''
        },
        route: {
            type: String,
            default: ''
        },
        showVideoTitle: {
            type: Boolean,
            default: true
        },
        showSeeAll: {
            type: Boolean,
            default: true
        },
        slidess: {
            type: Array,
            required: true
        }
    },
    mixins: [slickMixin],
    data () {
        return {
            autoPlay: false,
            isFullScreen: false,
            fullScreenVideoPlayer: {},
            isPlaying: false,
            slides: [],
            videosSlickOptions: {
                slidesToShow: 1,
                variableWidth: true,
                autoplay: false,
                dots: false,
                prevArrow: false,
                nextArrow: false,
                infinite: false,
                rtl: false,
                touchThreshold: 100,
            }
        }
    },
    mounted() {
        // this.$refs.slickVideo.slickGoTo(0)
        this.slides = this.slidess
    },
    methods: {
      sliderVisible(a){
        }, sliderHidden(a){
        }, sliderActive(a){
        },        sliderinActive(a){
        },
        redirectToCustomPage () {
            this.$router.push(`/page/${this.route}`)
        },
        exitScreen () {
            this.isFullScreen = false
        },
        setFullscreen (slide) {
            if (isIOS) {
                this.autoPlay = false
            } else {
                this.autoPlay = true
            }
            const type = this.identifyVideoType(slide.url)
            this.fullScreenVideoPlayer = slide
            this.isFullScreen = true
            this.$nextTick(()=> {
                if (type === 'youtube') {
                    this.$refs.videoPlayer.setFullScreen(true)
                } else {
                    this.$refs.videoPlayer.setFullScreen(true)
                }

            })
        },

        identifyVideoType(url) {
            const lowerUrl = url.toLowerCase()

            if (lowerUrl.includes('vimeo.com')) {
                return 'vimeo'
            } else if (lowerUrl.includes('youtube.com') || lowerUrl.includes('youtu.be')) {
                return 'youtube'
            } else {
                return 'unknown'
            }
        }
    },
}
</script>
