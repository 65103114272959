<template>
  <popup
    v-if="visible"
    :visible="visible"
    size="md"
    @hide="closePopup"
  >
    <popup-title>
      <popup-button-close @hide="closePopup($event)" />
    </popup-title>
    <poup-body class="text-center">
      <popup-content>
        <popup-head>
          <popup-text-heading class="intruduction-popup__header">
            <p>{{ $t('message["habit-tracker.pop-up-instructions-heading"]') }}</p>
          </popup-text-heading>
          <popup-text-body class="intruduction-popup__sub-header">
            <p>{{ $t('message["habit-tracker.pop-up-instructions-sub-heading"]') }}</p>
          </popup-text-body>
        </popup-head>
        <div class="intruduction-popup__status-wrapper">
          <div class="col-6">
            <icon-completed-linear
              class="intruduction-popup__status-icon"
              :size="'md2'"
            />
          </div>
          <div class="col-6">
            <popup-text-body class="intruduction-popup__status-text">
              <p>{{ $t('message["habit-tracker.pop-up-instructions-state-completed"]') }}</p>
            </popup-text-body>
          </div>
        </div>
        <!-- <div class="intruduction-popup__status-wrapper">
          <div class="col-6">
            <icon-incompleted-linear
              class="intruduction-popup__status-icon"
              :size="'md2'"
            />
          </div>
          <div class="col-6">
            <popup-text-body class="intruduction-popup__status-text">
              <p>{{ $t('message["habit-tracker.pop-up-instructions-state-in-completed"]') }}</p>
            </popup-text-body>
          </div>
        </div> -->
        <div class="intruduction-popup__status-wrapper">
          <div class="col-6">
            <icon-arrow-circular-linear-v3
              class="intruduction-popup__status-icon intruduction-popup__arrow-icon"
              :size="'md2'"
            />
          </div>
          <div class="col-6">
            <popup-text-body class="intruduction-popup__status-text">
              <p>{{ $t('message["habit-tracker.pop-up-instructions-state-skip"]') }}</p>
            </popup-text-body>
          </div>
        </div>
      </popup-content>
    </poup-body>
  </popup>
</template>
<script>
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import PoupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import IconCompletedLinear from '../../../../root/icons/IconCompletedLinear'
import IconArrowCircularLinearV3 from '@/components/root/icons/IconArrowCircularLinearV3.vue'
export default {
  name: 'PopUpInstructions',
  components: {
    Popup,
    PopupTitle,
    PopupButtonClose,
    PoupBody,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupTextBody,
    IconCompletedLinear,
    IconArrowCircularLinearV3
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: { default: false,type: Boolean }
  },
  methods: {
    closePopup (e) {
      this.closeAnimatedPopup(() => {
        this.$emit('close')
        // backdrop close event fire
        if (e && e.trigger === 'backdrop') {
          this.submitCloseEvent('HabitsTracker', 'background')
        }
      })
    }
  }
}
</script>
