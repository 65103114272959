<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.879 17.8146L18.879 17.8146L15.119 14.0648C16.2774 12.6723 16.975 10.8834 16.975 8.9348C16.975 4.50431 13.3693 0.9 8.93747 0.9C4.50559 0.9 0.9 4.50431 0.9 8.9348C0.9 13.3653 4.50563 16.9696 8.93747 16.9696C10.88 16.9696 12.664 16.277 14.0549 15.1261L17.8199 18.881L17.82 18.8811C17.9665 19.0269 18.1578 19.1 18.3494 19.1C18.5422 19.1 18.7351 19.026 18.8817 18.8783L18.8817 18.8782C19.1738 18.5836 19.1726 18.1077 18.879 17.8146ZM13.5448 13.5565L13.5448 13.5565C13.5278 13.5737 13.5117 13.5915 13.4966 13.6099C12.318 14.7575 10.709 15.4652 8.93747 15.4652C5.33335 15.4652 2.40123 12.5353 2.40123 8.93476C2.40123 5.33398 5.33335 2.40431 8.93747 2.40431C12.5416 2.40431 15.4737 5.33398 15.4737 8.93476C15.4737 10.711 14.7603 12.3239 13.6043 13.5026C13.5838 13.5193 13.5639 13.5373 13.5448 13.5565Z" fill="black" stroke="black" stroke-width="0.2"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconSearch',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-search': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
