<template>
  <div :class="componentClasses" @click="clicked()">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path d="M14.1786 17.8542C13.8444 18.1882 13.3027 18.1881 12.9687 17.8542C12.6344 17.52 12.6344 16.9783 12.9688 16.6441L16.2565 13.3565L6.73599 13.3556C6.26348 13.3555 5.88053 12.9725 5.88053 12.4998C5.88064 12.0273 6.26359 11.6445 6.7361 11.6445L16.2569 11.6454L12.9684 8.35712C12.6343 8.02299 12.6343 7.48113 12.9684 7.14711C13.1356 6.9801 13.3545 6.89648 13.5735 6.89648C13.7924 6.89648 14.0113 6.9801 14.1785 7.147L18.9274 11.8958C19.0879 12.0562 19.178 12.2737 19.178 12.5007C19.1779 12.7277 19.0877 12.9452 18.9272 13.1059L14.1786 17.8542Z" fill="black"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconForwardArrow',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-forward-arrow': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
