<template>

  <page-secondary class="pc-workout-programs" v-if="loaded" :header="headerOptions"  >
    <!-- Workout Programs -->
    <page-container>
      <workout-program-card
        :widget-type="'card'"
        :workouts="workoutPrograms"
        page="workout-programs"
        style="margin-bottom: 24px"
        class="pc-workout-programs" />

      <card-list-challenge-plans
        :workouts="challengePrograms"
        :meal-plans="[]"
        :plan-pages="[]"
      ></card-list-challenge-plans>
    </page-container>

  </page-secondary>

</template>

<script>
import WorkoutProgramCard from '../dashboard/page-components/CardListWorkouts'
import pageWorkoutListMixin from '../../../../mixins/component-mixins/page/workout/pageWorkoutListMixin'
import PageContainer from '../../../global/pages/page-sub-components/PageContainer'
import PageSecondary from '../../../global/pages/PageSecondary'
import CardListChallengePlans
  from "@/components/layout/template-1/dashboard/page-components/CardListChallengePlans.vue";

export default {
  name: 'PageWorkoutList',
  mixins: [pageWorkoutListMixin],
  components: {
    CardListChallengePlans,
    PageSecondary,
    PageContainer,

    WorkoutProgramCard
  },
  created () {

  }

}
</script>
