import WorkoutLogApi from './api/workout/WorkoutLogApi'
import WorkoutLogFeedBackAPI from './custom-api/WorkoutLogFeedBack'
import lodash from 'lodash'
import ActivityTracker from './ActivityTracker'
import TrainerFeedService from './TrainerFeedService'
import ProfileService from './ProfileService'
import WorkoutProgramsService from './WorkoutProgramsService'
import LanguageService from './LanguageService'

export default class WorkoutLogService {
  workoutID = 0
  exerciseID = 0
  logData = null
  genericExerciseHistory = null
  isLogged = null

  constructor (exerciseID, workoutID) {
    this.exerciseID = parseInt(exerciseID)
    this.workoutID = parseInt(workoutID)
  }

  getLogByExercise (force = false) {
    const service = new WorkoutLogApi()
    return new Promise((resolve, reject) => {
      if (this.logData === null || force) {
        service.getLogByExercise(this.exerciseID).then(data => {
          this.logData = data
          resolve(this.logData)
        }).catch(err => {
          reject(err)
        })
      } else {
        resolve(this.logData)
      }
    })
  }

  searchLogByName (name) {
    const service = new WorkoutLogApi()
    return new Promise((resolve, reject) => {
      service.searchWorkoutLogExercise(name).then(data => {
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  isLoggedExercises () {
    return new Promise((resolve) => {
      if (this.isLogged === null) {
        this.searchLogByName('').then(data => {
          this.isLogged = Array.isArray(data.data.exercises) && data.data.exercises.length > 0
        }).finally(() => {
          resolve(this.isLogged)
        })
      } else {
        resolve(this.isLogged)
      }
    })
  }

  saveNoteByExercise (payload, exerciseID = 0) {
    const service = new WorkoutLogApi()
    return new Promise((resolve, reject) => {
      service.saveNoteByExercise(exerciseID, payload).then(data => {
        this.resetData()
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  setCompleteExercise (payload, exercise) {
    const service = new WorkoutLogApi()
    if (payload.entries) {
      payload.entries = payload.entries.map(entry => {
        entry.attributes = entry.attributes.map(attr => {
          attr.value = parseFloat(attr.value)
          return attr
        })
        return entry
      })
    }

    return new Promise((resolve, reject) => {
      service.markExerciseComplete(exercise, payload).then(data => {
        if (exercise === this.exerciseID) {
          this.logData = data
        }
        this.resetData()
        this.makeComplete(exercise)
        this.recordLoggedExercise(exercise)
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  markExerciseComplete (payload, exercise) {
    const service = new WorkoutLogApi()
    return new Promise((resolve, reject) => {
      service.markExerciseComplete(exercise, payload).then(data => {
        if (this.logData === null) {
          this.logData = {}
        }
        this.logData.note = payload.note || ''
        this.resetData()
        this.makeComplete(exercise)
        this.recordLoggedExercise(exercise)
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  setIncompleteExercise (exercise) {
    const service = new WorkoutLogApi()
    return new Promise((resolve, reject) => {
      service.setInCompleteExercise(exercise).then(data => {
        this.logData = null
        this.makeIncomplete(exercise)
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  setCompleteDay (day) {
    const service = new WorkoutLogApi()
    return new Promise((resolve, reject) => {
      service.setDayComplete(day).then(data => {
        this.logData = null
        this.makeDayCompleted(day)
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getGenericExerciseHistory (genericExerciseID, force = false) {
    return new Promise((resolve, reject) => {
      if (this.genericExerciseHistory === null || force) {
        const service = new WorkoutLogApi()
        service.getGenericExerciseHistory(genericExerciseID).then(data => {
          data.data.history = lodash.orderBy(data.data.history, 'logged_at', 'desc')
          this.genericExerciseHistory = data
          resolve(this.genericExerciseHistory)
        }).catch(err => {
          reject(err)
        })
      } else {
        resolve(this.genericExerciseHistory)
      }
    })
  }

  makeDayCompleted (dayID) {
    dayID = parseInt(dayID)
    if (this.workoutID) {
      let workoutProgramsService = new WorkoutProgramsService()
      let program = workoutProgramsService.getWorkoutProgram(this.workoutID)
      if (program) {
        let day = program.getDayByID(dayID)
        if (day) {
          day.setDayCompleted()
        }
      }
    }
  }

  makeComplete (exerciseID) {
    const curExerciseID = parseInt(exerciseID)
    if (this.workoutID) {
      let workoutProgramsService = new WorkoutProgramsService()
      let program = workoutProgramsService.getWorkoutProgram(this.workoutID)
      if (program) {
        let exercise = program.getExerciseByID(curExerciseID)
        if (!exercise) {
          return
        }
        exercise.setExerciseCompleted()
        let dayID = exercise.getDayID()
        let weekID = exercise.getWeekID()

        let day = program.getDayByID(dayID)
        if (day) {
          let isDayCompletedBefore = day.getIsCompleted()
          if(isDayCompletedBefore){
            return

          }
          day.setDayCompleteStateByExercises()
          let week = program.getWeekByID(weekID)
          if (week) {
            week.setDayCompleteStateByDays()

            if (!isDayCompletedBefore && day.getIsCompleted()) {
              const activityTracker = new ActivityTracker()
              setTimeout(() => {
                let lang = new LanguageService()
                activityTracker.addEvent('WorkoutLog.DayCompleted', {
                  workout_program_id: this.workoutID,
                  day: lang.numberWordToNumeric(day.getName()),
                  week: lang.numberWordToNumeric(week.getName())
                })
              }, 100)
              if (program.getWeekIndex(weekID) === 0 && week.getDayIndex(dayID) === 0) {
                let feedService = new TrainerFeedService()
                feedService.logFirstDayComplete()
              }
            }
          }
        }
      }
    }
  }

  makeIncomplete (exerciseID) {
    const curExerciseID = parseInt(exerciseID)
    if (this.workoutID) {
      let workoutProgramsService = new WorkoutProgramsService()
      let program = workoutProgramsService.getWorkoutProgram(this.workoutID)
      if (program) {
        let exerciseObj = program.getExerciseByID(curExerciseID)
        if (!exerciseObj) {
          return
        }
        exerciseObj.setExerciseInComplete()
        let dayID = exerciseObj.getDayID()
        let weekID = exerciseObj.getWeekID()

        let day = program.getDayByID(dayID)
        if (day) {
          day.setDayCompleteStateByExercises()
          day.setDayIncomplete()
          let week = program.getWeekByID(weekID)
          if (week) {
            week.setDayCompleteStateByDays()
            week.setWeekIncomplete()
          }
          program.setProgramDetails(true)
        }
      }
    }
  }

  /**
   * save the worklog log data to the localStorage
   */
  recordLoggedExercise () {
    let logged = localStorage.getItem('workoutLogLoggedExercises') || '[]'
    logged = JSON.parse(logged) || []
    logged = [...logged, this.exerciseID]
    logged = lodash.uniq(logged, true)
    localStorage.setItem('workoutLogLoggedExercises', JSON.stringify(logged))
  }

  resetData () {
    this.logData = null
    this.genericExerciseHistory = null
  }

  /**
   *
   * @param time format 00:00:00,00:00
   */
  timeToSeconds (time) {
    const parts = time.toString().split(':')

    if (parts.length === 2) {
      return (parseInt(parts[0]) * 60) + parseInt(parts[1])
    } else if (parts.length === 3) {
      return (parseInt(parts[0]) * 3600) + (parseInt(parts[1]) * 60) + parseInt(parts[2])
    } else {
      return parts[0] || 0
    }
  }

  /**
   *
   * @param payload - {
        trainerID: trainerID,
        resellerID: resellerID,
        customer: maAppMetaCustomer,
        flag: flag,
        feedback: feedback,
        url: window.location.href
      }
   * @returns {Promise<unknown>}
   */
  sendFeedBack (payload) {
    const service = new WorkoutLogFeedBackAPI()
    return new Promise((resolve, reject) => {
      let profileService = new ProfileService()
      profileService.setProfileData().then(userData => {
        let feedBackPayload = {
          'customer': {
            'id': payload.customer,
            'email': userData.data.customer.email
          },
          'trainer_id': payload.resellerID + '.' + payload.trainerID,
          'support_email': userData.data.settings_manager.trainer.support_email,
          'outgoing_email': userData.data.settings_manager.trainer.support_email,
          'feedback': payload.feedback,
          'feature': payload.flag
        }

        service.sendFeedback(feedBackPayload).then(data => {
          resolve(data)
        }).catch(err => {
          reject(err)
        })
      }).catch(userErr => {
        reject(userErr)
      })
    })
  }
}
