<template>
  <div :class="componentClasses">
    <div @click="reduceValue" :class="leftButtonClasses">
      <icon-decrease />
    </div>
    <div class="gc-input-number-spinner__input-wrap">
      <input
        class="form-control ma-form__input gc-input-number-spinner__input"
        inputmode="numeric"
        :max="maxValue"
        :min="minValue"
        maxlength="2"
        :step="step"
        type="tel"
        pattern="[0-9]*"
        v-bind:class="inputClasses"
        v-model="rawValue"
        @input="handleTyping"
        @blur="validateValue"
        @focus="handleFocus"
      />
      <span v-if="isPercentage" :class="percentageClasses">
        %
      </span>
    </div>
    <div @click="addValue" :class="rightButtonClasses">
      <icon-increase />
    </div>
  </div>
</template>

<script>
import IconDecrease from '../../root/icons/IconDecrease';
import IconIncrease from '../../root/icons/IconIncrease';

export default {
  name: 'InputNumberSpinner',
  components: {
    IconDecrease,
    IconIncrease,
  },
  props: {
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    value: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    isPercentage: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rawValue: this.value.toString(), // Temporary value for user input
      comValue: this.value, // Validated value
      isTyped: false,
      isActive: false,
    };
  },
  watch: {
    value(newValue) {
      // Sync prop changes with internal state
      if (newValue !== this.comValue) {
        this.comValue = newValue;
        this.rawValue = newValue.toString();
      }
    },
    comValue(newValue) {
      this.$emit('input', newValue);
      this.$emit('change', newValue);
    },
  },
  computed: {
    componentClasses() {
      return {
        'gc-input-number-spinner': true,
      };
    },
    inputClasses() {
      return {
        'gc-input-number-spinner__input--typed': this.isTyped,
        'gc-input-number-spinner__input--error': !this.isValid,
        'gc-input-number-spinner__input--disabled': this.isDisabled,
      };
    },
    leftButtonClasses() {
      return {
        'gc-input-number-spinner__left-btn btn': true,
        'gc-input-number-spinner__btn--disabled': this.isDisabled || this.comValue <= this.minValue,
      };
    },
    rightButtonClasses() {
      return {
        'gc-input-number-spinner__right-btn btn': true,
        'gc-input-number-spinner__btn--disabled': this.isDisabled || this.comValue >= this.maxValue,
      };
    },
    percentageClasses() {
      return {
        'gc-input-number-spinner__input-percentage': true,
        'gc-input-number-spinner__input-percentage--active': this.isActive || this.isTyped,
      };
    },
  },
  methods: {
    addValue() {
      if (this.isDisabled) return;
      this.isTyped = true;
      let newValue = this.comValue + this.step;
      if (newValue > this.maxValue) newValue = this.maxValue;
      this.updateValue(newValue);
    },
    reduceValue() {
      if (this.isDisabled) return;
      this.isTyped = true;
      let newValue = this.comValue - this.step;
      if (newValue < this.minValue) newValue = this.minValue;
      this.updateValue(newValue);
    },
    handleTyping(event) {
      this.rawValue = event.target.value.replace(/[^0-9]/g, '');
      setTimeout(() => {
        this.updateValue(this.rawValue)
      }, 2000)
      // this.updateValue(this.rawValue) // Allow only numeric input
      // this.$emit('input', this.rawValue);
    },
    validateValue() {
      let numericValue = parseInt(this.rawValue, 10);
      if (isNaN(numericValue)) {
        numericValue = this.minValue;
      } else if (numericValue < this.minValue) {
        numericValue = this.minValue;
      } else if (numericValue > this.maxValue) {
        numericValue = this.maxValue;
      }
      this.updateValue(numericValue);
    },
    handleFocus() {
      this.isActive = true;
    },
    updateValue(newValue) {
      this.comValue = newValue;
      this.rawValue = newValue.toString(); // Sync rawValue with validated value
    },
  },
};
</script>
