export default class HabitSuggestion {
  /** @type {String} */
  productId = null
  /** @type {String} */
  habit = null

  constructor (suggestion) {
    this.productId = suggestion.productId
    this.habit = suggestion.habit
  }

  getHabit () {
    return this.habit
  }

  getProductId () {
    return this.productId
  }
}
