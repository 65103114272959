import { browserVersion, isAndroid, isChrome, isIOS, isMobile, isSafari, isTablet } from 'mobile-device-detect'
import DefineInclude from '../DefineInclude'
import { env as customEnv } from '../../config/custom-config'
import AuthService from '../services/AuthService'
import DashboardService from '../services/DashboardService'
import { checkIsPWA, checkNativeAppVersion, isMobileNativeApp } from './NativeAppCommon'
import changeToNewTheme from './SwitchThemes'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import Vue from 'vue'
import { isDesktopModeEnabled } from './TemplateSettings'

export function checkIsTemplateActivated () {
  return templateConfig.active || false
}

export function isCurrentURLValid () {
  if (typeof isUrl === 'function') {
    const skipUrl = DefineInclude.appUrlListToSkip
    for (const property in skipUrl) {
      if (isUrl(skipUrl[property])) {
        return false
      }
    }
    const urlList = DefineInclude.appUrlList
    urlList.workoutBase = '/workout-programs'
    // urlList.homeBase = '/'
    for (const property in urlList) {
      if (isUrl(urlList[property])) {
        return true
      }
    }
  } else {
    throw new Error('isUrl() not imported')
  }
  return false
}

/**
 * check user set theme to "new" or "old"
 * @returns {boolean}
 */
export function isNewTheme () {
  return localStorage.getItem('theme') === null || localStorage.getItem('theme') === 'new'
}

export function checkUsers () {
  return true
}

export function checkPrivateMode (email) {
  const privateUsers = templateConfig.private_users

  if (privateUsers && Array.isArray(privateUsers) && privateUsers.length > 0) {
    return privateUsers.includes(email)
  }

  // private mode inactive
  return true
}

/**
 * is private mode have users
 * @returns {boolean}
 */
export function isPrivateModeHasEmails () {
  const privateUsers = templateConfig.private_users || []

  return !(privateUsers && Array.isArray(privateUsers) && privateUsers.length === 0)
}

function checkDeviceMode (theme) {
  return isDesktopModeEnabled() || isMobile
}

/**
 *  check the new theme is enabled form the trainer console
 * @returns {boolean}
 */
export function checkTrainerEnabledTheme () {
  // app script is added only trainer enabled . So always true
  return true
}

export function checkBrowserSupport () {
  const version = parseInt(browserVersion)
  return !(isSafari && version < 8)
}

/** isUrl() in a util script **/
export async function checkURLsToMountByRouter (router, theme = 1) {
  /** start private mode related logic */
  let checkPrivateModeStatus = false
  if (customEnv !== 'toning-preview') {
    const currentRoute = window.location.pathname
    const routeObj = router.options.routes
    const routerKeys = Object.keys(routeObj)

    // check private mode check required
    let validRouteForPrivateCheck = false
    for (const routerKey of routerKeys) {
      /**
       * only when the current url need an auth. this will check otherwise ignored.because no need to identify the user
       */
      if (routeObj[routerKey].meta && routeObj[routerKey].meta.requiresAuth) {
        if (typeof isUrl === 'function' && isUrl(routeObj[routerKey].path)
        ) {
          validRouteForPrivateCheck = true
        } else if (routeObj[routerKey].path === currentRoute) {
          validRouteForPrivateCheck = true
        }
      }
    }

    if (!isPrivateModeHasEmails()) {
      checkPrivateModeStatus = true
    } else if (validRouteForPrivateCheck) { // if valid Auth Route
      let authService = new AuthService()
      if (!authService.isValidToken()) {
        await authService.getTokenFromAPI()
      }

      const dashboardService = new DashboardService()
      await dashboardService.getUserData().then(response => {
        checkPrivateModeStatus = checkPrivateMode(response.email)
      })
    } else {
      console.log('validRouteForPrivateCheck')
      checkPrivateModeStatus = false
    }
  } else {
    checkPrivateModeStatus = true
  }
  /** end private mode related logic */
  /**
   * is a native mobile app with supported version or is web APP
   * @type {boolean}
   */
  const checkMobileAppOrWebAppToMount = (isMobileNativeApp()) || !isMobileNativeApp()

  let isSuitableURL = false
  if (process.env.NODE_ENV !== 'production' && customEnv !== 'production') {
    console.log('check browser', checkBrowserSupport(), 'check trainer enabled', checkTrainerEnabledTheme(), 'check tab', isTablet, 'check mob', isMobile, 'is Current URL Valid', isCurrentURLValid(), 'is New theme by user', isNewTheme(), 'enable toning', checkIsTemplateActivated(), 'user check', checkUsers())
    console.log('private mode', checkPrivateModeStatus, (templateConfig.private_users || []))
    console.log('node_env', process.env.NODE_ENV, 'custom_env', customEnv)
  }

  if (process.env.NODE_ENV === 'production' && customEnv === 'production') {
    isSuitableURL = (checkTrainerEnabledTheme() && checkMobileAppOrWebAppToMount && isCurrentURLValid() && isNewTheme() && checkIsTemplateActivated() && checkUsers() && checkPrivateModeStatus)
    if (!isMobileNativeApp() && !checkDeviceMode(theme)) {
      isSuitableURL = false
    }
  } else if (process.env.NODE_ENV === 'production' && customEnv === 'toning-preview') {
    isSuitableURL = true
  } else if (process.env.NODE_ENV === 'development' || customEnv !== 'production') {
    isSuitableURL = true
  }

  if (!isSuitableURL) {
    if (checkDeviceMode(theme) && checkIsTemplateActivated() && !isNewTheme() && !isPrivateModeHasEmails()) {
      changeToNewTheme()
    }
  }

  return isSuitableURL
}

export function setSentry () {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn:
        'https://fd02f7e9d61e46c88ba1e9fbb806b0a4@o140491.ingest.sentry.io/5225218',
      integrations: [new VueIntegration({
        Vue,
        attachProps: true
      })],
      denyUrls: [window.location.origin],
      release: DefineInclude.app.version,
      environment: 'production',
      allowUrls: [
        DefineInclude.resourceBaseURL,
        'https://app-templates-2.macroactivemvp.com/'
      ],
      ignoreErrors: [
        'Non-Error promise rejection captured with value: Object Not Found Matching',
        'UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:5, MethodName:update, ParamCount:4', // not a NLAF issue
        'We’re having a little trouble.', // not a NLAF issue
        'SyntaxError: The string did not match the expected pattern.', // related to PTM fetch
        'Network Error', // network error is handled using a popup
        'The play() request was interrupted by a call to pause()', // can't handle on vimeo SDK
        'FetchNotificationError', // (profitwell) issue
        'profitwell', // (profitwell) issue
        'This video does not exist.', // vimeo issue
        'Load failed', // jqurey 3r party issue
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:3, MethodName:update, ParamCount:4',// 3rd party
        'NavigationDuplicated' // minor issue
      ],
      beforeSend (event) {
        return event
      },
      ignore: ['node_modules'],
      onerror: false,
      onunhandledrejection: false
    })
  } else {
    Vue.config.devtools = true
  }
}

export function checkTemplateConfig () {
  if (typeof templateConfig !== 'undefined') {
    return true
  } else {
    Sentry.captureMessage('toning templateConfig is not defined')
    return false
  }
}

export function showLegacyApp (msg='') {
  document.body.style.display = 'block'
  const event = new CustomEvent('app-ready', { detail: {} })
  document.dispatchEvent(event)
  if(!!MAnalytics  ){
    MAnalytics.sendEvents('Dev.showLegacyApp', {msg})
  }
}

/**
 * set body class by the APP type - native-app|pwa-app|web-app
 */
export function setBodyClasses () {
  if (checkNativeAppVersion()) {
    document.body.classList.add('inside-native-app')
  } else if (checkIsPWA()) {
    document.body.classList.add('inside-pwa-app')
  }
  if (isTablet) {
    document.body.classList.add('inside-tablet')
  }
  if (isIOS) {
    document.body.classList.add('inside-ios')
  } else if (isAndroid) {
    document.body.classList.add('inside-android')
  }
  if (isSafari) {
    document.body.classList.add('inside-safari')
  } else if (isChrome) {
    document.body.classList.add('inside-chrome')
  }
}

/**
 * add script to the Page
 * @param data
 */
export function createScriptInHeader (data) {
  const newScript = document.createElement('script')
  const inlineScript = document.createTextNode(data)
  newScript.appendChild(inlineScript)
  document.querySelector('body').appendChild(newScript)
}

export function listenServiceWorker () {
  if (!navigator && !navigator.serviceWorker) {
    return
  }
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data.action === 'login') {
      location.href = '/dashboard'
    }
  })
}
