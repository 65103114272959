<template>
  <div class="pc-video-area" :class="[random]">
    <div :style="wrapStyle" class="pc-video-area__full-width-wrap" style=" clear: both">
        <video-player v-if="(videoType==='vimeo'||videoType==='youtube')&&((showVideoPlayer &&loadVideoPlayer)||playing)"
                      :url="video.url"
                      :lazy="false"
                      :auto-adjust-video-size="false"
                      :mute="false"
                      :start-playing-full-screen-if-portrait="false"
                      @playing="setPlaying"
                      :poster="video.thumbnail_url"  />
        <div class="pc-video-area__image-wrap"  v-else v-show="!showVideo">
          <div class="pc-video-area__image-wrap--icon-wrapper">
            <icon-play v-show="loadVideoPlayer" ref="playIcon" class="image-wrap__icon" :size="'lg3'" v-on:click="showVideoArea()"/>
          </div>
          <img :src="video.thumbnail_url"
               style="cursor:pointer" v-if="showImage"
               :style="thumbnailStyle"
               class=""
               v-on:error="hideImage()"/>
          <div :style="thumbnailStyle" class="pc-video-area__card-custom-video-tumb--no-image" ref="noImageArea" v-if="!showImage" >

          </div>
        </div>
    </div>
    <div class="pc-video-area__content-wrapper">
      <text-content class="pc-video-area__head-title" :size="'sm3'" :weight="'extra-bold'" :lineHeight="'single'">{{video.title}}</text-content>
      <div class="pc-video-area__content-link" v-if="videoType==='other'" :href="video.url">{{ video.title}}</div>
      <div :href="void(0)" class="pc-video-area__content-link" v-bind:key="tag" v-for="tag in filterDelayTags">#{{tag}}</div>
      <page-content-text :description="video.description" />
    </div>
  </div>
</template>
<script>
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'
import PageContentText from './PageContentText'
import TextContent from '../../../../root/TextContent'
import IconPlay from '../../../../root/icons/IconPlay'
import VideoPlayer from '../../../../global/players/VideoPlayer'
export default {
  name: 'PageVideoArea',
  mixins: [NativeAppMixin],
  components: {
    VideoPlayer,
    PageContentText,
    TextContent,
    IconPlay
  },
  props: {
    video: {},
    loadVideoPlayer: {
      default: false
    }
  },
  data: function () {
    return {
      playing: false,
      random: null,
      height: 235,
      autoplay: true,
      isFrameReady: false,
      showImage: true,
      showVideo: false,
      showVideoPlayer: true,
      videoWidth: screen.width,
      videoType: '',
      videoID: '',
      vimeoPlayer: null,
      youtubePlayerVars: {
        autoplay: 1,
        muted: 1
      },
      wrapStyle: {
      },
      videoStyle: {
        height: '240px'
      },
      thumbnailStyle: {
        height: '240px'
      }
    }
  },
  beforeMount () {
    this.random = this.generateRandomID()
  },
  mounted () {
    const videoUrl = this.video.url
    if (
      videoUrl.match(
        /(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/g
      )
    ) {
      const videoname = videoUrl.split('?')[0]
      const urlParams = videoname.split('/')
      this.videoID = urlParams[urlParams.length - 1]
      this.videoType = 'vimeo'
    } else if (videoUrl.includes('youtube') || this.isYouTubeUrl(videoUrl)) {
      this.videoID = this.getYouTubeID(videoUrl)
      this.videoType = 'youtube'
    } else {
      this.videoType = 'other'
    }
    this.$nextTick(() => {
      this.resizeVideo()
    })
  },
  watch: {
    loadVideoPlayer: function (o,n) {
      console.log('showing', this.video.url,this.video.id,o,n)
    }
  },
  computed: {
    filterDelayTags: function (tags) {
      return this.video.tags.filter((tag) => {
        return !tag.match(/delay\d+\w/)
      })
    }
  },
  methods: {
    handleScroll () {
      const element = document.querySelector('.' + this.random)
      this.isElementOnScreen(element)
    },
    isElementOnScreen (element) {
      var rect = element.getBoundingClientRect()

      // Check if the element is completely on the screen
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    },
    generateRandomID (length = 8) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      let randomID = ''

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        randomID += characters.charAt(randomIndex)
      }

      return randomID
    },
    setPlaying () {

      this.playing = true
    },
    hideImage () {
      this.showImage = false
      setTimeout(() => {
        if (!this.$refs.noImageArea) {
          return
        }
        const background = window.getComputedStyle(this.$refs.noImageArea)
        const elements = $(this.$refs.playIcon)
        if (this.lightOrDark(background.getPropertyValue('background-color'))) {
          elements.css('filter', 'invert(1)')
        } else {
          elements.css('filter', 'invert(0)')
        }
      }, 500)
    },
    showVideoFrame (e) {
      this.autoplay = false
      this.showVideo = true
    },
    showVideoArea () {
      this.handleUrlNavigate(this.video.url)
    },
    resizeVideo () {

    },
    isYouTubeUrl (url) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      return (url[2] !== undefined)
    },
    getYouTubeID (url) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      // TODO -fix
      // eslint-disable-next-line no-useless-escape
      return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
    },
    linkNavigateClickHandler (event) {
      let targetElement = event.target
      const selector = '.content-text a'
      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          event.preventDefault()
          this.handleUrlNavigate(targetElement.getAttribute('href'))
          return
        }
        targetElement = targetElement.parentElement
      }
    }
  },
  beforeDestroy () {
    // window.addEventListener('load', this.handleScroll)
  }

}
</script>
<style>

</style>
