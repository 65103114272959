import FitnessDiaryAPI from './api/progress-check-in/FitnessDiaryAPI'
import TrainerFeedService from './TrainerFeedService'
import UserEventsService from './UserEventsService'
import { flagValue } from '../includes/TemplateSettings'
import unitSystem from '../config/measurement-units'
import store from "@/store";

export default class FitnessMetric {
  diaryService = null
  type =''
  name =''
  measurementSystem = ''
  unit = ''
  firstTime={
    value: 0,
    date: null
  }

  inputValue =0
  latestDate = null
  currentValue = 0

  constructor (type, service) {
    this.type = type
    this.diaryService = service
  }

  setName (name) {
    this.name = name
  }

  setMeasurementSystem (measurementSystem) {
    this.measurementSystem = measurementSystem
  }

  setUnit (unit) {
    if (unit) {
      this.unit = unit
    } else if (unitSystem[this.measurementSystem]) {
      if (this.type === 'weight') {
        this.unit = unitSystem[this.measurementSystem]['weight']
      } else if (this.type === 'body_fat') {
        this.unit = '%'
      } else {
        this.unit = unitSystem[this.measurementSystem]['distance.miner']
      }
    }
  }

  getMeasurementSystem () {
    return this.measurementSystem
  }

  setFirstTimeValue (value, date = null) {
    this.firstTime.value = value
    this.firstTime.date = this._parseDate(date)
  }

  setCurrentValue (value) {
    this.inputValue = value || this.firstTime.value
    this.currentValue = value
    if (!this.firstTime.value) {
      this.firstTime.value = this.currentValue
      const today = new Date()
      this.firstTime.date = this._parseDate((today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()))
    }
  }

  setLatestDate (date) {
    this.latestDate = date
  }

  getInputValue () {
    return parseFloat(this.inputValue)
  }

  getType () {
    return this.type
  }

  getUpdatedValue () {
    if (!parseFloat(this.inputValue)) {
      return ''
    } else {
      return this.roundValueByMeasurementSystem(this.inputValue)
    }
  }

  roundValueByMeasurementSystem (value) {
    if (value) {
      return Number.parseFloat(value).toFixed(1) || 0
    } else {
      return 0
    }
  }

  getFirstTimeData () {
    const firstTime = {
      value: this.firstTime.value,
      date: this.firstTime.date
    }
    if (this.type === 'body_fat' && firstTime.value) {
      firstTime.value = Number.parseFloat(firstTime.value).toFixed(2)
      firstTime.value = (parseFloat(firstTime.value) * 100)
    } else {
      firstTime.value = this.roundValueByMeasurementSystem(firstTime.value)
    }
    return firstTime
  }

  resetInputData () {
    this.inputValue = this.currentValue
  }

  setInputValue (newInputValue) {
    this.inputValue = newInputValue
  }

  getLatestData () {
    return {
      value: this.getCurrentValue(),
      date: this._parseDate(this.latestDate)
    }
  }

  getName () {
    return this.name
  }

  getLatestDate () {
    return this._parseDate(this.latestDate)
  }

  getCurrentValue () {
    if (this.type === 'body_fat' && this.currentValue) {
      const num = Number.parseFloat(this.currentValue).toFixed(2)
      return Number.parseFloat(parseFloat(num) * 100).toFixed(0)
    }
    return this.roundValueByMeasurementSystem(this.currentValue)
  }

  getMeasurementUnit () {
    return this.unit
  }

  _parseDate (input, format = null) {
    if (!input) {
      return ''
    }
    format = format || 'yyyy-mm-dd' // default format
    // eslint-disable-next-line one-var
    let parts = input.match(/(\d+)/g)
    let i = 0
    let fmt = {}
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++ })

    const date = new Date(parts[fmt.yyyy], parts[fmt.mm] - 1, parts[fmt.dd])
    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    const year = date.getFullYear()

    if (month.length < 2) { month = '0' + month }
    if (day.length < 2) { day = '0' + day }

    return [year, month, day].join('-')
  }

  getTodayValue () {
    const latestDate = new Date(this.latestDate)
    const todayDate = new Date()
    // call setHours to take the time out of the comparison
    if (latestDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
      return this.currentValue
    } else {
      return null
    }
  }

  getCurrentValueInMetric () {
    if (this.measurementSystem === 'metric' || this.type === 'body_fat') {
      return Number.parseFloat(this.currentValue)
    } else if (this.type === 'weight') {
      return Number.parseFloat(this.currentValue) / 0.0022046
    } else {
      return Number.parseFloat(this.currentValue) / 0.39370
    }
  }
  saveData (input = null) {
    if (!input) {
      input = this.inputValue
    }
    if (this.type === 'body_fat') {
      input = parseFloat(input) / 100
    }

    const params = {
      field: this.type,
      value: input
    }

    const api = new FitnessDiaryAPI()
    return new Promise((resolve, reject) => {
      api.setEntry(params).then(data => {
        this.latestDate = data.data.day

        if (this.latestDate === this.firstTime.date) {
          this.firstTime.value = input
        }
        if (!this.firstTime.date) {
          this.firstTime.date = this.latestDate
          this.firstTime.value = input
        }

        this.currentValue = input
        let feedService = new TrainerFeedService()
        feedService.logFitnessDiaryMetricUpdate(this)
        if (flagValue('fd_feedback_popup_show')) {
          UserEventsService.sendFitnessDiaryFeedBackPopupView()
        }
        resolve(data)
      }).catch(err => {
        if (err.response.status === 500) {
          store.commit('showServerErrorPopup')
          store.commit('setServerErrorPage', 'my-journey')
        }
        reject(err)
      })
    })
  }
}
