import HabitsApi from '../api/habits/HabitsApi'
import APIErrorHandler from '../api-handler/APIErrorHandler'
import moment from 'moment'
import Habit from './Habit'

export default class HabitEntry {
  /** @type {Habit} */
  habit= null
  entry = null
  date = null

  constructor (habit, date, entry = null) {
    this.habit = habit
    if (entry) {
      this.entry = entry
    } else {
      this.entry = this._makeNullEntryObject()
    }
    this.date = date
  }

  _makeNullEntryObject () {
    return {
      'id': null,
      'label': null,
      'state': null,
      'updated_at': null,
      'created_at': null
    }
  }

  isLogged () {
    return !!this.entry.id
  }

  getLabel () {
    return this.entry.label
  }
  getHabitID () {
    return this.habit.id
  }
  getHabitLastUseDate() {
    if(this.habit.lastSetOn === '') return this.habit.updatedAt
    return this.habit.lastSetOn
  }
  getDate () {
    return this.date
  }
  getEntryID () {
    return this.entry.id
  }

  isEntryEmpty () {
    return !!this.habit.id
  }

  setEntry (entry) {
    this.entry = entry
  }

  saveEntry (status, immediateUpdate = true) {
    let habitId = this.getHabitID();
    const setDate = this.date ? moment(this.date).hour(moment().get('hour')).minute(moment().get('minute')) : moment().local('en');

    return new Promise((resolve, reject) => {
      let api = new HabitsApi();
      api.setHabitEntry(habitId, status, setDate).then(data => data.data).then(data => {
        if(immediateUpdate) {
          this.setEntry(data)
        }
        resolve(data)
      }).catch(error => {
        console.error(error)
        if (error.response.status >= 500 && error.response.status < 600) {
          this._handleServerError(error)
        } else {
          APIErrorHandler.logSentry(error)
        }
        reject(error)
      })
    })
  }

  deleteEntry () {
    const habitID = this.getHabitID()
    const habitEntryID = this.getEntryID()
    return new Promise((resolve, reject) => {
      const api = new HabitsApi()

      api.deleteHabitEntry(habitID, habitEntryID).then(() => {
        this.entry = this._makeNullEntryObject()
        resolve()
      }).catch(error => {
        if (error.response.status >= 500 && error.response.status < 600) {
          this._handleServerError(error)
        } else {
          APIErrorHandler.logSentry(error)
        }
        reject(error)
      })
    })
  }

  /**
   * Log and show error popup
   * @param response
   * @private
   */
  _handleServerError (response) {
    let sentryEventID = APIErrorHandler.logSentry(response)
    APIErrorHandler.showErrorPopup(sentryEventID)
  }
}
