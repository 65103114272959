import StepTrackerApi from './api/step-tracker/StepTrackerApi';
import APIErrorHandler from "@/services/api-handler/APIErrorHandler";

export default class StepTrackerApiService {

  constructor () {
    this.user = null;
  }

  getAvailableDataSources (redirectUrl) {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.getDataSources(redirectUrl)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  getConnectedDataSources () {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.getConnectedDataSources()
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  getStats () {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.getStats()
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  setNewConnectedDataSource (sourceName) {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.setNewConnectedDataSource(sourceName)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  setNewRevokedDataSource (sourceName) {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.setNewRevokedDataSource(sourceName)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  disconnectDataSource (sourceName) {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.disconnectDataSource(sourceName)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  getGraphData (from, to, type) {
    return new Promise(async (resolve, reject) => {
      let stepTrackerApi = new StepTrackerApi();
      await stepTrackerApi.getGraphData(from, to, type)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
            this.handleServerError(error)
          reject(error);
        });
    });
  }

  handleServerError (error) {
    const apiUrl = error.config.url
    let isValidState = error.status >= 500 && error.status < 600
    if (error && apiUrl.includes('wearable/2024-07-03/plot') && isValidState) {
      APIErrorHandler.showErrorPopup('', 'step-tracker')
    } else if (error && apiUrl.includes('wearable/2024-07-03/datasources') && isValidState) {
      APIErrorHandler.showErrorPopup('', 'wearable')
    }
  }
}
