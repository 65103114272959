import MealPlanMealRecipeService from './MealPlanMealRecipeService'
import MealPlanAPI from '../api/meal-plan/MealPlanAPI'
import ProfileService from "@/services/ProfileService";
import ContactService from "@/services/ContactService";

export default class MealPlanMealService {
  /**
   * @property {string|null} _id - The ID of the meal plan.
   * @property {string} _name - The name of the meal plan.
   * @property {Array} _recipes - The recipes in the meal plan.
   * @property {string} _referenceMealId - The reference ID of the meal.
   * @property {boolean} _disliked - Whether the meal is disliked.
   * @property {boolean} _favourite - Whether the meal is a favourite.
   * @property {Object|null} _apiResponse - The API response data.
   */
  _id = null
  _name = ''
  _recipes = []
  _referenceMealId = ''
  _disliked = false
  _favourite = false
  _apiResponse = null

  /**
   * Constructor for the MealPlanMealService class.
   * It initializes the properties of the class with the data provided.
   * It also maps the recipes to MealPlanMealRecipeService instances.
   *
   * @param {Object} data - The data to initialize the class with.
   * @param {string} planId - The id of the plan.
   */
  constructor (data, planId) {
    this._apiResponse = data
    this._planId = planId
    this._id = data.id
    this._referenceMealId = data.reference_meal_id
    this._name = data.name || ''

    this._macroNutrients = data.macronutrients || {}
    this._calories = data.calories || 0

    this.swapStatus = data.swap_status || 'ok'
    this.canSwap = data.can_swap || false
    this.dislikeEnalbed = data.isPublished

    this.type = data.type || false
    this.isPaired = data.is_paired || false

    data.recipes.forEach(recipe => {
      this._recipes.push(new MealPlanMealRecipeService(recipe, this._planId))
    })
  }

  /**
   * Returns the ID of the meal plan.
   */
  getId () {
    return this._id
  }
  getName () {
    return this._name
  }

  /**
   * Sets the disliked status of the meal plan.
   *
   * @param {boolean} dislike - The disliked status to set.
   */
  setDisliked (dislike) {
    this._disliked = dislike
  }

  /**
   * Sets the favourite status of the meal plan.
   *
   * @param {boolean} favourite - The favourite status to set.
   */
  setFavourite (favourite) {
    this._favourite = favourite
  }

  /**
   * Returns whether the meal plan is disliked.
   */
  isDisliked () {
    return this._disliked
  }

  /**
   * Returns whether the meal plan is a favourite.
   */
  isFavourite () {
    return this._favourite
  }

  canDislike () {
    return this.dislikeEnalbed
  }

  getType () {
    return this.type
  }

  getCalories () {
    return this._calories
  }
  getMacroNutrients () {
    return this._macroNutrients
  }
  getReferenceMealId () {
    return this._referenceMealId
  }

  isPairedMeal () {
    return this.isPaired || false
  }
  swapEnabled () {
    return this.canSwap && this.swapStatus === 'ok'
  }

  getSwapStatus () {
    return this.swapStatus || 'ok'
  }

  getRecipes () {
    return this._recipes
  }

  getRecipesCount () {
    return this._recipes.length
  }

  /**
   * Swaps the meal plan.
   *
   * @param {boolean} isFavouriteSelected - Whether a favourite is selected.
   */
  swap (isFavouriteSelected = false) {
    return new Promise((resolve, reject) => {
      const payload = {
        suggest_favorite_meals: isFavouriteSelected
      }
      const api = new MealPlanAPI()
      api.substitute(this._planId, this._id, payload).then(response => {
        this.swapStatus = 'swap-requested'
        resolve(response)
      }).catch(err => {
        reject(err.response)
      })
    })
  }

  /**
   * Returns the swap meal options.
   *
   * @param {boolean} favorites - Whether to return favourites.
   */
  async getSwapMealOptions(favorites = false){
    const api = new MealPlanAPI()
    const payload = {
      fromFavorites: (favorites)?1:0
    }
    return api.getSwapMealOptions(this._planId, this._id, payload).then(data=>data.data)
  }

  async sendMealSwapRequestEmail (mealId, mealName, mealPlanId, emailText) {
    const profileService = new ProfileService()
    await profileService.setProfileData()
    let payload = {
      fullName: profileService.getFullName(),
      email: profileService.getEmail(),
      subject: 'Meal Swap request - Request to swap the '+mealName+' meal. '+ mealId +' - '+mealPlanId,
      message: emailText
    }
    const contactService = new ContactService()
    return contactService.submitContact(payload)
  }
  async swapWithMeal (mealId) {
    const api = new MealPlanAPI()

    return api.swapWithSelectedOption(this._planId, this._id, mealId).then(data=>data.data)
  }


  getTotalPreparationTime () {
    return this._recipes.reduce((accumulator, recipes) => {
      return accumulator + recipes.getPreparationTime()
    }, 0)
  }
  getAPIResponse () {
    return this._apiResponse
  }
}
