import { mapGetters, mapMutations } from 'vuex'
import SignUpService from '../../../../services/sign-up/SignUpService'
import headerEventBus from '../../../../event-buses/headerEventBus'
import { dispatchDOMContentLoaded } from '@/helpers/dom/events/documentEvents'
import { setMAApplicationData } from '@/helpers/dom/header'
import AppService from '@/services/AppService'
import { injectHTMLToBody } from '@/helpers/dom/body'
import store from '@/store'

export default {
  data: function () {
    return {
      logoImage: '',
      titleImage: '',
      service: null,
      supportPopup: {
        show: false
      },
      stepsData: [
        {
          id: 1,
          number: 1,
          text: this.$i18n.t('message["sign-up.header.register"]'),
          status: 'default',
          active: this.$route.fullPath.includes('sign-up')|| this.$route.fullPath.includes('activate')
        },
        {
          id: 2,
          number: 2,
          text: this.$i18n.t('message["sign-up.header.pay"]'),
          status: 'default',
          active: this.$route.fullPath.includes('pay')
        },
        {
          id: 3,
          number: 3,
          text: this.$i18n.t('message["sign-up.header.complete"]'),
          status: 'default',
          active: this.$route.fullPath.includes('thank-you')
        }
      ]

    }
  },
  computed: {
    ...mapGetters({
      getService: 'signUpStore/getService',
      getPreviousRoute: 'getPreviousRoute'
    }),
    optionalText: function () {
      if(this.$t('message[\'general.form.optional-with-brackets\']') !== '(Optional)'){
        return this.$t('message[\'general.form.optional-with-brackets\']')
      }

      return '('+this.$t('message[\'general.form.optional\']')+')'
    },
  },
  methods: {
    ...mapMutations({
      setRouteTransition: 'setRouteTransition',
      setLastVisitPage: 'setLastVisitPage',
      setService: 'signUpStore/setService'
    }),
    onSupportPopupListener () {
      headerEventBus.$on('support-icon-clicked', this.showSupportPopup)
    },
    offSupportPopupListener () {
      headerEventBus.$off('support-icon-clicked', this.showSupportPopup)
    },
    showSupportPopup () {
      this.supportPopup.show = true
    },
    initService (planID = null) {
      if (!planID) {
        planID = this.$route.params.plan
      }
      if (this.getService && this.getService.getPlanUUID() === planID) {
        // found service for relevant plan UUID
        this.service = this.getService
      } else {
        // create new sign up object
        this.service = new SignUpService(planID)
        this.setService(this.service)
      }
    },
    setImages () {
      this.logoImage = this.getStoreImage('login.logo')
      this.titleImage = this.getStoreImage('login.background')
    },
    goToLogin () {
      this.setRouteTransition({ name: 'fade', mode: 'out-in' })
      this.setLastVisitPage({ page: 'sign-up-pay', path: this.$appConfig.appUrlList.signUpPay.replace(':plan', this.$route.params.plan) })
      this.showLoading()
      setTimeout(()=>{
        this.$router.push({ path: this.$appConfig.appUrlList.signIn })
      },500)

    },
    dispatchDOMContentLoaded(){
      if(this.getPreviousRoute && this.getPreviousRoute.name){
        dispatchDOMContentLoaded()
        // trigger GTM on page load
        window.dataLayer = window.dataLayer || [];

        dataLayer.push({
          'event': 'gtm.dom'
        });
      }

    },
    setMAApplicationData(name,value){
      setMAApplicationData(name,value)
    }
  },
  beforeRouteEnter (to, from, next) {
    // create stripe script and load before load page
    const scripts = document.scripts
    let hasStripeJs = false

    // check script already injected
    for (let docScript of scripts) {
      if (docScript.getAttribute('src') && docScript.getAttribute('src').includes('https://js.stripe.com/v3/')) {
        hasStripeJs = true
        break
      }
    }
    if (hasStripeJs) {
      next()
      return
    }
    const script = document.createElement('script')
    script.setAttribute(
      'src',
      'https://js.stripe.com/v3/'
    )
    script.setAttribute('async', '')
    script.onload = function handleScriptLoaded () {
      next()
    }

    script.onerror = function handleScriptError () {
      next()
    }
    document.head.appendChild(script)

    if(store.getters.getPreviousRoute && store.getters.getPreviousRoute.name ===null){
      const app= new AppService()
      app.getScripts().then(() => {
        if(app.getFooterScript()){
         // injectHTMLToBody(app.getFooterScript())
        }

      })
    }

  },
  getColorVariable (variable) {
    return templateConfig.style_variables[variable]
  }
}
