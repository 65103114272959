<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-popup gc-popup-server-error gc-popup-error"
    v-if="visible"
  >
    <popup-title>
      <popup-button-close @click="closePopup"></popup-button-close>
    </popup-title>
    <popup-body class="text-center">
      <popup-content>
        <popup-head>
          <icon-warning class="gc-popup-error__header-icon" :size="'lg4'"></icon-warning>
          <popup-text-heading  class="gc-popup-error__title">{{$i18n.t("message['general.server-error-popup-title']")}}</popup-text-heading>
        </popup-head>
        <popup-text-body v-if="!eventPage" class="gc-popup-error__description">
          <text-content :size="'sm2'" :lineHeight="'multi'">{{$i18n.t("message['general.server-error-description']")}}</text-content>
        </popup-text-body>
        <popup-text-body v-if="eventPage" class="gc-popup-error__description">
          <text-content :size="'sm2'" :lineHeight="'multi'">{{eventPage}}</text-content>
        </popup-text-body>
        <popup-text-body v-if="eventID && !eventPage" class="gc-popup-error__description gc-popup-error__info" >
          <text-content :size="'sm2'" :lineHeight="'multi'">{{$i18n.t("message['general.server-error-id']",{errorID:eventID})}}</text-content>
        </popup-text-body>
        <div>
        </div>
      </popup-content>
      <popup-footer>
        <button-primary class="gc-popup-error__btn-wrapper" :text="$i18n.t('message[\'general.try-again-text\']' )"  @click="closePopup()" />
        <button-secondary :text="$i18n.t('message[\'settings.contact-support\']')" @click="navigateToContact()"></button-secondary>
      </popup-footer>
    </popup-body>

  </popup>
</template>

<script>

import popupAnimationMixin from '../../../mixins/popupAnimationMixin'
import ButtonPrimary from '../../global/buttons/ButtonPrimary'
import PopupFooter from '../../global/popups/popup-sub-components/PopupFooter'
import PopupTextBody from '../../global/popups/popup-sub-components/PopupTextBody'
import PopupTextHeading from '../../global/popups/popup-sub-components/PopupTextHeading'
import PopupHead from '../../global/popups/popup-sub-components/PopupHead'
import PopupContent from '../../global/popups/popup-sub-components/PopupContent'
import PopupBody from '../../global/popups/popup-sub-components/PopupBody'
import PopupButtonClose from '../../global/popups/popup-sub-components/PopupButtonClose'
import PopupTitle from '../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../global/popups/Popup'
import TextContent from '../../root/TextContent'
import ButtonSecondary from '../../global/buttons/ButtonSecondary'
import IconServerError from '../../root/icons/IconServerError'
import IconWarning from '../../root/icons/IconWarning'

export default {
  name: 'ServerErrorPopup',
  mixins: [popupAnimationMixin],
  components: {
    ButtonPrimary,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    PopupContent,
    PopupBody,
    PopupButtonClose,
    PopupTitle,
    Popup,
    TextContent,
    ButtonSecondary,
    IconServerError,
    IconWarning
  },
  data: function () {
    return {
      imagesUrl: this.$appConfig.imagesUrl
    }
  },
  watch: {
    visible: function () {
      if (this.visible) {
        this.hideLoading()
      }
    }
  },
  computed: {
    visible () {
      return this.$store.getters.getServerErrorPopupStatus && !this.$store.getters.getNoConnectionErrorPopup
    },
    eventID () {
      return this.$store.getters.getServerErrorID
    },
    eventPage () {
      const page = this.$store.getters.getServerErrorPage
      const messages = {
        'habits-tracker': 'message["general.server-error-popup.habit-tracker-text"]',
        'my-journey': 'message["general.server-error-popup.my-journey-text"]',
        'videos': 'message["general.videos"]',
        'meal-plan': 'message["general.server-error-popup.meal-plan-text"]',
        'workout': 'message["general.server-error-popup.workout-text"]',
        'period-tracker': 'message["general.server-error-popup.period-tracker-text"]',
        'step-tracker': 'message["general.server-error-popup.step-tracker-text"]',
        'wearable': 'message["general.server-error-popup.wearable-text"]'
      }
      return this.$i18n.t(messages[page])
    }
  },
  methods: {
    closePopup () {
      this.closeAnimatedPopup(() => {
        this.$store.commit('hideServerErrorPopup')
        this.$store.commit('unsetServerErrorID')
        this.$store.commit('unsetServerErrorPage')
      })
    },

    navigateToContact () {
      this.$store.commit('hideServerErrorPopup')
      this.$store.commit('unsetServerErrorID')
      this.$store.commit('unsetServerErrorPage')
      this.delayedRouterPush({
        path: this.$appConfig.appUrlList.contact
      })
    }
  }
}
</script>

<style scoped>
</style>
