<template>
  <button :type="submitType" :class="componentClasses" @click="clicked()" :disabled="disabled">
        <slot></slot>
  </button>
</template>

<script>

export default {
  name: 'ButtonBase',

  props: {
    // The disabled state of the button
    disabled: {
      default: false
    },
    // The border-radius of the button // values : 'none' = 0px | 'sm1' = 10px | 'md1' = 20px | 'lg1' = 30px
    borderRadius: {
      default: 'lg1'
    },
    // The type of the button // values: "solid" | "outline"
    type: {
      default: 'solid'
    },
    submitType: {
      default: ''
    }
  },
  computed: {
    componentClasses: function () {
      return {
        'rc-button': true,
        'rc-button--disabled': this.disabled,
        'rc-button--border-radius-none': this.borderRadius === 'none',
        'rc-button--border-radius-sm1': this.borderRadius === 'sm1',
        'rc-button--border-radius-md1': this.borderRadius === 'md1',
        'rc-button--border-radius-lg1': this.borderRadius === 'lg1',
        'rc-button--type-solid': this.type === 'solid',
        'rc-button--type-outline': this.type === 'outline'
      }
    }
  },
  methods: {
    // function to emit the click event
    clicked () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped></style>
