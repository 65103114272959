export default class Habit {
  id = null
  name = null
  customerId = null
  productId = null
  habitSource = null
  idDeleted = false

  updatedAt = null
  createdAt = null
  lastSetOn = null

  constructor (habit) {
    this.id = habit.id
    this.name = habit.name
    this.customerId = habit.customer_id
    this.productId = habit.product_id
    this.habitSource = habit.habit_source
    this.idDeleted = habit.is_deleted
    this.updatedAt = habit.updated_at
    this.createdAt = habit.created_at
    this.lastSetOn = habit.last_set_on
  }

  getName () {
    return this.name
  }

  getID () {
    return this.id
  }

  getSource () {
    return this.habitSource
  }
}
