<template>
  <div :class="componentClasses" @click="clicked()">
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66675 3.83317C9.66675 2.9165 8.91675 2.1665 8.00008 2.1665C7.08342 2.1665 6.33342 2.9165 6.33342 3.83317C6.33342 4.74984 7.08342 5.49984 8.00008 5.49984C8.91675 5.49984 9.66675 4.74984 9.66675 3.83317ZM9.66675 13.8332C9.66675 12.9165 8.91675 12.1665 8.00008 12.1665C7.08341 12.1665 6.33341 12.9165 6.33341 13.8332C6.33341 14.7498 7.08341 15.4998 8.00008 15.4998C8.91675 15.4998 9.66675 14.7498 9.66675 13.8332ZM9.66675 8.83317C9.66675 7.9165 8.91675 7.1665 8.00008 7.1665C7.08342 7.1665 6.33342 7.9165 6.33341 8.83317C6.33341 9.74984 7.08341 10.4998 8.00008 10.4998C8.91675 10.4998 9.66675 9.74984 9.66675 8.83317Z"
        fill="#AFAFAF"
      />
    </svg>
  </div>
</template>

<script>
import iconMixin from "../../../mixins/component-mixins/root/iconMixin";

export default {
  name: "IconMoreHorizontal",
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        "rc-icon": true,
        "rc-icon-more": true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses,
      };
    },
  },
};
</script>

<style scoped></style>
