<template>
  <div :class="componentClasses" @click="clicked()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3059 14.6006C12.2982 14.9404 13.1743 15.1568 14.4332 15.5437C13.9496 21.6545 8.17108 19.2277 11.3059 14.6006ZM16.4652 8.98694C16.4 7.1521 16.0769 4.7452 13.5026 5.12234C12.2958 5.43727 11.4042 6.76258 10.9848 9.04914C10.7545 10.3064 10.8885 12.071 11.1601 13.1289C11.4082 13.8743 11.3238 13.8285 11.5905 13.9701C12.6224 14.2025 13.6439 14.4599 14.6843 14.7223C15.7411 13.976 16.6091 10.0138 16.4652 8.98694ZM8.52081 8.84612C8.79228 7.78815 8.92621 6.02347 8.696 4.76639C8.27696 2.47972 7.38522 1.1542 6.17825 0.839479C3.60397 0.462339 3.28079 2.86921 3.21563 4.70416C3.07177 5.7308 3.93986 9.69326 4.9968 10.4394C6.03704 10.177 7.05847 9.91991 8.09071 9.68723C8.35701 9.5458 8.27264 9.59159 8.52081 8.84612ZM5.2475 11.261C5.73095 17.3718 11.5095 14.945 8.37474 10.3179C7.38247 10.6578 6.50635 10.8742 5.2475 11.261Z" fill="black"/>
      </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconSteps',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-steps': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
