<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="48" height="48" rx="24" fill="black" fill-opacity="0.52"/>
    <path d="M20.6336 14.441C18.7926 13.4067 17.3 14.254 17.3 16.332V31.6665C17.3 33.7466 18.7926 34.5928 20.6336 33.5595L34.3187 25.8728C36.1604 24.8382 36.1604 23.1619 34.3187 22.1275L20.6336 14.441Z" fill="white"/>
    </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconPlay',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-play': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
