import HabitsApi from '../api/habits/HabitsApi'
import APIErrorHandler from '../api-handler/APIErrorHandler'
import moment from 'moment'
import lodash from 'lodash'
import Habit from './Habit'
import HabitDayEntries from './HabitDayEntries'
import ProgressAPI from '../api/progress-check-in/ProgressAPI'
import { sortMixedStrings } from '@/helpers/string'
import DashBoardService from '../DashboardService'

export default class HabitsTrackerService {
    /** @type {HabitsApi}  */
    _api = null
    /** Logged data for all member's habits */
    habitsEntries = []
    /**  @type {Habit[]} */
    habits = [];
    /**  @type {Habit[]} */
    recommendedHabits = [];
    /** @type {boolean} */
    _isHabitsLoaded = [];

  constructor () {
    this._api = new HabitsApi()
  }

  /** Fetch client habits and set them as classes to HabitsTrackerService.habits
   * @returns {Promise<Habit[]>}
  */
  setHabits () {
    return new Promise((resolve, reject) => {
      this._api.getAllHabitsEntries() // fetch client habits
        .then(data => data.data.data)
        .then(data => {
          this.habits = []
          data.forEach(habit => {
            this.habits.push(new Habit(habit))
          })
          this._isHabitsLoaded = true;
          resolve(this.habits)
        }).catch(error => {
          if (error.response && error.response.status === 500) {
            this._handleServerError(error)
            reject(error)
          } else if (error.response && error.response.status !== 404 && error.response.status !== 403) {
            APIErrorHandler.logSentry(error)
            reject(error)
          }
        })
    })
  }

  /**
   * @return {Array<{habit: string, productId: string}>}
   */
  setRecommendedHabits () {
    // const membersHabits =  this.habits.length === 0 ? await this.setHabits() : this.habits;
    // const membersHabitsTitles = membersHabits.map(habit => habit.name.toLowerCase());
    return new Promise((resolve, reject) => {
      const dashboard = new DashBoardService()
      dashboard.getUserData(true).then( () => {
        console.log("UserInfo: ", dashboard.getSubscriptions().map(sub => ({product: sub.product_plan_uuid.slice(-6), pricing: sub.pricing_plan_uuid.slice(-6)})))
        const ids = dashboard.getSubscriptions().map( sub => sub.product_plan_uuid);

        this._api.getRecommendedHabits(ids).then(result => {
          const suggestions = result.data.data;
          this.recommendedHabits = suggestions;
          console.log("Recommended habits (service)", suggestions.map(suggestion => suggestion.habit));
          resolve(suggestions)
        })
      })
    }).catch(error => {
      if (error.response && error.response.status === 500) {
        this._handleServerError(error)
        // reject(error)
      } else if (error.response && error.response.status !== 404 && error.response.status !== 403) {
        APIErrorHandler.logSentry(error)
        // reject(error)
      }

      Promise.reject(error)
    })
  }

  getHabits () {
    if (this._isHabitsLoaded) {
      return this.habits
    }
    throw new Error('not habits loaded')
  }
  // adding new habit to the instance of HabitsTrackerService
  addNewHabitToService (habit) {
    this.habits.push(habit);
  }
/**
 *
 * @param {string} id to find the right habit to update
 * @param {object} newHabit returned object from API after the update
 */
  updateHabitInService (id, newHabit) {
    console.log("Service, update habit: ", id, newHabit)
    this.habits = this.habits.map(habit => {
      if (habit.id === id) return new Habit(newHabit);
      return habit;
    })
  }
  /**
 *
 * @param {string} id to find the right habit to update
 * @param {object} newHabit returned object from API after the update
 */
  deleteHabitInService (newHabitId) {
    console.log("Service, delete habit: ", newHabitId)
    this.habits = this.habits.filter(habit => habit.id !== newHabitId)
  }

  deleteSuggestedHabitInService (habitName) {
    console.log("Removeing suggested habit: ", habitName)
    this.recommendedHabits = this.recommendedHabits.filter(suggestion => suggestion.habit !== habitName)
  }

  // post new member habit
  // for now source is mostly not used by may be later
  addNewHabit (habit, source = 'member') {
    return new Promise((resolve, reject) => {
      this._api.addNewHabit(habit).then(newHabit => {
        // throw new Error('Some mock server error, cannot add a new habit.');
        // throw new Error('Some mock server error, cannot add a new habit.');
        this.habits.push(new Habit(newHabit.data))
        if(source === 'default') {
          this.deleteSuggestedHabitInService(habit)
        }

        resolve(newHabit.data)
      }).catch(error => {
        if (error.response && error.response.status === 500) {
          this._handleServerError(error)
          reject(error)
        } else if (error.response && error.response.status === 409) {
          reject(new Error("This habit already exist. Please try a different name"))
        } else if (error.response && error.response.status !== 404 && error.response.status !== 403) {
          APIErrorHandler.logSentry(error)
          reject(error)
        } else {

          reject(error)
        }
      })
    })
  }

  /**
   *
   * @param {string} id to find the habit to update
   * @param {string} habit a new title of a habit
   * @return {Promise<Habit>}
   */
  updateHabit(id, habit) {
    return new Promise((resolve, reject) => {
      // throw new Error('Some mock server error, cannot update the habit.');
      this._api.updateHabit(id, habit).then(newHabit => {
        this.updateHabitInService(id, newHabit.data);
        resolve(newHabit.data)
      }).catch(error => {
        if (error.response && error.response.status === 500) {
          this._handleServerError(error)
          reject(error)
        } else if (error.response && error.response.status === 409) {
          reject(new Error("This habit already exist. Please try a different name"))
        } else if (error.response && error.response.status !== 404 && error.response.status !== 403) {
          APIErrorHandler.logSentry(error)
          reject(error)
        } else {
          reject(error)
        }
      });
    });
  }

  // delete 1 member's habit
  deleteHabit (habitId) {
    return new Promise((resolve, reject) => {
      // throw new Error('Some mock server error, cannot delete that habit.');
      return this._api.deleteMemberHabit(habitId).then(() => {
        this.habits = this.habits.filter(habit => habit.id !== habitId);
        resolve(true);
      }).catch(error => {
        if (error.response && error.response.status === 500) {
          this._handleServerError(error)
          reject(error)
        } else if (error.response && error.response.status !== 404 && error.response.status !== 403) {
          APIErrorHandler.logSentry(error)
          reject(error)
        }
        reject(error)
      })
    });
  }
  //retrieve list of recommended habits
  getRecommendedHabits () {
    if (this._isHabitsLoaded) {
      return this.recommendedHabits
    }
    throw new Error('not habits loaded')
  }

  /**
   * @param {{habit: String, productId: String}} acceptedHabit
   * @param {boolean?} updateInstance
   * @returns {Promise<Habit>} Habit
   */
  acceptRecommendedHabit (acceptedHabit, updateInstance = false) {
    console.log("Accepting habit 2: ", acceptedHabit);
    return new Promise((resolve, reject) => {
      this._api.addRecommendedHabit(acceptedHabit).then(newHabitData => {
        // throw new Error('Some mock server error, cannot accept a recommended habit.');
        this.recommendedHabits = this.recommendedHabits.filter(recommendedHabit => recommendedHabit.habit !== acceptedHabit.habit);
        // console.log("New habit data: ", newHabitData);
        const newHabit = new Habit(newHabitData.data)
        // console.log("New habit data: ", newHabit)
        updateInstance && this.habits.push(newHabit);
        resolve(newHabit)
      }).catch(error => {
        if (error.response && error.response.status === 500) {
          this._handleServerError(error)
          reject(error)
        } else if (error.response && error.response.status === 409) {
          reject(new Error("This habit already exist. Please try a different name"))
        } else if (error.response && error.response.status !== 404 && error.response.status !== 403) {
          APIErrorHandler.logSentry(error)
          reject(error)
        }
        reject(error)
      })
    })
  }

  /** fetch status history for habits */
  query (from, to) {
    from = from.locale('en').format();
    to = to.locale('en').format();

    return new Promise((resolve, reject) => {
      this._api.queryHabits(from, to)
        .then(data => data.data.data).then(data => {
          let habitDayEntries = []
          if (Array.isArray(data) && data.length > 0) {
            if (this._isAllHabitsNotHavingEntries(data)) {
              habitDayEntries = [ this._makeHabitDayEntriesByDate(this.getCurrentDate()) ]
            } else {
              data.forEach(habit => {
                if (habit.status_history.length !== 0) {
                  habit.status_history.forEach(item => {
                    let currentHabitDayEntryIndex = habitDayEntries.findIndex(habitDayEntry => {
                      return habitDayEntry.getDate() === moment(item.set_on).locale('en').format('YYYY-MM-DD')
                    })
                    if (currentHabitDayEntryIndex >= 0) {
                      habitDayEntries[currentHabitDayEntryIndex].setEntry(habit.id, item)
                    } else {
                      let currentHabitDayEntries = this._makeHabitDayEntriesByDate(moment(item.set_on).locale('en').format('YYYY-MM-DD'))
                      if (currentHabitDayEntries) {
                        currentHabitDayEntries.setEntry(habit.id, item)
                        habitDayEntries.push(currentHabitDayEntries)
                      }
                    }
                  })
                }
              })
            }
          }
          this.addHabitsEntries(habitDayEntries)
          resolve(habitDayEntries)
        }).catch(error => {
          if (error.response && error.response.status === 500) {
            this._handleServerError(error)
            reject(error)
          } else if (error.response && error.response.status !== 404 && error.response.status !== 403) {
            APIErrorHandler.logSentry(error)
            reject(error)
          }

          reject(error)
        });
    })
  }

  /**
   * API response of habits
   * @param {Habit[]} habits
   * @returns {boolean}
   * @private
   */
  _isAllHabitsNotHavingEntries (habits) {
    return habits.every(habit => {
      return habit.status_history.length === 0
    })
  }

  /**
   *  Return array of dummy statuses with dates
   * @param date
   * @returns {HabitDayEntries}
   * @private
   */
  _makeHabitDayEntriesByDate (date) {
    return new HabitDayEntries(date, this.habits)
  }

  /** add dummy statuses to this.habitsEntries (HabitTrackerService) using addHabitsEntries() */
  addDayEntry (date) {
    let dayEntry = this._makeHabitDayEntriesByDate(date)
    this.addHabitsEntries([dayEntry])
  }

  addHabitsEntries (habitDayEntries) {
    let currentEntries = this.habitsEntries
    this.habitsEntries = [...habitDayEntries, ...currentEntries]
    this.habitsEntries = lodash.sortedUniqBy(this.habitsEntries, function (e) {
      return e.date
    })
  }

  getTodayDayEntries () {
    let todayDateRange = this.getTodayDateRange()
    return this.query(todayDateRange.from, todayDateRange.to).then(entries => {
      if(entries.length === 0) return new HabitDayEntries(this.getCurrentDate(), []);

      return entries[0]
    })
  }

  setHabit (habitId, status) {
    return new Promise((resolve, reject) => {
      this._api.setHabit(habitId, status).then(data => {
        this.habitsEntries = data
        resolve(data)
      }).catch(error => {
        if (error.response.status === 500) {
          this._handleServerError(error)
          reject(error)
        } else {
          APIErrorHandler.logSentry(error)
          reject(error)
        }
      })
    })
  }

  getTodayDateRange () {
    const dateRange = {
      'from': moment().locale('en').set({hour: 0, minute: 0, second: 0, millisecond: 0}),
      'to': moment().locale('en').set({hour: 23, minute: 59, second: 59, millisecond: 0})
    }

    return dateRange
  }

  getCurrentDate () {
    return moment().locale('en').format('YYYY-MM-DD')
  }

  /**
   * Log and show error popup
   * @param response
   * @private
   */
  _handleServerError (response) {
    let sentryEventID = APIErrorHandler.logSentry(response)
    APIErrorHandler.showErrorPopup(sentryEventID, 'habits-tracker')
  }
}
