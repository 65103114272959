import WorkoutApi from './api/workout/WorkoutApi'
import WorkoutProgramWeekService from './WorkoutProgramWeekService'
import store from '../store'
import DashBoardService from './DashboardService'
import WorkoutProgramExerciseService from './WorkoutProgramExerciseService'
import WorkoutProgramDayService from './WorkoutProgramDayService'
import { i18n } from '../helpers/localization/i18n'
import APIErrorHandler from "@/services/api-handler/APIErrorHandler";

export default class WorkoutProgramService {
  id = 0
  name = ''
  backgroundImage = ''
  description = ''
  notes = ''
  available = true
  activateAt = ''
  subscriptionId = ''
  planType = ''
  planName = ''
  _workoutLogEnabled = false
  setTypes = []
  isDetailsLoaded = false
  weeks = []
  _weekTileImages = []
  _weekBackgroundImages = []

  constructor (program) {
    this.id = program.id
    this.name = program.name
    this.backgroundImage = program.background || program.background_image
    this.available = program.available
    this.activateAt = program.activate_at
    this.subscriptionId = program.subscription_id
    this.planType = program.pricing_plan_type
    this.planName = program.pricing_plan_name
    this.setWeekImages()
    this.setWeekBackgroundImages()
  }

  loadProgramData () {
    let api = new WorkoutApi()
    return api.getWorkoutDetails(this.id).then((data) => {
      return data.data
    })
  }

  setProgramDetails (force = false) {
    this.setWorkoutLogEnabled()
    return new Promise((resolve, reject) => {
      if (this.isDetailsLoaded&&!force) {
        resolve()
        return
      }

      this.loadProgramData().then(data => {
        this.description = data.description
        this.notes = data.notes
        this.setTypes = data.set_types
        this.weeks = []
        data.weeks.forEach((week, index) => {
          let weekObject = {
            ...week,
            programID: this.id,
            tileImage: this.getWeekTileImageByIndex(index),
            backgroundImage: this.getWeekBackgroundImagesByIndex(index)
          }
          this.weeks.push(new WorkoutProgramWeekService(weekObject))
        })
        this.isDetailsLoaded = true
        resolve()
      }).catch((error) => {
       if (error.response && error.response.status === 500) {
         this.handleServerError(error)
       }
        reject(new Error('unable to load workout program'))
      })
    })
  }

  setWeekImages () {
    const images = store.getters.getImages
    this._weekTileImages = [
      images['workout.week1.tile'],
      images['workout.week2.tile'],
      images['workout.week3.tile'],
      images['workout.week4.tile']
    ]
  }

  getAvailability () {
    return this.available
  }

  getActiveAt () {
    return this.activateAt
  }

  getSubscriptionId () {
    return this.subscriptionId
  }

  getPlanType () {
    return this.planType
  }

  getPlanName () {
    return this.planName
  }

  getSetTypes () {
    return this.setTypes
  }

  getCircuitSetType () {
    return this.setTypes.find(setType => {
      return setType.name === i18n.t('message["workout.mode-circuit"]')
    })
  }
  getWeekTileImageByIndex (index) {
    return this._weekTileImages[(index % 4)] || null
  }

  setWorkoutLogEnabled () {
    let dashBoardService = new DashBoardService()
    this._workoutLogEnabled = dashBoardService.isWorkoutLogEnabled()
  }

  setWeekBackgroundImages () {
    const images = store.getters.getImages
    this._weekBackgroundImages = [
      images['workout.week1.background'],
      images['workout.week2.background'],
      images['workout.week3.background'],
      images['workout.week4.background']
    ]
  }

  getWeekBackgroundImagesByIndex (index) {
    return this._weekBackgroundImages[(index % 4)] || null
  }

  getID () {
    return this.id
  }

  checkDetailsLoaded () {
    return this.isDetailsLoaded
  }

  getWeeks () {
    if (!this.isDetailsLoaded) {
      throw new Error('workout program not loaded')
    }
    return this.weeks
  }

  getName () {
    return this.name
  }

  /**
   *
   * @param weekID
   * @returns {number}
   */
  getWeekByID (weekID) {
    return this.weeks.find(week => {
      return week.getID() === weekID
    })
  }

  /**
   *
   * @param weekID
   * @returns {number}
   */
  getWeekIndex (weekID) {
    return this.weeks.findIndex(week => {
      return week.getID() === weekID
    })
  }

  getBackgroundImage () {
    return this.backgroundImage
  }

  getDescription () {
    if (!this.isDetailsLoaded) {
      throw new Error('workout program not loaded')
    }
    return this.description
  }

  getNotes () {
    if (!this.isDetailsLoaded) {
      throw new Error('workout program not loaded')
    }
    return this.notes
  }

  getWorkoutLogEnabled () {
    return this._workoutLogEnabled
  }

  /**
   *
   * @param exerciseID
   * @returns {WorkoutProgramExerciseService}
   */
  getExerciseByID (exerciseID) {
    for (let week of this.weeks) {
      let exercise = week.getExerciseByID(exerciseID)
      if (exercise && exercise instanceof WorkoutProgramExerciseService) {
        return exercise
      }
    }
    return null
  }

  /**
   *
   * @param dayID
   * @returns {WorkoutProgramDayService}
   */
  getDayByID (dayID) {
    for (let week of this.weeks) {
      let day = week.getDayByID(dayID)
      if (day && day instanceof WorkoutProgramDayService) {
        return day
      }
    }
    return null
  }

  handleServerError (error) {
    let sentryEventID = APIErrorHandler.logSentry(error.response)
    APIErrorHandler.handleError(error.response)
  }
}
