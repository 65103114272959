<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_29862_12314)">
        <path d="M4.07799 13.8915C3.22734 13.2734 2.5352 12.4626 2.05825 11.5255C1.58129 10.5884 1.3331 9.55166 1.33399 8.50016C1.33399 4.81816 4.31865 1.8335 8.00065 1.8335C11.6827 1.8335 14.6673 4.81816 14.6673 8.50016C14.6682 9.55166 14.42 10.5884 13.9431 11.5255C13.4661 12.4626 12.774 13.2734 11.9233 13.8915L11.2467 12.7322C12.1309 12.0539 12.7805 11.1156 13.1041 10.0492C13.4278 8.98282 13.4092 7.84181 13.0511 6.78648C12.693 5.73115 12.0132 4.81452 11.1074 4.16534C10.2015 3.51616 9.1151 3.16705 8.00065 3.16705C6.88621 3.16705 5.79976 3.51616 4.89392 4.16534C3.98808 4.81452 3.30835 5.73115 2.95021 6.78648C2.59208 7.84181 2.57353 8.98282 2.89717 10.0492C3.22081 11.1156 3.87039 12.0539 4.75465 12.7322L4.07799 13.8915ZM5.43399 11.5682C4.804 11.0411 4.35155 10.3329 4.13809 9.53974C3.92463 8.74658 3.96052 7.90694 4.24088 7.1349C4.52125 6.36285 5.03249 5.69583 5.70515 5.22446C6.37781 4.75309 7.17928 4.50023 8.00065 4.50023C8.82203 4.50023 9.6235 4.75309 10.2962 5.22446C10.9688 5.69583 11.4801 6.36285 11.7604 7.1349C12.0408 7.90694 12.0767 8.74658 11.8632 9.53974C11.6498 10.3329 11.1973 11.0411 10.5673 11.5682L9.88065 10.3908C10.2552 10.0185 10.5107 9.5434 10.6149 9.02568C10.7191 8.50797 10.6672 7.97098 10.4658 7.48279C10.2645 6.99459 9.9227 6.57718 9.48382 6.28346C9.04495 5.98974 8.52875 5.83294 8.00065 5.83294C7.47256 5.83294 6.95636 5.98974 6.51748 6.28346C6.07861 6.57718 5.73683 6.99459 5.53546 7.48279C5.33409 7.97098 5.28221 8.50797 5.38639 9.02568C5.49057 9.5434 5.74613 10.0185 6.12065 10.3908L5.43399 11.5682ZM7.33399 9.16683H8.66732V15.1668H7.33399V9.16683Z" fill="#AFAFAF"/>
      </g>
      <defs>
        <clipPath id="clip0_29862_12314">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconConnectWearable',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-connect-wearable': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
