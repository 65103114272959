<template>
  <page :header="headerOptions" class="gc-page-injuries" @ready="pageReady">
    <validation-observer :name="title.main" ref="form" tag="div" v-slot="{ handleSubmit,invalid }">
    <page-container>

      <form id="form-input-injuries-prevent-exercising" @submit.prevent="handleSubmit(nextComponent)">
        <div class="gc-page-preferred-meals__header-wrapper">
          <text-heading4 class="gc-page-preferred-meals__title" :weight="'extra-bold'" :lineHeight="'multi'">{{title.main}}</text-heading4>
          <text-body-regular class="gc-page-preferred-meals__optional-tag" :lineHeight="'multi'">{{ optionalText }}</text-body-regular>
          <text-body-small class="gc-page-preferred-meals__sub-title" :lineHeight="'multi'">{{title.sub}}</text-body-small>
        </div>
        <validation-provider :name="title.main"
                            :rules="{ limit_selected_options: { limit: 2 } }"
                            tag="div"
                            v-slot="{ errors }">
          <input-multi-select
            v-on:change="setFormData"
            v-model="inputValue"
            :name="title.main"
            :options="options"
            :max-selected="2"
            :has-error="!!errors[0]"
            :max-selected-title="title.main"
            @change="inputChange()"
            :placeHolder="$t('message[\'general.select-your-preferred-meals\']')"/>
          <text-body-extra-small class="gc-page-preferred-meals__error-message">{{errors[0]}}</text-body-extra-small>
        </validation-provider>
      </form>

    </page-container>
    <page-footer ref="footer" :form="'form-input-injuries-prevent-exercising'" :validated="invalid"></page-footer>
     </validation-observer>
  </page>
</template>

<script>
import BaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import inputMultiSelect from './../inputs/InputMultiSelect'
import TextHeading4 from '../typography/TextHeading4'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextBodySmall from '../typography/TextBodySmall'
import Page from './PageForm'
import PageContainer from './page-sub-components/PageContainer'
import PageFooter from './page-sub-components/PageFormFooter'
import TextBodyRegular from '../typography/TextBodyRegular'

export default {
  name: 'InjuriesPreventExercising',
  extends: BaseComponent,
  components: {
    PageFooter,
    PageContainer,
    Page,
    TextBodyExtraSmall,
    TextHeading4,
    inputMultiSelect,
    TextBodySmall,
    TextBodyRegular
  },
  data: function () {
    return {
      headerOptions: {
        show: false,
        left: 'previous',
        right: '',
        modifiers: ['with-img'],
        mainTitle: '',
        subTitle: '',
        isTransparent: true
      }
    }
  },
  computed:{
    optionalText: function () {
      if(this.$t('message[\'general.form.optional-with-brackets\']') !== '(Optional)'){
        return this.$t('message[\'general.form.optional-with-brackets\']')
      }

      return '('+this.$t('message[\'general.form.optional\']')+')'
    },
  },
  created () {
    this.pageClass = ['page-injuries']
  },
  mounted () {
    this.hideLoading()
  },
  watch: {
    childValue: function (val) {
      this.setFormData()
    }
  },
  methods: {
    pageReady () {
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.validate()
        }
      })
    },
    inputChange () {
      this.$refs.footer.setFooterClass()
    }
  }
}
</script>

<style scoped>

</style>
