<template>
  <popup v-if="visible" :visible="visible" size="md" @hide="closePopup" :no-close-on-backdrop="true">
    <popup-title v-if="status === 'init' || status === 'error'">
      <popup-button-close @hide="closePopup($event)" />
    </popup-title>
    <popup-body class="text-center pc-popup-habits-add">
      <popup-content>
        <popup-head>
          <!-- <p>HEADER</p> -->
        </popup-head>
        <div
          v-if="status === 'loading'"
          class="text-center pc-popup-update-habit-data"
        >
          <TextHeading4 :weight="'extra-bold'">
            {{ $t('message[ "habit-tracker.pop-up-add-text-adding"]') }}
          </TextHeading4>
          <loading-buffer :height="'75'"></loading-buffer>
        </div>
        <div v-if="status === 'success'" class="pc-popup-update-habit-data">
          <TextHeading4 :weight="'extra-bold'">
            {{ $t('message[ "habit-tracker.pop-up-add-text-added"]') }}
          </TextHeading4>
          <icon-complete-filled :size="'lg3'" />
        </div>
        <div v-if="status === 'error'" class="info-modal__general-error">
          <div
            class="ma-form__message ma-form__message--error"
            v-for="(error, eid) in generalErrors"
            :key="'fd-general-errors' + eid"
            v-html="error.toString()"
          ></div>
        </div>
      </popup-content>
    </popup-body>
  </popup>
</template>
<script>
import Popup from "../../../../global/popups/Popup";
import PopupTitle from "../../../../global/popups/popup-sub-components/PopupTitle";
import PopupButtonClose from "../../../../global/popups/popup-sub-components/PopupButtonClose";
import PopupBody from "../../../../global/popups/popup-sub-components/PopupBody";
import PopupContent from "../../../../global/popups/popup-sub-components/PopupContent";
import PopupHead from "../../../../global/popups/popup-sub-components/PopupHead";
import popupAnimationMixin from "../../../../../mixins/popupAnimationMixin";
import IconCompleteFilled from "@/components/root/icons/IconCompleteFilled.vue";
import LoadingBuffer from "../../global/widgets/LoadingBuffer.vue";
import HabitsTrackerService from "@/services/habits-tracker/HabitsTrackerService";
import HabitSuggestion from "@/services/habits-tracker/HabitSuggestion";
import TextHeading4 from "../../../../global/typography/TextHeading4";
import { AxiosError } from 'axios'

export default {
  name: "PopupHabitsAcceptSuggestion",
  components: {
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupContent,
    PopupHead,
    IconCompleteFilled,
    LoadingBuffer,
    TextHeading4,
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: { default: false, type: Boolean },
    suggestion: { required: true, type: HabitSuggestion }, // {habit, productId}
    onSuccessAcceptCb: { type: Function },
  },
  data: function () {
    return {
      /** init, loading, success, error */
      status: "loading",
      hasError: false,
      generalErrors: [],
      errors: [],
    };
  },
  watch: {},
  beforeMount() {
    // this.showLoading();
  },
  mounted() {
    this.acceptSuggestion(this.suggestion);
  },
  computed: {},
  methods: {
    closePopup(e) {
      this.closeAnimatedPopup(() => {
        this.$emit("close");
        // backdrop close event fire
        if (e && e.trigger === "backdrop") {
          this.submitCloseEvent("HabitsTracker", "background");
        }
      });
    },
    acceptSuggestion(suggestion) {
      console.log("Accepting suggested habit");
      const api = new HabitsTrackerService();

      api.acceptRecommendedHabit(suggestion).then( (newHabit) => {
        this.status = 'success'
        this.$emit('onSuccessAcceptCb', newHabit)
      }).catch(err => {
        console.error(err);
        this.status = 'error'
        const errorMessage = err instanceof AxiosError ? err.response?.data.message : err.message;
        this.generalErrors = [errorMessage];
      }).finally(() => {
        // this.submited = false
        this.hideLoading();
        if (this.status === 'error') return;
        setTimeout(() => {
          this.closePopup()
        }, 1000)
      })

      // Platform API
      // api
      //   .addNewHabit(suggestion, 'default')
      //   .then((newHabit) => {
      //     this.status = "success";
      //     this.$emit('onSuccessAcceptCb', newHabit)
      //   }).catch(err => {
      //     console.error(err);
      //     this.status = 'error'
      //     const errorMessage = err instanceof AxiosError ? err.response?.data : err.message;
      //     this.generalErrors = [errorMessage];
      //   }).finally(() => {
      //     // this.submited = false
      //     this.hideLoading();
      //     if (this.status === 'error') return;
      //     setTimeout(() => {
      //       this.closePopup()
      //     }, 1000)
      //   })
    },
  },
};
</script>
