<template>
<div>
  <div :class="componentClasses"   @click="clicked()">
      <div class="pc-accordion-recipe-tile__left-section">
        <div v-if="imageSrc" class="pc-accordion-recipe-tile__image">
          <base-image :src="imageSrc" ></base-image>
        </div>
        <div v-else-if="imageSrc" class="pc-accordion-recipe-tile__image pc-accordion-recipe-tile__image--empty">
          <base-image :src="emptyImage" ></base-image>
        </div>
        <div class="pc-accordion-recipe-tile__text-section">
          <text-body-regular class="pc-accordion-recipe-tile__text" :line-height="'multi'" :weight="'extra-bold'">{{ text }}</text-body-regular>
          <text-body-extra-small class="pc-accordion-recipe-tile__sub-text" v-if="hasSubText" :line-height="'multi'">{{ subText }}</text-body-extra-small>
          <text-body-extra-small class="pc-accordion-recipe-tile__sub-secondary-text" v-if="hasSubSecondaryText" :line-height="'multi'">{{ subSecondaryText }}</text-body-extra-small>
        </div>
      </div>
      <div class="pc-accordion-recipe-tile__right-section">
        <icon-forward v-if="showArrow"></icon-forward>
      </div>
  </div>
  <div class="pc-accordion-recipe-tile__separator"></div>
  <div class="pc-accordion-recipe-tile__dropdown-area">
    <slot></slot>
  </div>
</div>
</template>

<script>
import TextContent from '../../../../root/TextContent'
import BaseImage from '../../../../root/Image'
import IconForward from '../../../../root/icons/IconForward'
import TextBodyRegular from '../../../../global/typography/TextBodyRegular'
import TextBodyExtraSmall from '../../../../global/typography/TextBodyExtraSmall'

export default {
  name: 'AccordionRecipeTile',
  components: {
    TextContent,
    IconForward,
    BaseImage,
    TextBodyRegular,
    TextBodyExtraSmall
  },
  props: {
    /**
     * The main text of the button
     */
    text: {
      default: ''
    },
    /**
     * The sub text of the button
     */
    subText: {
      default: ''
    },/**
     * The Sub Secondary Text of the button
     */
    subSecondaryText: {
      default: ''
    },
    /**
     * The disabled state of the button
     */
    disabled: {
      default: false
    },
    selected: {
      default: false
    },
    imageSrc: {
      default: ''
    },
    showArrow: {
      default: true
    }
  },
  computed: {
    hasSubText: function () {
      return this.subText.replace(/\s/g, '') !== ''
    },
    hasSubSecondaryText: function () {
      return this.subSecondaryText.replace(/\s/g, '') !== ''
    },
    hasIcon () {
      return !!this.$slots.default
    },
    textClass: function () {
      return {
        'pc-accordion-recipe-tile__text': true,
        'pc-accordion-recipe-tile__text--with-sub-text': this.hasSubText && this.hasSubSecondaryText,
      }
    },
    subTextClass: function () {
      return {
        'pc-accordion-recipe-tile__sub-text': this.hasSubText && this.hasSubSecondaryText
      }
    },
    componentClasses: function () {
      return {
        'pc-accordion-recipe-tile': true,
        'pc-accordion-recipe-tile--with-sub-text': this.hasSubText,
        'pc-accordion-recipe-tile--disabled': this.disabled,
        'pc-accordion-recipe-tile--selected': this.selected
      }
    }
  },
  data: function () {
    return {
      emptyImage: this.$appConfig.imagesUrl + 'mealplan/default-food-image.jpg'
    }
  },
  methods: {
    /**
     * function to emit the click event
     */
    clicked () {
      if (!this.disabled) {
        if (!this.selected) {
          this.selected = true
        } else {
          this.selected = false
        }
        this.$emit('click')
      }
    }
  }
}
</script>
