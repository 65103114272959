<template>
  <popup
    :visible="visible"
    @hide="closePopup"
    modal-class="gc-popup gc-popup--gender-info"
    v-if="visible"
    ref="modal"
  >
    <popup-title>
      <popup-button-close @hide="closePopup()" />
    </popup-title>
    <popup-body class="gc-popup-meal-swap__body text-center">
      <popup-head class="text-center">
        <popup-text-heading>{{
          $t('message["subscriptions.gender-info-title"]')
        }}</popup-text-heading>
      </popup-head>
      <popup-content>
        <popup-text-body class="text-center info-modal__subtitle mb-2">
          <div
            v-html="$t('message[\'subscriptions.gender-info-description\']')"
          ></div>
        </popup-text-body>
      </popup-content>
    </popup-body>
  </popup>
</template>
<script>
import LoadingBuffer from "../../layout/template-1/global/widgets/LoadingBuffer";
import popupAnimationMixin from "../../../mixins/popupAnimationMixin";
import Popup from "./Popup.vue";
import PopupBody from "./popup-sub-components/PopupBody";
import PopupButtonClose from "./popup-sub-components/PopupButtonClose";
import PopupContent from "./popup-sub-components/PopupContent";
import PopupFloatingFooter from "./popup-sub-components/PopupFloatingFooter";
import PopupFooter from "./popup-sub-components/PopupFooter";
import PopupTextBody from "./popup-sub-components/PopupTextBody";
import PopupHead from "./popup-sub-components/PopupHead";
import PopupTextHeading from "./popup-sub-components/PopupTextHeading";
import PopupTextSubHeading from "./popup-sub-components/PopupTextSubHeading";
import PopupTitle from "./popup-sub-components/PopupTitle";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonSecondary from "../buttons/ButtonSecondary";
import BaseComponent from "../../layout/template-1/global/base/BaseComponent";
import IconCompleteFilled from "../../root/icons/IconCompleteFilled";
import InputCheckBoxSquared from "../../global/inputs/InputCheckBoxSquared";
import TextContent from "../../root/TextContent";
export default {
  name: "SwapMeal",
  components: {
    LoadingBuffer,
    Popup,
    PopupBody,
    PopupButtonClose,
    PopupContent,
    PopupFloatingFooter,
    PopupFooter,
    PopupTextBody,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupTitle,
    ButtonSecondary,
    ButtonPrimary,
    IconCompleteFilled,
    InputCheckBoxSquared,
    TextContent,
  },
  extends: BaseComponent,
  mixins: [popupAnimationMixin],
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data: function () {
    return {
      title: this.$i18n.t('message["swap.meal-popup-title"]'),
      loading: true,
      confirmButtonText: this.$i18n.t('message["swap.meal-popup-button-text"]'),
    };
  },
  methods: {
    closePopup(e) {
      // Prevent default action for the event if it exists
      if (e && e.preventDefault) {
        e.preventDefault();
      }

      // Check if the event is triggered by the backdrop

      if (e && e.trigger === "backdrop") {
        this.$emit("close"); // Fire close event
        return; // Stop further execution
      }

      // Close the popup with animation
      this.closeAnimatedPopup(() => {
        this.$emit("close"); // Fire close event
      });
    },
  },
};
</script>
