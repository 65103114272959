<template>
  <page-secondary class="pc-community" :header="header" v-if="show">
    <side-bar v-if="!isDesktop" class="pc-community__sidebar" ref="sideBar"></side-bar>

    <script type="module" crossorigin :src="assertUrl+'index.js'"></script>
    <link rel="modulepreload" crossorigin :href="assertUrl+'preload-helper.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'__federation_fn_import.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'_sentry-release-injection-file.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'__federation_shared_react.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'jsx-runtime.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'SectionLoader.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'__federation_expose_Auth.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'__federation_expose_Routes.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'lodash.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'useActivityActions.js'">
    <link rel="modulepreload" crossorigin :href="assertUrl+'useActivities.js'">


    <link rel="stylesheet" :href="assertUrl+'style.css'">
    <script type="application/javascript">
      window.global ||= window;
    </script>
    <community-app
      v-if="show"
      type="nlaf"
      :feed-id="feedId"
    ></community-app>

  </page-secondary>
</template>

<script>
import PageSecondary from '../../../global/pages/PageSecondary'
import SideBar from '../../../global/navigators/SideBar'
import CommunityService from '../../../../services/community/CommunityService'
import DashBoardService from '../../../../services/DashboardService'
import globalMixin from '../../../../mixins/globalMixin'
import { mapGetters, mapMutations } from 'vuex'
import { checkIsMember } from '@/helpers/user'
import UserEventsService from '../../../../services/UserEventsService'
import AuthService from '@/services/AuthService'
import { pageReadyEvent } from '@/helpers/dom/events/customEvents'
import {isIOS, osVersion} from "mobile-device-detect";

export default {
  name: 'PageCommunity',
  components: {
    SideBar,
    PageSecondary,
  },
  mixins: [globalMixin],
  beforeMount () {
    const auth = new AuthService()
    window.cfAuthToken = auth.getToken();
    window.addEventListener('header-menu-click', this.headerMenuClick)
    window.addEventListener('community-mounted', this.communityMounted)
    window.addEventListener('notifications-click', this.navigateNotifications)
    this.showLoading()
  },
  mounted () {
    console.log(osVersion)
    if(isIOS && (osVersion.startsWith('11')||osVersion.startsWith('12')||osVersion.startsWith('13'))){
      alert('Please update your iOS version to 15 or above to use the community feature')
      this.$router.push('/dashboard')
      return
    }
    let dashBoardService = new DashBoardService()
    if (!dashBoardService.isCommunityEnabled()) {
      this.$router.push('/dashboard')
      return
    }
    const community = new CommunityService()

    community.getMeUser().then(() => {
      this.showCommunity()
    }).catch(() => {
      const params = { feedId: this.$route.query.community }

      community.initCommunities(params).then(() => {
        this.showCommunity()
      }).catch(() => {
        this.$router.push('/dashboard')
      })
    })

    setTimeout(() => {
      this.hideLoading()
    }, 7000)
  },
  data: function () {
    return {
      assertUrl: this.$appConfig.communityAssetURL,
      header: {
        isTransparent: false,
        isFixed: false,
        show: false,
        left: '',
        right: '',
        rightBlink: false,
        modifiers: [],
        mainTitle: '',
        subTitle: ''

      },
      feedId: this.$route.query.community,
      show: null

    }
  },
  computed: {
    ...mapGetters({
      getPlanExpiredPopupShow: 'getPlanExpiredPopupShow'
    })
  },
  watch: {
    getPlanExpiredPopupShow: function (newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$router.push({path: '/dashboard'})
      }
    }
  },
  methods: {
    ...mapMutations({
      showSubscriptionExpiredPopup: 'showSubscriptionExpiredPopup'
    }),
    checkCommunityIsEnabled(){
      const community = new CommunityService()
      community.getCommunities().then(() => {
        if (!community.isActiveCommunity(this.$route.query.community)) {
          this.$router.push('/dashboard')
        }
      })
    },
    navigateNotifications () {
      this.delayedRouterPush({
        path: '/dashboard?page=notifications&tab=community'
      })
    },
    showCommunity () {
      this.checkCommunityIsEnabled()
      if (!checkIsMember()) {
        this.show = true
        return
      }
      let dashboard = new DashBoardService()

      if (dashboard.hasActivePlan()) {
        this.show = true
        UserEventsService.trackCommunityPageVisit(this.feedId)
        setTimeout(()=>{
          pageReadyEvent()
        }, 1000)
      } else {
        this.show = false

        if (!this.getPlanExpiredPopupShow) {
          this.showSubscriptionExpiredPopup('community_feed')
        }
      }
    },
    headerMenuClick () {
      if (this.isDesktop) {
        return
      }
      this.$refs.sideBar.open()
    },
    communityMounted () {
      this.hideLoading()
    }
  },
  //   clicked (entry, status, entryIndex) {
  //   let dashboard = new DashBoardService()
  //   if (!(dashboard.hasActiveMealPlans())) {
  //     if (!(this.getPlanExpiredPopupShow)) {
  //       this.showSubscriptionExpiredPopup('community_feed')
  //     }
  //   }
  // },
  beforeDestroy () {
    window.removeEventListener('header-menu-click', this.headerMenuClick)
    window.removeEventListener('community-mounted', this.communityMounted)
    window.removeEventListener('notifications-click', this.navigateNotifications)
  }

}
</script>

<style scoped>

</style>
