<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins


export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true
    },
    maxValue: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      options: {
        layout: {
          padding: {
            top: 10,
            right: 0,
            left: -10
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        legend: {
          display: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.7,
              maxBarThickness: 40,
              align: 'end',
              display: true,
              gridLines: {
                tickMarkLength: false,
                drawBorder: false,
                display: true,
                lineWidth: 1.5,
                zeroLineColor:  templateConfig.style_variables['$text-area-bg-outline'],
                color:  templateConfig.style_variables['$text-area-bg-outline'],
              },
              ticks: {
                fontFamily: 'Poppins',
                fontSize: 12,
                fontStyle: 700,
                padding: 10,
                fontColor:  templateConfig.style_variables['$text-secondary-on-bg'],
                lineHeight: 1.5,
                maxRotation: 0,
                minRotation: 0
              },
              scaleLabel: {
                display: true
              }
            }
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                drawBorder: false,
                display: true,
                lineWidth: 1,
                zeroLineColor:  templateConfig.style_variables['$text-area-bg-outline'],
                color: templateConfig.style_variables['$text-area-bg-outline'],
              },
              position: 'right',
              ticks: {
                maxTicksLimit: 2, // Controls the maximum number of labels
                display: true, // Ensures labels are displayed
                fontFamily: 'Poppins',
                fontSize: 12,
                fontStyle: 700,
                fontColor: templateConfig.style_variables['$text-secondary-on-bg'],
                beginAtZero: true, // Start the y-axis at 0
                max: this.maxValue

              }
            }
          ]
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
