<template>
  <div :class="componentClasses" @click="clicked()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.62332 2.66392H2.45374C2.20315 2.66392 2 2.86707 2 3.11767C2 3.36826 2.20315 3.57141 2.45374 3.57141H2.91831L3.46681 18.1066C3.50124 19.0192 4.26359 19.7549 5.17594 19.7549H14.8558C15.767 19.7549 16.5305 19.0189 16.5649 18.1066L17.1134 3.57141H17.5785C17.8291 3.57141 18.0323 3.36826 18.0323 3.11767C18.0323 2.86707 17.8291 2.66392 17.5785 2.66392H13.4088L13.1385 1.98833C12.9139 1.42683 12.2835 1 11.6798 1H8.35233C7.75029 1 7.11757 1.4283 6.89356 1.98833L6.62332 2.66392ZM7.60071 2.66392H12.4314L12.296 2.32536C12.2092 2.1084 11.9124 1.90749 11.6798 1.90749H8.35233C8.12134 1.90749 7.82232 2.10989 7.73614 2.32536L7.60071 2.66392ZM4.37365 18.0724L3.82644 3.57141H16.2053L15.6581 18.0724C15.642 18.4973 15.2789 18.8474 14.8558 18.8474H5.17594C4.75188 18.8474 4.3897 18.4979 4.37365 18.0724ZM9.71372 16.8811V6.59623C9.71372 6.34564 9.91686 6.14249 10.1675 6.14249C10.4181 6.14249 10.6212 6.34564 10.6212 6.59623V16.8811C10.6212 17.1317 10.4181 17.3348 10.1675 17.3348C9.91686 17.3348 9.71372 17.1317 9.71372 16.8811ZM6.23534 6.60958L6.53783 16.8944C6.5452 17.1449 6.75423 17.342 7.00472 17.3346C7.25521 17.3273 7.45229 17.1182 7.44493 16.8677L7.14243 6.5829C7.13506 6.33241 6.92603 6.13532 6.67554 6.14269C6.42506 6.15006 6.22797 6.35909 6.23534 6.60958ZM12.5875 16.8677L12.89 6.5829C12.8974 6.33241 13.1064 6.13532 13.3569 6.14269C13.6074 6.15006 13.8045 6.35909 13.7971 6.60958L13.4946 16.8944C13.4873 17.1449 13.2782 17.342 13.0278 17.3346C12.7773 17.3273 12.5802 17.1182 12.5875 16.8677Z" fill="black"/>
      </svg>
  </div>
</template>

<script>
import iconMixin from '../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconDelete',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-delete': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
