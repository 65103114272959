<template>
  <grid-row class="pc-card-list-product-page">
    <grid-col cols="12">
    <header-simple
      class="pc-card-list-product-page__header"
      :header-text="headerText"
    />
    </grid-col>
    <grid-col v-for="page in pages" cols="12"  :lg="(hasAVideoSlider) ? 12 :6" :key="'product-page-card-' + page.name">
      <container-custom-video-slider v-if="page.sliderConfigs" :startDate="page.sliderConfigs.startDate" :slider-config="page.sliderConfigs"/>
      <card-navigation
        v-else
        class="pc-card-list-product-page__card"
        :image="page.image"
        :header-text="page.name"
        @click="navigate(page.url)"
      />
    </grid-col>

  </grid-row>
</template>
<script>
import HeaderSimple from '../../../../global/headers/HeaderSimple'
import CardFeaturedImage from '../../../../global/cards/CardFeaturedImage'
import NativeAppMixin from '../../../../../mixins/NativeAppMixin'
import CardNavigation from '../../../../global/cards/CardNavigation.vue'
import GridCol from '../../../../global/grid/GridCol'
import GridRow from '../../../../global/grid/GridRow'
import ContainerCustomVideoSlider from './ContainerCustomVideoSlider'

export default {
  name: 'ListCardProductPages',
  components: {
    GridCol,
    GridRow,
    CardFeaturedImage,
    HeaderSimple,
    CardNavigation,
    ContainerCustomVideoSlider
  },
  mixins: [NativeAppMixin],
  props: {
    headerText: {
      default: ''
    },
    pages: {
      default: []
    }
  },
  computed:{
    hasAVideoSlider(){
      return this.pages.some(page => page.sliderConfigs)
    }
  },
  methods: {
    navigate (url) {
      if (url.startsWith('/page')) {
        this.showLoading()
        this.$router.push({path: url})
      } else {
        this.handleUrlNavigate(url)
      }
    }
  }
}
</script>

<style scoped></style>
