<template>
    <popup class="pc-popup-period-tracker-enable" v-if="visible" :visible="visible" size="md" @hide="closePopup()">
        <popup-title class="pc-popup-period-tracker-enable__title">
            <popup-button-close v-if="popupStatus !== 'processing' && popupStatus !== 'success'"  @click="closePopup"> </popup-button-close>
        </popup-title>
        <popup-body v-if="status === 'ready'">
            <popup-content class="pc-popup-period-tracker-enable__content">
                <popup-head v-if="popupStatus === 'init'">
                    <popup-text-heading>
                        {{ $t('message[\'period-tracker.popup-enable-title\']') }}
                    </popup-text-heading>
                </popup-head>
                <popup-head v-if="popupStatus === 'processing'">
                    <popup-text-heading>
                        {{ $t('message[\'period-tracker.popup-processing\']') + '...' }}
                    </popup-text-heading>
                </popup-head>
                <popup-head v-if="popupStatus === 'success'">
                    <popup-text-heading>
                      {{ $t('message[\'period-tracker.popup-saved\']') }}
                    </popup-text-heading>
                </popup-head>
                <div v-if="popupStatus === 'success'">
                  <icon-completed-filled :size="'lg1'"/>
                </div>
                <popup-head class="pc-popup-period-tracker-enable__error-title" v-if="popupStatus === 'error'">
                    <popup-text-heading>
                        {{ $t('message[\'period-tracker.popup-error-title\']')}}
                    </popup-text-heading>
                </popup-head>
                <text-body-small v-if="popupStatus === 'init'" class="pc-popup-period-tracker-enable__description" :line-height="'multi'">
                  <div v-html="$t('message[\'period-tracker.popup-enable-description\']')"></div>
                </text-body-small>
                <text-body-small v-if="popupStatus === 'error'" class="pc-popup-period-tracker-enable__description" :line-height="'multi'">
                  <div v-html="$t('message[\'period-tracker.popup-error-description\']')"></div>
                </text-body-small>
                <loading-buffer v-if="showLoader" :height="'50'"></loading-buffer>
                <div v-if="popupStatus === 'init' && showRestoreTick" class="pc-popup-period-tracker-enable__check-box-input">
                  <input-check-box-squared :id="true" class="pc-popup-period-tracker-enable__check-box"  v-model="restoreActive" @click="toggleRestore"></input-check-box-squared>
                    <label class="pc-popup-period-tracker-enable__check-box-input-label">{{ $t('message[\'period-tracker.popup-restore-logged-data\']') }}</label>
                </div>
                <div v-if="showWarning && popupStatus === 'init'" class="pc-popup-period-tracker-enable__warning-wrapper">
                  <icon-warning size="sm1" class="gc-alert-injury__icon"/>
                  <text-body-extra-small class="pc-popup-period-tracker-enable__warning-text">
                    {{ $t('message[\'period-tracker.popup-enable-warning\']') }}
                  </text-body-extra-small>
                </div>
            </popup-content>
            <popup-footer>
                <button-primary
                        v-if="popupStatus === 'error'"
                        :text="$t('message[\'period-tracker.popup-try-again\']')"
                        @click="enable()"
                        />
                <button-primary
                v-if="popupStatus === 'init' && !showLoader"
                :text="$t('message[\'period-tracker.popup-enable-accept\']')"
                @click="enable()"
                />
            </popup-footer>
        </popup-body>
    </popup>
</template>

<style></style>

<script>
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../../../../global/popups/Popup'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import TextBodySmall from '../../../../../global/typography/TextBodySmall'
import LoadingBuffer from '../../../global/widgets/LoadingBuffer'
import IconCompleteFilled from '../../../../../root/icons/IconCompleteFilled'
import InputCheckBoxSquared from '../../../../../global/inputs/InputCheckBoxSquared'
import TextBodyExtraSmall from '../../../../../global/typography/TextBodyExtraSmall'
import IconWarning from '@/components/root/icons/IconWarning.vue'
import PeriodTrackerService from '../../../../../../services/period-tracker/PeriodTrackerService'
import IconCompletedFilled from '@/components/root/icons/IconCompletedFilled'
export default {
  components: {
    TextBodySmall,
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupTextBody,
    PopupFooter,
    ButtonPrimary,
    LoadingBuffer,
    IconCompleteFilled,
    InputCheckBoxSquared,
    TextBodyExtraSmall,
    IconWarning,
    IconCompletedFilled
  },
  name: 'PopupEnablePeriodTracker',
  mixins: [popupAnimationMixin],
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    popupStatus: {
      type: String,
      default: 'init'
    }
  },
  data: function () {
    return {
      status: 'ready', // ready, processing, processed, error,
      restoreActive: [true],
      showWarning: false,
      showRestoreTick: false,
      showLoader: false
    }
  },
  mounted () {
    this.checkPeriodHistory()
  },
  methods: {
    async checkPeriodHistory () {
      try {
        this.showLoader = true
        const periodTrackerService = new PeriodTrackerService()
        await periodTrackerService.setHistory()
        const history = periodTrackerService.getHistory()

        if (history.length > 0) {
          this.showRestoreTick = true
        } else {
          this.showRestoreTick = false
        }
      } catch (error) {
        
      } finally {
        this.showLoader = false
      }
    },
    enable () {
      this.$emit('enable-period-tracker', {enableRestore: this.showWarning})
    },
    toggleRestore () {
      if (this.restoreActive.length === 0) {
        this.showWarning = true
      } else {
        this.showWarning =  false
      }
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }
  }
}
</script>
