<template>
    <popup class="pc-popup-meal-plans-pair" v-if="visible" :visible="visible" size="md" @hide="closePopup()">
        <popup-title class="pc-popup-meal-plans-pair__title">
            <popup-button-close v-if="popupStatus !== 'processing' && popupStatus !== 'success'"  @click="closePopup"> </popup-button-close>
        </popup-title>
        <popup-body v-if="status === 'ready'">
            <popup-content class="pc-popup-meal-plans-pair__content">
                <popup-head v-if="popupStatus === 'init'">
                    <popup-text-heading>
                        {{ $t('message[\'period-tracker.popup-disable-title\']') }}
                    </popup-text-heading>
                </popup-head>
                <popup-head v-if="popupStatus === 'processing'">
                    <popup-text-heading>
                        {{ $t('message[\'period-tracker.popup-processing\']') + '...' }}
                    </popup-text-heading>
                </popup-head>
                <popup-head v-if="popupStatus === 'success'">
                    <popup-text-heading>
                      {{ $t('message[\'period-tracker.popup-saved\']') }}
                    </popup-text-heading>
                </popup-head>
                <div v-if="popupStatus === 'success'">
                  <icon-completed-filled :size="'lg1'"/>
                </div>
                <popup-head class="pc-popup-period-tracker-enable__error-title" v-if="popupStatus === 'error'">
                    <popup-text-heading>
                        {{ $t('message[\'period-tracker.popup-error-title\']')}} {{ popupStatus }}
                    </popup-text-heading>
                </popup-head>
                <text-body-small v-if="popupStatus === 'init'" :line-height="'multi'">
                  <div v-html="$t('message[\'period-tracker.popup-disable-description\']')"></div>
                </text-body-small>
                <text-body-small v-if="popupStatus === 'error'" class="pc-popup-period-tracker-enable__description" :line-height="'multi'">
                  <div v-html="$t('message[\'period-tracker.popup-error-description\']')"></div>
                </text-body-small>
            </popup-content>
            <popup-footer>
                <button-primary
                        v-if="popupStatus === 'error'"
                        :text="$t('message[\'period-tracker.popup-try-again\']')"
                        @click="disable()"
                        />
                <button-primary
                v-if="popupStatus === 'init'"
                :text="$t('message[\'period-tracker.popup-disable-accept\']')"
                @click="disable()"
                />
            </popup-footer>
        </popup-body>
    </popup>
</template>

<style></style>

<script>
import popupAnimationMixin from '../../../../../../mixins/popupAnimationMixin'
import PopupTitle from '../../../../../global/popups/popup-sub-components/PopupTitle'
import Popup from '../../../../../global/popups/Popup'
import PopupButtonClose from '../../../../../global/popups/popup-sub-components/PopupButtonClose'
import PopupBody from '../../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../../global/popups/popup-sub-components/PopupContent'
import PopupHead from '../../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupFooter from '../../../../../global/popups/popup-sub-components/PopupFooter'
import ButtonPrimary from '../../../../../global/buttons/ButtonPrimary'
import TextBodySmall from '../../../../../global/typography/TextBodySmall'
import LoadingBuffer from '../../../global/widgets/LoadingBuffer'
import IconCompletedFilled from '@/components/root/icons/IconCompletedFilled'

export default {
  components: {
    TextBodySmall,
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupTextBody,
    PopupFooter,
    ButtonPrimary,
    LoadingBuffer,
    IconCompletedFilled
  },
  name: 'PopupDisablePeriodTracker',
  mixins: [popupAnimationMixin],
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    popupStatus: {
      type: String,
      default: 'init'
    }
  },
  data: function () {
    return {
      status: 'ready', // ready, processing, processed, error
    }
  },
  mounted () {
  },
  methods: {
    disable () {
      this.$emit('disable-period-tracker')
    },
    closePopup (e) {
      if (e) {
        e.preventDefault()
      }
      this.closeAnimatedPopup(() => {
        this.$emit('close')
      })
    }
  }
}
</script>
