<template>
  <div class="pc-input-search-bar">
    <input
      class="pc-input-search-bar__input"
      type="text"
      :name="name"
      v-model="value"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
    />
    <icon-search :size="'sm4'" class="pc-input-search-bar__icon" @click="$emit('click')"/>
  </div>
</template>

<script>
import TextInput from '../../../global/inputs/TextInput.vue'
import IconSearch from '../../../root/icons/IconSearch.vue'
export default {
  name: 'InputSearchBar',
  components: { IconSearch, TextInput },
  props: {
    placeholder: {
      default: 'Search'
    },
    name: {
      default: ''
    }
  },
  data: function () {
    return {
      value: ''
    }
  },
  methods: {

  }
}
</script>

<style></style>
