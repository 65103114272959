<template>
  <div :class="componentClasses" @click="clicked()">
    <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 10C0 4.47715 4.47715 0 10 0H22C27.5228 0 32 4.47715 32 10C32 15.5228 27.5228 20 22 20H10C4.47715 20 0 15.5228 0 10Z" fill="#A46E7B"/>
    <path d="M20.4214 6.25152C20.7964 5.90283 21.3882 5.91898 21.7433 6.28572C22.0989 6.65276 22.083 7.23262 21.7084 7.58073L15.0695 13.7487C14.8884 13.9163 14.6572 14 14.4259 14C14.1821 14 13.939 13.9072 13.7561 13.7231L10.2652 10.2119C9.9049 9.84942 9.91269 9.26957 10.2827 8.91661C10.653 8.56328 11.2442 8.57123 11.6048 8.93368L14.4521 11.7975L20.4214 6.25152Z" fill="white"/>
    </svg>

  </div>
</template>

<script>
import iconMixin from '../../../../../mixins/component-mixins/root/iconMixin'

export default {
  name: 'IconCompleted',
  mixins: [iconMixin],
  computed: {
    componentClasses: function () {
      return {
        'rc-icon': true,
        'rc-icon-completed': true,
        ...this.componentSizeClasses,
        ...this.componentStateClasses
      }
    }
  }
}
</script>

<style scoped></style>
