import DefineInclude from '../../DefineInclude'
import { flagValue } from '../../includes/TemplateSettings'

export default class {
  getBodyFats () {
    let customImages = flagValue('custom_body_fat_image_config', null)
    customImages = typeof customImages === 'string' ? JSON.parse(customImages) : customImages
    if (customImages && typeof customImages === 'object') {
      return customImages
    }

    //temp hard coded resourceBaseURL for testing
    DefineInclude.resourceBaseURL = 'https://app-redesign-production-builds.s3.ap-southeast-2.amazonaws.com'
    const baseImageURL = DefineInclude.resourceBaseURL + '/common/bodyfat/V2/'
    const maleBaseImageUrl = baseImageURL + 'men/'
    const femaleBaseImageUrl = baseImageURL + 'women/'
    // const baseImageURL = DefineInclude.resourceBaseURL + '/static/assets/images/bodyfat/'
    // const maleBaseImageUrl = baseImageURL + 'men/'
    // const femaleBaseImageUrl = baseImageURL + 'women/'
    return {
      isTextLabel: false,
      showInput: true,
      m:
        [
          { image: maleBaseImageUrl + 'm1.png', min: '3', max: '5' },
          { image: maleBaseImageUrl + 'm2.png', min: '6', max: '9' },
          { image: maleBaseImageUrl + 'm3.png', min: '10', max: '14' },
          { image: maleBaseImageUrl + 'm4.png', min: '15', max: '19' },
          { image: maleBaseImageUrl + 'm5.png', min: '20', max: '24' },
          { image: maleBaseImageUrl + 'm6.png', min: '25', max: '29' },
          { image: maleBaseImageUrl + 'm7.png', min: '30', max: '34' },
          { image: maleBaseImageUrl + 'm8.png', min: '35', max: '39' },
          { image: maleBaseImageUrl + 'm9.png', min: '40', max: '45' }
        ],
      f: [
        { image: femaleBaseImageUrl + 'f1.png', min: '10', max: '14' },
        { image: femaleBaseImageUrl + 'f2.png', min: '15', max: '19' },
        { image: femaleBaseImageUrl + 'f3.png', min: '20', max: '24' },
        { image: femaleBaseImageUrl + 'f4.png', min: '25', max: '29' },
        { image: femaleBaseImageUrl + 'f5.png', min: '30', max: '34' },
        { image: femaleBaseImageUrl + 'f6.png', min: '35', max: '39' },
        { image: femaleBaseImageUrl + 'f7.png', min: '40', max: '44' },
        { image: femaleBaseImageUrl + 'f8.png', min: '45', max: '50' }
      ]
    }
  }
}
