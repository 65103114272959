import BaseAPI from '../BaseAPI.js'
import axios from 'axios'
import DefineInclude from '../../../DefineInclude'
import moment from 'moment'

export default class HabitsApi extends BaseAPI {
    /** /me */
    _resource ='me'
    _habitsTrackerBaseUrl = ''

    constructor () {
      super()
      this._habitsTrackerBaseUrl = DefineInclude.habitsTrackerAPIUrl
    }


    // GET /me
    getUserData () {
      const url = this._habitsTrackerBaseUrl
      const pathArr = [this._resource]
      return axios.get(url + this._getPath(pathArr), {headers: this._getCustomHeaders()})
    }

    // GET /me/habits
    /** Fetch list of habits for a customer */
    getAllHabitsEntries () {
      // If have other url need to add on DefineIncludes.
      const url = this._habitsTrackerBaseUrl
      const pathArr = [this._resource, 'habits']
      return axios.get(url + this._getPath(pathArr),{headers: this._getCustomHeaders()})
    }

    /**
     * @param habit string
     * @returns {{Habit}}
     */
    addNewHabit(habit) {
      if(!habit) throw new Error("postNewMemberHabit() => Habit is not undefined");

      const url = this._habitsTrackerBaseUrl
      const pathArr = [this._resource, 'habits']
      return axios.post(url + this._getPath(pathArr), {name: habit}, {headers: this._getCustomHeaders()})
    }

    /**
   * @param {string} habit
   * @param {string} id
   * @returns {{Habit}}
   */
    updateHabit(id, habit) {
      if(!habit) throw new Error("postNewMemberHabit() => Habit is not undefined");

      const url = this._habitsTrackerBaseUrl
      const pathArr = [this._resource, 'habits', id]
      return axios.put(url + this._getPath(pathArr), {name: habit}, {headers: this._getCustomHeaders()})
    }

    deleteMemberHabit (habitId) {
      if(!habitId) throw new Error("deleteMemberHabit() => Habit ID is undefined")
      const url = this._habitsTrackerBaseUrl
      const pathArr = [this._resource, 'habits', habitId]
      return axios.delete(url + this._getPath(pathArr), {headers: this._getCustomHeaders()})
    }
    // fetch list of recommended habits for a customer
    getRecommendedHabits (productIds) {
      const url = this._habitsTrackerBaseUrl
      const pathArr = [this._resource, 'default','habits', 'products']
      return axios.get(url + this._getPath(pathArr), {
        headers: this._getCustomHeaders(),
        params: {productIds},
        paramsSerializer: {
          indexes: true,
        }
      })
    }

    // add recommended habit to the list of member's habits
    addRecommendedHabit(suggestion) {
      const url = this._habitsTrackerBaseUrl
      const pathArr = [this._resource, 'default','habits', 'products', suggestion.productId]
      return axios.post(url + this._getPath(pathArr), {name: suggestion.habit}, { headers: this._getCustomHeaders(), params: {productId: suggestion.productId} })
    }

    queryHabits (from, to) {
      // If have other url need to add on DefineIncludes.
      const url = this._habitsTrackerBaseUrl
      const pathArr = [this._resource, 'habits_status_history']
      const configs = {
        headers: this._getCustomHeaders(),
        params: { from: from, to: to }
      }
      return axios.get(url + this._getPath(pathArr), configs)
    }

    /**
   * upsert (create or update) habit entry
   * @param habitId
   * @param status
   * @param date
   * @returns {Promise<AxiosResponse<T>>}
   */
    setHabitEntry (habitId, status, date = moment()) {
      // If have other url need to add on DefineIncludes.
      const url = this._habitsTrackerBaseUrl
      let bodyParams = {'label': status, 'set_on': date.format()}
      const pathArr = [this._resource, 'habits', habitId, 'status']
      return axios.put(url + this._getPath(pathArr), bodyParams, {headers: this._getCustomHeaders()})
    }

    /**
   * delete the habit entry by it's ID and habit ID
   * @param habitId - habit ID
   * @param habitEntryID - habit entry ID
   * @returns {Promise<AxiosResponse<T>>}
   */
    deleteHabitEntry (habitId, habitEntryID) {
      const url = this._habitsTrackerBaseUrl

      const pathArr = [this._resource, 'habits', habitId, 'statuses', habitEntryID]
      return axios.delete(url + this._getPath(pathArr), {headers: this._getCustomHeaders()})
    }

    _getCustomHeaders () {
      let headers = this._headers
      headers['Authorization'] = 'Bearer ' + this._token
      headers['X-Api-Host'] = DefineInclude.apiUrl.replace(/\/$/, '')
      return headers
    }
}
