import WorkoutApi from './api/workout/WorkoutApi'
import WorkoutProgramService from './WorkoutProgramService'
import { checkIsMember } from '../helpers/user'
import APIErrorHandler from "@/services/api-handler/APIErrorHandler";

export default class WorkoutProgramsService {
  _workoutProgramList = []
  workoutPrograms = [] // only active plans
  allWorkoutPrograms = [] // all plans
  inactiveWorkoutPrograms = [] // inactive plans

  constructor () {
    if (!WorkoutProgramsService.instance) {
      WorkoutProgramsService.instance = this
    }
    return WorkoutProgramsService.instance
  }

  /**
   * Returns active workout programs
   * @returns {[]}
   */
  getWorkoutPrograms () {
    return this.workoutPrograms
  }

  /**
   * Returns inactive workout programs
   * @returns {[]}
   */
  getInactiveWorkoutPrograms () {
    return this.inactiveWorkoutPrograms
  }

  /**
   * Returns all workout programs
   * @returns {[]}
   */
  getAllWorkoutPrograms () {
    return this.allWorkoutPrograms
  }

  /**
   * Returns challenge workout programs
   * @returns {[]}
   */
  getChallengeWorkoutPrograms () {
    return this.allWorkoutPrograms.filter((program) => {
      return program.getPlanType() === 'challenge' || program.getActiveAt()
    })
  }

  /**
   * get workout programs list using workout API
   * @returns {Promise<unknown>}
   */
  setWorkoutProgramList () {
    return new Promise((resolve, reject) => {
      if (!checkIsMember()) {
        resolve([])
      }
      if (this.workoutPrograms.length > 0) {
        resolve(this._workoutProgramList)
      } else {
        const workoutApi = new WorkoutApi()
        if (this._workoutProgramList && Array.isArray(this._workoutProgramList) && this._workoutProgramList.length) {
          resolve(this._workoutProgramList)
        } else {
          workoutApi.getWorkoutList().then((data) => {
            this.makeWorkoutProgramsByList(data.data.workouts)
            resolve(this._workoutProgramList)
          }).catch(err => {
            if (err.response && err.response.status === 500) {
              this.handleServerError(err)
            }
            reject(err)
          })
        }
      }
    })
  }

  async getWorkoutProgramListFromAPI () {
    const workoutApi = new WorkoutApi()
    return workoutApi.getWorkoutList().then((data) => {
      this.makeWorkoutProgramsByList(data.data.workouts)
      return  this._workoutProgramList
    })


  }

  /**
   * make WorkoutProgramService services based on the API response
   * @param workouts
   */
  makeWorkoutProgramsByList (workouts = []) {
    this._workoutProgramList = workouts
    if (Array.isArray(this._workoutProgramList) && this._workoutProgramList.length) {
      this.workoutPrograms = []
      this.inactiveWorkoutPrograms = []
      this.allWorkoutPrograms = []
      this._workoutProgramList.forEach(program => {
        if (program.available && !(program.pricing_plan_type === 'challenge' || program.activate_at)) {
          this.workoutPrograms.push((new WorkoutProgramService(program)))
        } else if (!program.available) {
          this.inactiveWorkoutPrograms.push((new WorkoutProgramService(program)))
        }
        this.allWorkoutPrograms.push((new WorkoutProgramService(program)))
      })
    }
  }

  /**
   *
   * @param id
   * @returns {WorkoutProgramService}
   */
  getWorkoutProgram (id) {
    return this.getAllWorkoutPrograms().find(program => {
      return program.getID() === id
    })
  }

  /**
   * check circuit mode enabled
   * @returns {boolean}
   */
  isCircuitModeEnabled () {
    if (templateConfig.instance_config &&
      'is_circuit_mode_enabled' in templateConfig.instance_config) {
      return templateConfig.instance_config.is_circuit_mode_enabled
    }
    return false
  }

  /**
   * reset the class data
   */
  reset () {
    this._workoutProgramList = []
    this.workoutPrograms = []
    this.inactiveWorkoutPrograms = []
    this.allWorkoutPrograms = []
  }

  handleServerError (error) {
    let sentryEventID = APIErrorHandler.logSentry(error.response)
    APIErrorHandler.handleError(error.response)
  }
}
