<template>
  <popup
    class="pc-popup-update-habit-data__success"
    v-if="visible"
    :visible="visible"
    size="md"
    @hide="closePopup"
  >
    <popup-title v-if="status === 'init' || status === 'error'">
      <!-- temp to test states -->
      <!-- <button @click="testChangeState()" style="cursor: pointer; margin: 0 20px">State: {{ status }}</button> -->
      <popup-button-close @hide="closePopup($event)" />
    </popup-title>
    <popup-body class="text-center pc-popup-habits-delete">
      <popup-content>
        <popup-head>
          <popup-text-heading
            v-if="status === 'init'"
            class="intruduction-popup__header"
          >
            <p>Delete a Habit?</p>
          </popup-text-heading>
          <popup-text-sub-heading v-if="status === 'init'">
            <text-body-small>
              {{
                $t('message["habit-tracker.pop-up-delete-description-text"]')
              }}</text-body-small
            >
          </popup-text-sub-heading>
        </popup-head>
        <div
          v-if="status === 'loading'"
          class="pc-popup-update-habit-data pc-popup-update-habit-data--loading"
        >
          <TextHeading4 :weight="'extra-bold'">
            {{ $t('message[ "habit-tracker.pop-up-delete-text-deleting"]') }}
          </TextHeading4>
          <loading-buffer :height="'75'"></loading-buffer>
        </div>

        <div
          class="pc-popup-update-habit-data pc-popup-update-habit-data--success"
          v-if="status === 'success'"
        >
          <TextHeading4 :weight="'extra-bold'">
            {{ $t('message[ "habit-tracker.pop-up-delete-text-deleted"]') }}
          </TextHeading4>
          <icon-complete-filled :size="'lg3'" />
        </div>
        <div v-if="status === 'error'" class="info-modal__general-error">
          <div
            class="ma-form__message ma-form__message--error"
            v-for="(error, eid) in generalErrors"
            :key="'fd-general-errors' + eid"
            v-html="error.toString()"
          ></div>
        </div>
      </popup-content>
      <popup-footer v-if="status === 'init'">
        <!-- button change text -->
        <button-primary
          class="pc-form-workout-log__button-submit"
          :text="'Yes, Delete'"
          @click="deleteHabit"
        />
      </popup-footer>
    </popup-body>
  </popup>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Popup from "../../../../global/popups/Popup";
import PopupTitle from "../../../../global/popups/popup-sub-components/PopupTitle";
import PopupButtonClose from "../../../../global/popups/popup-sub-components/PopupButtonClose";
import PopupBody from "../../../../global/popups/popup-sub-components/PopupBody";
import PopupContent from "../../../../global/popups/popup-sub-components/PopupContent";
import PopupHead from "../../../../global/popups/popup-sub-components/PopupHead";
import PopupTextHeading from "../../../../global/popups/popup-sub-components/PopupTextHeading";
import popupAnimationMixin from "../../../../../mixins/popupAnimationMixin";
import PopupTextBody from "../../../../global/popups/popup-sub-components/PopupTextBody";
import IconCompletedLinear from "../../../../root/icons/IconCompletedLinear";
import IconArrowCircularLinear from "../../../../root/icons/IconArrowCircularLinear";
import IconIncompletedLinear from "../../../../root/icons/IconIncompletedLinear";
import ButtonPrimary from "@/components/global/buttons/ButtonPrimary.vue";
import IconCompleteFilled from "@/components/root/icons/IconCompleteFilled.vue";
import TextArea from "@/components/global/inputs/TextArea.vue";
import PopupFooter from "@/components/global/popups/popup-sub-components/PopupFooter.vue";
import LoadingBuffer from "../../global/widgets/LoadingBuffer.vue";
import HabitsTrackerService from "@/services/habits-tracker/HabitsTrackerService";
import PopupTextSubHeading from "@/components/global/popups/popup-sub-components/PopupTextSubHeading.vue";
import TextBodySmall from "../../../../global/typography/TextBodySmall";
import { AxiosError } from "axios";
import TextHeading4 from "../../../../global/typography/TextHeading4";

export default {
  name: "PopupHabitsDelete",
  components: {
    Popup,
    PopupTitle,
    PopupButtonClose,
    PopupBody,
    PopupContent,
    PopupHead,
    PopupTextHeading,
    PopupTextSubHeading,
    PopupFooter,
    PopupTextBody,
    IconCompletedLinear,
    IconArrowCircularLinear,
    IconIncompletedLinear,
    ButtonPrimary,
    IconCompleteFilled,
    TextArea,
    ValidationProvider,
    ValidationObserver,
    LoadingBuffer,
    TextBodySmall,
    TextHeading4,
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: { default: false, type: Boolean },
    habitId: { type: String, required: true },
    onDeleteCb: { type: Function },
  },
  data: function () {
    return {
      /** init, loading, success, error */
      status: "init",
      hasError: false,
      generalErrors: [],
      errors: [],
    };
  },
  watch: {},
  beforeMount() {},
  mounted() {
    console.log("Status: ", this.status);
  },
  computed: {},
  methods: {
    closePopup(e) {
      this.closeAnimatedPopup(() => {
        this.$emit("close");
        // backdrop close event fire
        if (e && e.trigger === "backdrop") {
          this.submitCloseEvent("HabitsTracker", "background");
        }
      });
    },
    deleteHabit() {
      // this.showLoading();
      console.log("Deleting habit");

      this.status = "loading";
      const api = new HabitsTrackerService();
      console.log("Deleting habit 1");

      api
        .deleteHabit(this.habitId)
        .then(() => {
          console.log("Deleting habit - success");
          this.status = "success";
          this.$emit("onDeleteCb", this.habitId);
        })
        .catch((err) => {
          console.log("CATCH BLOCK");
          this.status = "error";
          const errorMessage =
            err instanceof AxiosError ? err.response?.data.message : err.message;
          this.generalErrors = [errorMessage];
        })
        .finally(() => {
          console.log("Deleting habit - finally");
          if (this.status === "error") {
            console.log("Deleting habit - finally - error");
            this.hideLoading();
            return;
          }
          console.log("Deleting habit - finally - end");
          this.status = "success";
          this.hideLoading();
          setTimeout(() => {
            this.closePopup();
          }, 1000);
        });
    },
  },
};
</script>
