<template>
  <div :class="componentClasses"  @touchstart="clickvideo" ref="com" :style="componentStyles">
    <div class="gc-video-player__wrapper" @touchstart="clickvideo" >
      <div @touchstart="clickvideo" >
        <div class="gc-video-player__area-loading" v-if="loading" style="background: #000000bf;">
          <div class="gc-video-player__content-col gc-video-player__content-col--loading" style="flex-direction: column" >
            <p v-if="!showExternalVideoPlay"> Loading video…</p>
            <p style="text-decoration: underline" v-if="showExternalVideoPlay" @click.stop="openVideoExternal">{{ $t('message["general.video-play-fallback-text"]') }}</p>
          </div>

        </div>
        <div class="row gc-video-player__content" v-if="!showExternalVideoPlay &&videoHover">
          <div class="gc-video-player__content-col gc-video-player__content-col-left col text-left">
            <div
              class="gc-video-player__left-controller"
              @click="replay()"
              v-if="showLeftController"
            >
              <icon-replay class="gc-video-player__icon-controller gc-video-player__icon-replay" size="md2" />
            </div>
            <div class="gc-video-player__content-container gc-video-player__content-container--l-b position-absolute">
              <slot v-if="!(isPlaying || showSeekBarComponent)" name="left"></slot>
            </div>
          </div>
          <div class="gc-video-player__content-col col-2 text-center" @click="playVideo()">
            <div class="gc-video-player__center-controller" v-if="showCenterController">
              <icon-play class="gc-video-player__icon-controller gc-video-player__icon-play" size="lg4"  v-if="!isPlaying" />
              <icon-pause class="gc-video-player__icon-controller gc-video-player__icon-pause" size="lg4"  v-else />
            </div>
          </div>
          <div class="gc-video-player__content-col col text-left">
            <div
              class="gc-video-player__right-controller"
              v-if="showRightController"
              @click="toggleFullScreen()"
            >

              <icon-normal-screen class="gc-video-player__icon-controller gc-video-player__icon-normal-screen" size="md1"  v-if="isPlayerFullScreen" />
              <icon-full-screen class="gc-video-player__icon-controller gc-video-player__icon-full-screen" size="md1" v-else/>
            </div>
            <div class="gc-video-player__content-container gc-video-player__content-container--r-b position-absolute">
              <slot v-if="!(isPlaying || showSeekBarComponent)" name="right"></slot>
            </div>
          </div>
        </div>
        <div
          @click="exitFullScreen()"
          v-if="showCloseController"
          class="gc-video-player__close-controller"
        >
          <icon-close size="md1"/>
        </div>
        <video-player-seek-bar
          class="gc-video-player__seek-bar-controller"
          v-if="showSeekBarComponent"
          ref="seekBar"
          :video-duration="videoDuration"
          @onSeek="onSeek"
        />
      </div>
      <div class="gc-video-player__video-area" @touchstart="clickvideo" @click="clickvideo">
        <img
          :src="poster"
          alt=""
          class="gc-video-player__image-thumb"
          v-if="showImage && poster"
          @error="errorOnImageLoading()"
        />
        <div @touchstart="clickvideo" class="gc-video-player__video-wrapper video-js" v-if="videoID">
          <component
            v-bind:is="cardComponent"
            :videoId="options.id"
            :options="options"
            ref="videoPlayer"
            @ready="videoReady"
            @playing="vieoPlayingEvent"
            @bufferstart="vieoPlayingEvent"
            @fullScreen="setFullScreen"
            @ended="endedEvent"
            @timeupdate="timeUpdateEvent"
            @durationupdate="durationUpdateEvent"
            @error="errorEvent"
            @paused="videoPaused"
            @seeked="seekEndEvent"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UrlPattern from 'url-pattern'
import jsonp from 'jsonp'
import axios from 'axios'
import { getIdFromUrl as getYoutubeIdFromUrl } from 'vue-youtube'
import VimeoVideo from './video-player-sub-components/VideoPlayerVimeoVideoOld'
import YoutubeVideo from './video-player-sub-components/VideoPlayerYoutubeVideo'
import NativeAppMixin from '../../../mixins/NativeAppMixin'
import IconClose from '../../root/icons/IconClose'
import VideoPlayerSeekBar from './video-player-sub-components/VideoPlayerSeekBar'
import IconPlay from '../../root/icons/IconPlay'
import IconPause from '../../root/icons/IconPause'
import IconReplay from '../../root/icons/IconReplay'
import IconFullScreen from '../../root/icons/IconFullScreen'
import IconNormalScreen from '../../root/icons/IconNormalScreen'

/**
   * supports only vimeo and youtube videos
   * if android native app video online in inApp page because of the performance issue of the video playing
   * video seek bar only support on vimeo videos
   */
export default {

  name: 'VideoPlayerOld',
  props: {
    disableFullScreen: {
      default: false
    },
    url: {
      required: true,
      type: String
    },
    poster: {
      default: '',
      type: String
    },
    controls: {
      default: false,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    },
    showSeekBar: {
      type: Boolean,
      default: true
    },
    mute: {
      type: Boolean,
      default: true
    }
  },
  components: {
    VideoPlayerSeekBar,
    IconClose,
    IconPlay,
    IconPause,
    IconReplay,
    IconFullScreen,
    IconNormalScreen
  },
  mixins: [NativeAppMixin],
  data () {
    return {
      videoExternalPlayTimeOut: null,
      showExternalVideoPlay:false,
      componentStyles: {},
      videoID: null,
      imagePath: this.$appConfig.imagesUrl + 'video-player/',
      showFullScreen: true,
      showReplay: true,
      videoWidth: screen.width,
      videoHover: false,
      isPlaying: false,
      isPlayedVideo: false,
      isPlayerFullScreen: false,
      videoClicks: 0,
      isPlayedBefore: false,
      currentVideoUrl: '',
      loading: true,
      player: '',
      showImage: true,
      playedOnce: false,
      hasVideoEnded: false,
      videoDuration: 0,
      error: null,
      isSeeked: false,
      cardComponent: null
    }
  },
  mounted () {
    // set default state for no video
    if (this.options.id === '') {
      this.setNoVideoDefaultStatus()
    }
    window.addEventListener('resize', this.onWindowResize)
    this.onWindowResize()
  },
  computed: {
    showLeftController: function () {
      return this.isPlayedVideo && this.showReplay && this.showControllers
    },
    showCenterController: function () {
      return this.showControllers
    },
    showRightController: function () {
      return !this.disableFullScreen && this.isPlayedVideo && this.showFullScreen && this.showControllers
    },
    showCloseController: function () {
      return this.isPlayerFullScreen && this.showControllers
    },
    componentClasses: function () {
      return {
        'gc-video-player': true,
        'gc-video-player--fullscreen': this.isPlayerFullScreen
      }
    },
    options: function () {
      return {
        showSeekBar: this.showSeekBar,
        type: this.cardComponent,
        poster: this.image,
        url: this.url,
        id: this.videoID,
        controls: false,
        title: this.title,
        mute: this.mute
      }
    },
    showSeekBarComponent () {
      return this.type === 'vimeo' && this.showSeekBar && (this.isPlayedVideo && !this.hasVideoEnded)
    },
    videoId () {
      return this.id
    },
    showControllers () {
      return (
        this.videoId !== '' &&
          this.videoHover &&
          !this.youtubeControllersStatus()
      )
    }

  },
  watch: {
    isPlayerFullScreen: function () {
      this.onWindowResize()
    },
    url: {
      immediate: true,
      handler: function () {
        this.setVideoData()
      }
    },
    showSeekBar: function () {
      this.options.showSeekBar = this.showSeekBar
    },
    /**
       * emit video changed event
       * @param val
       */
    videoHover: function (val) {
      this.$emit('change', !val)
    },
    videoId: function (val) {
      if (val !== '') {
        this.loading = true
        this.playedOnce = false
        this.$refs.videoPlayer.play(val)
      } else {
        this.setNoVideoDefaultStatus()
      }
    }
  },
  methods: {
    onWindowResize () {
      this.componentStyles = {}
      if (this.showSeekBar && this.isDesktop && !this.isPlayerFullScreen) {
        this.componentStyles.height = (window.innerHeight - this.$refs.com.getBoundingClientRect().top) + 'px'
      }
    },
    videoPaused () {
      this.isPlaying = false
    },
    vieoPlayingEvent () {
      if (this.isPlayedVideo) {
        this.isPlaying = true
      }
    },
    timeUpdateEvent (event) {
      if (this.showSeekBarComponent && this.$refs['seekBar']) {
        this.$refs['seekBar'].timeUpdateEvent(event)
      }
    },
    resetSeekBarData (event = null) {
      if (this.showSeekBarComponent && this.$refs['seekBar']) {
        this.$refs['seekBar'].resetSeekBarData(event)
      }
    },
    durationUpdateEvent (duration) {
      this.videoDuration = duration
    },
    errorOnImageLoading () {
      this.$emit('image-loading-error')
    },
    setNoVideoDefaultStatus () {
      this.loading = false
      this.videoHover = true
    },
    youtubeControllersStatus () {
      return false
    },
    videoReady (player = '') {
      // set player instance
      if (player !== '') {
        this.player = player
      }
      this.videoLoaded()
    },
    onSeek (value) {
      this.isSeeked = true
      this.$refs.videoPlayer.seekToPosition(value, this.isPlaying)
      // The requirement wasn't clear when a user seeks back after the video has already been stopped
      // Current implementation will put the player to a paused state.
      if (this.hasVideoEnded && value < 100) {
        this.hasVideoEnded = false
        this.isPlayedVideo = true
        this.showImage = false
        this.outvideo()
      }
    },
    async setVideoReadyFullScreen () {
      if (this.disableFullScreen) {
        return
      }
      let vidHeight = 0
      let vidWidth = 0

      if (
        this.player &&
          typeof this.player.getVideoHeight === 'function' &&
          typeof this.player.getVideoWidth() === 'function'
      ) {
        await this.player.getVideoHeight().then((height) => {
          vidHeight = height
        })
        await this.player.getVideoWidth().then((width) => {
          vidWidth = width
        })

        if (vidHeight > vidWidth && !this.disableFullScreen) {
          this.setFullScreen(true)
          this.setSeekBarBottomPadding()
        }
      }
    },
    videoLoaded () {
      this.invideo()
      this.loading = false
    },
    /**
       * set video hover
       */
    invideo () {
      this.videoHover = true
    },
    /**
       * video hover
       */
    outvideo () {
      this.videoHover = false
    },
    /**
       * handle the video click event
       * show the player buttons
       * and hide in 3 seconds
       */
    clickvideo () {
      this.videoHover = true
      this.videoClicks++
      setTimeout(() => {
        this.videoClicks--
        if (this.videoClicks < 1 && this.isPlaying) {
          this.outvideo()
        }
      }, 1500)
    },
    /**
       * get the video playlist
       * @returns {Promise<void>}
       */
    async getHLSPlaylist () {
      for (const source in this.options.sources) {
        const res = await axios.get(
          this.$appConfig.vimeoVideoAPI +
            'vimeo/' +
            this.options.sources[source].src
              .replace('?autoplay=0', '')
              .replace('https://player.vimeo.com/video/', '')
        )

        this.options.sources[source].src = res.data.playlist_url
      }
    },
    /**
       * handle the video play
       */
    playVideo () {
      if(!this.videoExternalPlayTimeOut){
        this.videoExternalPlayTimeOut = setTimeout(() => {
          if(this.loading){
            this.showExternalVideoPlay = true
          }
        }, 5000)
      }
      // ignore logic if video id is not available
      if (this.options.id === '') {
        return
      }
      // for fix vimeo video lag issues
      if (this.checkVideoExternalOpening()) {
        this.openVideoExternal()
        this.logEvent('Viewed Video', {
          video_url: this.options.url,
          exercise_title: this.options.title
        }, false)
        return
      }
      if (!this.isPlaying) {
        if (this.hasVideoEnded) {
          // reset data when playing only after the video has ended
          this.resetSeekBarData()
          this.hasVideoEnded = false
        }
        this.isPlayedVideo = true
        this.showImage = false
        this.$refs.videoPlayer.play()
        this.isPlaying = true
        this.playedOnce = true
        if (!this.isPlayedBefore) {
          let videoUrl = ''
          if (this.type === 'youtube') {
            videoUrl = 'https://www.youtube.com/embed/' + this.options.id
          } else {
            videoUrl = 'https://player.vimeo.com/video/' + this.options.id
          }

          this.logEvent('Viewed Video', {
            video_url: videoUrl,
            exercise_title: this.options.title
          }, false)
          this.isPlayedBefore = true
        }
        this.setVideoReadyFullScreen()
        this.clickvideo()
      } else {
        this.$refs.videoPlayer.pause()
        this.isPlaying = false
      }
    },
    checkVideoExternalOpening () {
      return false
    },
    openVideoExternal () {
      this.logEvent('Viewed Video External', {
        video_url: this.options.url,
        exercise_title: this.options.title
      }, false)
      this.handleUrlNavigate(this.options.url)
    },
    /**
       * handle the video fullscreen
       */
    toggleFullScreen () {
      if (this.disableFullScreen) {
        this.setFullScreen(false)
        return
      }
      if (!this.getFullScreen()) {
        this.setFullScreen(true)
      } else {
        this.setFullScreen(false)
      }
      this.$nextTick(() => {
        this.onWindowResize()
      })
    },
    exitFullScreen () {
      this.setFullScreen(false)
      this.$refs.videoPlayer.pause()
      this.isPlaying = false
      this.$nextTick(() => {
        this.onWindowResize()
      })
    },
    setFullScreen (val) {
      if (this.disableFullScreen) {
        return
      }
      this.isPlayerFullScreen = val

      this.emitFullScreen()
    },
    getFullScreen () {
      return this.isPlayerFullScreen
    },
    emitFullScreen () {
      this.$emit('fullScreen', this.isPlayerFullScreen)
    },

    /**
       * play the video from 0 seconds
       */
    replay () {
      if (this.hasVideoEnded) {
        this.hasVideoEnded = false
      }
      this.resetSeekBarData()
      this.isPlayedVideo = true
      this.showImage = false
      this.$refs.videoPlayer.replay()
      this.isPlaying = true
      this.setVideoReadyFullScreen()
      this.clickvideo()
    },
    /**
       * Player Events
       */
    pausedEvent () {
      this.isPlaying = false
    },
    playingEvent () {
      this.isPlaying = true
    },
    endedEvent () {
      this.hasVideoEnded = true
      this.isPlaying = false
      this.showImage = true
      this.invideo()
      if (!this.getFullScreen()) {
        this.isPlayedVideo = false
      }
    },
    seekEndEvent () {
      this.isSeeked = false
    },
    errorEvent (error) {
      this.error = error
    },
    toggleFullscreenByResolution () {
      if (this.disableFullScreen) {
        this.setFullScreen(false)
        return
      }
      if (window.innerHeight > window.innerWidth) {
        this.setFullScreen(false)
      } else {
        this.setFullScreen(true)
      }
    },

    setVideoData () {
      if (this.getVimeoIdFromUrl(this.url)) {
        this.setVimeoVideoData()
      } else if (getYoutubeIdFromUrl(this.url)) {
        this.setYoutubeVideoData()
      }
    },
    setYoutubeVideoData () {
      this.videoID = getYoutubeIdFromUrl(this.url)
      this.cardComponent = YoutubeVideo
      this.type = 'youtube'
      this.showVideo = true
    },
    setVimeoVideoData () {
      this.videoID = this.getVimeoIdFromUrl(this.url)
      this.type = 'vimeo'
      this.cardComponent = VimeoVideo

      if (!this.image && this.id) {
        this.getVimeoImage(this.id)
          .then(url => {
            this.image = url
          }).catch(() => {
            // do nothing
          }).finally(() => {
            this.showVideo = true
          })
      } else {
        this.showVideo = true
      }
    },
    getVimeoIdFromUrl (url) {
      const pattern = new UrlPattern('(http(s)\\://)player.vimeo.com(\\::port)/video/:videoId(/)(?*)')
      let matchUrl = pattern.match(url)
      if (!matchUrl) {
        const patternNotEmbed = new UrlPattern('(http(s)\\://)vimeo.com(\\::port)/:videoId(/)(?*)')
        matchUrl = patternNotEmbed.match(url)
        return matchUrl ? matchUrl.videoId : null
      } else {
        return matchUrl.videoId ? matchUrl.videoId : null
      }
    },
    getVimeoImage (videoId) {
      return new Promise((resolve, reject) => {
        try {
          jsonp('https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + videoId + '?format=jsonp',
            null,
            (error, data) => {
              if (!error) {
                let height = parseInt(data.height)
                let width = parseInt(data.width)

                // checking for valid dimensions
                if (height === 0 || width === 0) {
                  reject(new Error('invalid image dimensions'))
                  return
                }

                // setting image width to defined value and height according to ratio
                let expectedWidth = 1200
                let ratio = expectedWidth / width
                let expectedHeight = Math.floor(height * ratio)

                let parts = data.thumbnail_url.split('_')
                let fileNameParts = parts[1].split('.')
                let extension = fileNameParts[fileNameParts.length - 1]
                parts[1] = expectedWidth + 'x' + expectedHeight + '.' + extension
                let url = parts.join('_')

                // return vimeo image (if not empty)
                if (url && url.trim() !== '') {
                  resolve(url)
                } else {
                  reject(new Error('empty image'))
                }
              } else {
                reject(new Error('fetch failed'))
              }
            })
        } catch (e) {
          reject(new Error('fetch failed'))
        }
      })
    }

  },
  beforeDestroy () {
    if(this.videoExternalPlayTimeOut){
      clearTimeout(this.videoExternalPlayTimeOut)
    }
    window.removeEventListener('resize', this.onWindowResize)
  }
}
</script>
