<template>
  <div
    v-if="showCard"
    class="pc-dashboard__card-habit-trackers"
  >
      <card-habits-list
      :isLoading="state === 'loading'"
      :habits-entries="todayEntry"
      @showInstructions="showInstructionsPopup"
      @header-click="navigateToHabitsTrackerHome()"
      @manage-click="navigateToHabitsTrackerManager()"
    />
    <popup-instructions
      v-if="instructionsPopup.show"
      :visible="instructionsPopup.show"
      @close="closePopup()"
    />
  </div>
</template>
<script>
import CardHabitsList from '../../../../global/cards/CardHabitsList'
import PopupInstructions from '../../habits-tracker/page-components/PopupInstructions'
import HabitsService from '../../../../../services/habits-tracker/HabitsTrackerService'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import CardContent from '@/components/global/cards/CardContent.vue';
export default {
  components: {
    CardHabitsList,
    PopupInstructions,
    LoadingBuffer,
    CardContent
  },
  data () {
    return {
      habitsEntriesDetails: [],
      instructionsPopup: {
        show: false
      },
      todayEntry: null,
      habitTracker: new HabitsService(),
      state: 'init'
    }
  },
  computed: {
    showCard: function () {
      return true// !(this.state === 'loaded' && (!this.todayEntry || (this.todayEntry && this.todayEntry.getEntries().length === 0)))
    }
  },
  beforeMount () {
    this.setHabits()
    console.log("CardDashboardHabitsTracker.vue");
    this.state = 'loading'
  },
  methods: {
    closePopup () {
      if (this.instructionsPopup.show) {
        this.instructionsPopup.show = false
      }
    },
    showInstructionsPopup () {
      this.instructionsPopup.show = true
    },
    setHabits () {
      this.habitTracker.setHabits().then((response) => {
        // showing preloader when user having one or more habits
        let habits = this.habitTracker.getHabits()
        if (Array.isArray(habits) && habits.length > 0) {
          this.state = 'loading'
        }
        this.habitsEntriesDetails = response.data
        this.setHabitEntries()
      }).catch(() => {
        this.state = 'loaded'
      })
    },
    setHabitEntries () {
      this.habitTracker.getTodayDayEntries().then(entries => {
        this.todayEntry = entries
      }).finally(() => {
        this.state = 'loaded'
      })
    },

    navigateToHabitsTrackerHome () {
      this.showLoading()
      // holding router so slick dom breaking is not visible before fade effect
      this.delayedRouterPush({
        path: '/dashboard',
        query: { page: 'habit-tracker' }
      })
    },

    navigateToHabitsTrackerManager () {
      this.showLoading()
      // holding router so slick dom breaking is not visible before fade effect
      this.delayedRouterPush({
        path: '/dashboard',
        query: { page: 'habit-tracker-manage' }
      })
    }
  }
}
</script>
