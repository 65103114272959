import LanguageService from '../../../../services/LanguageService'
import AuthService from '../../../../services/AuthService'
import { setMAApplicationData } from '@/helpers/dom/header'
import { clearAllCookies } from '@/helpers/dom/body'
import { enablePayPal } from '@/includes/TemplateSettings'
export default {
  data: function () {
    return {
      enablePayPal: false,
      baseImageURL : this.$appConfig.imagesUrl,
      showPaymentElements: true,
      hasOtherCurrencyPlan: false,
      state: 'init',
      isStripeElementReady: false,
      selectedPaymentMethod: 'card',
      paymentError: {
        code: '',
        message: ''
      },
      contactSupport: {
        popupShow: false
      },
      paymentGateways: null,
      hasSubscribed: false,
      shownPopupPlanExists: false,
      user: {
        email: '',
        name: ''
      },
      plan: {
        title: '',
        period: '-',
        price: 0.0,
        type: 'subscription',
        trialPeriod: 0,
        currency: '',
        status: 'loading',
        discountedPrice: 0,
        description: '',
        couponEnabled: false,
        checkoutType: '',
      },
      coupon: {
        applied: false,
        updatedPrice: null,
        discountDetails: null,
        isOneOff: false
      },
      formData: {
        name: '',
        cardNum: false,
        expDate: false,
        cvc: false,
        zip: '',
        coupon: ''
      },
      stripeElementErrors: {
        cardNum: [],
        expDate: [],
        cvc: []
      },
      subscriptionID: null,
      stripeKey: '',
      paymentMethods:[],
      instanceOptions: {
        locale: 'en'
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        locale: 'en'
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      cardOptions: {
        style: {
          base: {
            height: '159px',
            padding: '15px',
            borderRadius: '10px',
            border: '1px solid red',
            color: '#000',
            //    backgroundColor: '#0099f9',
            fontSize: '18px',
            fontWeight: '700',
            iconColor: '#fffff9',
            fontSmoothing: 'antialiased',
            ':hover': {
              border: '2px solid #012CFF',
              boxSizing: 'border-box'
            },
            ':focus': {
              color: '#495057'

            },
            ':-webkit-autofill': {
              color: '#000'
            },
            '::placeholder': {
              color: 'rgba(255,255,255,0.51)'
            }
          },
          invalid: {
            iconColor: '#fd0006',
            color: '#fd0006'
          }
        }
      },
      headerOptions: {
        show: false
      },
      introDescriptionText: ``,
      couponCodeValidity: false
    }
  },
  computed: {
    supportedPaymentMethods: function () {
      if (this.paymentGateways) {
        return Object.keys(this.paymentGateways.payment_methods)
      }

      return []
    },
    payButtonText: function () {
      if (this.state !== 'init' && this.state !== 'conformation-error') {
        return ''
      }

      let data = {
        currency: this.plan.currency,
        price: (this.coupon.applied) ? this.coupon.updatedPrice.toFixed(2) : this.plan.price.toFixed(2)
      }
      return this.$i18n.t('message["sign-up.payment.pay-button"]', data)
    },
    couponSuccessText: function () {
      return this.$i18n.t('message[\'sign-up.form.coupon-code-success\']', {
        currency: this.plan.currency,
        price: (this.plan.price - this.coupon.updatedPrice).toFixed(2),
        percentage: this.coupon.discountDetails
      })
    },
    hasStripeFormError: function () {
      return Object.values(this.stripeElementErrors).some(errors => {
        return errors.length > 0
      })
    }
  },
  watch: {
    selectedPaymentMethod: function () {
      this.isStripeElementReady = false
    }
  },
  methods: {
    setCustomerCurrencyMismatch(){
      this.hasOtherCurrencyPlan= true
    },
    afterSuccessPayPalPayment(){
      this.showPaymentElements =false
      this.plan.couponEnabled = false
    },
    async restartPayment () {
      this.showLoading()
      const authService = new AuthService()
      await authService.removeTokens()
      await fetch('/logout', {
        method: 'GET'
      })
      clearAllCookies()
      setTimeout(()=>{
        this.$router.replace({ path: this.$appConfig.appUrlList.signUp.replace(':plan', this.$route.params.plan) })
      },2000)
   },
    showPopupContactSupport () {
      this.contactSupport.popupShow = true
    },
    goToStripeCheckout () {
      setTimeout(()=>{
        this.service.getStripeCheckout().then(url => {
          window.location.replace(
            url
          );
        })
      },1500)


    },
    async setPlanData () {
      let data = await this.service.getPlanDetails()
      console.log('---plan----')
      console.log(data)
      this.paymentGateways = data.payment_gateways[0]
      this.stripeKey = this.paymentGateways['publishable_key']
      this.paymentMethods = this.paymentGateways['payment_methods']
      this.plan.currency = data.currency
      this.plan.period = data.billing_period
      this.plan.price = data.price
      this.plan.discountedPrice = data.price
      this.coupon.updatedPrice = data.price
      this.plan.title = data.plan_name
      this.plan.couponEnabled = data.coupon_enabled
      this.plan.type = data.plan_type
      this.plan.trialPeriod = data.trial_period
      this.plan.activationCodeRequired = data.activation_code_required

      if(typeof  data.contents['payment-page']['page-content']!== 'undefined'){
        this.plan.description = data.contents['payment-page']['page-content']
      }

      this.plan.trialPeriod = data.trial_period
      this.plan.checkoutType = data.checkout_type
      this.plan.status = 'default'

      setMAApplicationData('plan-id',this.plan.title)
      setMAApplicationData('plan-price',this.plan.price)
      setMAApplicationData('plan-currency',this.plan.currency)
      this.dispatchDOMContentLoaded()
    },
    setLang () {
      let lang = new LanguageService()

      this.elementsOptions.locale = lang.getLangForStripe()
      this.instanceOptions.locale = lang.getLangForStripe()
    },
    async getPaymentDetails () {
       await this.service.getPaymentGatewayDetails()
    },
    resetPaymentError () {
      this.paymentError = {
        code: '',
        message: ''
      }
    },
    pay () {
      // @deprecated
    },
    confirmPayment (token) {
      this.state = 'requested-conformation'
      let lang = new LanguageService()

      let stripe = Stripe(this.stripeKey, {
        locale: lang.getLangForStripe()
      })
      // request card conformation
      stripe.confirmCardPayment(token).then(data => {
        if (data.error) {
          // card conformation fail, canceled by user or stripe error
          this.state = 'conformation-error'
          this.paymentError.code = data.error.code
          this.paymentError.message = data.error.message
        } else {
          // card conformation success
          this.state = 'conformation-success'
          this.validateConfirmedPayment(data.paymentIntent.id)
        }
      }).catch(err => {
        this.state = 'conformation-error'
        console.log(err)
      })
    },
    validateConfirmedPayment (paymentIntentID) {
      // send success payment indent id to API
      this.service.confirmPaymentViaStripe(this.subscriptionID, paymentIntentID).then(() => {
        // success
        this.state = 'conformation-submitted'
        this.goToThankYouPage()
      }).catch(err => {
        this.state = 'conformation-submit-error'
        this.state = 'conformation-error'
        this.paymentError.code = err.error.code
        this.paymentError.message = err.error.message
      })
    },
    stripeElementChangeEvent (event) {
      // @deprecated
    },
    stripeElementReadyEvent () {
      this.isStripeElementReady = true
      this.hideLoading()
    },
    stripeElementBlurEvent (event) {
      if (event.elementType === 'cardCvc' && !this.formData.cvc && this.stripeElementErrors.cvc.length === 0) {
        this.stripeElementErrors.cvc = [
          (this.$i18n.t('message["validation.required"]')).replace(':attribute', this.$i18n.t('message[\'sign-up.form.card.cvc\']'))
        ]
      } else if (event.elementType === 'cardExpiry' && !this.formData.expDate && this.stripeElementErrors.expDate.length === 0) {
        this.stripeElementErrors.expDate = [
          (this.$i18n.t('message["validation.required"]')).replace(':attribute', this.$i18n.t('message[\'sign-up.form.card.expiration-date\']'))
        ]
      } else if (event.elementType === 'cardNumber' && !this.formData.cardNum && this.stripeElementErrors.cardNum.length === 0) {
        this.stripeElementErrors.cardNum = [
          (this.$i18n.t('message["validation.required"]')).replace(':attribute', this.$i18n.t('message[\'sign-up.form.card.card-number\']'))
        ]
      }
    },
    resetCoupon () {
      this.coupon.applied = false
      this.coupon.discountDetails = null
      this.coupon.updatedPrice = this.plan.price
      this.coupon.isOneOff = false
      if(this.$refs.form){
        this.$refs.form.setErrors({ coupon: [] })
      }

    },
    validateCoupon (input) {
      if (input) {
        try {
          // validate coupon
          this.service.validateCoupon(input).then((data) => {
            this.coupon.applied = data.success
            this.coupon.discountDetails = data.message
            this.coupon.updatedPrice = (data.price/100)
            this.couponCodeValidity = true
            this.coupon.isOneOff= data.is_oneoff || false
            this.$refs.couponForm.setErrors({ coupon: [] })
          }).catch((err) => {
            if (err.name && err.name === 'InvalidCouponError') {
              this.$refs.couponForm.setErrors({
                coupon: [
                  this.$i18n.t('message[\'sign-up.form.error-invalid-coupon\']')
                ]
              })
            } else if (err.name && err.name === 'ToManyCouponRequestsError') {
              this.$refs.couponForm.setErrors({
                coupon: [
                  this.$i18n.t('message[\'sign-up.form.error-to-many-coupon-request\']')
                ]
              })
            }
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    goToThankYouPage () {
      this.$router.push({ path: this.$appConfig.appUrlList.signUpThankYou.replace(':subscription', this.subscriptionID) })
    },
    checkCouponCodeValidity (code) {
      if (code.length > 0) {
        this.couponCodeValidity = true
      }
    },
    clearCouponCode () {
      this.resetCoupon()
      this.couponCodeValidity = false
      this.showPaymentElements = false
      this.$nextTick(() => {
        this.showPaymentElements = true
      })
    },
    isSupportPaymentMethod (payment) {
      return this.supportedPaymentMethods.includes(payment)
    },
    setCardElementStyles () {
      // set stripe elements style
      this.cardOptions.style = {
        base: {

          padding: '15px',
          color: templateConfig.style_variables['$text-primary-on-bg'],
          fontSize: '18px',
          fontWeight: '700',
          iconColor: templateConfig.style_variables['$text-primary-on-bg'],
          '::placeholder': {
            color: templateConfig.style_variables['$text-primary-on-bg'] + '77  '

          }
        },
        invalid: {
          iconColor: templateConfig.style_variables['$error-state'],
          color: templateConfig.style_variables['$text-primary-on-bg']
        }
      }
    },
    setPaymentMethod (id) {
      this.selectedPaymentMethod = id
    }
  }

}
