<template>
  <div class="gc-page__content-image" v-on="$listeners" v-if="contentImage" :style="{ backgroundImage: `url(${contentImage})` }">
    <!-- Main centered image -->
    <img :src="contentImage" alt="Main Image" class="gc-page__main-image" />
  </div>
</template>


<script>
export default {
  name: 'PageContentImage',
  props: {
    contentImage: {
      type: String,
      required: false
    }
  }
}
</script>



