import DefineInclude from '../DefineInclude'
import customConfig from '../../config/custom-config'
import jquery from 'jquery'
const _assetsUrl = DefineInclude.assetsUrl

let assets = [

  {
    location: 'head',
    asset_type: 'script',
    path: _assetsUrl + 'js/select2.full.min.js',
    type: ''

  }, {
    location: 'body',
    asset_type: 'script',
    path: _assetsUrl + 'js/bootstrap-4.4.1/popper.min.js',
    type: ''

  }, {
    location: 'body',
    asset_type: 'script',
    path: _assetsUrl + 'js/bootstrap-4.4.1/bootstrap.min.js',
    type: ''

  }, {
    'location': 'body',
    'asset_type': 'script',
    'path': 'https://wurfl.io/wurfl.js',
    'type': ''
  }
]

function addCSSFromToning () {
  if (typeof templateConfig !== 'undefined' & typeof templateConfig.css !== 'undefined') {
    templateConfig.css.forEach(css => {
      assets.push({
        location: 'head',
        asset_type: 'style',
        path: css
      })
    })
  }
}

function addRawCssFromToning () {
  if (typeof templateConfig !== 'undefined' & typeof templateConfig.raw_css !== 'undefined') {
    const style = document.createElement('style')
    style.setAttribute('id', 'injectedCss')
    style.textContent = templateConfig.raw_css
    document.head.appendChild(style)
  }
}

function appendLocalAsserts () {
  const localAsserts = [{
    location: 'body',
    asset_type: 'script',
    path: 'https://7fd96fc5afe48927ee5d-9adb70e6f12dc0583b323415794abce3.ssl.cf1.rackcdn.com/analytics/analytics-dev-latest-min.js',
    type: ''
  }, {
    location: 'head',
    asset_type: 'style',
    path: _assetsUrl + 'css/main-combined.css'

  },
    {
      location: 'head',
      asset_type: 'style',
      path: _assetsUrl + 'css/bootstrap-4.4.1/bootstrap.min.css'

    }, {
      location: 'head',
      asset_type: 'style',
      path: '//vjs.zencdn.net/5.4.6/video-js.min.css'

    }]

  assets = [...localAsserts, ...assets]
}

function appendDemoLocalAsserts () {
  const localAsserts = [
    {
      location: 'head',
      asset_type: 'style',
      path: _assetsUrl + 'css/bootstrap-4.4.1/bootstrap.min.css'

    }, {
      location: 'head',
      asset_type: 'style',
      path: '//vjs.zencdn.net/5.4.6/video-js.min.css'

    }]

  assets = [...localAsserts, ...assets]
}

function addDNSPrefetch () {

  // Create the link element
  let linkElement = document.createElement('link')

  // Set the rel attribute
  linkElement.setAttribute('rel', 'dns-prefetch')

  // Set the href attribute
  linkElement.setAttribute('href', 'https://food-diary.macroactivemvp.com/')

  // Append the link element to the head of the document
  document.head.appendChild(linkElement)
}

export { assets }

export default function () {
  addDNSPrefetch()
  if (customConfig.asserts.toning_css_injection && !customConfig.asserts.demo_assets) {
    addCSSFromToning()
  } else if (!customConfig.asserts.toning_css_injection && customConfig.asserts.demo_assets) {
    addRawCssFromToning()
    appendDemoLocalAsserts()
  } else {
    appendLocalAsserts()
  }
  $('link[href*="css"]').not('link[href*="olaf-hide.css"]').remove()
  assets.forEach(asset => {
    // TODO - remove the stripe js temp
    if (asset.asset_type === 'script' && !asset.path.includes('js.stripe.com')) {
      jquery(asset.location).append('<script src="' + asset.path + '"></script>')
    } else if (asset.asset_type === 'style') {
      jquery(asset.location).prepend('<link type="text/css" rel="stylesheet" href="' + asset.path + '" />')
    }
  })
  const event = new CustomEvent('new-app-assets-added', { detail: { assets: assets } })
  document.dispatchEvent(event)
  communityAssetsPreload()
}



function communityAssetsPreload(){

  const assets = {
    scripts: [],
    links: [
      {
        rel: "modulepreload",
        crossorigin: true,
        href: DefineInclude.communityAssetURL+"index.js"
      }
    ]
  };

  assets.scripts.forEach(script => {
    const scriptEl = document.createElement("script");
    Object.assign(scriptEl, script);
    document.head.prepend(scriptEl);
  });

  assets.links.forEach(link => {
    const linkEl = document.createElement("link");
    Object.assign(linkEl, link);
    document.head.prepend(linkEl);
  });


}
