import ContentPageApi from './api/content-page/ContentPageApi'
import ContentPageFactoryService from './ContentPageFactoryService'

export default class ContentRecipeService{

  _sequence = 0;

  _name = '';
  _ingredients = '';
  _instructions = '';
  _macronutrients = null;
  _calories = 0;
  _servings = 0;
  _prepTime = 0;
  _mealTypes = [];
  _tags = [];
  _images = [];

  constructor({
    sequence,
    name,
    ingredients,
    instructions,
    single_serving_macronutrients,
    single_serving_calories,
    number_of_servings,
    preparation_time,
    meal_types,
    tags,
    images
  }) {
    this._sequence = sequence;
    this._name = name;
    this._ingredients = ingredients;
    this._instructions = instructions;
    this._macronutrients = single_serving_macronutrients;
    this._calories = single_serving_calories;
    this._servings = number_of_servings;
    this._prepTime = preparation_time;
    this._mealTypes = meal_types;
    this._tags = tags;
    this._images = images;
  }

  // Getters for Recipe properties
  get sequence() {
    return this._sequence;
  }

  get name() {
    return this._name;
  }

  get ingredients() {
    return this._ingredients;
  }

  get instructions() {
    return this._instructions;
  }

  get macronutrients() {
    return this._macronutrients;
  }

  get calories() {
    return this._calories;
  }

  get servings() {
    return this._servings;
  }

  get prepTime() {
    return this._prepTime;
  }

  get mealTypes() {
    return this._mealTypes;
  }

  get tags() {
    return this._tags;
  }

  get images() {
    return this._images;
  }
}
