<template>
  <page :header="headerOptions" class="gc-page-add-photos">
    <page-container>
      <div class="gc-page-add-photos__header-wrapper">
        <text-heading4 class="gc-page-add-photos__title" :weight="'extra-bold'" :lineHeight="'multi'">
          {{$t('message["fitness-diary.add-photos"]')}}<br>
          {{
            optionalText
          }}
        </text-heading4>
        <text-body-extra-small class="gc-page-add-photos__sub-title" :weight="'extra-bold'" :lineHeight="'multi'">
          {{$t('message["fitness-diary.add-photos.sub"]')}}
        </text-body-extra-small>
        <text-body-extra-small  class="gc-page-add-photos__error-message" v-if="hasError">{{ errorMessage }}</text-body-extra-small>
      </div>
      <div class="gc-page-add-photos__inputs-wrapper">

        <div class="gc-page-add-photos__input-wrapper">
          <text-body-extra-small class="gc-page-add-photos__label">
            {{ $t('message[\'fitness-diary.front-image\']') }}
          </text-body-extra-small>
          <div class="gc-page-add-photos__input">
            <fitness-image-image-upload
              :image-name="'front_image'"
              :image-object="service().images['front-image']"
              :is-private="isPrivate"
              :ready-to-upload="readyToUpload&&startUpload['front-image']"
              v-on:error-upload="errorUpload('front-image',$event)"
              v-on:invalid-upload="invalidImage('front-image',$event)"
              v-on:remove-upload="removeUpload('front-image')"
              v-on:to-upload="toUploadedImage('front-image')"
              v-on:uploaded="uploadedImage('front-image')"
              @enable-submit="enableImageSubmit"
              @disable-submit="disableImageSubmit"
            />
          </div>
        </div>

        <div class="gc-page-add-photos__input-wrapper">
          <text-body-extra-small class="gc-page-add-photos__label">
            {{ $t('message[\'fitness-diary.side-image\']') }}
          </text-body-extra-small>
          <div class="gc-page-add-photos__input">
            <fitness-image-image-upload
              :image-name="'side_image'"
              :image-object="service().images['side-image']"
              :is-private="isPrivate"
              :ready-to-upload="readyToUpload&&startUpload['side-image']"
              v-on:error-upload="errorUpload('side-image',$event)"
              v-on:invalid-upload="invalidImage('side-image',$event)"
              v-on:remove-upload="removeUpload('side-image')"
              v-on:to-upload="toUploadedImage('side-image')"
              v-on:uploaded="uploadedImage('side-image')"
              @enable-submit="enableImageSubmit"
              @disable-submit="disableImageSubmit"
            />
          </div>
        </div>

        <div class="gc-page-add-photos__input-wrapper">
          <text-body-extra-small class="gc-page-add-photos__label">
            {{ $t('message[\'fitness-diary.back-image\']') }}
          </text-body-extra-small>
          <div class="gc-page-add-photos__input">
            <fitness-image-image-upload
              :image-name="'back_image'"
              :image-object="service().images['back-image']"
              :is-private="isPrivate"
              :ready-to-upload="readyToUpload&&startUpload['back-image']"
              v-on:error-upload="errorUpload('back-image',$event)"
              v-on:invalid-upload="invalidImage('back-image',$event)"
              v-on:remove-upload="removeUpload('back-image')"
              v-on:to-upload="toUploadedImage('back-image')"
              v-on:uploaded="uploadedImage('back-image')"
              @enable-submit="enableImageSubmit"
              @disable-submit="disableImageSubmit"
            />
          </div>
        </div>
      </div>
      <div class="gc-page-add-photos__private-check" v-if="canUploadPublic&&!readyToUpload">
        <div class="gc-page-add-photos__private-check-input" v-if="showTeamSeeImagesText()">
          <div class="gc-page-add-photos__private-check-checkbox">
            <input-radio-button-squared :selected="isSetPrivate" @click="changeIsSetPrivate"></input-radio-button-squared>
          </div>
        </div>
        <div>
          <text-body-extra-small
            class="gc-page-add-photos__private-check-text"
            v-if="showTeamSeeImagesText()">{{$t('message["fitness-diary.let-team-see-check"]', [trainerEmail])}}
          </text-body-extra-small>
          <div
            class="gc-page-add-photos__helper-team"
            v-if="hasImageToUpload()&&!readyToUpload && isSetPrivate && showTeamSeeImagesText()">
            <text-body-extra-small>
              {{ $t('message["fitness-diary.team-see-images"]', [trainerEmail]) }}
            </text-body-extra-small>
          </div>
          <div
            class="gc-page-add-photos__helper-only-you"
            v-if="hasImageToUpload()&&!readyToUpload && !isSetPrivate && showTeamSeeImagesText()">
            <text-body-extra-small>
              {{ $t('message["fitness-diary.you-see-images"]', [trainerEmail]) }}
            </text-body-extra-small>
          </div>
        </div>
      </div>

      <div class="gc-page-add-photos__upload-later" v-if="!hasImageToUpload() || hasError">
        <button-link :text="$t('message[\'general.skip-for-now\']')" weight="light"
                     @click="nextComponent()"></button-link>
      </div>
    </page-container>
    <page-footer :hideReturnButton="true" @click="upload" :validated="!uploadReady || !imageSubmit" :text="$t('message[\'general.upload\']')"></page-footer>
  </page>
</template>

<script>
import {flagValue} from '@/includes/TemplateSettings'
import UserEventsService from '../../../services/UserEventsService'
import FitnessImageImageUpload from '../inputs/InputImageUpload'
import ProfileService from '../../../services/ProfileService'
import {mapGetters} from 'vuex'
import FitnessDiaryStore from '../../../store/modules/FitnessDiaryStore'
import BaseComponent from '../../layout/template-1/global/widgets/forms/FormBaseComponent'
import TextHeading3 from '../typography/TextHeading3'
import TextBodySmall from '../typography/TextBodySmall'
import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonLinkSecondary from '../buttons/ButtonLinkSecondary'
import ButtonLink from '../buttons/ButtonLink'
import ButtonStandard from '../buttons/ButtonStandard'
import TextBodyExtraSmall from '../typography/TextBodyExtraSmall'
import TextContent from '../../root/TextContent'
import Page from './PageForm'
import PageContainer from './page-sub-components/PageContainer'
import TextHeading4 from '../typography/TextHeading4'
import InputRadioButtonSquared from '../inputs/InputRadioButtonSquared'
import PageFooter from './page-sub-components/PageFormFooter'

export default {
  name: 'AddPhotos',
  extends: BaseComponent,
  props: {
    backButton: {
      default: true
    }
  },
  data () {
    return {
      imageSubmit: true,
      uploadErrorMessage: this.$i18n.t('message["fitness-diary.error-upload"]'),
      trainerEmail: '',
      hasAlreadyUploadedImages: false,
      hasErrorUpload: false,
      isImagesUploaded: false,
      readyToUpload: false,
      isSetPrivate: false,
      imagesUrl: this.$appConfig.imagesUrl,
      success: {
        image: this.$appConfig.imagesUrl + 'swap-success.svg',
        status: false
      },
      canUploadPublic: false,
      submited: false,
      isUploaded: {
        'front-image': false,
        'side-image': false,
        'back-image': false
      },
      invalidImages: {
        'front-image': '',
        'side-image': '',
        'back-image': ''
      },
      toUploaded: {
        'front-image': false,
        'side-image': false,
        'back-image': false
      },
      startUpload: {
        'front-image': false,
        'side-image': false,
        'back-image': false
      },
      validationErrorMsg: null,
      headerOptions: {
        show: false,
        left: 'previous',
        right: '',
        modifiers: ['with-img'],
        mainTitle: '',
        subTitle: '',
        isTransparent: true
      }
    }
  },
  components: {
    PageFooter,
    InputRadioButtonSquared,
    TextHeading4,
    PageContainer,
    Page,
    FitnessImageImageUpload,
    TextHeading3,
    TextBodySmall,
    ButtonPrimary,
    ButtonLinkSecondary,
    TextBodyExtraSmall,
    TextContent,
    ButtonStandard,
    ButtonLink
  },
  computed: {
    optionalText: function () {
      if(this.$t('message[\'general.form.optional-with-brackets\']') !== '(Optional)'){
        return this.$t('message[\'general.form.optional-with-brackets\']')
      }

      return '('+this.$t('message[\'general.form.optional\']')+')'
    },
    hasInvalidImage () {
      return Object.values(this.invalidImages).some(msg => {
        return Boolean(msg)
      })
    },
    isPrivate () {
      return !this.isSetPrivate ? 1 : 0
    },
    invalidImageError () {
      return Object.values(this.invalidImages).find(msg => {
        return Boolean(msg)
      })
    },
    uploadReady () {
      return !this.success.status && this.hasImageToUpload() && !this.readyToUpload
    },
    hasError () {
      return this.hasInvalidImage || this.hasErrorUpload || this.validationErrorMsg
    },
    errorMessage () {
      if (this.hasError) {
        return this.invalidImageError || this.validationErrorMsg || this.uploadErrorMessage
      } else {
        return ''
      }
    }
  },
  beforeCreate () {
    const store = this.$store
    if (!(store && store.state && store.state.fitnessDiaryStore)) {
      store.registerModule('fitnessDiaryStore', FitnessDiaryStore)
    }
  },
  mounted () {
    this.service()
      .getDataFromAPI()
      .finally(() => {
        let pService = new ProfileService()
        pService.setProfileData().then(() => {
          if (pService.getAge() >= 18) {
            this.canUploadPublic = true
          }
          this.trainerEmail = pService.trainer.brand_name || ''
        })
        this.hideLoading()
      })
  },
  methods: {
    ...mapGetters({
      service: 'fitnessDiaryStore/getService'
    }),
    navigatePrevious () {
      this.$router.go(-2)
    },
    changeIsSetPrivate () {
      if (this.isSetPrivate) {
        this.isSetPrivate = false
      } else {
        this.isSetPrivate = true
      }
    },
    upload () {
      this.hasErrorUpload = false
      if (!this.toUploaded['front-image']) {
        this.validationErrorMsg = this.$i18n.t('message["fitness-diary.front-image-validation"]')
      } else {
        this.readyToUpload = this.hasImageToUpload()
        this.setNextImageToUpload()
      }
    },
    setNextImageToUpload () {
      let imageType = Object.keys(this.toUploaded).find((type) => {
        return this.isImageWaitingUpload(type)
      })
      this.startUpload[imageType] = true
    },
    isImageWaitingUpload (type) {
      return this.toUploaded[type] && !this.isUploaded[type]
    },
    hasImageToUpload () {
      return Object.values(this.toUploaded).some((ele) => {
        return ele
      })
    },
    uploadedImageCount () {
      const images = Object.keys(this.toUploaded).filter((type) => {
        return this.toUploaded[type]
      })
      return images.length
    },
    hasUploadedAll () {
      return Object.keys(this.toUploaded).every((type) => {
        return this.toUploaded[type] === this.isUploaded[type]
      })
    },
    showTeamSeeImagesText () {
      return !flagValue('enable_fitness_diary_only_private_photo_upload', false)
    },
    toUploadedImage (imageType) {
      this.validationErrorMsg = null
      this.toUploaded[imageType] = true
      this.invalidImages[imageType] = ''
    },
    errorUpload (imageType, error) {
      this.uploadErrorMessage = error
      this.hasErrorUpload = true
      this.readyToUpload = false
      this.toUploaded[imageType] = true
    },
    invalidImage (type, message) {
      this.invalidImages[type] = message
    },
    removeUpload (imageType) {
      this.validationErrorMsg = null
      this.toUploaded[imageType] = false
    },
    uploadedImage (imageType) {
      this.isUploaded[imageType] = true
      if (this.hasUploadedAll() && !this.hasErrorUpload) {
        this.success.status = true
        this.isImagesUploaded = true
        let context = 'onboard'
        if (this.$route.name === 'MealPlanUpdateMain') {
          context = 'meal-progress-update'
        } else if (this.$route.name === 'WorkoutUpdateMain') {
          context = 'workout-progress-update'
        }
        this.logEvent('Journey.AddedPhoto', {
          'no_of_photos ': this.uploadedImageCount(),
          'private': this.isPrivate,
          'context': context
        })
        setTimeout(() => {
          this.$emit('uploaded', {
            'no_of_photos ': this.uploadedImageCount(),
            'private': this.isPrivate,
            'context': context,
            isUploaded: this.isUploaded
          })
          if (flagValue('fd_feedback_popup_show')) {
            UserEventsService.sendFitnessDiaryFeedBackPopupView()
          }
        }, 1500)
        this.nextComponent()
      } else if (!this.hasUploadedAll() && !this.hasErrorUpload) {
        this.setNextImageToUpload()
      }
    },
    goBack () {
      this.$emit('back')
    },
    enableImageSubmit () {
      this.imageSubmit = true
    },
    disableImageSubmit () {
      this.imageSubmit = false
    }
  }
}
</script>

<style scoped>

</style>
