import { render, staticRenderFns } from "./PagePhotoJourney.vue?vue&type=template&id=127b1888&scoped=true"
import script from "./PagePhotoJourney.vue?vue&type=script&lang=js"
export * from "./PagePhotoJourney.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127b1888",
  null
  
)

export default component.exports