<template>
    <div class="pc-container-custom-video-slider">
        <slider-video v-if="slides.length > 0" :slider-title="title" :route="route" :slidess="slides" :showSeeMore="showMore"/>
    </div>
</template>
<script>
import SliderVideo from '../../../../global/sliders/SliderVideo'
import lodash from 'lodash'
export default {
    name: 'ContainerCustomVideoSlider',
    props: {
        sliderConfig: {
            type: Object,
            default: (() => {
                return {}
            })
        },
        startDate: {
            type: Number,
            required: true
        }
    },
    mounted () {
        this.filterSliders()
    },
    components: {
        SliderVideo
    },
    data () {
        return {
            slides: [],
            route: '',
            title: '',
            showMore: false,
            convert: {
                m: 60000,
                h: 3600000,
                d: 86400000,
                w: 604800000,
                y: 31536000000
            }
        }
    },
    methods: {
        filterSliders () {
            const videosWithoutDelay = this.filterVideos(this.sliderConfig.items)
            this.showMore = videosWithoutDelay.videos.length > 50 ? true : false
            this.slides = videosWithoutDelay.videos.slice(0, 50)
            this.route = this.sliderConfig.page.route
            this.title = this.sliderConfig.page.title
        },
        filterVideos (videos) {
            let noDelayTags = []
            const filteredVideos = videos.filter((video) => {
                let show = true
                const tags = video.tags
                if (tags) {
                tags.forEach((tag, j) => {
                    const tdelay = tags[j].match(/^delay(\d+)(m|h|d|w|y)$/)
                    if (tdelay) {
                    show = show && this.isVideoVisibleToUser( tdelay)
                    } else {

                    noDelayTags.push({ id: tag, text: tag })

                    }
                })
                }

                return show
            })
            return { videos: filteredVideos, tags: lodash.uniqBy(noDelayTags,'id') }
        },

        isVideoVisibleToUser ( tdelay) {
            const amt = tdelay[1]
            const period = tdelay[2]
            let now = new Date()
            if (period === 'd' || period === 'w' || period === 'y') {
                now.setHours(0, 0, 0, 0)
                now = now.getTime()
            } else {
                now = now.getTime()
            }
            const compare = now - amt * this.convert[period]
            return (compare > this.startDate)

        },
    }
}
</script>
