<template>
  <popup
    modal-class="gc-popup-fd-custom-meal-log"
    :visible="true"
    @hide="close"
  >
    <popup-title>
      <popup-button-close @click="close" />
    </popup-title>
    <popup-body>
      <popup-content>
        <popup-head>
          <popup-text-heading
            v-if="state!=='submitting'"
            class="gc-popup-fd-custom-meal-log__title"
          >
            {{ title }}
          </popup-text-heading>
        </popup-head>
        <div v-if="state!=='submitting'">
          <popup-text-body
            v-if="description"
            class="gc-popup-fd-custom-meal-log__description"
          >
            <div v-html="description" />
          </popup-text-body>
          <calendar-slider
            v-if="dates.length>0 && showSlider"
            :dates="dates"
            :has-more="hasMoreLogs"
            :max-slides="7"
            :last-selected-day="currentSelectedDay"
            class="gc-popup-fd-custom-meal-log__slider"
            @load-more="addMoreDays()"
            @selected="selectedDate($event)"
          />
          <loading-buffer
            v-else-if="state !== 'submitted'&&(!showSlider||dates.length===0)"
            height="100"
          />
          <text-body-small2
            v-if="state==='init'"
            class="gc-popup-fd-custom-meal-log__text-date"
            line-height="lg"
            weight="extra-bold"
          >
            {{ logDate }}
          </text-body-small2>
        </div>
        <div v-else>
          <loading-buffer height="100" />
        </div>
      </popup-content>
      <popup-footer>
        <button-primary
          v-if="state==='init'||state==='loading'"
          :disabled="state==='loading'"
          :text="$t('message[\'custom-meal-log.add-to-food-diary\']')"
          @click="logMeal"
        />
        <button-primary
          v-if="state==='submitted'"
          class="gc-popup-fd-custom-meal-log__button-back"
          :text="$t('message[\'general.go-back\']')"
          @click="close"
        />
        <button-secondary
          v-if="state==='submitted'"
          :text="$t('message[\'custom-meal-log.goto-food-diary\']')"
          @click="goToFoodDiary"
        />
      </popup-footer>
    </popup-body>
  </popup>
</template>

<script>
import Popup from '../../../../global/popups/Popup'
import PopupTitle from '../../../../global/popups/popup-sub-components/PopupTitle'
import PopupBody from '../../../../global/popups/popup-sub-components/PopupBody'
import PopupContent from '../../../../global/popups/popup-sub-components/PopupContent'
import NutritionService from '../../../../../services/nutrition/NutritionService'
import CalendarSlider from '../../../../global/sliders/CalendarSlider'
import lodash from 'lodash'
import moment from 'moment'
import LanguageService from '../../../../../services/LanguageService'
import { mapGetters, mapMutations } from 'vuex'
import FoodDiaryService from '../../../../../services/FoodDiaryService'
import DashboardService from '../../../../../services/DashboardService'
import ButtonPrimary from '../../../../global/buttons/ButtonPrimary'
import PopupHead from '../../../../global/popups/popup-sub-components/PopupHead'
import PopupTextHeading from '../../../../global/popups/popup-sub-components/PopupTextHeading'
import PopupTextBody from '../../../../global/popups/popup-sub-components/PopupTextBody'
import PopupFooter from '../../../../global/popups/popup-sub-components/PopupFooter'
import TextBodyMedium from '../../../../global/typography/TextBodyMedium'
import TextBodyRegular from '../../../../global/typography/TextBodyRegular'
import TextBodySmall from '../../../../global/typography/TextBodySmall'
import LoadingBuffer from '../../global/widgets/LoadingBuffer'
import PopupButtonClose from '../../../../global/popups/popup-sub-components/PopupButtonClose'
import ButtonSecondary from '../../../../global/buttons/ButtonSecondary'
import FoodDiarySavedMealsService from '../../../../../services/FoodDiarySavedMealsService'
import TextBodySmall2 from '../../../template-2/global/typography/TextBodySmall2'
import popupAnimationMixin from '../../../../../mixins/popupAnimationMixin'

export default {
  name: 'PopupCustomMealLog',
  components: {
    TextBodySmall2,
    ButtonSecondary,
    PopupButtonClose,
    LoadingBuffer,
    TextBodySmall,
    TextBodyRegular,
    TextBodyMedium,
    PopupFooter,
    PopupTextBody,
    PopupTextHeading,
    PopupHead,
    ButtonPrimary,
    CalendarSlider,
    PopupContent,
    PopupBody,
    PopupTitle,
    Popup
  },
  mixins: [popupAnimationMixin],
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      showSlider: false,
      currentDayLog: null,
      logs: [],
      state: 'loading',
      hasMoreLogs: true,
      mealPlan: null,
      currentSelectedDay: null,
      registeredDate: null,
      dates: [],
      service: new FoodDiaryService()
    }
  },
  computed: {
    title: function () {
      if (this.state === 'submitted') {
        return this.$i18n.t('message["food-diary.meal-logged-success"]')
      }
      return this.$i18n.t('message["custom-meal-log.title"]')
    },
    description: function () {
      if (this.state === 'submitted') {
        return this.$i18n.t('message["custom-meal-log.success-description"]', { meal: '<strong>' + this.getCustomMeal().name + '</strong>', day: '<strong>' + this.logDate + '</strong>' })
      }
      return ''
    },
    logDate: function () {
      if (this.currentDayLog && this.currentDayLog.logged_date) {
        return moment(this.currentDayLog.logged_date).format('ll')
      }

      return ''
    }
  },
  beforeMount () {
    let dashboard = new DashboardService()
    this.registeredDate = dashboard.getRegisteredDate()
  },
  mounted () {
    this.loadMealPlan()
  },
  methods: {
    ...mapMutations({
      setTodayLog: 'foodDiaryStore/setTodayLog',
      hidePopup: 'customMealLogPopupStore/hidePopup',
      setPaginationData: 'foodDiaryStore/setPaginationData',
      setLastSelectedDay: 'foodDiaryStore/setLastSelectedDay',
      setEditableMealLog: 'foodDiaryStore/setEditableMealLog'
    }),
    ...mapGetters({
      getCustomMeal: 'customMealLogPopupStore/getCustomMeal',
      getUserLogContext: 'customMealLogPopupStore/getUserLogContext',
      getPaginationCurrentPage: 'foodDiaryStore/getPaginationCurrentPage',
      getCurrentDays: 'foodDiaryStore/getCurrentDays',
      getCurrentLogs: 'foodDiaryStore/getCurrentLogs',
      getHasMoreLogs: 'foodDiaryStore/getHasMoreLogs',
      getLastSelectedDate: 'foodDiaryStore/getLastSelectedDate'
    }),
    async loadMealPlan () {
      const nutrition = new NutritionService()
      await nutrition.init()
      this.mealPlan = nutrition.getLatestMealPlan()
      await this.mealPlan.getData()
      this.state = 'init'
      this.loadLogs()
    },
    loadLogs () {
      if (this.hasMoreLogs && this.state !== 'loading') {
        this.$nextTick(() => {
          this.state = 'loading'
          let paginateData = {
            page: (this.currentPage + 1)
          }
          this.service.getPaginateData(paginateData).then(data => {
            if (!data.data.logs) {
              this.logs = []
            } else {
              this.hasMoreLogs = data.data.has_more_pages || false
              this.currentPage = data.data.current_page || this.currentPage
              let lastLog = lodash.last(data.data.logs)
              let lastLogDate = moment().locale('en').format('YYYY-MM-DD')
              if (lastLog) {
                lastLogDate = lastLog.logged_date
              }
              if (!this.hasMoreLogs) {
                lastLogDate = this.registeredDate.locale('en').format('YYYY-MM-DD')
                if (this.currentPage === 1 && moment().subtract(2, 'months').diff(this.registeredDate, 'days') > 0) {
                  lastLogDate = moment().subtract(2, 'months').locale('en').format('YYYY-MM-DD')
                }
              }
              this.logs = lodash.concat(this.logs, data.data.logs)
              let lastDate = (this.dates.length > 0) ? lodash.first(this.dates).date : moment().add(7 * 6, 'days').locale('en').format('YYYY-MM-DD')
              let dates = []
              for (let i = 0; moment(lastLogDate).locale('en').add(i, 'days').diff(moment(lastDate).locale('en'), 'days') <= 0; ++i) {
                let cDay = moment(lastLogDate).locale('en').add(i, 'days')
                let currentFormattedDate = cDay.locale('en').format('YYYY-MM-DD')
                let currentLog = this.logs.find(log => {
                  return log.logged_date === currentFormattedDate
                })
                let languageService = new LanguageService()
                dates.push({
                  month: cDay.locale(languageService.setAppLangCode()).format('MMM'),
                  day: cDay.locale(languageService.setAppLangCode()).format('DD'),
                  date: currentFormattedDate,
                  id: (currentLog) ? currentLog._id : '',
                  logged: !!(currentLog && currentLog.logged_meals && currentLog.logged_meals.length > 0)
                })
              }

              dates = lodash.concat(dates, this.dates)
              this.dates = lodash.uniqBy(dates, 'date')
              this.setPaginationData({
                paginationCurrentPage: this.currentPage,
                currentDays: this.dates,
                currentLogs: this.logs,
                hasMoreLogs: this.hasMoreLogs
              })
            }
          }).catch((err) => {
            this.underMaintenance = (err.response.status === 504 || err.response.status === 503)
            if (this.underMaintenance) {
              this.headerOptions.right = ''
            }
            this.hasMoreLogs = false
          }).finally(() => {
            this.state = 'init'
            if (this.hasMoreLogs && this.dates.length < 8 * 7) {
              this.addMoreDays()
            } else {
              this.showSlider = true
            }
            this.hideLoading()
          })
        })
      }
    },
    addMoreDays () {
      this.loadLogs()
    },
    async logMeal () {
      this.state = 'submitting'
      let fd = new FoodDiaryService()
      let savedRecipes = []
      const recipePromises = []
      const customMeal = this.getCustomMeal()
      for (const recipe of customMeal.recipes) {
        recipe.macronutrients = Object.assign({
          fats: 0,
          carbohydrates: 0,
          show_net_carbs: false,
          proteins: 0,
          net_carbohydrates: 0

        }, recipe.macronutrients)
        let cleanedRecipe =
        Object.assign({
          name: 'recipe',
          image: null,
          calories: 1,
          saved: false,
          macronutrients: {
          }
        }, recipe)
        recipePromises.push(fd.saveRecipe(cleanedRecipe))
      }
      const promiseResult = await Promise.all(recipePromises)
      for (const savedRecipe of promiseResult) {
        savedRecipes.push(savedRecipe.recipe)
      }
      let mealData = {
        name: customMeal.name,
        saved: customMeal.saved,
        type: {
          id: 9999, // to hide the meal type
          name: this.$i18n.t('message[\'food-diary.new-meal\']')
        },
        recipes: savedRecipes
      }
      const mealResponse = await fd.saveMeal(mealData)

      this.currentDayLog.logged_meals.push({
        id: mealResponse.meal._id,
        time: moment().locale('en').format('HH:mm'),
        date: moment().locale('en').format('YYYY-MM-DD'),
        mealType: 'custom'
      })

      const savedLog = await fd.saveMealLog(this.currentDayLog)
      this.currentDayLog = savedLog.log
      this.state = 'submitted'
      this.showSlider = false
      this.logs = this.logs.map(log => {
        if (log.logged_date === savedLog.log.logged_date) {
          return savedLog.log
        }

        return log
      })

      this.dates = this.dates.map(date => {
        if (date.date === savedLog.log.logged_date) {
          date.logged = true
          date.id = savedLog.log._id
          this.setLastSelectedDay(date)
        }
        return date
      })
      let isToday= false
      if (this.currentDayLog.logged_date === moment().locale('en').format('YYYY-MM-DD')) {
        this.setTodayLog(this.currentDayLog)
        isToday= true
      }
      this.setPaginationData({
        paginationCurrentPage: this.currentPage,
        currentDays: this.dates,
        currentLogs: this.logs,
        hasMoreLogs: this.hasMoreLogs
      })
      const fDSavedMeals = new FoodDiarySavedMealsService()
      fDSavedMeals.reset()
      this.logEvent('FoodDiary.MealLogged',{
        context: this.getUserLogContext(),
        page: this.$route.fullPath,
        recipe_name: customMeal.name,
        selected_option:  null,
        is_today: isToday,
        is_saved: customMeal.saved || false,
        is_custom: true
      })
    },
    selectedDate (e) {
      this.currentSelectedDay = e
      if (e.date) {
        const log = this.logs.find(cLog => {
          return cLog.logged_date === e.date
        })
        if (log) {
          this.currentDayLog = log
        } else {
          let fd = new FoodDiaryService()
          const newLog = fd.emptyLog()
          newLog.logged_date = moment(e.date).locale('en').format('YYYY-MM-DD')
          newLog.required.calories = this.mealPlan.getAverageCaloriesOfDays()
          newLog.required.macronutrients = this.mealPlan.getAverageMacroNutrientsOfDays()

          this.currentDayLog = newLog
        }
        this.setLastSelectedDay(this.currentSelectedDay)
      }
    },
    goToFoodDiary () {
      this.$router.push({ path: '/meal-plans?page=food-diary' })
      this.hidePopup()
    },
    close () {
      this.closeAnimatedPopup(() => {
        this.hidePopup()
        this.$emit('close')
      })
    }

  }

}
</script>

<style scoped>

</style>
